import { LetterRunStatusEnums } from "../constants";
import { getFileData, getFileById } from "../fetch";
import { base64ToArrayBuffer } from "../helpers";

export interface IFile {
  fileId?: number;
  name: string;
  fileType: string;
  dateCreated?: string;
  data: null | string;
}

export interface IResponseFile {
  fileId?: number;
  name: string;
  fileType: string;
  dateCreated?: string;
}

export interface IFileObject {
  requirementFileId?: number;
  requirementFileStatus?: string;
  requirementId?: number;
  monitoredEntityId: number;
  file: IFile | IResponseFile;
}

export interface IRequirementFilesResponseItem {
  monitoredEntityFileId: number;
  requirementFileStatus: number;
  file: IFile;
}

export interface IRequirementFileResponse {
  [index: number]: IRequirementFilesResponseItem;
}

export interface IMonitoredEntityFile {
  monitoredEntityFileId?: number;
  fileStatus: number;
  file?: IFile;
  monitoredEntityId: number;
  lineOfCoverageIds: number[];
  expirationDate?: string;
  documentDate?: string;
  user?: string;
}

export interface IMonitoredEntityFileDelete {
  monitoredEntityFileId: number;
  deleteAllEntitiesFromFile: boolean;
}

export interface ILetterRunDetailToMonitoredEntity {
  letterRunDetailToMonitoredEntityId: null | number;
  clientId: null | number;
  clientName: string;
  contactId?: number;
  contactName: null | string;
  dateTime?: string;
  sourceType: number;
  fileId?: number;
  status: LetterRunStatusEnums;
  letterRunId: number;
  letterRunDetailId: number;
}

export interface IFrontEndMappingToBackendIds {
  feId: string;
  beId: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IFile = {
  /**
   * Given (the necessary parts of) an `IFile` instance, fetch the file it
   * represents from the network and download it via the user's browser.
   *
   * @see getFileData
   * @throws If the given file does not contain a `fileId` field.
   */
  async download(file: Pick<IFile, 'fileId' | 'fileType' | 'name'>): Promise<void> {
    if (!file?.fileId) return Promise.reject('Unable to download file because the file ID is not valid.');
    const { fileId, fileType, name } = file;
    try {
      const fileContents = await getFileData(fileId);
      // Create a DOM element behind the scenes
      const link = document.createElement('a');
      link.style.display = 'none';
      // Convert string of file contents to a file-like object
      const blob = new Blob([base64ToArrayBuffer(fileContents)], { type: fileType });
      // Create a DOMString containing a URL representing the file
      link.href = window.URL.createObjectURL(blob);
      // Remove `.download` field to open in a new browser.
      link.download = name;
      // Click file link programmatically, downloading the file
      link.click();
    } catch (error) {
      console.error(error);
    }
  },

  async downloadFileById(fileId: number): Promise<void> {
    if (!fileId) return Promise.reject('Unable to download file because the file ID is not valid.');
    try {
      const file: IFile = await getFileById(fileId);
      // Create a DOM element behind the scenes
      const link = document.createElement('a');
      link.style.display = 'none';
      // Convert string of file contents to a file-like object
      const blob = new Blob([base64ToArrayBuffer(file.data)], { type: file.fileType });
      // Create a DOMString containing a URL representing the file
      link.href = window.URL.createObjectURL(blob);
      // Remove `.download` field to open in a new browser.
      link.download = file.name;
      // Click file link programmatically, downloading the file
      link.click();
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
};