import { CoverageTypeEnums, RequirementStatusEnums } from '../constants';
import {
  IAdditionalInsuredForMonitoredEntity,
  IBulkUpdateMonitoredEntityRequirementsPost,
  IEntityRequirement,
  IFrontEndMappingToBackendIds,
  INamedPolicyStatus,
  IBulkUpdateExistingRequirementMapping
} from '../types';
import { IBulkDataDefaultRequirement } from '../types/bulkUpdate';

class BulkUpdateHelper {
  static mapBulkUpdateDataToPersistableData(
    entityIds: number[],
    reqData: IBulkDataDefaultRequirement[],
    existingReqMap: IBulkUpdateExistingRequirementMapping,
    fileMapping: IFrontEndMappingToBackendIds[],
    documentDate: any, //a date
    expirationDate: any, //a date
    additionalInsuredData: IAdditionalInsuredForMonitoredEntity[]
  ) {
    const postObjects = entityIds.map(entityId => {
      const applyingRequirements: Set<number> = new Set(reqData.map(x => x.lineOfCoverageId));
      const holder: IBulkUpdateMonitoredEntityRequirementsPost = {
        aiInformation: additionalInsuredData
          .find(x => x.monitoredEntityId === entityId)
          .additionalInsureds.flatMap(x =>
            x.linesOfCoverage
              .filter(y => applyingRequirements.has(y.lineOfCoverageId))
              .map(y => {
                let status = 3;
                switch (y.namedPolicyStatus) {
                  case INamedPolicyStatus.Expired:
                    status = INamedPolicyStatus.IncorrectlyListed;
                    break;
                  default:
                    status = y.namedPolicyStatus;
                    break;
                }
                return { airsId: y.additionalInsuredRequirementStatusId, status: status };
              })
          ),
        documentDate: documentDate,
        expirationDate: expirationDate,
        monitoredEntityId: entityId,
        requirements: reqData.map<IEntityRequirement>(req => {

          const existingRequirement = 
            existingReqMap[entityId.toString()]
              .hasOwnProperty(req.defaultRequirementId.toString()) ? 
            existingReqMap[entityId.toString()][req.defaultRequirementId.toString()] : 
            null;

          const existingRequirementId: number = existingRequirement?.requirementId ?? 0;
          const resolvedCoverageType:number = existingRequirement?.coverageType ?? req.coverageType;
          let requirementAmountToUse:string|number = existingRequirement?.requiredAmount ?? req.requiredAmount;

          const hasEvidence:boolean = (resolvedCoverageType === CoverageTypeEnums.EVIDENCE) &&
            (Boolean(req.documentDate) || Boolean(req.carrierId) || Boolean(req.expirationDate));

          const docDate = new Date(req.documentDate);
          const docDateOnly = new Date(Date.UTC(docDate.getFullYear(), docDate.getMonth(), docDate.getDate()));

          const expDate = new Date(req.expirationDate);
          const expDateOnly = new Date(Date.UTC(expDate.getFullYear(), expDate.getMonth(), expDate.getDate()));

          const fileId = fileMapping.find(mapping => mapping.feId === entityId.toString()).beId;

          return {
            actualAmount: req.actualAmount,
            associatedDocumentIds: [fileId],
            carrierId: req.carrierId,
            carrierName: '', //required by typescript
            complianceStatus: 9, //maps to not evaluated
            coverageType: resolvedCoverageType,
            defaultRequirementId: req.defaultRequirementId,
            documentDate: docDateOnly.toISOString(),
            expirationDate: expDateOnly.toISOString(),
            hasEvidence: hasEvidence,
            isDeleted: false,
            lineOfCoverageId: req.lineOfCoverageId,
            requiredAmount: requirementAmountToUse,
            requirementId: existingRequirementId,
            status: existingRequirement?.requirementStatus ?? RequirementStatusEnums.NotRequired,
            defaultRequirementEndDate: null
          };
        }),
      };
      return holder;
    });
    return postObjects;
  }
}

export { BulkUpdateHelper };
