import { Environments } from '../types';

/**
 * Get site environment (one of 'localhost' | 'dev' | 'stg' | 'prod')
 * @param {string} origin string
 * @returns {Environments} Environments
 */
export const getEnvironment = (): Environments => {
  const environment = window.location.href.includes('localhost') // http://localhost:5001
    ? 'localhost'
    : window.location.href.includes('dev-beechercarlson') // https://dev-beechercarlson-web01.azurewebsites.net/
    ? 'dev'
    : window.location.href.includes('stg-beechercarlson') // https://stg-beechercarlson-web01.azurewebsites.net/
    ? 'stg'
    : 'prod';
  return environment;
};
