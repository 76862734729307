import React, { FC } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { IOptionType } from './Select';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

export interface IMultiSelectProps {
  label?: string;
  options: IOptionType[];
  selected: any[];
  setSelected: (values: any) => void;
  clearSelected?: () => void;
}

export const MultiSelect: FC<IMultiSelectProps> = ({
  label,
  options = [],
  selected = [],
  setSelected,
  clearSelected,
}) => {
  const classes = useStyles();
  const ITEM_PADDING_TOP = 8;
  const ITEM_HEIGHT = 48;

  const mapItemValueToLabel = displayValue => {
    const mappedSelected: IOptionType[] = options.filter(option => displayValue.includes(option.value));
    const mappedSelectedOption = mappedSelected.map((selectedOption: IOptionType) => selectedOption?.label);
    return mappedSelectedOption.join(', ');
  };

  return (
    <FormControl className={classes.formControl}>
      {/* FORM LABEL */}
      {label && <InputLabel id='multiple-select-label'>{label}</InputLabel>}

      {/* MULTISELECT */}
      <Select
        labelId='multiple-select-label'
        multiple
        value={selected}
        onChange={e => setSelected(e.target.value)}
        renderValue={(displayValue: IOptionType[]) => mapItemValueToLabel(displayValue)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
        }}
      >
        {/* OPTIONS LIST */}
        {options.map(option => (
          <MenuItem key={option.key} value={option.value}>
            {/* CHECKBOX */}
            {!!option.value && (
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option.value) > -1} />
              </ListItemIcon>
            )}

            {/* LABEL */}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>

      {/* CLEAR BUTTON */}
      {!!(clearSelected && selected?.length > 0) && (
        <IconButton size='small' className={classes.dropdownClear} onClick={() => clearSelected()}>
          <Clear />
        </IconButton>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: theme.spacing(16),
  },
  dropdownClear: {
    fill: theme.palette.grey[400],
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(0),
  },
}));
