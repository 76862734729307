import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

export const Separator = () => {
  const classes = useStyles();

  return (
    <div className={classes.separator}>|</div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    separator: {
      margin: theme.spacing(0, 1)
    }
  };
});
