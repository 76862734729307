import React, { FC, useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { fileValidator, preventBrowserDefaults } from './utils';
// styles
import { colors } from '../../styles';
// helpers
import { convertToUTC } from '../../helpers';
// types
import { IBasicObject } from '../../types';

// default config options that can be updated at a later time
const defaultFileSizeMBLimit = 100;
const defaultFilesLimit = 1;

const FILE_UPLOADER_STATE = {
  INIT: 'INIT',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  FAILURE: 'FAILURE'
};

interface BasicFileUploaderProps{
  allowedFiles: string[];
  setFileInParent: (value: IBasicObject | null) => void;
}

export const BasicFileUploader: FC<BasicFileUploaderProps> = ({
  allowedFiles,
  setFileInParent,
}) => {
  const [loaderState, setLoaderState] = useState<string>(FILE_UPLOADER_STATE.INIT);

  const [fileToUpload, setFileToUpload] = useState<IBasicObject | null>(null);
  const [error, setError] = useState<boolean>(false);
  const classes = useStyles();

  // drag and drop stuff
  let dragCounter = useRef(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [dragOverlay, setDragOverlay] = useState(false);

  const handleDrag = e => {
    preventBrowserDefaults(e);
  };

  const handleDragIn = e => {
    preventBrowserDefaults(e);
    dragCounter.current++;
    if (get(e, 'dataTransfer.items.length') > 0) {
      setDragOverlay(true);
    }
  };

  const readFileAsync = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e:any) => resolve(e.target.result.split(',')[1]);
      reader.onerror = reject;
    });
  };

  const handleDrop = async e => {
    setLoaderState(FILE_UPLOADER_STATE.PROCESSING);
    const files = get(e, 'dataTransfer.files');
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;

    const config = {
      fileSizeMBLimit: defaultFileSizeMBLimit,
      filesLimit: defaultFilesLimit,
      allowedFileFormats: allowedFiles
    };

    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setLoaderState(FILE_UPLOADER_STATE.INIT);
      }
      return false;
    }

    try {
      const rawFileData = await readFileAsync(files[0]);
      
      setFileToUpload({
        name: files[0].name,
        fileType: files[0].type,
        data: rawFileData,
        dateCreated: convertToUTC(new Date().toISOString()) ,
      });
      setLoaderState(FILE_UPLOADER_STATE.COMPLETE);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setFileInParent(fileToUpload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload]);

  return (
    <div
      className={classes.uploaderContainer}
      onDragEnter={handleDragIn}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      style={{ background: '#f9f9f9', padding: '1rem', borderRadius: '5px' }}
    >
      <div style={{ marginTop: 0, fontSize: '.875rem', fontWeight: 600, color: '#002855' }}>
        {loaderState === FILE_UPLOADER_STATE.INIT && 'Drag and drop file to upload.'}
        {loaderState === FILE_UPLOADER_STATE.PROCESSING && 'Uploading'}
        {loaderState === FILE_UPLOADER_STATE.COMPLETE && `${fileToUpload.name}`}
      </div>
      {error && <div className={classes.feedback}>{error}</div>}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75)
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  },
  placeholder: {
    color: theme.palette.grey[400]
  },
  uploaderContainer: {},
  feedback: {
    color: colors.primary.accentRed
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite
  },
}));
