import { Divider, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, Edit } from '@material-ui/icons';
import { Form, FormikProps, withFormik } from 'formik';
import { orderBy } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { RequirementStatusEnums } from '../../constants';

import { Comments, Loader, Page, PageTitle, UnsavedChanges } from '../../components';
import { routes, CoverageTypeEnums } from '../../constants';
import { ClientProvider } from '../../context';
import {
  associateDefaultAdditionalInsureds,
  getContactInformationListByClientId,
  getDefaultRequirementsForEntity,
  getLinesOfCoverage,
  getMonitoredEntityByEntityId,
  getMonitoredEntityRequirements,
  getRequirementComplianceStatusEnums,
  getThinClient,
  ICommentsAPI,
  updateAllRequirements,
  updateRequirements,
} from '../../fetch';
import { RequirementsHelper } from '../../helpers';
import { useToastContext } from '../../hooks';
import {
  CommentType,
  IClientThin,
  IContactDropdownListItem,
  IEntityRequirement,
  IEnum,
  ILineOfCoverage,
  IMonitoredEntity,
  IMonitoredEntityComment,
  IMonitoredEntityRequirements,
  IMonitoredEntityRequirementsPost,
  IMonitoredEntityType,
} from '../../types';
import AddEditContractor from '../manageClients/AddEditContractor';
import AddEditUnit from '../manageClients/AddEditUnit';
import AdditionalInsuredTable from './AdditionalInsuredTable';
import { Documents } from './Documents';
import { NotificationsTable } from './NotificationsTable';
import { RequirementsTable } from './RequirementsTable';

interface IRequirementsFormProps {
  initialValues: IRequirementsFormProps | {};
}

const tabProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const RequirementsForm: FC<IRequirementsFormProps & FormikProps<IMonitoredEntityRequirementsPost>> = ({
  resetForm,
  values,
  initialValues,
  setFieldValue,
  dirty,
}) => {
  const classes = useStyles();
  const history = useHistory();

  // @ts-ignore
  let { monitoredEntityId } = useParams();
  const { showToast } = useToastContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [data, setData] = useState<IMonitoredEntityRequirements | null>(null);
  const [entityData, setEntityData] = useState<IMonitoredEntityRequirements | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [contactGroupId, setContactGroupId] = useState<number | null>(null);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [linesOfCoverage, setLinesOfCoverage] = useState<ILineOfCoverage[]>([]);
  const [clientData, setClientData] = useState<IClientThin | null>(null);
  const [useLocations, setUseLocations] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('requirements');
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [complianceStatusEnums, setComplianceStatusEnums] = useState<IEnum[]>([]);
  const [isRequirementsEditing, setIsRequirementsEditing] = useState<boolean>(false);
  const [isAdditionalInsuredEditing, setIsAdditionalInsuredEditing] = useState<boolean>(false);
  const [associatedFiles, _setAssociatedFilesOnly] = useState<IEnum[]>([]);
  const [initialActiveDocument, setInitialActiveDocument] = useState<null | IEnum>(null);
  const [showAddEditUnitModal, setShowAddEditUnitModal] = useState<boolean>(false);
  const [showAddEditContractorModal, setShowAddEditContractorModal] = useState<boolean>(false);
  const [emailFilter, setEmailFilter] = useState<string>('');
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const unwaivedRequirements = useMemo(
    () => values?.requirements?.filter?.(f => !f.isDeleted) ?? [],
    [values?.requirements]
  );
  const [isEntityDeficient, setIsEntityDeficient] = useState<boolean>(false);
  const [isEntityActive, setIsEntityActive] = useState<boolean>(false);

  /** Updates associated files and the active document in the selector. */
  const setAssociatedFiles = useCallback((newAssociatedFiles: IEnum[]) => {
    _setAssociatedFilesOnly(existingAssociatedFiles => {
      if (newAssociatedFiles.length === 0) return existingAssociatedFiles;

      return newAssociatedFiles;
    });
  }, []);

  const [isFetchingRequirements, setIsFetchingRequirements] = useState(true);
  const fetchRequirements = async (reset = true): Promise<void> => {
    if (typeof monitoredEntityId === 'undefined') {
      return;
    }

    setIsFetchingRequirements(true);
    try {
      const reqResponse: IMonitoredEntityRequirements = await getMonitoredEntityRequirements(monitoredEntityId);

      setEntityData(reqResponse);
      setClientId(reqResponse.clientId);
      setLocationId(reqResponse.locationId);
      setContactGroupId(reqResponse.contactGroupId);

      if (reset) {
        setData(reqResponse);
        setIsEntityDeficient(Boolean(reqResponse.isEntityDeficient));
        setIsEntityActive(Boolean(reqResponse.isActive));
        setAssociatedFiles(reqResponse?.associatedFiles);

        const formatted = {
          monitoredEntityId: reqResponse.entityId,
          requirements: reqResponse.requirements,
          additionalInsured: reqResponse.additionalInsured,
        };
        resetForm({ values: formatted });
      }
    } catch (error) {
      const message = error instanceof Error && error.message;
      showToast('error', message || 'Failed to load requirements.');
      console.error(error);
    } finally {
      setIsFetchingRequirements(false);
    }
  };

  const filterInactiveRequirements = (requirements: IEntityRequirement[]): IEntityRequirement[] => {
    if (showInactive) return requirements;

    if (!showInactive)
      return requirements?.filter?.(
        requirement =>
          requirement.status !== RequirementStatusEnums.NotRequired &&
          requirement.status !== RequirementStatusEnums.Waived &&
          complianceStatusEnums.find(_ => _.text === 'NotMonitored')?.value !== requirement.complianceStatus
      );
  };

  const loadEntityDetails = async () => {
    setIsLoading(true);
    try {
      const [locResponse, complianceStatusEnums] = await Promise.all([
        getLinesOfCoverage(),
        getRequirementComplianceStatusEnums(),
      ]);
      setLinesOfCoverage(locResponse);
      setComplianceStatusEnums(complianceStatusEnums);

      if (typeof monitoredEntityId !== 'undefined') {
        await fetchRequirements();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEntityDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const loadClientDetails = async () => {
    try {
      const clientResponse = await getThinClient(clientId);
      setClientData(clientResponse); // clientData.defaultRequirements
      setUseLocations(clientResponse.useLocations);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (clientId) {
      loadClientDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setIsRequirementsEditing(false);
    setIsAdditionalInsuredEditing(false);
    setSelectedTab(newValue);
    resetForm();
    setHasChanged(false);
  };

  const handleCloneDefaultRequirements = async () => {
    setIsLoading(true);

    try {
      // Get the default requirements
      const response = await getDefaultRequirementsForEntity(clientId);
      if (!response.length) {
        showToast('error', 'No default requirements for this client.');
        return;
      }

      // Format the data for the post
      const updatedValues = {
        monitoredEntityId: values.monitoredEntityId,
        requirements: [...response],
      };

      await updateRequirements(updatedValues);
      await associateDefaultAdditionalInsureds(monitoredEntityId);

      await loadEntityDetails();
      showToast('success', 'Default requirements imported successfully.');
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  /**
   * Handle local state updates for the requirements form.
   */
  const handleUpdateRequirements = (newReqs: IEntityRequirement[]) => {
    setFieldValue('requirements', newReqs);
  };

  /**
   * Write updates to requirements for the entity under view.
   */
  const submitUpdates = async (): Promise<{ shouldOnDemandSummaryTrigger: boolean }> => {
    setIsLoading(true);
    setSubmitting(true);
    try {
      const updatedValues = {
        monitoredEntityId: values.monitoredEntityId,
        requirements: values.requirements.map(r => ({
          ...r,
          actualAmount: r.coverageType !== CoverageTypeEnums.EVIDENCE ? Number(r.actualAmount) : null,
          requiredAmount: r.coverageType !== CoverageTypeEnums.EVIDENCE ? Number(r.requiredAmount) : null,
          hasEvidence: r.coverageType === CoverageTypeEnums.EVIDENCE ? !!r.expirationDate : r.hasEvidence,
        })),
        additionalInsureds: values.additionalInsured,
      };

      const res = await updateAllRequirements(updatedValues);

      //@ts-ignore
      setIsEntityDeficient(res?.requirements?.shouldOnDemandSummaryTrigger);
      _setAssociatedFilesOnly(res.associatedFiles);
      showToast('success', 'Requirement Updated!');
      const formatted = {
        monitoredEntityId: values.monitoredEntityId,
        requirements: res.requirements,
      };
      resetForm({ values: formatted });
      setHasChanged(false);
      //@ts-ignore
      return { shouldOnDemandSummaryTrigger: res?.requirements?.shouldOnDemandSummaryTrigger};
    } catch (error) {
      console.log('error', error);
      showToast(
        'error',
        error?.Title ??
          'We were unable to create the requirement at this time. Please try again later. Please contact support if this issue continues.',
        null,
        true
      );
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const [clientInfo, setClientInfo] = useState<IClientThin | null>(null);
  const [clientContacts, setClientContacts] = useState<IContactDropdownListItem[] | null>(null);
  const [monitoredEntityTypes, setMonitoredEntityTypes] = useState<IMonitoredEntityType[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<IMonitoredEntity | null>(null);
  const handleModalOpen = async () => {
    try {
      const clientInfo = await getThinClient(entityData.clientId);
      const clientContacts = await getContactInformationListByClientId(entityData.clientId, emailFilter);
      const entity = await getMonitoredEntityByEntityId(entityData.entityId);

      setClientInfo(clientInfo);
      setMonitoredEntityTypes(clientInfo?.monitoredEntityTypes?.filter?.(type => !type.isDeleted) ?? null);
      setClientContacts(clientContacts);

      setSelectedEntity(entity);
      clientData?.entityType?.slice(0, clientData.entityType.length - 1) === 'Contractor'
        ? setShowAddEditContractorModal(true)
        : setShowAddEditUnitModal(true);
    } catch (e) {
      showToast('error', 'We are unable to open the modal. Please try again.');
    }
  };

  const getClientContacts = async () => {
    try {
      const clientContacts = await getContactInformationListByClientId(entityData.clientId, emailFilter);
      setClientContacts(clientContacts);
    } catch (e) {
      showToast('error', 'We are unable to fetch clients by email, please try again.');
    }
  };

  useEffect(() => {
    if (entityData?.clientId) {
      getClientContacts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFilter]);

  const handleCancel = () => {
    setIsRequirementsEditing(false);
    setIsAdditionalInsuredEditing(false);
    loadEntityDetails();
  };

  const getEntityTypeComments = useCallback(async (): Promise<IMonitoredEntityComment[]> => {
    const fetched = await ICommentsAPI.getAllByType(monitoredEntityId, CommentType.Entities);
    return orderBy(fetched, ['dateCreated'], ['desc']);
  }, [monitoredEntityId]);

  const uploadComment = useCallback(
    async (commentText: string, commentType: CommentType): Promise<void> => {
      let id: undefined | number;
      if (commentType === CommentType.Entities) id = monitoredEntityId;
      else if (commentType === CommentType.ContactGroups) id = contactGroupId;
      else throw Error('Unhandled CommentType variant.');

      // POST the new comment
      await ICommentsAPI.create({
        text: commentText,
        commentType,
        relatedObjectId: String(id),
      });
    },
    [contactGroupId, monitoredEntityId]
  );

  // Enforce that Exp Date, Doc Date, & Carrier are either all present or none present
  const hasValidFields = (values?.requirements?.filter?.(_ => !_.isDeleted) ?? []).every(req => {
    // Only validate requirement lines that are Active
    if (req.status !== RequirementStatusEnums.Active) {
      return true;
    }
    const fields = [req.expirationDate, req.documentDate, req.carrierId];
    const hasMissing = fields.filter(_ => !_).length > 0;
    const hasPresent = fields.filter(_ => !!_).length > 0;

    if (hasMissing && hasPresent) {
      return false;
    }
    return true;
  });

  return (
    <Page customPageHeader title={monitoredEntityId ? 'Edit Requirements' : 'Add Requirements'}>
      <Form autoComplete='none' className={classes.formContainer}>
        <div id={'form-container'} className={classes.formContainer}>
          <UnsavedChanges dirty={dirty || hasChanged} />
          <div id={'header-area'}>
            {/** PAGE TITLE */}
            <div className={classes.headerContainer}>
              <IconButton
                className={classes.backButton}
                color='primary'
                onClick={() => {
                  history.push(routes.manageRequirements);
                }}
              >
                <ArrowBackIos />
              </IconButton>

              <PageTitle
                title={`Manage Requirements${clientData && clientData.name ? ` - ${clientData.name}` : ''}`}
                marginOff
              />
              <div className={classes.contentSpacer} />
            </div>

            {submitting && <Loader type='overlay' position='centered' title={'Saving...'} />}

            {/** SUBHEADERS */}
            {entityData && !isFetchingRequirements && (
              <Grid container spacing={1} direction='row' className={classes.detailsContainer}>
                {useLocations === true && (
                  <Grid item xs={12} sm={4}>
                    <Typography color='primary' className={classes.detailsHeader}>
                      Location Name
                    </Typography>
                    <Typography variant='body2'>{entityData.locationName}</Typography>
                    <Typography variant='body2'>{entityData.locationAddress}</Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={4}>
                  <Typography color='primary' className={classes.detailsHeader}>
                    {!!clientData?.entityType &&
                      `${clientData.entityType.slice(0, clientData.entityType.length - 1)} Name`}
                    <IconButton
                      className={classes.editEntityButton}
                      color='primary'
                      size='small'
                      onClick={() => {
                        handleModalOpen();
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Typography>
                  <Typography variant='body2'>{entityData.name}</Typography>
                  <Typography variant='body2'>
                    {entityData.monitoredEntityInfo?.userFriendlyEntityNumberText}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography color='primary' className={classes.detailsHeader}>
                    Contact Name
                    <IconButton
                      className={classes.editEntityButton}
                      color='primary'
                      size='small'
                      onClick={() => window.open(`${routes.manageContacts}/${entityData.contactId}`)}
                    >
                      <Edit />
                    </IconButton>
                  </Typography>
                  <Typography variant='body2'>{entityData.contactName}</Typography>
                  <Typography variant='body2'>{entityData.contactGroupName}</Typography>
                </Grid>
              </Grid>
            )}

            {isFetchingRequirements && <Loader />}

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor='secondary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              <Tab label='REQUIREMENTS' {...tabProps(0)} value={'requirements'} />
              <Tab label='DOCUMENTS' {...tabProps(1)} value={'documents'} />
              <Tab label='NOTIFICATIONS' {...tabProps(2)} value={'notifications'} />
              <Tab label='COMMENTS' {...tabProps(3)} value={'comments'} />
            </Tabs>

            <Divider className={classes.tabDivider} />
          </div>

          {selectedTab === 'requirements' && (
            <Grid direction={'column'} item xs={12}>
              <RequirementsTable
                requirements={values?.requirements?.filter?.(_ => !_.isDeleted) ?? []}
                displayedRequirements={
                  filterInactiveRequirements(values?.requirements?.filter?.(_ => !_.isDeleted)) ?? []
                }
                hasValidFields={hasValidFields}
                associatedFiles={associatedFiles}
                setAssociatedFiles={files => setAssociatedFiles(files)}
                initialActiveDocument={initialActiveDocument}
                setInitialActiveDocument={file => setInitialActiveDocument(file)}
                unwaivedRequirements={unwaivedRequirements}
                onUpdate={handleUpdateRequirements}
                linesOfCoverage={linesOfCoverage}
                handleClone={handleCloneDefaultRequirements}
                loading={isLoading}
                setLoading={setIsLoading}
                dirty={dirty}
                submitting={submitting}
                submitUpdates={submitUpdates}
                handleCancel={handleCancel}
                showWaived={showInactive}
                toggleWaived={() => setShowInactive(!showInactive)}
                clientId={clientId}
                monitoredEntityId={values.monitoredEntityId}
                resetForm={resetForm}
                contactGroupId={contactGroupId}
                load={loadEntityDetails}
                complianceStatusEnums={complianceStatusEnums}
                editing={isRequirementsEditing}
                isAdditionalInsuredEditing={isAdditionalInsuredEditing}
                setIsAdditionalInsuredEditing={setIsAdditionalInsuredEditing}
                setEditing={setIsRequirementsEditing}
                isEntityDeficient={isEntityDeficient}
                fetchRequirements={fetchRequirements}
                setHasChanged={setHasChanged}
                hasChanged={hasChanged}
                isEntityActive={isEntityActive}
              />

              <AdditionalInsuredTable
                setHasChanged={setHasChanged}
                hasChanged={hasChanged}
                parentDirty={dirty}
                isEditingRequirements={isRequirementsEditing}
                clientId={clientId}
                locationId={locationId}
                reloadData={loadEntityDetails}
                formIsLoading={isLoading}
                editing={isAdditionalInsuredEditing}
                setEditing={setIsAdditionalInsuredEditing}
                hasValidFields={hasValidFields}
                initialValues={
                  data && data.additionalInsured
                    ? {
                        monitoredEntityId: monitoredEntityId,
                        additionalInsureds: data.additionalInsured,
                      }
                    : {
                        monitoredEntityId: monitoredEntityId,
                        additionalInsureds: [],
                      }
                }
                requirements={(values && values.requirements && values.requirements.filter?.(f => !f.isDeleted)) || []}
                setParentFieldValue={setFieldValue}
                parentValues={values}
              />
            </Grid>
          )}

          {selectedTab === 'documents' && (
            <Documents
              monitoredEntityId={values.monitoredEntityId}
              linesOfCoverage={linesOfCoverage}
              inUseLinesOfCoverage={RequirementsHelper.requirementsToActiveLinesOfCoverage(values.requirements)}
              fetchRequirements={fetchRequirements}
            />
          )}

          {selectedTab === 'notifications' && <NotificationsTable />}

          {clientData !== null && clientData?.clientType && (
            <ClientProvider client={clientData}>
              {selectedTab === 'comments' && (
                <Comments getComments={getEntityTypeComments} uploadComment={uploadComment} />
              )}
            </ClientProvider>
          )}
        </div>
      </Form>
      {showAddEditUnitModal && entityData && clientInfo && clientContacts && selectedEntity && (
        <AddEditUnit
          open={showAddEditUnitModal}
          jobLabel={entityData.name}
          locations={clientInfo?.locations ?? []}
          contacts={clientContacts}
          initialValues={selectedEntity}
          useLocations={useLocations}
          showSupplementalCode={clientInfo.showSupplementalCode}
          clientType={clientInfo.clientType}
          showToast={showToast}
          clientId={clientId}
          onClose={async () => {
            setShowAddEditUnitModal(false);
            await fetchRequirements(false);
          }}
          monitoredEntityTypes={monitoredEntityTypes}
          refreshContacts={async () => await getContactInformationListByClientId(entityData.clientId)}
          contactsIsLoading={isLoading}
          updateContactsEmailFilter={(chars: string) => setEmailFilter(chars)}
          requireUnitCodes={clientInfo.requireUnitCodes}
        />
      )}
      {showAddEditContractorModal && entityData && clientInfo && clientContacts && selectedEntity && (
        <AddEditContractor
          open={showAddEditContractorModal}
          jobLabel={entityData.name}
          locations={clientInfo?.locations ?? []}
          contacts={clientContacts}
          initialValues={selectedEntity}
          useLocations={useLocations}
          showSupplementalCode={clientInfo.showSupplementalCode}
          clientType={clientInfo.clientType}
          showToast={showToast}
          clientId={clientId}
          onClose={async () => {
            setShowAddEditContractorModal(false);
            await fetchRequirements(false);
          }}
          monitoredEntityTypes={monitoredEntityTypes}
          refreshContacts={async () => await getContactInformationListByClientId(entityData.clientId)}
          contactsIsLoading={isLoading}
          updateContactsEmailFilter={(chars: string) => setEmailFilter(chars)}
          requireUnitCodes={clientInfo.requireUnitCodes}
        />
      )}
    </Page>
  );
};

const RequirementsFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export default withFormik<IRequirementsFormProps, IMonitoredEntityRequirementsPost>({
  enableReinitialize: true,
  handleSubmit: () => {},
  validationSchema: RequirementsFormSchema,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      monitoredEntityId: null,
      requirements: [],
      ...initialValues,
    };
  },
})(RequirementsForm);

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    height: 'calc(100% + 1rem)',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  detailsHeader: {
    fontWeight: 'bold',
  },
  detailsHeaderEmpty: {
    height: 32,
  },
  backButton: {
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  contentSpacer: {
    width: '.001rem',
  },
  formTextField: {
    width: theme.spacing(12),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabDivider: {
    marginBottom: theme.spacing(1),
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  detailsContainer: {
    marginBottom: theme.spacing(2),
  },
  editEntityButton: {
    marginLeft: theme.spacing(1),
  },
}));
