import { Card, CardContent, Container, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import background from '../../assets/background.png';
import BBLogo from '../../assets/logo.png';
import { Button, Loader, LoginHeader, Toast } from '../../components';
import { routes, screenSizes, userRoles } from '../../constants';
import { ClientSelectorContext, UserContext } from '../../context';
import { login } from '../../fetch';
import { setUserLocalStorage } from '../../helpers';
import { colors } from '../../styles';

interface ILoginValues {
  username: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const Login: FC = () => {
  const isMobile = useMedia(screenSizes.mobile);
  const username = useRef(null);
  const history = useHistory<{ from: { pathname: string } & Record<string, unknown> }>();
  const userContextProvider = useContext(UserContext);
  const { setClientSelectorContext } = useContext(ClientSelectorContext);
  const classes = useStyles();

  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState<null | { message: string }>(null);

  useEffect(() => {
    document.title = `Login | Beecher Carlson`;
    setIsLoading(false);
  }, []);

  if (userContextProvider.isFetching) {
    return null;
  }

  // Redirect user on successful login
  if (userContextProvider.userContext && !userContextProvider.isFetching) {
    // Set default redirect to Manage Requirements page
    let redirectTo: string = routes.manageRequirements;

    // If the user clicked a link but they were not logged in - after they
    // successfully authenticate - then take them to that original URL
    const desiredPath = history.location.state?.from?.pathname;
    if (!!desiredPath) {
      redirectTo = desiredPath;
    }

    // External users cannot access Manage Requirements page
    if (userContextProvider.userContext.role === userRoles.EXTERNAL) {
      redirectTo = routes.reports;
    }

    // Navigate to the appropriate landing page
    return <Redirect to={redirectTo} />;
  }

  const handleFormSubmit = async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
    try {
      const res: any = await login({ username: values.username, password: values.password });

      if (!res) {
        throw Error();
      } else if (res && res.role) {
        setClientSelectorContext(null);
        setUserLocalStorage(res);
        await userContextProvider.setUserContext(res);
      } else {
        throw Error();
      }
    } catch (error) {
      console.dir(error);
      if (error && error.Detail) {
        setError(error.Detail);
      } else {
        setError(error);
      }
      actions.resetForm({ values: { username: values.username, password: '' } });
      username.current && username.current.focus();
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <LoginHeader />
      <div className={classes.loginContainer}>
        {isLoading && (
          <Loader position='centered' type='fullscreen'>
            Loading...
          </Loader>
        )}
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) =>
            handleFormSubmit(values, actions)
          }
        >
          {(formikProps: FormikProps<ILoginValues>) => (
            <Form data-testid='login-form' onSubmit={formikProps.handleSubmit} className={classes.formik}>
              <Container maxWidth='sm'>
                <Grid alignItems='center' container direction='column' justify='center'>
                  <Card>
                    {/* LOGIN FORM */}
                    <CardContent className={isMobile ? classes.mobileFormContainer : classes.formContainer}>
                      <Grid container direction='column' alignItems='center'>
                        {/* B&B LOGO */}
                        <img src={BBLogo} alt='logo' className={isMobile ? classes.mobileLogo : classes.logo} />

                        {/* WELCOME MESSAGE */}
                        <Typography className={isMobile ? classes.mobileWelcomeMessage : classes.welcomeMessage}>
                          Welcome, login to our site below.
                        </Typography>

                        <div className={isMobile ? classes.mobileInputContainer : classes.inputContainer}>
                          {/* USERNAME TEXT-FIELD */}
                          <FormControl margin='dense'>
                            <Field name='username'>
                              {({ field, form }: FieldProps<ILoginValues>) => (
                                <TextField
                                  {...field}
                                  autoFocus
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.username && form.errors && form.errors.username)}
                                  fullWidth={true}
                                  helperText={
                                    <Typography variant={'caption'} display='inline'>
                                      <Link className={classes.forgotPassword} to={routes.forgotUsername}>
                                        Forgot Username
                                      </Link>
                                    </Typography>
                                  }
                                  inputRef={username}
                                  label='Username'
                                  margin='none'
                                  required={true}
                                  value={formikProps.values.username}
                                />
                              )}
                            </Field>
                          </FormControl>

                          {/* PASSWORD TEXT-FIELD */}
                          <FormControl margin='dense'>
                            <Field name='password'>
                              {({ field, form }: FieldProps<ILoginValues>) => (
                                <TextField
                                  {...field}
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.password && form.errors && form.errors.password)}
                                  fullWidth={true}
                                  helperText={
                                    <Typography variant={'caption'} display='inline'>
                                      <Link className={classes.forgotPassword} to={routes.requestResetPassword}>
                                        Forgot Password
                                      </Link>
                                    </Typography>
                                  }
                                  id='password'
                                  label='Password'
                                  margin='none'
                                  required={true}
                                  type='password'
                                  value={formikProps.values.password}
                                />
                              )}
                            </Field>
                          </FormControl>

                          {/* LOGIN BUTTON */}
                          <FormControl>
                            <Button
                              color='secondary'
                              className={isMobile ? classes.mobileLoginButton : classes.loginButton}
                              disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}
                              id='submit'
                              loading={formikProps.isSubmitting ? 'Logging in...' : ''}
                              size='large'
                              type='submit'
                              variant='contained'
                              startIcon={<ArrowForwardOutlinedIcon />}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  formikProps.handleSubmit();
                                }
                              }}
                            >
                              {!formikProps.isSubmitting ? 'Login' : ''}
                            </Button>
                          </FormControl>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>

        {/* SUCCESS */}
        <Toast
          id='login-success'
          message={success && success.message}
          onClick={() => setSuccess(null)}
          onClose={() => setSuccess(null)}
          open={!!success}
          variant='success'
        />

        {/* FAILURE */}
        <Toast
          id='login-error'
          message={error}
          onClick={() => setError(null)}
          onClose={() => setError(null)}
          open={!!error}
          variant='error'
        />
      </div>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    loginContainer: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflow: 'auto',
    },
    formik: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainer: {
      width: '486px',
      height: '602px',
      padding: '0px',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    mobileFormContainer: {
      maxWidth: '328px',
      maxHeight: '446px',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    logo: {
      width: '177px',
      height: '198px',
      marginTop: '56px',
    },
    mobileLogo: {
      maxWidth: '80px',
      maxHeight: '90px',
      marginTop: '12px',
    },
    welcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '19px',
      marginBottom: '20px',
      fontWeight: 600,
    },
    mobileWelcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '9px',
      marginBottom: '10px',
      fontWeight: 600,
      textAlign: 'center',
    },
    inputContainer: {
      width: '321px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    mobileInputContainer: {
      width: '205px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    accountResetButtonsContainer: {
      width: '321px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: '1rem',
    },
    mobileAccountResetButtonsContainer: {
      width: '205px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: '1rem',
    },
    textField: {
      width: '321px',
    },
    mobileTextField: {
      width: '205px',
    },
    loginButton: {
      marginTop: '1rem',
    },
    mobileLoginButton: {
      marginTop: '16px',
    },
    forgotPassword: {
      color: colors.primary.navyBlue,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };
});
