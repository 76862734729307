import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import CloseIcon from '@material-ui/icons/Close';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import MuiDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

interface IModalProps extends DialogProps {
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  subtitle?: string;
  title?: string;
  actions?: JSX.Element;
  noPaddingContent?: boolean;
  maxWidth?: any;
  fullWidth?: boolean;
  isLoading?: boolean;
  customButtonHandler?: Function;
  customButtonIcon?: any;
}

export const Modal: FC<IModalProps> = ({ customButtonIcon, customButtonHandler, isLoading = false, maxWidth, fullWidth = true, DialogContentProps, DialogTitleProps, subtitle, title, actions, children, noPaddingContent, ...dialogProps }) => {
  const classes = modalStyles({ noPaddingContent });

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth ? maxWidth : 'xl'} {...dialogProps}>
      {/* CIRCLE LOADER */}
      <Backdrop open={isLoading}>
        <CircularProgress color='primary' />
      </Backdrop>

      {/* MODAL CONTENT */}
      {!isLoading &&
        <div className={classes.dialogContainer}>
          <MuiDialogTitle classes={{ root: classes.dialogTitle }} disableTypography={true} {...DialogTitleProps}>
            {title && (
              <Typography className={classes.title} variant='h6'>
                {title}
              </Typography>
            )}
            {customButtonHandler &&
              <IconButton
                className={classes.customButton}
                onClick={() => customButtonHandler()}
              >
                {customButtonIcon}
              </IconButton>
            }
            <IconButton
              aria-label='Close'
              className={classes.close}
              onClick={() => {
                if (dialogProps && dialogProps.onClose) {
                  dialogProps.onClose({}, 'backdropClick');
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <MuiDialogContent classes={{ root: classes.dialogContent }} dividers={false} {...DialogContentProps}>
            {subtitle && (
              <Typography className={classes.subtitle} variant='h4'>
                {subtitle}
              </Typography>
            )}
            {children}
          </MuiDialogContent>
          {actions && <DialogActions>{actions}</DialogActions>}
        </div>
      }
    </Dialog>
  );
};

const modalStyles = makeStyles<Theme, { noPaddingContent?: boolean }>((theme: Theme) => {
  return {
    dialogContainer: {
      padding: '12px'
    },
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: colors.primary.navyBlue
    },
    customButton: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(0.25),
      color: colors.primary.navyBlue
    },
    dialogContent: {
      // minHeight is to give <Loader /> component top and bottom space, if shown with no content to overlay
      minHeight: theme.spacing(4),
      padding: ({ noPaddingContent }) => (noPaddingContent ? 0 : theme.spacing(0.5, 1.5, 1.5))
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(1, 4, 1, 1.5)
    },
    subtitle: {
      color: theme.palette.secondary.main,
      fontSize: 28,
      lineHeight: 1.3,
      margin: theme.spacing(0, 0, 1)
    },
    title: {
      fontSize: 20,
      textTransform: 'uppercase',
      color: colors.primary.navyBlue
    }
  };
});
