import { Button, CardActions, Fade, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import React, { FC, useCallback, useState } from 'react';

import { CheckboxList, DatePicker, Loader, Modal, Select } from '../../components';
import { addFileToMonitoredEntity } from '../../fetch';
import { dateFormatter } from '../../helpers';
import { useToastContext } from '../../hooks';
import { ILineOfCoverage, IMonitoredEntityFile } from '../../types';

const statusOptions = [
  { label: 'Applied', value: 1 },
  { label: 'Not Accepted', value: 2 }
];

interface IRequirementFileEditModalProps {
  open: boolean;
  onClose: (shouldUpdate: boolean) => void;
  selectedFile: IMonitoredEntityFile;
  linesOfCoverage: ILineOfCoverage[];
  monitoredEntityId: number;
  inUseLinesOfCoverage: number[];
}

export const RequirementFileEditModal: FC<IRequirementFileEditModalProps> = ({
  open,
  onClose,
  selectedFile,
  linesOfCoverage,
  monitoredEntityId,
  inUseLinesOfCoverage
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | null>(selectedFile.fileStatus || null);
  const [coverageIds, setCoverageIds] = useState<number[]>(selectedFile.lineOfCoverageIds || []);
  const [expirationDate, setExpirationDate] = useState<string>(selectedFile.expirationDate || '');
  const [documentDate, setDocumentDate] = useState<string>(selectedFile.documentDate || '');

  const handleSaveChanges = useCallback(async () => {
    setLoading(true);
    try {
      const postData = {
        lineOfCoverageIds: coverageIds,
        fileStatus: status,
        monitoredEntityId: monitoredEntityId,
        monitoredEntityFileId: selectedFile.monitoredEntityFileId,
        expirationDate: expirationDate,
        documentDate: documentDate
      };

      await addFileToMonitoredEntity(postData);
      showToast('success', 'File Updated.');
      onClose(true);
    } catch (error) {
      showToast('error', 'There was a problem updating the file. Please try again.');
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [coverageIds, documentDate, expirationDate, monitoredEntityId, onClose, selectedFile.monitoredEntityFileId, showToast, status]);

  const lineOfCoverageOptions = linesOfCoverage
    .filter(l => inUseLinesOfCoverage.indexOf(l.lineOfCoverageId) > -1)
    .map(c => ({ id: c.lineOfCoverageId, name: c.name }));

  return (
    <Modal maxWidth={'xs'} open={open} title='Edit Document' onClose={() => onClose(false)}>
      {loading && <Loader type='overlay' position='centered' title='Saving...' />}
      <Fade in={open}>
        <div>
          <Grid container spacing={1} className={classes.gridContainer} direction={'column'}>
            <Grid item xs={12}>
              <Select
                name='status'
                id='status'
                value={status}
                label='Document Status'
                showReset={false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus(Number(e.target.value));
                }}
                className={classes.modalSelect}
                options={statusOptions.map(s => ({ key: s.value, label: s.label, value: s.value }))}
              />
            </Grid>
            <Grid container spacing={1}>
              {/* EXPIRATION DATE */}
              <Grid item xs={6}>
                <DatePicker
                  label='Expiration Date'
                  id='expiration-date-picker'
                  fullWidth
                  placeholder='Expiration Date'
                  autoComplete='nope'
                  name='expirationDate'
                  value={expirationDate ? new Date(expirationDate) : null}
                  onChange={date => setExpirationDate(date?.toISOString())}
                />
              </Grid>

              {/* DOC. DATE */}
              <Grid item xs={6}>
                <DatePicker
                  label='Document Date'
                  id='document-date-picker'
                  fullWidth
                  placeholder='Document Date'
                  autoComplete='nope'
                  name='documentDate'
                  value={documentDate ? new Date(documentDate) : null}
                  onChange={date => setDocumentDate(date?.toISOString())}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {/* ENTERED DATE */}
              {selectedFile?.file?.dateCreated && (
                <Grid item xs={6}>
                  <Typography variant='h6'>Entered Date</Typography>
                  <Typography>{dateFormatter(selectedFile?.file?.dateCreated)}</Typography>
                </Grid>
              )}

              {/* USER */}
              {selectedFile.user && (
                <Grid item xs={6}>
                  <Typography variant='h6'>User</Typography>
                  <Typography>{selectedFile.user}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <CheckboxList
                header='Applicable Lines of Coverage'
                checkedValuesList={coverageIds}
                saveCheckboxSelectionToState={updatedCheckedValuesList => setCoverageIds(updatedCheckedValuesList)}
                checkboxItems={lineOfCoverageOptions}
              />
            </Grid>
          </Grid>
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button onClick={() => onClose(false)}>Close</Button>
              <Button className={classes.saveButton} onClick={handleSaveChanges} startIcon={<Save />} variant='contained' color='primary'>
                Save
              </Button>
            </div>
          </CardActions>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75)
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1)
  },
  modalSelect: {}
}));
