import { IStatusBase } from '.';
import { ClientType } from '../constants';
import { IEntityRequirement } from './requirements';

export interface IMonitoredEntityStatus extends IStatusBase {
  statusText: 'Active' | 'Inactive';
  status: boolean;
}

export interface IMonitoredEntity {
  entityId: number;
  name: string;
  locationId: null | number;
  locationStatus: null | string;
  unitNumber: null | string;
  contactId: null | number;
  contactGroupId: null | number;
  contactGroupName: string;
  contactName: null | string;
  isActive: boolean;
  clientId: null | number;
  jobName: null | string;
  jobLocation: null | string;
  jobNumber: null | string;
  /** Unused field. */
  contractorType?: null | number;
  contractOnFile: null | boolean;
  contractExpires: null | string;
  isDeleted: boolean;
  requirements?: IEntityRequirement[];
  clientType: ClientType;
  dba: null | string;
  alternateDBA: null | string;
  supplementalCode: null | string;
  contractNumber: null | string;
  contractReviewDate: null | string;
  contractReviewedBy: null | string;
  clientStoreNumber: null | string;
  monitoredEntityTypeId: null | number;
  statuses: IMonitoredEntityStatus[];
}

export enum MonitoredEntitySortBy {
  Name = 1,
  Location = 2,
  ContactName = 3,
  Status = 4,
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IMonitoredEntity = {
  /**
   * Returns an instance with all fields in their empty value.
   */
  createInitialValue(clientType: ClientType): IMonitoredEntity {
    const initial: IMonitoredEntity = {
      alternateDBA: '',
      clientStoreNumber: '',
      clientId: 0,
      clientType,
      contactGroupId: null,
      contactGroupName: '',
      contactId: null,
      contactName: '',
      contractExpires: '',
      contractNumber: '',
      contractOnFile: false,
      contractReviewDate: '',
      contractReviewedBy: '',
      dba: '',
      entityId: 0,
      isActive: true,
      isDeleted: false,
      jobLocation: '',
      jobName: '',
      jobNumber: '',
      locationId: null,
      locationStatus: null,
      monitoredEntityTypeId: null,
      name: '',
      statuses: [],
      supplementalCode: '',
      unitNumber: '',
    };

    // `clientId` is not present on the data that gets sent to the server unless
    // the user is in edit mode in the AddEditUnit or AddEditContractor modals.
    // This exists to retain the set of fields that was previously existing (AKA not break things).
    // This also exists to avoid placing @ts-ignore on the definition of
    // `initial` which could hide TypeScript issues (besides the fact that this
    // field is missing from the interface/model that we are telling TypeScript is a valid IMonitoredEntity).
    delete initial.clientId;

    return initial;
  },
};

export interface IMonitoredEntityFilters {
  locationId?: number;
}
