import { Box, IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { factories, IEmbedConfiguration, models, service } from 'powerbi-client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Page } from '../../components';
import { routes } from '../../constants';
import { getPowerBIReport, getReport } from '../../fetch';
import { useToastContext } from '../../hooks';


const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

let reportContainer: HTMLElement;

export const PowerBIReport: FC = () => {
  const reportRef = useRef();
  const history = useHistory();
  const { showToast } = useToastContext();
  // @ts-ignore
  const { reportId, powerBIReportId, reportSecurityType } = useParams();

  const [reportTitle, setReportTitle] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const loadReportViewer = async (embedUrl: string, accessToken: string) => {
    setError(false);

    const embedConfiguration: IEmbedConfiguration = {
      id: powerBIReportId,
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      settings: {
        background: models.BackgroundType.Transparent,
        filterPaneEnabled: false
      }
    };

    try {
      const report = powerbi.embed(reportContainer, embedConfiguration);
      // Clear any other error handler event
      report.off('error');

      // Below patch of code is for handling errors that occur during embedding
      report.on('error', function (event) {
        const errorMsg: any = event.detail;
        errorMsg && errorMsg.detailedMessage && showToast('error', errorMsg.detailedMessage.toString());
      });
    } catch (error) {
      showToast('error', error.toString());
    }
  };

  useEffect(() => {
    //Validate that the UI container exists for the report to be embedded
    if (reportRef !== null) {
      reportContainer = reportRef['current'];
    }

    getReport(reportId).then(response => {
      if (response.name) {
        setReportTitle(response.name);
      }
    });

    getPowerBIReport(powerBIReportId, reportSecurityType)
      .then(response => {
        //Check to validate that object exists and child elements also have values
        if (response && response.embedReport.length && response.embedToken) {
          loadReportViewer(response.embedReport[0].embedUrl, response.embedToken.token);
        }
      })
      .catch(error => {
        showToast('error', error.Detail);
        setError(true);
      });

    return powerbi && powerbi.reset(reportContainer);
    // eslint-disable-next-line
  }, [reportId, powerBIReportId]);

  return (
    <Page customPageHeader title={reportTitle}>
      <Box display='flex' flexDirection='row' width='100%' height='100%'>
        <Box>
          {/* BACK BUTTON */}
          <IconButton color='primary' onClick={() => history.push(routes.reports)}>
            <ArrowBackIos />
          </IconButton>
        </Box>

        {!error && (
          <div id='reportContainer' ref={reportRef} style={{ width: '100%', height: '100%' }}>
            <Skeleton variant={'rect'} width={'100%'} height={'100%'} />
          </div>
        )}
      </Box>
    </Page>
  );
};
