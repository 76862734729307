import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from '../constants';
import { UserContext } from '../context';
import { useToastContext } from '../hooks';
import { userLogout } from '.';
import { logWarn } from '../services';


const expirationTimeStorageKey = 'expirationTime';
/**
 * Amount of time in seconds it takes before the user is automatically logged
 * out.
 */
const expirationTimeInSeconds = 3600;

/**
 * Resets the idle timer used by the Idle Logout
 */
export const resetIdleTimer = () => {
  localStorage.setItem(expirationTimeStorageKey, String(Date.now() + expirationTimeInSeconds * 1000));
};

/**
 * This component is responsible for automatically log out the user if they are
 * idle for `expirationTimeInSeconds` time 
 * 
 * CRITICAL: This MUST be rendered as a child of `<UserContext />`, `<Router />`,
 * and `<ToastContext />` due to its dependency on data from those contexts.
 */
export const IdleLogout: FC = () => {
  const userContext = useContext(UserContext);
  const { showToast } = useToastContext();
  const history = useHistory();

  let timeoutId: undefined | NodeJS.Timeout;

  const idleUserLogout = async () => {
    const consoleLogWarn = (message) => {console.warn('idleUserLogout', message)}
    logWarn({},'idleUserLogout fired to log user out');
    consoleLogWarn('Fired')
    // Update stored auth cookies
    consoleLogWarn('userLogout started')
    await userLogout();
    consoleLogWarn('userLogout completed')

    // Update auth state passed to app components
    consoleLogWarn('setUsetContext started')
    await userContext.setUserContext(null);
    consoleLogWarn('setUsetContext completed')

    // Tell the user what happened so behavior is not unexpected
    const showUntilClose = true;
    showToast('info', 'Due to inactivity, you have been logged out.', undefined, showUntilClose);

    // Actually take the user to the login screen
    history.push(routes.login);
  };

  const cleanupFunction = () => {
      clearTimeout(timeoutId);
      localStorage.removeItem(expirationTimeStorageKey);
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('scroll', resetIdleTimer);
      window.removeEventListener('keydown', resetIdleTimer);
    };


  const checkExpirationTimer = () => {
    timeoutId = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(expirationTimeStorageKey), 10);
      const idleTimeHasExpired = expiredTime < Date.now();
      console.debug('Idle Time checked', idleTimeHasExpired)
      if (idleTimeHasExpired && userContext.userContext) {
        idleUserLogout();
      }
    }, 1000);
  };

  // Automatically log out the user if the are idle for some time when the
  // environment is NOT Development
  useEffect(() => {
    // Do nothing if already logged out
    if (userContext.userContext === null) {
      return;
    }

    checkExpirationTimer();
    // Start watching for idle behavior
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('scroll', resetIdleTimer);
    window.addEventListener('keydown', resetIdleTimer);

    // Stop watching for idle behavior
    return cleanupFunction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.userContext]);

  return null;
};
