import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, } from '@material-ui/core';
// components
import { Page } from '../../components';
// fetch
import { initiateComplianceJob } from '../../fetch';
// styles
import { colors } from '../../styles';
// hooks
import { useToastContext } from '../../hooks';
// types
import { IError } from '../../types';
import { ApplicationSettingEditor } from '../../components/inputs/ApplicationSetting';
import { ApplicationSettings } from '../../types/applicationSettings';

export const AdminSystem = () => {
  const { showToast } = useToastContext();
  const classes = useStyles();

  const handleInitiateComplianceJob = async () => {
    try {
      const confirmationResult = window.confirm('Are you sure you would like to run the compliance job now?');
      if (confirmationResult)
      {
        showToast('info', 'Compliance Job Initiated');
        await initiateComplianceJob();
      }
    } catch (error) {
      let message = 'There was a problem initiating the compliance job. Please try again.';
      if (IError.is(error) && error.Detail.length > 0) {
        message = error.Detail;
      }
      showToast('error', message);
    }
  };

  return (
    <Page title='System'>
      <Grid container direction='column' alignItems={'flex-start'} justify={'space-between'}>
        <Grid item>
          <Button
            color={'primary'}
            onClick={() => handleInitiateComplianceJob()}
            className={classes.complianceJobButton}
          >
            Initiate Compliance Job
          </Button>
        </Grid>
        <Grid item>
          <ApplicationSettingEditor
            setting={ApplicationSettings.RunComplianceJob} />
            <Typography variant='subtitle2'>
              Scheduled Compliance Job: 1:00am EST
            </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  columnOrientation: {
    flexDirection: 'column'
  },
  header: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightLavendar,
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  body: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: theme.spacing(10),
    overflow: 'hidden',
    backgroundColor: colors.secondary.lightLavendar
  },
  footer: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightestGray
  },
  complianceJobButton: {
    marginBottom: theme.spacing(1),
    color: colors.secondary.catskillWhite,
    backgroundColor:colors.primary.accentRed,
    "&:hover, &:focus":{
      color: colors.primary.navyBlue,
    }
  },
}));