import { FormControl } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import { DatePicker } from '../../../components';
import { RequirementStatusEnums } from '../../../constants';
import { IEntityRequirement } from '../../../types';

interface IDocumentDateCellProps {
  cell: { row: { original: IEntityRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
  touched: boolean;
  setTouched: (arg: boolean) => void;
}

export const DocumentDateCell: FC<IDocumentDateCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateData,
  touched,
  setTouched
}) => {
  const [err, setErr] = useState(false);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<null | IEntityRequirement['documentDate']>(original.original.documentDate ?? null);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.documentDate);
  }, [original.original.documentDate]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.documentDate) {
      updateData('documentDate', value, original.original);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const req: IEntityRequirement = original.original;
    // Only validate requirement lines that are Active
    if (req.status !== RequirementStatusEnums.Active) {
      return;
    }

    // For each requirement line, Exp Date, Doc Date, & Carrier are required
    // We display a "Field required" validation error on these fields if one is
    // filled out and the others are not.
    const fields = {
      expirationDate: req.expirationDate,
      documentDate: req.documentDate,
      carrierId: req.carrierId
    };
    const entries = Object.entries(fields);
    const missing = entries.filter(([_, v]) => !v);
    const present = entries.filter(([_, v]) => !!v);

    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (missing.length > 0 && present.length > 0) {
      if (missing.some(([key, _]) => key === 'documentDate')) {
        setErr(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original.original.expirationDate, original.original.documentDate, original.original.carrierId]);

  return (
    <FormControl fullWidth>
      <DatePicker
        id='document-date-picker'
        fullWidth
        placeholder=''
        autoComplete='nope'
        name='documentDate'
        value={value ? new Date(value) : null}
        onChange={date => setValue(date?.toISOString() ?? null)}
        onClear={() => setValue(null)}
        onFocusCapture={() => {
          if (touched) {
            return;
          }
          setTouched(true);
        }}
        label={touched && err ? 'Field required.' : undefined}
        error={touched && err}
        InputLabelProps={{ shrink: touched && err }}
      />
    </FormControl>
  );
};
