import { Button as MuiButton, PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React, { forwardRef } from 'react';
// components
import { Loader, ILoaderProps } from '../loader';

export interface IButtonProps extends ButtonProps {
  /**  'default' | 'inherit' | 'primary' | 'secondary' */
  color?: PropTypes.Color;
  id: string;
  loading?: JSX.Element | string;
  LoaderProps?: ILoaderProps;
  target?: string;
  rel?: string;
  asLink?: boolean;
  to?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const classes = buttonStyles();

  const { children, className, color, id, LoaderProps, loading, asLink, to, ...rest } = props;

  return (
    <MuiButton
      {...rest}
      className={className}
      color={color}
      data-testid={id}
      disabled={loading || props.disabled ? true : false}
      id={id}
      ref={ref}
      startIcon={loading ? null : props.startIcon}
      component={asLink ? Link as React.ElementType : undefined}
      to={to}
    >
      {loading ? (
        <Loader className={classes.loader} position='centered' {...LoaderProps}>
          {loading}
        </Loader>
      ) : (
        children
      )}
    </MuiButton>
  );
});

Button.displayName = 'Button';

const buttonStyles = makeStyles(() => {
  return {
    loader: {
      color: 'inherit'
    }
  };
});
