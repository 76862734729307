import { useContext, useEffect } from "react";
import { axiosInstance } from "../helpers/axios";
import { UserContext } from "./user";
import axios, { AxiosRequestConfig } from "axios";
import { logInfo, logWarn } from "../services";
import { getUserAccessTokenCookie, getUserRefreshTokenCookie, setUserAccessTokenCookies, setuserRefreshTokenCookies, userLogout } from "../helpers";
import { useHistory } from "react-router-dom";
import { routes } from "../constants";
import { useToastContext } from "../hooks";
import { resetIdleTimer } from "../helpers/IdleLogout";

const buildConfig = require('../buildSettings.json')

const WithAxios = ({ children }) => {
    const userContext = useContext(UserContext);
    const { showToast } = useToastContext();
    const history = useHistory();

    function addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes * 60000);
    }



    const requestErrorInterceptor = function (err) { return Promise.reject(err) };

    const responseResInterceptor = function (res: any): any {
        return Promise.resolve(res);
    }

    useEffect(() => {
        let refreshTokenPromise: Promise<any> | null = null;

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();

    const logoutHandler = async function () {
        await userContext.setUserContext(undefined);
        source.cancel('Request cancelled due to logout.');
        logWarn({},"User was logged out due to WithAxios component")
        setTimeout(() =>
            showToast('info',
                'Due to inactivity or login elsewhere, your session has ended.',
                undefined,
                true),
            250)
        userLogout();
        history.push(routes.login);
    }

        const getUserTokenOrRefresh = async function () {
            const accessToken: any = getUserAccessTokenCookie();
            if (!accessToken) return null;
            if (addMinutes(new Date(), 5) > new Date(accessToken.expiresAt)) {
                console.info('access token about to expire fetching new one')
                const refreshToken: any = getUserRefreshTokenCookie();
                if (!refreshToken || new Date() > new Date(refreshToken.expiresAt)) {
                    console.info('refresh token is expired, logging user out');
                    logWarn({}, "User was logged out due to an expired refresh token.");
                    await logoutHandler();
                } //log the user out
                else {
                    console.info('refresh token still valid');
                    if (refreshTokenPromise == null) {
                        console.info('beginning token refresh')
                        refreshTokenPromise = axios.create({
                            baseURL: `${buildConfig.REACT_APP_API_URL}/`,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).post('api/user/refreshtoken', {
                            body: {
                                token: accessToken.accessToken,
                                refreshToken: refreshToken.refreshToken
                            }
                        }).then(tokenInfo => {
                            refreshTokenPromise = null;
                            console.info('tokens retireved successfully');
                            setUserAccessTokenCookies(tokenInfo.data.accessToken, tokenInfo.data.expiresAt);
                            setuserRefreshTokenCookies(tokenInfo.data.refreshToken, tokenInfo.data.refreshTokenExpiresAt);
                            return tokenInfo;
                        }).catch(async err => {
                            refreshTokenPromise = null;
                            console.error('Token Refresh failed, logging the user out')
                            logWarn({}, 'User was logged out after a failed token refresh')
                            await logoutHandler();
                            console.error(err);
                        })
                    }
                    return refreshTokenPromise.then(tokenInfo => {
                        return tokenInfo.data.accessToken;
                    });
                }
            }
            console.info('using cached token')
            return accessToken.accessToken;
        };

        /**
         * Is run on each request, handles getting the current access token, getting a new one, and logging a user out if their refresh token is dead
         * @param config 
         * @returns 
         */
        const requestConfigInterceptor = async function (config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
            logInfo(null, `axios.js request started: ${config.url}`);
            config.cancelToken = source.token;
            //@ts-ignore
            if (config.skipTokens) return config;
            const token = await getUserTokenOrRefresh();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            resetIdleTimer();
            return config;
        }

        const responseErrorInterceptor = async function (error) {
            const config = error?.config;
            if (error.response &&
                error.response.status === 401 &&
                !config._retry) {
                if (config._retry) {
                    return Promise.reject(error);
                }
                console.info('fetching token from interceptor');

                config._retry = true;

                if (refreshTokenPromise === null) {
                    console.info('creating refresh token promise')
                    const accessToken: any = getUserAccessTokenCookie();
                    const refreshToken: any = getUserRefreshTokenCookie();

                    if (!accessToken || !refreshToken) {
                      logWarn({accessToken, refreshToken}, 'User was logged out during token refresh attempt due to missing tokens');
                      logoutHandler();
                      return Promise.reject(error);
                    }

                    refreshTokenPromise = axiosInstance(
                        'api/User/RefreshToken',
                        {
                            method: 'post',
                            data: {
                                body: {
                                    token: accessToken.accessToken,
                                    refreshToken: refreshToken.refreshToken
                                },
                                skipTokens: true
                            },
                        }).then(tokenResponse => {
                            refreshTokenPromise = null;
                            setUserAccessTokenCookies(tokenResponse.data.accessToken, tokenResponse.data.expiresAt);
                            setuserRefreshTokenCookies(tokenResponse.data.refreshToken, tokenResponse.data.refreshTokenExpiresAt);
                            console.info('tokens retrieved successfully from interceptor');
                            return tokenResponse;
                        }).catch(err => {
                            refreshTokenPromise = null;
                            console.error('Token Refresh failed, logging the user out')
                            logWarn({}, 'User was logged out after a failed token refresh')
                            logoutHandler();
                            console.error(err);
                            return Promise.reject(err);
                        });
                }
                return refreshTokenPromise.then(token => {
                    console.info('retrying call with updated auth', error.config);
                    config.headers['Authorization'] = `Bearer ${token.data.accessToken}`
                    return axiosInstance(config);
                });
            } else {
                return Promise.reject(error);
            }
        }

        const requestInterceptor = axiosInstance.interceptors.request.use(requestConfigInterceptor, requestErrorInterceptor);
        const responseInterceptor = axiosInstance.interceptors.response.use(responseResInterceptor, responseErrorInterceptor)
        return () => {
            axiosInstance.interceptors.response.eject(responseInterceptor);
            axiosInstance.interceptors.request.eject(requestInterceptor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.userContext]);
    return children;
}

export { WithAxios };