import { FC, useState, useMemo, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
// components
import { Table, PageTitle, ITableColumn, Button, ConfirmationDialogue, Separator } from '../../components';
import { AddEditAdditionalInsured } from './AddEditAdditionalInsured';
import { NamedPolicyStatusCell } from './tableCells';
// styles
import { colors } from '../../styles';
// types
import {
  IAdditionalInsured,
  ILineOfCoverage,
  IAdditionalInsuredForMonitoredEntity,
  IEntityRequirement,
  IEnum,
} from '../../types';
import { getLinesOfCoverage, getNamedPolicyStatuses, deleteAdditionalInsuredRequirementStatus } from '../../fetch';
import { useToastContext } from '../../hooks';
import { FormikProps, withFormik } from 'formik';
import { RequirementsHelper } from '../../helpers';

interface IAdditionalInsuredTableProps {
  initialValues: IAdditionalInsuredForMonitoredEntity;
  clientId: number;
  locationId?: number;
  reloadData: Function;
  formIsLoading: boolean;
  requirements: IEntityRequirement[];
  isEditingRequirements: boolean;
  setEditing: (b: boolean) => void;
  editing: boolean;
  hasValidFields: boolean;
  parentDirty: boolean;
  setHasChanged: (b: boolean) => void;
  hasChanged: boolean;
  parentValues: any;
  setParentFieldValue: (field: string, value: any) => void;
}

const AdditionalInsuredTable: FC<IAdditionalInsuredTableProps & FormikProps<IAdditionalInsuredForMonitoredEntity>> = ({
  clientId,
  locationId,
  reloadData,
  formIsLoading,
  requirements,
  setFieldValue,
  parentDirty,
  dirty,
  values,
  resetForm,
  isEditingRequirements,
  initialValues,
  editing,
  setEditing,
  hasValidFields,
  setHasChanged,
  hasChanged,
  parentValues,
  setParentFieldValue,
}) => {
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [linesOfCoverage, setLinesOfCoverage] = useState<ILineOfCoverage[] | []>([]);
  const [namedPolicyStatusEnums, setNamedPolicyStatusEnums] = useState<IEnum[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedAdditionalInsured, setSelectedAdditionalInsured] = useState<IAdditionalInsured>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IAdditionalInsured | null>(null);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);

  const { showToast } = useToastContext();
  const classes = useStyles();

  const load = async () => {
    setIsLoading(true);

    try {
      const response: ILineOfCoverage[] = await getLinesOfCoverage();
      const namedPolicyStatusEnums = await getNamedPolicyStatuses();

      if (response.length) {
        setLinesOfCoverage(response);
      }

      if (namedPolicyStatusEnums.length) {
        setNamedPolicyStatusEnums(namedPolicyStatusEnums);
      }
    } catch (error) {
      showToast(
        'error',
        'We were unable to retrieve the lines of coverage for additional insured. Please refresh and try again.'
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = (field: string, newValue: any, original: IAdditionalInsured) => {
    let updatedValues = [...values.additionalInsureds];

    const reqIndexToUpdate = updatedValues.findIndex(v => v.additionalInsuredId === original.additionalInsuredId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      [`${field}`]: newValue,
    };

    setHasChanged(true);
    setFieldValue('additionalInsureds', updatedValues);
  };

  const useTableProps = {
    updateData,
    editing,
    linesOfCoverage,
    requirements,
    namedPolicyStatusEnums,
  };

  useEffect(() => {
    setFieldValue('additionalInsureds', parentValues.additionalInsured);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues?.additionalInsured]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Additional Insured',
      },
      {
        id: 'linesOfCoverage',
        Header: 'Covered Policies',
        Cell: NamedPolicyStatusCell,
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IAdditionalInsured; index: number } };
        }) => {
          return (
            <div key={original.additionalInsuredId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              {!editing && (
                <Button
                  id={'edit-ai'}
                  color='primary'
                  disabled={isLoading || hasChanged || submitting}
                  onClick={() => {
                    setSelectedAdditionalInsured(original);
                    setShowAddEditModal(true);
                  }}
                >
                  Edit Additional Insured
                </Button>
              )}
              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.additionalInsureds, editing, namedPolicyStatusEnums, linesOfCoverage, hasChanged, isLoading, submitting]);

  const determineAdditionalInsured = () => {
    if (values?.additionalInsureds) {
      return values.additionalInsureds;
    } else if (initialValues?.additionalInsureds) {
      return initialValues.additionalInsureds;
    }
    return [];
  };

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Additional Insured' marginOff />
        {/* EDIT GRID BUTTON */}
        <IconButton
          className={classes.editButton}
          color='primary'
          size='medium'
          disabled={editing}
          onClick={() => {
            setEditing(true);
          }}
        >
          <Edit />
        </IconButton>

        <Separator />

        {/* ADD NEW BUTTON */}
        <Tooltip title='Add Additional Insured'>
          <IconButton
            color='primary'
            size='medium'
            disabled={isLoading || hasChanged || submitting}
            onClick={() => {
              setAddMode(true);
              setShowAddEditModal(true);
              setSelectedAdditionalInsured(null);
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading || formIsLoading}
          useTableProps={useTableProps}
          loadingPageSize={
            !Array.isArray(values?.additionalInsureds) || values?.additionalInsureds?.length === 0
              ? 3
              : values?.additionalInsureds?.length
          }
          columns={columns}
          data={determineAdditionalInsured()}
          headerClasses={classes.tableHeader}
          hidePagination
          stickyHeader
        />
      </div>

      {/* ADD/EDIT MODAL */}
      <AddEditAdditionalInsured
        inUseLinesOfCoverage={RequirementsHelper.requirementsToActiveLinesOfCoverage(requirements)}
        inUseAdditionalInsured={determineAdditionalInsured()}
        selectedAdditionalInsured={selectedAdditionalInsured}
        addMode={addMode}
        clientId={clientId}
        locationId={locationId}
        linesOfCoverage={linesOfCoverage}
        open={showAddEditModal}
        reloadData={reloadData}
        onClose={() => {
          setAddMode(false);
          setShowAddEditModal(false);
        }}
      />

      {/* CONFIRM DELETE MODAL */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-additional-insured-delete'
          title='Delete Additional Insured'
          text='Are you sure you want to delete this additional insured?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            try {
              setSubmitting(true);
              setIsLoading(true);

              const additionalInsuredRequirementStatusIds = selectedRowItem.linesOfCoverage.map(
                loc => loc.additionalInsuredRequirementStatusId
              );

              // Delete selected additional insured item
              await deleteAdditionalInsuredRequirementStatus(additionalInsuredRequirementStatusIds);

              // Re-fetch state
              reloadData();
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
              showToast('success', 'Successfully deleted the additional insured!');
            } catch (error) {
              showToast('error', 'There was an error deleting the additional insured. Please try again.');
            } finally {
              setSubmitting(false);
              setIsLoading(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default withFormik<IAdditionalInsuredTableProps, IAdditionalInsuredForMonitoredEntity>({
  enableReinitialize: true,
  handleSubmit: () => {},
  mapPropsToValues: ({ initialValues }) => {
    return {
      monitoredEntityId: null,
      additionalInsureds: [],
      ...initialValues,
    };
  },
})(AdditionalInsuredTable);

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    importButton: {
      marginLeft: theme.spacing(1),
    },
    editButton: {
      marginLeft: theme.spacing(1),
    },
    saveButton: {
      color: theme.palette.success.main,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.common.white,
      },
    },
    cancelButton: {
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
      },
    },
    cancelIcon: {
      fontSize: '2rem',
    },
    gridContainer: {
      width: '100%',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
