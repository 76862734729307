import React, { createContext, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// models
import { IClientSelector } from '../types';

interface IClientSelectorContext {
  setClientSelectorContext: (clientSelectorContext: IClientSelector | undefined) => void;
  clientSelectorContext: IClientSelector | undefined;
  isFetchingClientSelectorContext: boolean;
}

export const ClientSelectorContext = createContext<IClientSelectorContext>({ setClientSelectorContext: () => {}, clientSelectorContext: undefined, isFetchingClientSelectorContext: false });

interface IClientSelectorContextProviderProps {}

export const ClientSelectorContextProvider: FC<IClientSelectorContextProviderProps> = ({ children }): JSX.Element => {
  const location = useLocation();

  const [clientSelectorContext, setClientSelectorContext] = useState<IClientSelector | undefined>(undefined);
  const [isFetchingClientSelectorContext, setIsFetchingClientSelectorContext] = useState<boolean>(true);

  const handleSetClientSelectorContext = (clientSelectorContext: IClientSelector | undefined) => {
    if (clientSelectorContext) {
      sessionStorage.setItem('client', JSON.stringify(clientSelectorContext));
    } else {
      sessionStorage.removeItem('client');
    }
    setClientSelectorContext(clientSelectorContext);
  };

  const fetchClientSelectorFromSessionStorage = async () => {
    try {
      const clientSelectorContext = await JSON.parse(sessionStorage.getItem('client'));
      if (clientSelectorContext) {
        setClientSelectorContext(clientSelectorContext);
      }
    } catch (e) {
      console.log(e, 'fetchClientSelectorFromSessionStorage');
    } finally {
      setIsFetchingClientSelectorContext(false);
    }
  };
  useEffect(() => {
    fetchClientSelectorFromSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <ClientSelectorContext.Provider value={{ clientSelectorContext, setClientSelectorContext: handleSetClientSelectorContext, isFetchingClientSelectorContext }}>{children}</ClientSelectorContext.Provider>;
};
