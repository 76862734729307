import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Page } from '../../components';
import { Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Table, ITableColumn, Pagination, Toast, ConfirmationDialogue } from '../../components';
import { ManageCarriersFilters } from './ManageCarriersFilters';
import AddEditCarriers from './AddEditCarriers';
// Models
import { SortOptions, DESC_SORT, ASC_SORT, ICarrier, ICarrierGrid, IManageCarriersFilters } from '../../types';
// Fetch
import { getCarriers, getCarrier, deleteCarrier } from '../../fetch';
// Icons
import { Edit, Delete } from '@material-ui/icons';
// Helpers
import { screenSizes } from '../../constants';
import { colors } from '../../styles';
// hooks
import { useToastContext } from '../../hooks';

export const ManageCarriers = () => {
  const [carriers, setCarriers] = useState<ICarrierGrid[]>([]);
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [isCarrierModalOpen, showCarrierModal] = useState<boolean>(false);
  const [currentCarrier, setCurrentCarrier] = useState<ICarrier | null>(null);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [filters, setFilters] = useState<IManageCarriersFilters>({});
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
    BestNumber?: SortOptions;
    BestRating?: SortOptions;
  }>({
    Name: ASC_SORT
  });
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const { showToast } = useToastContext();

  // api
  const fetchCarriers = async () => {
    setLoading(true);
    try {
      const res = await getCarriers({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        name: filters.name,
        bestNumber: filters.bestNumber,
        bestRating: filters.bestRating
      });
      setCarriers(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async id => {
    try {
      const res = await getCarrier(id);
      setCurrentCarrier(res);
      showCarrierModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteCarrier(id);
      fetchCarriers();
      showSuccess(true);
    } catch (error) {
      console.log(error);
      showError(true);
    }
  };

  useEffect(() => {
    fetchCarriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters]);

  const hasData = carriers && carriers.length > 0;

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: 'Name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Name');
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT
          });
        },
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ICarrierGrid } };
        }) => {
          return <div>{original.name}</div>;
        }
      },
      {
        id: 'bestNumber',
        Header: 'Best Number',
        isServerSorted: selectedSort === 'BestNumber',
        isServerSortedDesc: sortDirection.BestNumber === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('BestNumber');
          setSortDirection({
            ...sortDirection,
            BestNumber: sortDirection.BestNumber === ASC_SORT ? DESC_SORT : ASC_SORT
          });
        },
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ICarrierGrid } };
        }) => {
          return <div>{original.bestNumber}</div>;
        }
      },
      {
        id: 'bestRating',
        Header: 'Best Rating',
        isServerSorted: selectedSort === 'BestRating',
        isServerSortedDesc: sortDirection.BestRating === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('BestRating');
          setSortDirection({
            ...sortDirection,
            BestRating: sortDirection.BestRating === ASC_SORT ? DESC_SORT : ASC_SORT
          });
        },
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ICarrierGrid } };
        }) => <div>{original.bestRating}</div>
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ICarrierGrid } };
        }) => {
          return (
            <div key={original.carrierId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={async () => {
                  await handleEdit(original.carrierId);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedId(original.carrierId);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      }
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Manage Carriers'>
      {/* FILTER BAR */}
      <ManageCarriersFilters handleFilter={setFilters} isLoading={isLoading} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table hidePagination columns={columns} data={carriers} isLoading={isLoading} headerClasses={classes.tableHeader} stickyHeader />
        {!isLoading && hasData && <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />}
      </div>

      {/* ADD/EDIT MODAL */}
      <AddEditCarriers
        initialValues={currentCarrier}
        open={isCarrierModalOpen}
        onClose={() => {
          showCarrierModal(false);
          setTimeout(() => {
            setCurrentCarrier(null);
          }, 500);
        }}
        onSave={() => fetchCarriers()}
        showToast={showToast}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedId && (
        <ConfirmationDialogue
          id={`${selectedId}`}
          title={'Delete Carrier'}
          text={'Are you sure you want to delete this carrier?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedId);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab color='secondary' className={isMobile ? classes.mobileAddButton : classes.addButton} aria-label='add' onClick={() => showCarrierModal(true)}>
        <AddIcon />
      </Fab>

      {/* SUCCESS/FAIL MESSAGING */}
      <Toast id='carrier-success' message={`Carrier Deleted!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='carrier-error'
        autoHideDuration={6000}
        message={'We were unable to delete the carrier at this time. Please try again later. Please contact support if this issue continues.'}
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden'
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem'
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem'
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5)
      }
    }
  };
});
