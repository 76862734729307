import axios, { AxiosResponse } from 'axios';

import { authFetch } from './';
import { IFile, IMonitoredEntity, IMonitoredEntityType } from '../types';


const baseUrl = '/api/MonitoredEntity';

export const updateMonitoredEntity = async (data: IMonitoredEntity): Promise<IMonitoredEntity[]> => {
  try {
    const response: AxiosResponse<IMonitoredEntity[]> = await authFetch.post(`${baseUrl}/`, data);

    if (!(response.status >= 200 && response.status < 300)) {
      return Promise.reject(response.statusText);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const postUnitTypes = async (unitType: IMonitoredEntityType): Promise<IMonitoredEntityType[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/MonitoredEntityType`, unitType);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating the unit types. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const importEntities = async (clientId: number, fileInfo: IFile, createDefaultRequirements: boolean): Promise<{}> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Import`, { clientId, fileInfo, createDefaultRequirements });

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error importing monitored entities. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
