export interface IContactGroupDistributionList {
  contactGroupDistributionListId: number;
  contactType: IContactTypeValue;
  email: string;
  isDeleted?: boolean;
}

export interface IAddress {
  address1: string;
  address2: null | string;
  addressId: null | number;
  city: string;
  country: null | string;
  postalCode: string;
  state: string;
}

export interface IContactGroup {
  address: IAddress;
  clientId: number | null;
  clientName: string | null;
  contactGroupId: number;
  distributionLists: IContactGroupDistributionList[];
  isDeleted?: boolean;
  name: string;
  phoneNumber: string | null;
  attention: string | null;
  sendNotifications: boolean;
}

export interface IBlanketCertificate {
  blanketCertificateId: number;
  clientId: number | null;
  clientName: string | null;
  contactName: string;
  contactId: number;
  enteredDate: Date | null;
  isDeleted: boolean;
  user: string | null;
  expirationDate: Date | null;
  documentDate: Date | null;
  matchAllCertificateHolders: boolean;
  matchAllContactGroups: boolean;
  contactGroupAssociations: IAssociation[];
  certificateHolderAssociations: IAssociation[];
}

export interface IContactForm {
  contactId: number;
  name: string;
}

export interface IContact {
  contactId: number;
  name: string;
  contactGroups: IContactGroup[];
  blanketCertificates?: IBlanketCertificate[];
}

export interface IUpdateContact {
  contactId: number;
  name: string;
}

export interface INewContact {
  name: string;
}

export interface IDistributionListPost {
  contactGroupId: number;
  distributionList: IContactGroupDistributionList[];
}

export interface IContactGridRes {
  records: IContact[];
  totalRecordCount: number;
}

export interface IManageContactsFilters {
  name?: string;
  email?: string;
}

export interface IContactDropdownListItem {
  contactId: number;
  name: string;
  contactGroups: IContactGroup[];
}

export enum IContactTypeValue {
  Contact = 1,
  Agent = 2,
}

export interface IEnumDropdown {
  value: string;
  text: string;
  description: null | string;
}

export interface IUpdateBlanketCertificate {
  clientId?: number;
  blanketCertificateId: number;
  contactId: number;
  expirationDate: Date;
  documentDate: Date;
  matchAllCertificateHolders: boolean;
  matchAllContactGroups: boolean;
  contactGroupAssociations: number[];
  certificateHolderAssociations: number[];
  requirements: IBlanketCertificateDefaultRequirement[];
  fileId: string | number;
  fileName: string;
}

export interface INewBlanketCertificate {
  clientId: number;
  contactId: number;
  expirationDate: Date;
  documentDate: Date;
  matchAllCertificateHolders: boolean;
  matchAllContactGroups: boolean;
  contactGroupAssociations: IAssociation[];
  certificateHolderAssociations: IAssociation[];
}

export interface IAssociation {
  id: number;
  text: string;
  isChecked: boolean;
}

export type IPayloadBlanketCertificate = IUpdateBlanketCertificate & INewBlanketCertificate;

export interface IBlanketCertificateDefaultRequirement {
  blanketCertificateRequirementId: number;
  blanketCertificateId: number;
  defaultRequirementId: number;
  lineOfCoverageName: string;
  coverageType: number;
  actualAmount: number;
  expirationDate: Date;
  documentDate: Date;
  carrierId: number;
  carrierName: string;
  applyCredit: boolean;
}
export interface IBulkUpdateStatus {
  entitiesCompleted: number;
  entitiesRemaining: number;
  entitiesTotal: number;
}
