import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { useMedia } from 'react-use';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { Form, withFormik, FormikProps } from 'formik';
// Components
import { Modal, Loader, DatePicker } from '../../components';
import { Fade, CardActions, Button, TextField } from '@material-ui/core';
// Types
import { ICarrier } from '../../types';
// Icons
import { Add, Save } from '@material-ui/icons';
// Fetch
import { updateCarrier, createCarrier } from '../../fetch';
// constants
import { screenSizes } from '../../constants';

interface IAddEditCarriersFormProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialValues: ICarrier | {};
  showToast: (type: string, message: string) => void;
}

const AddEditCarriers: FC<IAddEditCarriersFormProps & FormikProps<ICarrier>> = ({
  open,
  onClose,
  onSave,
  initialValues,
  resetForm,
  isSubmitting,
  values,
  setFieldValue,
  errors, touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur
}) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);

  return (
    <Modal
      maxWidth={'md'}
      open={open}
      title={values.carrierId ? 'Edit Carrier' : 'Add New Carrier'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <div className={isMobile ? classes.mobileContent : classes.content}>
            <div className={`${classes.formColumn} ${!isMobile ? classes.columnPadding : ''}`}>
              <TextField
                fullWidth
                required
                autoComplete='nope'
                label='Carrier Name'
                name='name'
                value={values.name}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('name', e.target.value)}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                label='Best Number'
                autoComplete='nope'
                name='bestNumber'
                value={values.bestNumber}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('bestNumber', e.target.value)}
                error={Boolean(touched.bestNumber && errors.bestNumber)}
                helperText={touched.bestNumber && errors.bestNumber}
              />
              <TextField
                fullWidth
                label='Best Rating'
                autoComplete='nope'
                name='bestRating'
                value={values.bestRating}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('bestRating', e.target.value)}
                error={Boolean(touched.bestRating && errors.bestRating)}
                helperText={touched.bestRating && errors.bestRating}
              />
              <TextField
                fullWidth
                label='Group Name'
                autoComplete='nope'
                name='groupName'
                value={values.groupName}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('groupName', e.target.value)}
                error={Boolean(touched.groupName && errors.groupName)}
                helperText={touched.groupName && errors.groupName}
              />
            </div>
            <div className={classes.formColumn}>
              <TextField
                fullWidth
                label='Group Number'
                autoComplete='nope'
                name='bestNumber'
                value={values.groupNumber}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('groupNumber', e.target.value)}
                error={Boolean(touched.groupNumber && errors.groupNumber)}
                helperText={touched.groupNumber && errors.groupNumber}
              />
              <TextField
                fullWidth
                label='FEIN Number'
                autoComplete='nope'
                name='feinNumber'
                value={values.feinNumber}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('feinNumber', e.target.value)}
                error={Boolean(touched.feinNumber && errors.feinNumber)}
                helperText={touched.feinNumber && errors.feinNumber}
              />
              <TextField
                fullWidth
                label='NAIC Number'
                autoComplete='nope'
                name='naicNumber'
                value={values.naicNumber}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={e => setFieldValue('naicNumber', e.target.value)}
                error={Boolean(touched.naicNumber && errors.naicNumber)}
                helperText={touched.naicNumber && errors.naicNumber}
              />
              <DatePicker
                id='effective-date-date-picker'
                fullWidth
                label='Effective Date'
                placeholder=''
                autoComplete='nope'
                name='naicNumber'
                value={values.effectiveDate ? new Date(values.effectiveDate) : null}
                className={classes.formTextField}
                onBlur={handleBlur}
                onChange={date => setFieldValue('effectiveDate', date?.toISOString())}
                error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                helperText={touched.effectiveDate && errors.effectiveDate}
              />
            </div>
          </div>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.carrierId ? <Save /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.carrierId ? 'Save' : 'Add Carrier'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const CarrierSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  bestNumber: Yup.string(),
  bestRating: Yup.string(),
  groupName: Yup.string(),
  groupNumber: Yup.string(),
  feinNumber: Yup.string(),
  naicNumber: Yup.string(),
  effectiveDate: Yup.string()
});

export default withFormik<IAddEditCarriersFormProps, ICarrier>({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      carrierId: 0,
      name: '',
      bestNumber: '',
      bestRating: '',
      groupName: '',
      groupNumber: '',
      feinNumber: '',
      naicNumber: '',
      effectiveDate: '',
      ...initialValues
    }
  },
  validationSchema: CarrierSchema,
  handleSubmit: async (values, { resetForm, props: { onClose, onSave, showToast }}) => {
    try {
      if (values.carrierId) {
        await updateCarrier(values);
        showToast('success', 'Carrier Updated!');
        onClose();
        onSave();
        resetForm();
      } else {
        await createCarrier(values);
        showToast('success', 'Carrier Created!');
        onClose();
        onSave();
        resetForm();
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'We were unable to create the carrier at this time. Please try again later. Please contact support if this issue continues.');
    }
  }
})(AddEditCarriers);


const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px'
  },
  formTextField: {
    marginBottom: theme.spacing(3)
  },
  content: {
    marginTop: '31px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  mobileContent: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column'
  },
  columnPadding: {
    paddingRight: theme.spacing(9)
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  }
}));
