import React, { FC, useState, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Edit, Delete, Add } from '@material-ui/icons';
import { Fab, IconButton } from '@material-ui/core';
// components
import { Table, ITableColumn, ConfirmationDialogue, PageTitle } from '../../components';
import AddEditUnitTypes from './AddEditUnitTypes';
// helpers
import { colors } from '../../styles';
// types
import { ILocation, IMonitoredEntityType } from '../../types';
// hooks
import { useToastContext } from '../../hooks';
// fetch
import { postUnitTypes } from '../../fetch';
import { ClientType } from '../../constants';

interface IUnitTypesProps {
  monitoredEntityTypes?: Array<IMonitoredEntityType>;
  isLoading: boolean;
  useLocations: boolean;
  clientId: number;
  clientType: number;
  updateMonitoredEntityTypes: (values: IMonitoredEntityType[]) => void;
  locations?: Array<ILocation>;
}

export const UnitTypes: FC<IUnitTypesProps> = ({
  useLocations,
  isLoading,
  clientId,
  updateMonitoredEntityTypes,
  monitoredEntityTypes,
  locations,
  clientType
}) => {
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IMonitoredEntityType>(null);
  const classes = useStyles();
  const { showToast } = useToastContext();

  const getLocationNames = (locationIds) => {
    const locationNames = [];

    locationIds.forEach(locationId => {
      const location = locations.find(location => location.locationId === locationId);

      if (location && location.name) {
        locationNames.push(location.name);
      }
    });
    return locationNames.join(', ');
  }

  const columns = useMemo(() => {
    const columnDefs = [
      {
        id: 'name',
        accessor: 'name',
        Header: clientType === ClientType.Retail ? 'Unit Type Name' : 'Contractor Type Name'
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
                 cell: {
                   row: { original }
                 }
               }: {
          cell: { row: { original: IMonitoredEntityType } };
        }) => {
          return (
            <div key={original.monitoredEntityTypeId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      }
    ].filter(Boolean) as ITableColumn[];

    if (useLocations) {
      columnDefs.splice(1, 0, {
        id: 'locationName',
        Header: 'Location',
        accessor: (original: IMonitoredEntityType) => {
          if (!!original?.locationIds?.length) {
            if (locations.filter(_ => !_.isDeleted).length === original.locationIds.length) {
              return 'ALL';
            }
            return getLocationNames(original.locationIds);
          }
          return '';
        },
      });
    }

    return columnDefs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredEntityTypes]);

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title={clientType === ClientType.Retail ? 'Unit Types' : 'Contractor Types'} marginOff />
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          loadingPageSize={!Array.isArray(monitoredEntityTypes) || monitoredEntityTypes.length === 0 ? 3 : monitoredEntityTypes.length}
          key={JSON.stringify(monitoredEntityTypes?.filter(unitType => !unitType.isDeleted))}
          columns={columns}
          data={monitoredEntityTypes ? monitoredEntityTypes?.filter(unitType => !unitType.isDeleted) : []}
          headerClasses={classes.tableHeader}
          centerPagination
          stickyHeader
        />
      </div>

      {/* ADD NEW BUTTON */}
      <Fab
        className={classes.addButton}
        onClick={() => setShowAddEditModal(true)}
      >
        <Add />
      </Fab>

      {/* ADD/EDIT MODAL */}
      <AddEditUnitTypes
        locations={locations}
        useLocations={useLocations}
        clientId={clientId}
        clientType={clientType}
        open={showAddEditModal}
        showToast={showToast}
        onClose={() => {
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
        onSave={async (unitType) => {
          updateMonitoredEntityTypes(unitType);
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
        initialValues={{
          clientId: clientId,
          ...selectedRowItem
        }}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-unit-type-delete'
          title={clientType === ClientType.Retail ? 'Delete Unit Type' : 'Delete Contractor Type'}
          text='Are you sure you want to delete this unit type?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            const updated = {
              ...selectedRowItem,
              isDeleted: true
            };
            try {
              const response = await postUnitTypes(updated);
              updateMonitoredEntityTypes(response);
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
              showToast('success', clientType === ClientType.Retail ? 'Successfully deleted the unit type!' : 'Successfully deleted the contractor type!');
            } catch (error) {
              showToast('error', 'There was an error deleting the monitored entity type. Please try again.');
            }
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    },
    gridContainer: {
      width: '100%'
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5)
      }
    }
  };
});
