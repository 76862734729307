import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from './TablePaginationActions';

interface IPaginationProps {
  labelRowsPerPage?: string;
  rowsPerPageOptions?: number[] | { label: string; value: number }[] | any;
  count: number;
  rowsPerPage: number;
  page: number;
  setPage: (newPage: number) => void;
  setRowsPerPage: (val: number) => void;
}

export const Pagination: FC<IPaginationProps> = ({ labelRowsPerPage, rowsPerPageOptions, count, rowsPerPage, page, setPage, setRowsPerPage }) => {
  const classes = paginationStyles();
  return (
    <TablePagination
      labelRowsPerPage={labelRowsPerPage}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50]}
      component='div'
      classes={{
        root: classes.paginationRoot
      }}
      onChangePage={(e: any, newPage: number) => setPage(newPage)}
      onChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value, 10));
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
};

const paginationStyles = makeStyles((theme: Theme) => ({
  paginationRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    '&& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: '5px'
    },
    '&& .MuiToolbar-gutters': {
      paddingLeft: 0
    },
    '&& .MuiTablePagination-actions': {
      marginLeft: 0
    },
    [theme.breakpoints.up('md')]: {
      '&& .MuiTablePagination-selectRoot': {
        marginLeft: '8px',
        marginRight: '32px'
      },
      '&& .MuiToolbar-gutters': {
        paddingLeft: 0
      },
      '&& .MuiTablePagination-actions': {
        marginLeft: '20px'
      }
    }
  }
}));
