import { FC, useState } from 'react';
import { Save } from '@material-ui/icons';
import { Modal, Loader, Select, CheckboxList } from '../../components';
import { deepEqual } from 'fast-equals';
import { Fade, CardActions, Button, Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { colors } from '../../styles';
import { useToastContext } from '../../hooks';
import { IAdditionalInsured, ILocation } from '../../types';
import { Autocomplete } from '@material-ui/lab';
import { replaceAdditionalInsured } from '../../fetch';
import * as Yup from 'yup';

interface IReplaceAdditionalInsuredProps {
  open: boolean;
  onClose: () => void;
  locations?: Array<ILocation>;
  useLocations?: boolean;
  additionalInsureds?: Array<IAdditionalInsured>;
}

const ReplacementAdditionalInsuredSchema = Yup.object().shape({
  additionalInsuredId: Yup.number().required('Required'),
  additionalInsuredReplacementId: Yup.number().nullable(),
  newAdditionalInsuredName: Yup.string().when('additionalInsuredReplacementId', {
    is: additionalInsuredReplacementId => additionalInsuredReplacementId === null,
    then: Yup.string().required('You must select another additional insured to replace with.'),
    otherwise: Yup.string().nullable(),
  }),
});

export const ReplaceAdditionalInsured: FC<IReplaceAdditionalInsuredProps> = ({
  open,
  onClose,
  locations,
  useLocations,
  additionalInsureds,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showToast } = useToastContext();
  const classes = useStyles();

  const getLocationsFromAdditionalInsured = additionalInsured => {
    if (!additionalInsured) {
      return;
    }

    const locationList = [];

    additionalInsured.locationIds.forEach(x => {
      const matchingLine = locations.find(y => x === y.locationId);
      if (matchingLine) {
        locationList.push(matchingLine);
      }
    });

    return locationList;
  };

  const getLocationCheckboxItems = selectedAdditionallyInsuredId => {
    const matchingAdditionalInsured: IAdditionalInsured = additionalInsureds.find(
      ai => ai.additionalInsuredId === selectedAdditionallyInsuredId
    );
    const locationList: ILocation[] = getLocationsFromAdditionalInsured(matchingAdditionalInsured);

    if (locationList) {
      return locationList.map(location => {
        return { id: location.locationId, name: location.name };
      });
    } else {
      return [];
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        additionalInsuredId: null,
        additionalInsuredReplacementId: null,
        locationIds: [],
        maintainCurrentCredit: false,
        newAdditionalInsuredName: '',
      }}
      validationSchema={ReplacementAdditionalInsuredSchema}
      onSubmit={(values, actions) => {
        setIsLoading(true);

        try {
          replaceAdditionalInsured({ ...values }).then(() => {
            actions.resetForm();
            showToast('success', 'Successfully replaced the additional insured!');
            setIsLoading(false);
            onClose();
          });
        } catch (error) {
          showToast('error', 'There was a problem replacing the additional insured. Please try again later.');
          setIsLoading(false);
        }
      }}
    >
      {({
        resetForm,
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        touched,
        errors,
        handleBlur,
      }) => {
        return (
          <Modal
            maxWidth={'sm'}
            open={open}
            title={'Replace Additional Insured'}
            onClose={() => {
              if (!deepEqual(initialValues, values)) {
                const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                if (result) {
                  resetForm();
                  onClose();
                } else {
                  return;
                }
              } else {
                onClose();
                resetForm();
              }
            }}
          >
            {/* FORM */}
            {isLoading && <Loader type='overlay' position='centered' />}
            <Fade in={open}>
              <Form onSubmit={handleSubmit} autoComplete='none'>
                <Grid container direction={'column'}>
                  <Grid container spacing={1}>
                    {/* ADDITIONAL INSURED TO REPLACE */}
                    <Grid item xs={12} md={6}>
                      <Select
                        name='additionalInsuredId'
                        id='additional-insured-replace-select'
                        value={values.additionalInsuredId}
                        label='Additional Insured to Replace *'
                        onChange={e => setFieldValue('additionalInsuredId', e.target.value)}
                        error={Boolean(touched.additionalInsuredId && errors.additionalInsuredId)}
                        onBlur={handleBlur}
                        options={additionalInsureds.map(ai => ({
                          key: ai.additionalInsuredId,
                          label: ai.name,
                          value: ai.additionalInsuredId,
                        }))}
                      />
                    </Grid>

                    {/* NEW ADDITIONAL INSURED REPLACEMENT */}
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id='additional-insured-new-autocomplete'
                        options={additionalInsureds}
                        getOptionLabel={option => option.name}
                        freeSolo
                        onChange={(e, item: any) => {
                          if (!item) {
                            return;
                          }

                          if (item.additionalInsuredId) {
                            setFieldValue('additionalInsuredReplacementId', item.additionalInsuredId);
                          } else if (item.newAdditionalInsuredName) {
                            setFieldValue('newAdditionalInsuredName', item.newAdditionalInsuredName);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label='Replacement Additional Insured *'
                            onChange={e => setFieldValue('newAdditionalInsuredName', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {/* LOCATIONS FOR REPLACEMENT */}
                  {useLocations && (
                    <Grid item xs={12} className={classes.formField}>
                      <CheckboxList
                        header={'Locations'}
                        saveCheckboxSelectionToState={selectedItems => setFieldValue('locationIds', selectedItems)}
                        checkboxItems={getLocationCheckboxItems(values.additionalInsuredId)}
                        checkedValuesList={values.locationIds}
                        enableSelectAll
                      />
                    </Grid>
                  )}

                  <Grid container spacing={1} className={classes.formField}>
                    {/* REPLACE WITH CREDIT */}
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        label='Maintain Current Credit'
                        control={
                          <Checkbox
                            checked={values.maintainCurrentCredit}
                            onChange={() => setFieldValue('maintainCurrentCredit', !values.maintainCurrentCredit)}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* FORM BUTTONS */}
                <CardActions>
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={() => {
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.saveButton}
                      disabled={!dirty || isSubmitting || !isValid}
                      type='submit'
                      startIcon={<Save />}
                      variant='contained'
                      color='primary'
                    >
                      {'Update'}
                    </Button>
                  </div>
                </CardActions>
              </Form>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  formField: {
    marginTop: theme.spacing(1),
  },
}));
