import React, { FC, useState } from 'react';
import { Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Switch, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface ICheckboxList {
  header: string;
  saveCheckboxSelectionToState(values: number[]): void;
  checkboxItems: { id: number; name: string }[];
  checkedValuesList: number[];
  enableSelectAll?: boolean;
  selectAllLabel?: string;
  emptyStateMessage?: string;
}

export const CheckboxList: FC<ICheckboxList> = ({
  header,
  saveCheckboxSelectionToState,
  checkboxItems,
  checkedValuesList,
  enableSelectAll = false,
  selectAllLabel = `All ${header}`,
  emptyStateMessage
}) => {
  const [isSelectAllToggled, setIsSelectAllToggled] = useState<boolean>(false);
  const classes = useStyles();

  const onCheckboxClick = (checkedValues, newCheckedValue) => {
    const updatedCheckedValuesList = [...checkedValues];
    const foundIndex = updatedCheckedValuesList.findIndex(i => i === newCheckedValue);

    if (foundIndex > -1) {
      updatedCheckedValuesList.splice(foundIndex, 1);
    } else {
      updatedCheckedValuesList.push(newCheckedValue);
    }

    // This will pass back the updated list to whichever save function
    // you pass in
    saveCheckboxSelectionToState(updatedCheckedValuesList);
  };

  const handleSelectAllToggle = () => {
    if (!isSelectAllToggled) {
      const updatedCheckedValuesList = checkboxItems.map(item => item.id);
      saveCheckboxSelectionToState(updatedCheckedValuesList);
    }

    setIsSelectAllToggled(!isSelectAllToggled);
  };

  return (
    <div className={classes.checkboxContainer}>
      <Typography variant='h6'>{header}</Typography>

      {/* EMPTY STATE */}
      {checkboxItems.length === 0 && <Typography>{emptyStateMessage ?? `There are no "${header}" to show yet.`}</Typography>}

      {
        /* SELECT ALL TOGGLE */
        checkboxItems.length !== 0 && enableSelectAll && (
          <FormControlLabel
            className={classes.toggle}
            control={
              <Switch
                checked={isSelectAllToggled}
                onChange={() => {
                  handleSelectAllToggle();
                }}
              />
            }
            label={selectAllLabel}
          />
        )
      }

      {/* CHECK-BOX LIST */}
      <List className={classes.listContainer}>
        {checkboxItems.length > 0 &&
          checkboxItems.map(item => (
            <ListItem
              disabled={isSelectAllToggled}
              key={item.id}
              role={undefined}
              dense
              button
              onClick={() => onCheckboxClick(checkedValuesList, item.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checkedValuesList?.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': item.name }}
                />
              </ListItemIcon>
              <ListItemText id={item.name} primary={item.name} />
            </ListItem>
          ))}
      </List>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: {
    height: '100%',
    width: '100%'
  },
  listContainer: {
    marginLeft: theme.spacing(-0.75),
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  toggle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
