import {
  IBulkUpdateStatus,
  IAssociation,
  IBlanketCertificateDefaultRequirement,
  INewBlanketCertificate,
  IUpdateBlanketCertificate,
} from './../types/contacts';
import axios from 'axios';
import { IHttpPostMessageResponse } from 'http-post-message';

import {
  IContact,
  IContactDropdownListItem,
  IContactGridRes,
  IContactGroup,
  IDistributionListPost,
  IEnum,
  IEnumDropdown,
  IError,
  IFile,
  INewContact,
  IUpdateContact,
} from '../types';
import { authFetch } from './';

const baseUrl = '/api/Contact';

export const getContact = async (id: number): Promise<IContact> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting contact. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getContactInformationListByClientId = async (
  clientId: number,
  searchTerm?: string
): Promise<IContactDropdownListItem[]> => {
  try {
    const params = {
      clientId,
      searchTerm,
    };
    const res = await authFetch.get(`${baseUrl}/client/`, { params });

    if (res?.status !== 200) {
      throw Error(res?.statusText);
    }

    if (res?.data) {
      return res.data;
    }

    const error = { Detail: 'Error getting contact for client. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.response.data);
    }
  }
};

export const deleteContact = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting contact. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createContact = async (contact: INewContact): Promise<IContact> => {
  try {
    const res = await authFetch.post(`${baseUrl}`, contact);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating contact. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateContact = async (contact: IUpdateContact): Promise<IContact> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, contact);
    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating contact. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const upsertContactGroup = async (contactId: number, contactGroup: IContactGroup): Promise<IContactGroup> => {
  try {
    const res = await authFetch.put(`${baseUrl}/${contactId}/Group`, contactGroup);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getContacts = async (filters: {
  page: number;
  perPage: number;
  name?: string;
  email?: string;
  clientId?: number;
  sortBy: string;
  sortDirection: 'desc' | 'asc';
}): Promise<IContactGridRes> => {
  let query = '';
  if (filters) {
    query = `?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`;
    if (filters.name) {
      query += `&name=${filters.name}`;
    }
    if (filters.email) {
      query += `&email=${filters.email}`;
    }
    if (filters.clientId) {
      query += `&clientId=${filters.clientId}`;
    }
  }

  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return {
    records: [],
    totalRecordCount: 0,
  };
};

export const getContactsDropdownList = async (): Promise<Array<IContactDropdownListItem>> => {
  try {
    const res = await authFetch.get(`${baseUrl}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting contacts for unit dropdown. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getContactTypes = async (): Promise<IEnum[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/ContactTypes`);
    if (res?.status !== 200) throw Error(res.statusText);
    if (res?.data) return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const createDistributionList = async (distributionList: IDistributionListPost): Promise<any> => {
  try {
    return await authFetch.put(`${baseUrl}/DistributionList `, distributionList);

  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const importContacts = async (fileInfo: IFile): Promise<IHttpPostMessageResponse<any>> => {
  try {
    await authFetch.post(`${baseUrl}/Import`, { fileInfo });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getCountriesDropdown = async (): Promise<IEnumDropdown[]> => {
  try {
    const res = await authFetch.get('/api/Lookup/Countries');
    if (res?.status !== 200) {
      throw Error(res.statusText);
    }
    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const getUnitedStatesDropdown = async (): Promise<IEnumDropdown[]> => {
  try {
    const res = await authFetch.get('/api/Lookup/US_States');
    if (res?.status !== 200) {
      throw Error(res.statusText);
    }
    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const createBlanketCertificate = async (data: INewBlanketCertificate): Promise<any> => {
  try {
    const res = await authFetch.post(`${baseUrl}/BlanketCertificate`, data);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating blanket certificate. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateBlanketCertificate = async (data: IUpdateBlanketCertificate): Promise<any> => {
  try {
    const res = await authFetch.put(`${baseUrl}/blanketCertificate`, data);

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getBlanketCertificateById = async (blanketCertificateId: number): Promise<IUpdateBlanketCertificate> => {
  try {
    const res = await authFetch.get(`${baseUrl}/blanketCertificate/${blanketCertificateId}`);
    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const getBlanketCertificateAvailableCertificateHolders = async (clientId: number): Promise<IAssociation[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/blanketCertificate/AvailableCertificateHolders/${clientId}`);
    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const getBlanketCertificateAvailableContactGroups = async (filters: {
  clientId: number;
  contactId: number;
}): Promise<IAssociation[]> => {
  try {
    let query = '';
    if (filters) {
      query = `?clientId=${filters.clientId}`;
      if (filters.contactId) {
        query += `&contactId=${filters.contactId}`;
      }
    }
    const res = await authFetch.get(`${baseUrl}/blanketCertificate/AvailableContactGroups${query}`);

    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const deleteBlanketCertificate = async (blanketCertificateId: number): Promise<any> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/blanketCertificate/${blanketCertificateId}`);

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getBlanketCertificateDefaultRequirementsByClientId = async (
  clientId: number
): Promise<IBlanketCertificateDefaultRequirement[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/blanketCertificate/DefaultRequirements/${clientId}`);

    if (res?.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
};

export const createBulkUpdateQueue = async (blanketCertificateId: number): Promise<any> => {
  try {
    return await authFetch.post(`${baseUrl}/BlanketCertificate/BulkUpdateQueue/${blanketCertificateId}`, null);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const runBulkUpdateQueue = async (bulkUpdateRunId: number): Promise<any> => {
  try {
    const res = await authFetch.post(`${baseUrl}/BlanketCertificate/BulkUpdateRun/${bulkUpdateRunId}`, null);
    return res.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getBulkUpdateProgress = async (bulkUpdateRunId: number): Promise<IBulkUpdateStatus> => {
  try {
    const res = await authFetch.get(`${baseUrl}/BlanketCertificate/BulkUpdateProgress/${bulkUpdateRunId}`, null);
    return res.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
