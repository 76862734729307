import React, { FC, useState, useEffect } from 'react';
import { FormControl } from '@material-ui/core';
// components
import { DatePicker } from '../../../components';
// types
import { IEntityRequirement } from '../../../types';
import { IBulkDataDefaultRequirement } from '../../../types/bulkUpdate';

interface IExpirationDateCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  updateData: (
    columnId: string,
    value: any,
    original: IEntityRequirement,
    values: any[],
    setFieldValue: Function
  ) => void;
  values: any;
  setFieldValue: Function;
  selectedRequirements: number[];
  touched: boolean;
  setTouched: (arg: boolean) => void;
  valuesAreNotValid: (values: any) => any;
  setSelectedRequirements?: (value: React.SetStateAction<number[]>) => void;
  requirements: IBulkDataDefaultRequirement[];
  setRequirements: React.Dispatch<React.SetStateAction<IBulkDataDefaultRequirement[]>>;
  updateSelectedRequirements?: (row: any) => void;
  setEntitiesIsDirty(isChanged: boolean): void;
}

export const ExpirationDateCell: FC<IExpirationDateCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateData,
  values,
  setFieldValue,
  selectedRequirements,
  touched,
  setTouched,
  valuesAreNotValid,
  requirements,
  updateSelectedRequirements,
  setRequirements,
  setEntitiesIsDirty,
}) => {
  const [err, setErr] = useState(false);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.expirationDate ?? null);

  // See if the row is selected
  const [isSelected, setIsSelected] = useState(selectedRequirements.includes(original.original.defaultRequirementId));

  useEffect(() => {
    setIsSelected(selectedRequirements.includes(original.original.defaultRequirementId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirements]);

  useEffect(() => {
    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (isSelected) {
      setErr(true);
    }
    if (!isSelected) {
      setErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.expirationDate);
  }, [original.original.expirationDate]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.expirationDate) {
      updateData('expirationDate', value, original.original, values, setFieldValue);
    }
    valuesAreNotValid(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormControl fullWidth>
      <DatePicker
        id='expiration-date-picker'
        onClear={() => setValue(null)}
        onFocusCapture={() => {
          if (touched) {
            return;
          }
          setTouched(true);
        }}
        fullWidth
        placeholder=''
        autoComplete='nope'
        name='expirationDate'
        value={!!value ? new Date(value) : null}
        onChange={date => {
          setValue(date?.toISOString());
          if (setEntitiesIsDirty) setEntitiesIsDirty(true);
          if (updateSelectedRequirements) {
            updateSelectedRequirements(original.original);
          }
          if (setRequirements) {
            const index = values.requirements.findIndex(
              _ => _.defaultRequirementId === original.original.defaultRequirementId
            );
            // Copy the list
            const updatedRequirements = JSON.parse(JSON.stringify(values.requirements));
            updatedRequirements[index] = {
              ...updatedRequirements[index],
              expirationDate: date?.toISOString() ?? null,
            };
            setRequirements(updatedRequirements);
          }
        }}
        label={!value && err ? 'Field required.' : undefined}
        error={!value && err}
        InputLabelProps={{
          shrink: !value && err,
        }}
      />
    </FormControl>
  );
};
