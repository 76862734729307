import axios, { AxiosResponse } from 'axios';

import { CommentType, IMonitoredEntityComment } from '../types';
import { authFetch } from './';

const commentsBaseUrl = '/api/Comments';

export const ICommentsAPI = {
  async create(commentData: Pick<IMonitoredEntityComment, 'text' | 'commentType' | 'relatedObjectId'>): Promise<void> {
    try {
      const response: AxiosResponse<void> = await authFetch.post(commentsBaseUrl, { commentModel: commentData });

      // Check if status is in the 2XX range
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;

    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    }
  },
  async getAllByType(id: number, commentType: CommentType): Promise<IMonitoredEntityComment[]> {
    try {
      const params = {
        id,
        commentType
      };
      const response: AxiosResponse<IMonitoredEntityComment[]> = await authFetch.get(commentsBaseUrl, { params });

      // Check if status is in the 2XX range
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;

    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error?.response?.data);
      }
      return Promise.reject(error);
    }
  },
  async delete(commentId: number): Promise<void> {
    try {
      const response: AxiosResponse<void> = await authFetch.delete(`${commentsBaseUrl}/${commentId}`);

      // Check if status is in the 2XX range
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error?.response?.data);
      }
      return Promise.reject(error);
    }
  },
};
