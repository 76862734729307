export const formatError = (error, showToast, backupMessage=undefined) => {
  if (error?.Detail) {
    showToast('error', error?.Detail);
    return;
  }
  if (error?.Title) {
    showToast('error', error?.Title);
    return;
  }
  if (backupMessage) {
    showToast('error', backupMessage);
    return null;
  }
  if (!error?.errors) {
    return null;
  }
  const { errors } = error;

  const keys = Object.keys(errors);
  const allErrors = keys?.reduce((acc, curr) => {
    return [...acc, ...errors[curr]];
  }, []);

  allErrors.forEach(error => {
    showToast('error', error);
  });
};