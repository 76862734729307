import { IBasicObject } from './global';
import { IAdditionalInsured, IAdditionalInsuredUpdateInformation } from './additionalInsureds';
import { RequirementStatusEnums } from '../constants/RequirementStatuses';
import { IStatusBase } from '.';
import { IEnum } from './enums';

export interface IManageRequirements {
  monitoredEntityId: number;
  monitoredEntityName: string;
  clientId: number;
  clientCode: string;
  locationId: number;
  locationName: string;
  city: null | string;
  state: null | string;
  contactName: null | string;
  contactEmail: null | string;
  contactGroupName: null | string;
  complianceStatus: null | string;
  isActive: null | boolean;
  letterRunDetailsId: number;
  unitNumber: null | string;
}

export enum ManageRequirementsSortBy {
  Name = 1,
  Location = 2,
  Contact = 3,
  City = 4,
  State = 5,
  Client = 6,
  Compliance = 7,
  ContactGroup = 8,
  UnitNumber = 9,
}

// A subset of IManageRequirements with all fields optional
export interface IManageRequirementsFilters
  extends Partial<
    Pick<
      IManageRequirements,
      'clientCode' | 'locationName' | 'monitoredEntityName' | 'unitNumber' | 'contactName' | 'contactEmail' | 'contactGroupName' | 'complianceStatus' | 'isActive' | 'letterRunDetailsId'
    >
  > {}

export interface IEntityRequirementsFormProps {
  clientId: number;
  clientName: string;
  locationName: string;
  requirements: IEntityRequirement[];
  contactName: string;
  unitName: string;
  unitNumber: number;
}

export interface IEntityRequirementHistory {
  entityPrimaryKey: number;
  updatedBy: string;
  changeTrackingDate: string;
  lineOfCoverageName: string;
  monitoredEntityName: string;
  coverageType: string;
  requiredAmount?: number;
  actualAmount?: number;
  expirationDate?: string;
  documentDate?: string;
  carrierName: string;
  status: string;
  hasEvidence: string;
  complianceStatus: string;
}

export interface IEntityRequirement {
  actualAmount: number | string;
  associatedDocumentIds: number[];
  carrierId: number;
  carrierName: null | string;
  documentDate: null | string;
  expirationDate: null | string;
  lineOfCoverageId: number;
  lineOfCoverageName?: string;
  requiredAmount: number | string;
  requirementId: number;
  isDeleted: boolean;
  coverageType: number;
  files?: IBasicObject[];
  status: RequirementStatusEnums;
  hasEvidence?: boolean;
  complianceStatus: number; // Corresponds to Enums from api route /RequirementComplianceStatuses
  monitoredEntityId?: number;
  monitoredEntityName?: string;
  clientName?: string;
  defaultRequirementId?: number;
  shouldOnDemandSummaryTrigger?: boolean;
  defaultRequirementEndDate: null | string;
}

export interface IMonitoredEntityRequirements {
  monitoredEntityInfo: null | {
    userFriendlyEntityNumberText: string;
  };
  associatedFiles: IEnum[];
  statuses: IStatusBase[];
  locationAddress: string;
  contactGroupName: string;
  entityId: number;
  name: string;
  locationId?: number | null;
  unitNumber?: string;
  contactId?: number | null;
  contactGroupId?: number | null;
  isActive: boolean;
  jobName?: string;
  jobLocation?: string;
  jobNumber?: string;
  contractorType?: number | null;
  contractOnFile?: boolean;
  contractExpires?: string;
  clientType: number;
  clientId: number;
  requirements?: IEntityRequirement[];
  locationName: string | null;
  contactName: string | null;
  clientName: string | null;
  additionalInsured?: IAdditionalInsured[];
  isEntityDeficient?: boolean;


}

export interface IMonitoredEntityRequirementsPost {
  updatedRequirements?: IEntityRequirement[];
  monitoredEntityId: number;
  requirements?: IEntityRequirement[];
  additionalInsured?: IAdditionalInsured[];
  isEntityDeficient?: boolean;
}

export interface IBulkUpdateMonitoredEntityRequirementsPost extends IMonitoredEntityRequirementsPost {
  documentDate: Date,
  expirationDate: Date,
  aiInformation: IAdditionalInsuredUpdateInformation[],
}

export interface INotificationsTableFilters {
  sourceType?: number;
}
