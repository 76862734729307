import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './components';
import { routes, userRoles } from './constants';
import {
  ClientSelectorContextProvider,
  NavMenuStateProvider,
  ToastContextProvider,
  UserContextHandler,
} from './context';
import { IdleLogout } from './helpers';
import {
  Letters,
  LinesOfCoverage,
  Login,
  ManageCarriers,
  ManageClients,
  ManageContacts,
  ManageLocationTypes,
  ManageRequirements,
  ManageUsers,
  Reports,
  AdminSystem,
} from './pages';
import { DownloadFile } from './pages/files';
import { LetterGenerationProgress } from './pages/letters/LetterGenerationProgress';
import { LettersGenerated } from './pages/letters/LettersGenerated';
import { NewLetterRun } from './pages/letters/NewLetterRun';
import { ClientForm } from './pages/manageClients/ClientForm';
import { ContactsForm } from './pages/manageContacts/ContactsForm';
import { BulkUpdateRequirementsForm } from './pages/manageRequirements/BulkUpdateRequirementsForm';
import RequirementsForm from './pages/manageRequirements/RequirementsForm';
import { PowerBIReport } from './pages/reports/PowerBIReport';
import { theme } from './styles';
import { WithAxios } from './context/WithAxios';
import { RequestResetPassword } from './pages/resetPassword';
import { ResetPassword } from './pages/resetPassword/ResetPassword';
import { ForgotUsername } from './pages/manageUsers/forgotUsername';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services';
import { ContactLetters } from './pages/contactLetters';
import { ContactLetterRun } from './pages/contactLetters/contactLetterRun';

export default function App() {
  const classes = useStyles();

  const GlobalCss = withStyles({
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        fontFamily: 'Roboto, Arial, sans-serif',
        fontSize: '14px',
        height: '100%',
        margin: '0px',
        overflow: 'hidden',
      },
      '[id="root"]': {
        height: '100%',
      },
      'body *': {
        boxSizing: 'border-box',
      },
    },
  })(() => null);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router>
        <UserContextHandler>
          <ToastContextProvider>
            <WithAxios>
              <IdleLogout />
              <ClientSelectorContextProvider>
                <NavMenuStateProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className={classes.appContainer}>
                      <GlobalCss />
                      {/* APP ROUTES */}
                      <Switch>
                        <Redirect exact from='/' to={routes.login} />
                        <Route exact path={routes.login} component={Login} />
                        <Route exact path={routes.requestResetPassword} component={RequestResetPassword} />
                        <Route exact path={routes.resetPassword} component={ResetPassword} />
                        <Route exact path={routes.forgotUsername} component={ForgotUsername} />
                        <PrivateRoute
                          exact
                          path={routes.manageRequirements}
                          component={ManageRequirements}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.manageRequirements}/:monitoredEntityId`}
                          component={RequirementsForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.bulkUpdateRequirements}/monitoredEntityIds`}
                          component={BulkUpdateRequirementsForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.manageClients}
                          component={ManageClients}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.manageClients}/-1`}
                          component={ClientForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.manageClients}/:clientId`}
                          component={ClientForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.letterRuns}
                          component={Letters}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.newLetterRun}
                          component={NewLetterRun}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.letterGenerationProgress}/letterRunId=:letterRunId`}
                          component={LetterGenerationProgress}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.lettersGenerated}/letterRunId=:letterRunId`}
                          component={LettersGenerated}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.contactLetterRuns}
                          component={ContactLetters}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.newContactLetterRun}
                          component={ContactLetterRun}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.contactLetterGenerationProgress}/letterRunId=:letterRunId`}
                          component={LetterGenerationProgress}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.contactLettersGenerated}/letterRunId=:letterRunId`}
                          component={LettersGenerated}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.reports}
                          component={Reports}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.manageUsers}
                          component={ManageUsers}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.manageCarriers}
                          component={ManageCarriers}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.manageLocationTypes}
                          component={ManageLocationTypes}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.manageContacts}
                          component={ManageContacts}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.manageContacts}/-1`}
                          component={ContactsForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.manageContacts}/:contactId`}
                          component={ContactsForm}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.linesOfCoverage}
                          component={LinesOfCoverage}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={routes.adminSystem}
                          component={AdminSystem}
                          roles={[userRoles.ADMIN]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.powerBi}/:reportId/:powerBIReportId/:reportSecurityType`}
                          component={PowerBIReport}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL]}
                        />

                        <PrivateRoute
                          exact
                          path={`${routes.letterGenerationProgress}/letterRunId=:letterRunId`}
                          component={LetterGenerationProgress}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.lettersGenerated}/letterRunId=:letterRunId`}
                          component={LettersGenerated}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL]}
                        />
                        <PrivateRoute
                          exact
                          path={`${routes.files}/:fileId`}
                          component={DownloadFile}
                          roles={[userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL]}
                        />
                      </Switch>
                    </div>
                  </ThemeProvider>
                </NavMenuStateProvider>
              </ClientSelectorContextProvider>
            </WithAxios>
          </ToastContextProvider>
        </UserContextHandler>
      </Router>
    </AppInsightsContext.Provider>
  );
}

const useStyles = makeStyles(() => {
  return {
    appContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  };
});
