// future proofing validation stuff
export const fileValidator = (files, config) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = false;
  let result = {
    isValidFile: false,
    errVal: err
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    // @ts-ignore
    err = filesLimit > 1
      ? `Only ${filesLimit} files are allowed to be uploaded at a time` :
      'Only one file is allowed to be uploaded at a time';
  } else if (!allowedFileFormats.includes(type)) {
    // @ts-ignore
    err = 'This file format is not allowed';
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    // @ts-ignore
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

export const preventBrowserDefaults = e => {
  e.preventDefault();
  e.stopPropagation();
};

/**
 * Returns the given string with HTML tags removed.
 */
export const stripHtml = (str: string): string =>
  new DOMParser().parseFromString(str, 'text/html').body.textContent || '';
