import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarProps } from '@material-ui/core/Snackbar';

interface IToastProps extends SnackbarProps {
  autoHideDuration?: number;
  id: string;
  message: React.ReactNode | string;
  open: boolean;
  onClose: (e: React.SyntheticEvent, reason?: string) => void;
  variant: 'error' | 'info' | 'success' | 'warning';
}

export const Toast: FC<IToastProps> = ({ autoHideDuration = 3000, id, message, onClose, open, variant, ...props }) => {
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.toast}
      {...props}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={autoHideDuration}
      id={id}
      onClose={onClose}
      open={open}
    >
      <Alert onClose={onClose} severity={variant} variant='filled'>
        {message}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toast: {
    bottom: '68px'
  }
}));
