import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
// images
import TypefontLogo from '../../assets/logo_with_typeface.png';

interface ILogoProps {
    className?: string;    
}

export const Logo: FC<ILogoProps> = ({className}) => {
    const classes = useStyles();
    return <img src={TypefontLogo} alt='App Logo' className={clsx(classes.appLogo, className)} />;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        appLogo: {
            margin: 'auto 0',
            width: '196px'
        }    
    };
});