import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, Assignment, OpenInNew } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ITableColumn, MultiSelect, Page, PageTitle, Separator, Table, UnsavedChanges } from '../../components';
import { LetterRunSourceEnums, routes } from '../../constants';
import {
  getClientDeficiencies,
  getClientsForSelector,
  getContactDeficiencies,
  updateLetterRun,
  updateLetterRunDetails,
} from '../../fetch';
import { getClientCertificateHolders } from '../../fetch/clientCertificateHolders';

import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  IClientSelector,
  IContact,
  IContactGroup,
  IDeficiencies,
  IDeficientEntity,
  IMissingRequirementEntity,
  INewLetterRunFilters,
} from '../../types';
import { NewLetterRunFilters } from './NewLetterRunFilters';
import { ClientSelectorContext } from '../../context';
import { IClientCertificateHolder } from '../../types/clientCertificateHolders';
import { formatError } from '../../helpers/errors';

const tabProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

export const NewLetterRun: FC = () => {
  const [selectedTab, setSelectedTab] = useState<'client'>('client');
  const [clientList, setClientList] = useState<IClientSelector[]>([]);
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [certificateHolderList, setCertificateHolderList] = useState<IClientCertificateHolder[]>([]);
  // This stores the full list of deficiency data with enabled notifications
  const [fullSelectableDeficiencyList, setFullSelectableDeficiencyList] = useState<IDeficientEntity[]>([]);
  const [deficiencyData, setDeficiencyData] = useState<IDeficiencies>({
    clientId: 0,
    contactId: 0,
    deficientEntities: [],
    missingRequirementEntities: [],
    certificateHolderId: 0,
  });
  // This stores the list of deficiency data with enabled notifications that is currently displayed in the grid via filters
  const [selectableFilteredDeficiencyList, setSelectableFilteredDeficiencyList] = useState<IDeficientEntity[]>([]);
  const [filteredDeficiencyData, setFilteredDeficiencyData] = useState<IDeficiencies>({
    clientId: 0,
    contactId: 0,
    deficientEntities: [],
    missingRequirementEntities: [],
    certificateHolderId: 0,
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [startLetterRun, setStartLetterRun] = useState<boolean>(false);
  const [selectedMonitoredEntities, setSelectedMonitoredEntities] = useState<number[]>([]);
  const [filters, setFilters] = useState<INewLetterRunFilters>({});
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const { showToast } = useToastContext();
  const history = useHistory();
  const classes = useStyles();

  const load = async () => {
    try {
      // Fetch necessary all at once rather than one after the other
      const [clientResponse] = await Promise.all([
        getClientsForSelector(),
        clientSelectorContext?.clientId
          ? getClientCertificateHolders(clientSelectorContext.clientId).then(list => setCertificateHolderList(list))
          : Promise.resolve(),
      ]);
      setClientList(clientResponse);
    } catch (error) {
      showToast('error', 'There was a problem getting the data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When we load up the tables, load them with everything selected
  useEffect(() => {
    const fullMonitoredEntityIdList = [];
    deficiencyData.deficientEntities?.forEach(entity => fullMonitoredEntityIdList.push(entity.monitoredEntityId));

    setSelectedMonitoredEntities(fullMonitoredEntityIdList);
  }, [deficiencyData]);

  // Handle filtering
  useEffect(() => {
    const filteredData = JSON.parse(JSON.stringify(deficiencyData));
    let filteredDeficientEntities: typeof deficiencyData.deficientEntities = JSON.parse(
      JSON.stringify(deficiencyData.deficientEntities)
    );
    let filteredMissingRequirementsEntities: IMissingRequirementEntity[] = JSON.parse(
      JSON.stringify(deficiencyData.missingRequirementEntities)
    );

    if (filters.contactName && filters.contactName !== '') {
      filteredDeficientEntities = filteredDeficientEntities.filter(
        entity => entity.contactName && entity.contactName.toLowerCase().includes(filters.contactName.toLowerCase())
      );
      filteredMissingRequirementsEntities = filteredMissingRequirementsEntities.filter(
        entity => entity.contactName && entity.contactName.toLowerCase().includes(filters.contactName.toLowerCase())
      );
    }

    if (filters.contactGroupName && filters.contactGroupName !== '') {
      filteredDeficientEntities = filteredDeficientEntities.filter(
        entity =>
          entity.contactGroupName &&
          entity.contactGroupName.toLowerCase().includes(filters.contactGroupName.toLowerCase())
      );
      filteredMissingRequirementsEntities = filteredMissingRequirementsEntities.filter(
        entity =>
          entity.contactGroupName &&
          entity.contactGroupName.toLowerCase().includes(filters.contactGroupName.toLowerCase())
      );
    }

    if (filters.monitoredEntityName && filters.monitoredEntityName !== '') {
      filteredDeficientEntities = filteredDeficientEntities.filter(
        entity =>
          entity.monitoredEntityName &&
          entity.monitoredEntityName.toLowerCase().includes(filters.monitoredEntityName.toLowerCase())
      );
      filteredMissingRequirementsEntities = filteredMissingRequirementsEntities.filter(
        entity =>
          entity.monitoredEntityName &&
          entity.monitoredEntityName.toLowerCase().includes(filters.monitoredEntityName.toLowerCase())
      );
    }

    if (filters.unitNumber && filters.unitNumber !== '') {
      filteredDeficientEntities = filteredDeficientEntities.filter(
        entity => entity.unitNumber && entity.unitNumber.toLowerCase().includes(filters.unitNumber.toLowerCase())
      );
      filteredMissingRequirementsEntities = filteredMissingRequirementsEntities.filter(
        entity => entity.unitNumber && entity.unitNumber.toLowerCase().includes(filters.unitNumber.toLowerCase())
      );
    }

    if (filters.contactGroupNotifications && filters.contactGroupNotifications !== 'unselected') {
      filteredDeficientEntities =
        filters.contactGroupNotifications === 'on'
          ? filteredDeficientEntities.filter(e => e.contactGroupAllowsNotifications)
          : filteredDeficientEntities.filter(e => !e.contactGroupAllowsNotifications);
    }

    filteredData.deficientEntities = filteredDeficientEntities;
    filteredData.missingRequirementEntities = filteredMissingRequirementsEntities;

    // Source data for the Contact Name and Contact Group filters from the Deficient Entities grid
    const contacts = deficiencyData.deficientEntities.map(
      (_): IContact => ({
        contactId: _.contactId,
        name: _.contactName,
        // Extract the needed fields: contactGroupId, name
        contactGroups: [{ name: _.contactGroupName, contactGroupId: _.contactGroupId } as IContactGroup],
      })
    );
    setContactList(contacts);

    setFilteredDeficiencyData(filteredData);

    setSelectableFilteredDeficiencyList(filteredData?.deficientEntities);
  }, [filters, deficiencyData, selectedMonitoredEntities, fullSelectableDeficiencyList]);

  const getCertificateHolderDropdownOptions = () => {
    const certificateHolderOptions = certificateHolderList?.map(certificateHolder => ({
      key: certificateHolder.certificateHolderId,
      label: certificateHolder.name,
      value: certificateHolder.certificateHolderId,
    }));
    if (certificateHolderOptions?.length > 0) {
      return certificateHolderOptions;
    } else {
      return [{ key: null, label: 'No Certificate Holders found.', value: null }];
    }
  };

  // The following lists represent what is currently editable in view of the user
  const selectableFilteredEntityIdList = selectableFilteredDeficiencyList?.map(
    deficiency => deficiency?.monitoredEntityId
  );
  const filteredSelectedMonitoredEntities = selectedMonitoredEntities?.filter(entityId =>
    selectableFilteredEntityIdList?.includes(entityId)
  );

  //TODO: Good Candidate for Refactoring
  const deficiencyColumns = useMemo(() => {
    return [
      {
        id: 'checkboxes',
        Header: () => {
          const updatedSelectedMonitoredEntities = [...selectedMonitoredEntities];

          return (
            <Checkbox
              indeterminate={
                !!(
                  selectableFilteredDeficiencyList?.length !== filteredSelectedMonitoredEntities.length &&
                  filteredSelectedMonitoredEntities.length
                )
              }
              checked={
                !!(
                  selectableFilteredDeficiencyList?.length === filteredSelectedMonitoredEntities.length &&
                  filteredSelectedMonitoredEntities.length
                )
              }
              onClick={() => {
                // Select all if indeterminate or if all are unselected
                if (
                  !!(
                    selectableFilteredDeficiencyList?.length !== filteredSelectedMonitoredEntities.length &&
                    filteredSelectedMonitoredEntities.length
                  ) ||
                  !filteredSelectedMonitoredEntities.length
                ) {
                  selectableFilteredEntityIdList.forEach(entityId => {
                    // Only select IDs that weren't already added to avoid duplicates
                    if (!selectedMonitoredEntities.includes(entityId)) {
                      updatedSelectedMonitoredEntities.push(entityId);
                    }
                  });
                  setSelectedMonitoredEntities(updatedSelectedMonitoredEntities);
                }

                // Unselect all from filtered results if they are all selected
                if (selectableFilteredDeficiencyList?.length === filteredSelectedMonitoredEntities.length) {
                  selectableFilteredEntityIdList.forEach(entityId => {
                    if (updatedSelectedMonitoredEntities.includes(entityId)) {
                      const index = updatedSelectedMonitoredEntities.indexOf(entityId);
                      if (index > -1) {
                        updatedSelectedMonitoredEntities.splice(index, 1);
                      }
                    }
                  });
                  setSelectedMonitoredEntities(updatedSelectedMonitoredEntities);
                }
              }}
            />
          );
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMissingRequirementEntity | IDeficientEntity } };
        }) => {
          // Remove checkbox entirely when notifications are turned off for a contact group.
          if (!original.contactGroupAllowsNotifications) return null;

          // Create a list of items that are actually selectable for indeterminate/select all checkbox logic
          const updatedArray = fullSelectableDeficiencyList;
          updatedArray.push(original);
          setFullSelectableDeficiencyList(updatedArray);

          const updatedCheckedValuesList = [...selectedMonitoredEntities];
          const foundIndex = updatedCheckedValuesList.findIndex(i => i === original.monitoredEntityId);

          // MONITORED ENTITY CHECKBOX FOR LETTER GENERATION
          return (
            <Checkbox
              size='small'
              checked={filteredSelectedMonitoredEntities.includes(original.monitoredEntityId)}
              onClick={() => {
                if (foundIndex > -1) {
                  updatedCheckedValuesList.splice(foundIndex, 1);
                } else {
                  updatedCheckedValuesList.push(original.monitoredEntityId);
                }
                setSelectedMonitoredEntities(updatedCheckedValuesList);
              }}
            />
          );
        },
      },
      {
        id: 'unitNumber',
        accessor: original => original?.unitNumber ?? '',
        Header: 'Store #',
      },
      {
        accessor: 'monitoredEntityName',
        Header: 'Entity Name',
      },
      {
        accessor: 'locationName',
        Header: 'Location',
      },
      {
        accessor: 'contactName',
        Header: 'Contact',
      },
      {
        accessor: 'contactGroupName',
        Header: 'Contact Group',
      },
      {
        accessor: 'deficiencies',
        Header: 'Deficiencies',
      },
      {
        accessor: 'details',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMissingRequirementEntity | IDeficientEntity } };
        }) => {
          return (
            <IconButton
              size='small'
              color='primary'
              onClick={() => window.open(`${routes.manageRequirements}/${original.monitoredEntityId}`, '_blank')}
            >
              <Assignment />
            </IconButton>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonitoredEntities, contactList, selectableFilteredDeficiencyList]);

  const noRequirementsColumns = useMemo(() => {
    return [
      {
        accessor: 'unitNumber',
        Header: 'Store #',
      },
      {
        accessor: 'monitoredEntityName',
        Header: 'Entity Name',
      },
      {
        accessor: 'locationName',
        Header: 'Location',
      },
      {
        accessor: 'contactName',
        Header: 'Contact',
      },
      {
        accessor: 'contactGroupName',
        Header: 'Contact Group',
      },
      {
        accessor: 'deficiencies',
        Header: 'Deficiencies',
      },
      {
        accessor: 'details',
        Header: 'Details',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMissingRequirementEntity | IDeficientEntity } };
        }) => {
          return (
            <IconButton
              size='small'
              onClick={() => window.open(`${routes.manageRequirements}/${original.monitoredEntityId}`, '_blank')}
            >
              <OpenInNew />
            </IconButton>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonitoredEntities, contactList]);

  const resetTable = () => {
    setCertificateHolderList([]);
    setContactList([]);
    setDeficiencyData({
      clientId: 0,
      contactId: 0,
      deficientEntities: [],
      missingRequirementEntities: [],
      certificateHolderId: 0,
    });
    setFilteredDeficiencyData({
      clientId: 0,
      contactId: 0,
      deficientEntities: [],
      missingRequirementEntities: [],
      certificateHolderId: 0,
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          client: {
            clientId: clientSelectorContext?.clientId ?? null,
            name: clientSelectorContext?.name ?? '',
            code: clientSelectorContext?.code ?? undefined,
            clientType: clientSelectorContext?.clientType ?? undefined,
          },
          contact: {
            contactId: null,
            name: '',
          },
          certificateHolderIds: [],
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, dirty, handleBlur }) => {
          return (
            <Page customPageHeader title='New Letter Run'>
              <UnsavedChanges dirty={dirty && startLetterRun} />
              <div className={classes.headerContainer}>
                {/* BACK BUTTON */}
                <IconButton
                  className={classes.backButton}
                  color='primary'
                  onClick={() => {
                    history.push(routes.letterRuns);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                {/* HEADER TITLE */}
                <PageTitle title={'New Letter Run'} marginOff />
                <div className={classes.contentSpacer} />
              </div>

              <Grid container alignItems={'center'} justify={'center'} spacing={1}>
                {/* CLIENT/CONTACT TABS */}
                <Grid item xs={12}>
                  <Tabs
                    centered
                    value={selectedTab}
                    onChange={(_event, newValue) => {
                      setSelectedTab(newValue);
                      setDeficiencyData({
                        clientId: 0,
                        contactId: 0,
                        deficientEntities: [],
                        missingRequirementEntities: [],
                        certificateHolderId: 0,
                      });
                      setCertificateHolderList([]);
                    }}
                  >
                    <Tab label='By Client' {...tabProps(0)} value={'client'} />
                  </Tabs>
                </Grid>

                {/* CLIENT TAB */}
                {selectedTab === 'client' && (
                  <div className={classes.tabContent}>
                    <div className={classes.dropdownWithLabel}>
                      <Typography className={classes.label}>Client:</Typography>
                      <Autocomplete
                        value={values.client}
                        id='searchable-dropdown'
                        options={clientList}
                        getOptionLabel={option => option.name}
                        className={classes.dropdown}
                        noOptionsText='No Clients'
                        onChange={async (_e, item: IClientSelector) => {
                          if (!!item) {
                            setFieldValue('client', item);
                            setFieldValue('certificateHolderIds', []);
                            setDeficiencyData({
                              clientId: 0,
                              contactId: 0,
                              deficientEntities: [],
                              missingRequirementEntities: [],
                              certificateHolderId: 0,
                            });
                            try {
                              setCertificateHolderList(await getClientCertificateHolders(item.clientId));
                            } catch (error) {
                              showToast(
                                'error',
                                error instanceof Error
                                  ? error.message
                                  : 'Failed to retrieve client certificate holders.'
                              );
                            }
                          } else {
                            setFieldValue('client', '');
                            setFieldValue('certificateHolderIds', []);
                            resetTable();
                          }
                        }}
                        renderInput={params => (
                          <TextField {...params} name={`client-textfield`} onBlur={handleBlur} autoComplete='none' />
                        )}
                      />
                    </div>

                    {/* CERTIFICATE HOLDER SELECTOR */}
                    <div className={classes.dropdownWithLabel}>
                      <Typography className={classes.label}>Certificate Holder:</Typography>
                      <MultiSelect
                        options={getCertificateHolderDropdownOptions()}
                        selected={values.certificateHolderIds}
                        setSelected={async certificateHolderIds => {
                          if (!certificateHolderIds?.length) return;
                          setFieldValue('certificateHolderIds', certificateHolderIds);
                        }}
                        clearSelected={() => {
                          setFieldValue('certificateHolderIds', []);
                        }}
                      />
                    </div>

                    {/* SELECT ALL CERTIFICATE HOLDERS */}
                    <Button
                      color='primary'
                      size='medium'
                      onClick={() => {
                        if (values.certificateHolderIds.length === 0) {
                          const ids = certificateHolderList.map(certHolder => certHolder.certificateHolderId);
                          setFieldValue('certificateHolderIds', ids);
                        } else {
                          setFieldValue('certificateHolderIds', []);
                        }
                      }}
                    >
                      {values.certificateHolderIds.length > 0 ? 'Deselect All' : 'Select All'}
                    </Button>
                    <div className={classes.horizontalRule}>
                      <Separator />
                    </div>
                    {/* REFRESH DEFICIENCIES BUTTON */}
                    <Button
                      color='primary'
                      size='medium'
                      disabled={
                        isLoading ||
                        !values?.client?.clientId ||
                        !values?.certificateHolderIds?.length ||
                        values?.certificateHolderIds?.includes(null)
                      }
                      onClick={async () => {
                        setLoading(true);
                        try {
                          if (selectedTab === 'client') {
                            const clientId = values.client?.clientId;
                            await getClientDeficiencies(clientId, values.certificateHolderIds).then(setDeficiencyData);
                          } else {
                            setDeficiencyData(await getContactDeficiencies(values?.contact?.contactId));
                          }
                        } catch {
                          showToast('error', 'There was a problem retrieving the compliance data. Please try again.');
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      PROCESS
                    </Button>
                  </div>
                )}

                {/* GRIDS */}
                <div className={classes.header}>
                  <Grid container>
                    <PageTitle title='Deficient Entities' marginOff />
                  </Grid>

                  <Grid container alignItems='center' justify={'flex-end'}>
                    {/* GENERATE LETTERS BUTTON */}
                    <Button
                      disabled={
                        isLoading ||
                        !selectedMonitoredEntities.length ||
                        startLetterRun ||
                        (selectedTab === 'client' && !values?.certificateHolderIds?.length)
                      }
                      color='primary'
                      onClick={async () => {
                        setStartLetterRun(true);

                        try {
                          // A necessary pre-step before generating letters
                          const letterRunId = await updateLetterRun(
                            values.client ? values.client.clientId : null,
                            values.contact ? values.contact.contactId : null,
                            LetterRunSourceEnums.LetterRun
                          );

                          // Start generating the letters
                          await updateLetterRunDetails(letterRunId, selectedMonitoredEntities);

                          setStartLetterRun(false);

                          const url = `${routes.letterGenerationProgress}/letterRunId=${letterRunId}`;
                          history.replace(url);
                        } catch (letterError) {
                          formatError(letterError, showToast);
                        } finally {
                          setStartLetterRun(false);
                        }
                      }}
                    >
                      {startLetterRun && (
                        <CircularProgress size={'1rem'} color={'primary'} className={classes.circularProgress} />
                      )}
                      Generate Letters
                    </Button>
                  </Grid>
                </div>

                {/* FILTER BAR */}
                <NewLetterRunFilters handleFilter={setFilters} isLoading={isLoading} contacts={contactList} />

                {/* DEFICIENT ENTITIES GRID */}
                <div className={classes.gridContainer}>
                  <Table
                    columns={deficiencyColumns}
                    data={filteredDeficiencyData?.deficientEntities ?? []}
                    isLoading={isLoading}
                    headerClasses={classes.tableHeader}
                    stickyHeader
                  />
                </div>

                {/* COMPLIANCE MESSAGE */}
                {filteredDeficiencyData &&
                  filteredDeficiencyData.missingRequirementEntities &&
                  filteredDeficiencyData.missingRequirementEntities.length > 0 && (
                    <Grid container>
                      <Typography className={classes.complianceMessage}>
                        There were {filteredDeficiencyData.missingRequirementEntities.length} Unit(s)/Contractor(s) with
                        no requirements. Letters will not be sent for Units/Contractors with no Requirements.
                      </Typography>
                    </Grid>
                  )}

                {/* MISSING REQUIREMENTS GRID */}
                <div className={classes.header}>
                  <PageTitle title='Entities With No Requirements' marginOff />
                </div>
                <div className={classes.gridContainer}>
                  <Table
                    columns={noRequirementsColumns}
                    data={
                      filteredDeficiencyData && filteredDeficiencyData.missingRequirementEntities
                        ? filteredDeficiencyData.missingRequirementEntities
                        : []
                    }
                    isLoading={isLoading}
                    headerClasses={classes.tableHeader}
                    stickyHeader
                  />
                </div>
              </Grid>
            </Page>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: 0,
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  contentSpacer: {
    width: '.001rem',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'hidden',
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  tabContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    marginTop: theme.spacing(1),
  },
  dropdownWithLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  horizontalRule: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  label: {
    marginRight: theme.spacing(1),
  },
  dropdown: {
    width: theme.spacing(16),
  },
  complianceMessage: {
    marginTop: theme.spacing(2),
  },
  circularProgress: {
    marginRight: theme.spacing(0.5),
  },
}));
