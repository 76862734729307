import React, { FC, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  CardActions,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { Modal, Loader, Select } from '../../components';
import { Add, ArrowRight, AttachFile, Send } from '@material-ui/icons';
import {
  createDistributionList,
  createOnDemandSummary,
  getContactTypes,
  getLetterRunGenerationProgress,
  getOnDemandSummary,
  ICommentsAPI,
  ODSPrecheck,
  postOnDemandSummaryNotifications,
  updateLetterRun,
} from '../../fetch';
import { convertToUTC } from '../../helpers';
import { useToastContext } from '../../hooks';
import {
  CommentType,
  IContactGroupDistributionList,
  IContactTypeValue,
  IDistributionList,
  IEnum,
  IFile,
  IOnDemandSummary,
} from '../../types';
import { Formik } from 'formik';
// import { fileValidator } from '../../components/files/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { LetterRunSourceEnums } from '../../constants';
import { logError } from '../../services';
import { formatError } from '../../helpers/errors';

const FILE_UPLOADER_STATE = {
  INIT: 'INIT',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  FAILURE: 'FAILURE',
};

// default config options that can be updated at a later time
// const defaultFileSizeMBLimit = 100;
// const defaultFilesLimit = 1;

interface IOnDemandSummaryForm extends Pick<IOnDemandSummary, 'distributionList'> {
  attachLatestDocument: boolean;
  addToComments: boolean;
  emailContent: string;
  emailText: string;
}

interface IOnDemandSummaryModalProps {
  open: boolean;
  onClose: () => void;
  clientId: number;
  contactGroupId: number;
  monitoredEntityId: number;
  commentType: CommentType;
  isBulkUpdate?: boolean;
  documentForBulkUpdate?: File[];
}

export const OnDemandSummaryModal: FC<IOnDemandSummaryModalProps> = ({
  open,
  onClose,
  clientId,
  contactGroupId,
  monitoredEntityId,
  commentType,
  isBulkUpdate,
  documentForBulkUpdate,
}) => {
  const [onDemandSummaryData, setOnDemandSummaryData] = useState<IOnDemandSummary | null>(null);
  /**
   * Tracks pre-existing/non-new distribution list rows for readonly-ness.
   */
  const [existingRecipients, setExistingRecipients] = useState<IDistributionList[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loaderState, setLoaderState] = useState<string>(FILE_UPLOADER_STATE.INIT);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [letterRunId, setLetterRunId] = useState<number | null>(null);
  const [isOnDemandSummaryGenerated, setIsOnDemandSummaryGenerated] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  const [newDistributionListId, setNewDistributionListId] = useState<number>(-1);
  const [newDistributionListIndex, setNewDistributionListIndex] = useState<number>(-1);
  const [contactTypes, setContactTypes] = useState<IEnum[]>([]);
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([]);

  const { showToast } = useToastContext();
  const fileInputRef = useRef<HTMLInputElement>();
  const classes = useStyles();

  const checkLetterRunGenerationProgress = async (letterRunId: number) => {
    if (isOnDemandSummaryGenerated) {
      return;
    }

    const progressResponse = await getLetterRunGenerationProgress(letterRunId);

    if (progressResponse && progressResponse.completedLetters === 1) {
      setIsOnDemandSummaryGenerated(true);
      return;
    }

    setTimeout(() => checkLetterRunGenerationProgress(letterRunId), 3000);
  };

  const [precheckIsInvalid, setPrecheckIsInvalid] = useState(true);
  let [allErrors, setAllErrors] = useState([]);
  const handleLoadOnDemandSummary = async () => {
    if (!clientId || !contactGroupId || !monitoredEntityId || !open) {
      return;
    }
    setIsLoading(true);
    try {
      const preCheck = await ODSPrecheck(clientId, contactGroupId, monitoredEntityId);
      if (!preCheck?.isValid) {
        setPrecheckIsInvalid(true);
        setAllErrors(preCheck.problems);
        setIsLoading(false);
      } else {
        setPrecheckIsInvalid(false);
        setAllErrors([]);
        const [contactTypeResponse, letterRunId] = await Promise.all([
          getContactTypes(),
          // 2 Represents an "On Demand Summary" letter run source.
          updateLetterRun(clientId, null, LetterRunSourceEnums.OnDemandSummary),
        ]);
        setContactTypes(contactTypeResponse);
        setLetterRunId(letterRunId);

        if (!letterRunId) {
          return;
        }
        await Promise.all([
          createOnDemandSummary(clientId, contactGroupId, monitoredEntityId, letterRunId),
          checkLetterRunGenerationProgress(letterRunId),
        ]);
      }
    } catch (error) {
      formatError(error, showToast);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoadOnDemandSummary();

    return () => {
      setIsOnDemandSummaryGenerated(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, contactGroupId, monitoredEntityId, open]);

  const handleGetOnDemandSummary = async () => {
    try {
      // Call this once letter generation is ready
      const onDemandSummaryResponse = await getOnDemandSummary(letterRunId, contactGroupId);

      setOnDemandSummaryData(onDemandSummaryResponse);
      setExistingRecipients(onDemandSummaryResponse?.distributionList?.filter(recipient => !recipient.isDeleted) ?? []);
      setIsLoading(false);
    } catch {
      setAdditionalFiles([]);
      setFilesToUpload([]);
      onClose();
      showToast('error', 'Could not successfully load the on demand summary. Please try again.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOnDemandSummaryData(null);
      setExistingRecipients([]);
    }
  }, [open]);

  useEffect(() => {
    if (documentForBulkUpdate?.length === 1) {
      handleBulkUpdateFile(documentForBulkUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentForBulkUpdate]);

  useEffect(() => {
    // Only attempt to get the on demand summary when it has been generated
    if (isOnDemandSummaryGenerated) {
      handleGetOnDemandSummary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDemandSummaryGenerated]);

  const handleGetFile = async () => {
    if (!onDemandSummaryData || !onDemandSummaryData.letter) {
      return;
    }

    try {
      await IFile.download(onDemandSummaryData.letter);
    } catch (_) {
      showToast('error', 'Could not download the summary report. Please try again.');
    }
  };

  const readFileAsync = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => resolve(e.target.result.split(',')[1]);
      reader.onerror = reject;
    });
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoaderState(FILE_UPLOADER_STATE.PROCESSING);
    const files: FileList = event.target.files;

    const filesArray = Array.from(files);
    setAdditionalFiles(prev => [...prev, ...filesArray]);
    event.preventDefault();
    event.stopPropagation();

    try {
      const filesReadyToBeUploaded = [];
      for (const file of Array.from(files)) {
        const rawFileData = await readFileAsync(file);

        filesReadyToBeUploaded.push({
          name: file.name,
          fileType: file.type,
          data: rawFileData,
          dateCreated: convertToUTC(new Date().toISOString()),
        });
      }

      setFilesToUpload(prev => [...prev, ...filesReadyToBeUploaded]);
      const inputElement = fileInputRef.current;
      if (typeof inputElement !== 'undefined') {
        inputElement.value = null;
      }

      setLoaderState(FILE_UPLOADER_STATE.COMPLETE);
    } catch (error) {
      showToast('error', 'Could not upload the file. Please try again.');
    }
  };

  const handleBulkUpdateFile = async (filesArray: File[]) => {
    setAdditionalFiles(prev => [...prev, ...filesArray]);

    try {
      const filesReadyToBeUploaded = [];
      for (const file of Array.from(filesArray)) {
        const rawFileData = await readFileAsync(filesArray[0]);

        filesReadyToBeUploaded.push({
          name: file.name,
          fileType: file.type,
          data: rawFileData,
          dateCreated: convertToUTC(new Date().toISOString()),
        });
      }

      setFilesToUpload(prev => [...prev, ...filesReadyToBeUploaded]);
      const inputElement = fileInputRef.current;
      if (typeof inputElement !== 'undefined') {
        inputElement.value = null;
      }
    } catch (error) {
      showToast('error', 'Could not upload the file. Please try again.');
    }
  };

  const handleOnDemandSummarySubmit = async (values: IOnDemandSummaryForm): Promise<void> => {
    setIsLoading(true);

    try {
      // Add comment if they had it selected
      if (values.addToComments && values.emailText !== '') {
        let relatedObjectId = null;
        switch (commentType) {
          case CommentType.Entities:
            relatedObjectId = String(monitoredEntityId);
            break;
          case CommentType.ContactGroups:
            relatedObjectId = String(contactGroupId);
            break;
          default:
            logError({ commentType }, 'An unhandled comment type was found during comment creation from an ODS.');
            break;
        }

        await ICommentsAPI.create({
          text: `ON DEMAND SUMMARY - ${values?.emailText}`,
          commentType: commentType,
          relatedObjectId: relatedObjectId,
        });
      }

      const distributionList = values.distributionList
        .filter(_ => !_.isDeleted)
        .filter(listItem => listItem.addToContactGroup || listItem.contactGroupDistributionListId > 0);

      // Update distribution list with changes
      await createDistributionList({
        contactGroupId: contactGroupId,
        distributionList,
      });

      // Send the actual notifications
      await postOnDemandSummaryNotifications({
        letterRunId,
        summaryNotification: {
          letterRunId,
          //Setting this to null on bulk update
          monitoredEntityId: isBulkUpdate ? null : monitoredEntityId,
          // Filter out deleted elements. Remaining elements are mapped to their emails.
          recipients: values.distributionList.flatMap(_ => (_.isDeleted ? [] : _.email)),
          attachments: filesToUpload,
          emailBody: values?.emailContent,
          emailSubject: '',
          attachLatestDocument: isBulkUpdate ? false : values.attachLatestDocument,
        },
      });

      setAdditionalFiles([]);
      setFilesToUpload([]);
      onClose();
      showToast('success', 'Successfully sent the on demand summary!');
    } catch (error) {
      formatError(error, showToast);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Formik<IOnDemandSummaryForm>
        enableReinitialize
        initialValues={{
          attachLatestDocument: isBulkUpdate ? false : true,
          addToComments: false,
          distributionList: onDemandSummaryData?.distributionList || [],
          emailContent: onDemandSummaryData?.onDemandEmailContent || '', // This represent the email body with HTML tags
          emailText: '', // This represents only the text in the email body
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, handleBlur, touched, errors, resetForm }) => {
          return (
            <Modal
              open={open}
              title={'On Demand Summary'}
              onClose={() => {
                resetForm();
                setAdditionalFiles([]);
                setFilesToUpload([]);
                onClose();
                setIsLoading(false);
              }}
              maxWidth={'md'}
            >
              <Fade in={open}>
                <>
                  {isLoading && <Loader type='overlay' position='centered' title='Processing Deficiencies' />}
                  {!isLoading && !precheckIsInvalid && (
                    <>
                      <Grid container spacing={1} className={classes.gridContainer}>
                        {/* ATTACHMENTS */}
                        <Grid item xs={12} sm={4}>
                          <Grid container direction={'column'} className={classes.attachmentsContainer}>
                            <Typography variant={'h6'}>Attachments</Typography>

                            <div
                              style={{ cursor: 'pointer', color: '#002855' }}
                              onClick={handleGetFile}
                              className={classes.attachment}
                            >
                              {onDemandSummaryData?.letter?.name ?? ''}
                            </div>

                            {/* INCLUDE LATEST DOCUMENT ON ENTITY CHECKBOX */}
                            {!isBulkUpdate && (
                              <FormControlLabel
                                label={'Attach Latest COI'}
                                control={
                                  <Checkbox
                                    onClick={() => setFieldValue('attachLatestDocument', !values.attachLatestDocument)}
                                    checked={values.attachLatestDocument}
                                  />
                                }
                              />
                            )}

                            {/* ATTACH ADDITIONAL FILES */}
                            <div className={classes.attachmentButton}>
                              <Button
                                color={'primary'}
                                onClick={() => {
                                  if (fileInputRef && fileInputRef.current) {
                                    // @ts-ignore
                                    fileInputRef.current.click();
                                  }
                                }}
                              >
                                <AttachFile />
                                {'Attach Additional Files'}
                              </Button>
                              <Button
                                disabled={filesToUpload.length < 1}
                                color={'secondary'}
                                onClick={() => {
                                  setAdditionalFiles([]);
                                  setFilesToUpload([]);
                                }}
                              >
                                Clear
                              </Button>
                              <input
                                onChange={handleUpload}
                                multiple={true}
                                ref={fileInputRef}
                                name='files[]'
                                type='file'
                                hidden
                              />
                            </div>
                            <div className={classes.addditionalFilesContainer}>
                              {additionalFiles &&
                                additionalFiles.map((file, index) => (
                                  <div key={`${file.name}-${index}-${file.type}`} className={classes.ellipsis}>
                                    {file.name}
                                  </div>
                                ))}
                            </div>
                          </Grid>
                        </Grid>

                        {/* RECIPIENTS */}
                        <Grid item xs={12} sm={8}>
                          <Typography variant={'h6'} className={classes.recipientHeader}>
                            Recipients
                            <IconButton
                              color='primary'
                              size='medium'
                              onClick={() => {
                                const newDistributionList = [...values.distributionList];
                                newDistributionList.push({
                                  contactGroupDistributionListId: newDistributionListId,
                                  email: '',
                                  contactType: IContactTypeValue.Contact,
                                  isDeleted: false,
                                  addToContactGroup: false,
                                });

                                setFieldValue('distributionList', newDistributionList);
                                // Set a new value for next use
                                setNewDistributionListId(newDistributionListId - 1);
                                // Set the index so the new TextField can autofocus
                                setNewDistributionListIndex(newDistributionList.filter(l => !l.isDeleted).length);
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Typography>

                          {/* EMAIL LIST */}
                          <Grid item xs={12} className={classes.emailListContainer}>
                            {values.distributionList
                              ?.filter(recipient => !recipient.isDeleted)
                              .map((recipient, index) => (
                                <Grid
                                  container
                                  key={recipient.contactGroupDistributionListId}
                                  wrap='nowrap'
                                  className={classes.listItemContainer}
                                >
                                  {/* ADD TO CONTACT GROUP - only applies to new items */}
                                  {recipient?.contactGroupDistributionListId < 0 ? (
                                    <FormControlLabel
                                      label='Capture'
                                      control={
                                        <Checkbox
                                          checked={recipient.addToContactGroup}
                                          onChange={() => {
                                            const deepCopy = JSON.parse(JSON.stringify(values.distributionList));
                                            if (deepCopy.length) {
                                              const updateIndex = values.distributionList.findIndex(
                                                obj =>
                                                  obj.contactGroupDistributionListId ===
                                                  recipient.contactGroupDistributionListId
                                              );
                                              deepCopy[updateIndex].addToContactGroup = !recipient.addToContactGroup;
                                            }
                                            setFieldValue('distributionList', deepCopy);
                                          }}
                                        />
                                      }
                                    />
                                  ) : (
                                    <div className={classes.checkboxPlaceholder} />
                                  )}

                                  {/* EMAIL TEXT FIELD */}
                                  <Grid item xs={8}>
                                    {/** Value is readonly text if recipient is pre-existing */}
                                    {existingRecipients.find(
                                      existing =>
                                        existing?.contactGroupDistributionListId ===
                                        recipient?.contactGroupDistributionListId
                                    ) ? (
                                      <Typography>{recipient.email}</Typography>
                                    ) : (
                                      <TextField
                                        autoFocus={newDistributionListIndex === index + 1}
                                        name={`email_${recipient.contactGroupDistributionListId}`}
                                        autoComplete='nope'
                                        placeholder={'E-Mail'}
                                        value={recipient.email}
                                        className={classes.emailField}
                                        onBlur={handleBlur}
                                        onChange={({ target: { value } }) => {
                                          const deepCopy = JSON.parse(JSON.stringify(values.distributionList));
                                          if (deepCopy.length) {
                                            const updateIndex = values.distributionList.findIndex(
                                              obj =>
                                                obj.contactGroupDistributionListId ===
                                                recipient.contactGroupDistributionListId
                                            );
                                            deepCopy[updateIndex].email = value;
                                          }

                                          setFieldValue('distributionList', deepCopy);
                                        }}
                                        error={
                                          touched &&
                                          // @ts-ignore
                                          touched[`email_${recipient.contactGroupDistributionListId}`] &&
                                          errors &&
                                          errors.distributionList &&
                                          // @ts-ignore
                                          errors.distributionList[index]?.email
                                        }
                                        helperText={
                                          touched &&
                                          // @ts-ignore
                                          touched[`email_${recipient.contactGroupDistributionListId}`] &&
                                          errors &&
                                          errors.distributionList &&
                                          // @ts-ignore
                                          errors.recipients[index]?.email
                                        }
                                      />
                                    )}
                                  </Grid>

                                  {/* CONTACT TYPE DROPDOWN */}
                                  <Grid item xs={4}>
                                    {existingRecipients.find(
                                      existing =>
                                        existing?.contactGroupDistributionListId ===
                                        recipient?.contactGroupDistributionListId
                                    ) ? (
                                      <Typography>
                                        {recipient.contactType === 1 ? 'Contact Email' : 'Agent Email'}
                                      </Typography>
                                    ) : (
                                      <Select
                                        name='Contact Type'
                                        id='contact-type'
                                        showReset={false}
                                        value={recipient.contactType}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          const deepCopy: IContactGroupDistributionList[] = JSON.parse(
                                            JSON.stringify(values.distributionList)
                                          );
                                          const updateIndex = values.distributionList.findIndex(
                                            obj =>
                                              obj.contactGroupDistributionListId ===
                                              recipient.contactGroupDistributionListId
                                          );
                                          // Convert string to number with unary `+`
                                          deepCopy[updateIndex].contactType = +e.target.value;
                                          setFieldValue('distributionList', deepCopy);
                                        }}
                                        options={contactTypes?.map(contactTypes => ({
                                          key: contactTypes.value,
                                          label: `${contactTypes.text} Email`,
                                          value: contactTypes.value,
                                        }))}
                                      />
                                    )}
                                  </Grid>

                                  {/* DELETE BUTTON */}
                                  <Grid item className={classes.deletePadding}>
                                    <IconButton
                                      size='small'
                                      className={classes.clearIcon}
                                      onClick={() => {
                                        const deepCopy = JSON.parse(JSON.stringify(values.distributionList));
                                        const deleteRowIndex = values.distributionList.findIndex(
                                          obj =>
                                            obj.contactGroupDistributionListId ===
                                            recipient.contactGroupDistributionListId
                                        );

                                        deepCopy[deleteRowIndex].isDeleted = true;

                                        setFieldValue('distributionList', deepCopy);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>

                        {/* EMAIL CONTENT */}
                        <Grid item xs={12} className={classes.emailContentContainer}>
                          <Typography variant={'h6'}>Email Body</Typography>
                          <ReactQuill
                            className={classes.quill}
                            modules={{
                              toolbar: [['bold', 'italic', 'underline']],
                            }}
                            theme='snow'
                            value={values.emailContent}
                            onChange={(input, delta, source, editor) => {
                              setFieldValue('emailContent', input);
                              setFieldValue('emailText', editor.getText());
                            }}
                          />

                          {/* ADD TO COMMENTS */}
                          <FormControlLabel
                            label={'Add to comments'}
                            control={
                              <Checkbox
                                onClick={() => setFieldValue('addToComments', !values.addToComments)}
                                checked={values.addToComments}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {!isLoading && precheckIsInvalid && (
                    <Grid container spacing={1} className={classes.gridContainer}>
                      <Grid item xs={12}>
                        <Grid container direction={'column'} className={classes.attachmentsContainer}>
                          <Typography variant={'h6'}>
                            Before you can send an On Demand Summary, please ensure the following conditions are met:
                          </Typography>
                          <List>
                            {allErrors.map((error, index) => (
                              <ListItem key={`list-item-${error}`}>
                                <ListItemIcon>
                                  <ArrowRight />
                                </ListItemIcon>
                                <ListItemText primary={`${error}`} />
                              </ListItem>
                            ))}
                          </List>
                          <div
                            style={{ cursor: 'pointer', color: '#002855' }}
                            onClick={handleGetFile}
                            className={classes.attachment}
                          >
                            {onDemandSummaryData?.letter?.name ?? ''}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {/* MODAL ACTIONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          onClose();
                          setAdditionalFiles([]);
                          setFilesToUpload([]);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        // disabled={!file || !status}
                        disabled={isLoading || precheckIsInvalid}
                        type='button'
                        onClick={() => {
                          if (values.distributionList?.filter(x => !x.isDeleted).length < 1) {
                            showToast('error', 'Please select at least one recipient.');
                          } else {
                            handleOnDemandSummarySubmit(values);
                          }
                        }}
                        variant='contained'
                        color='primary'
                      >
                        <Send className={classes.addIcon} />
                        Send
                      </Button>
                    </div>
                  </CardActions>
                </>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  attachmentsContainer: {
    paddingTop: theme.spacing(0.5),
  },
  attachmentButton: {
    marginLeft: theme.spacing(-0.75),
  },
  emailListContainer: {
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    paddingLeft: theme.spacing(1),
  },
  emailField: {
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  deletePadding: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  addIcon: {
    marginRight: theme.spacing(0.5),
  },
  clearIcon: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  emailContentContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  quill: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  attachment: {
    marginBottom: theme.spacing(0.75),
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  checkboxPlaceholder: {
    width: theme.spacing(9),
  },
  recipientHeader: {
    marginLeft: theme.spacing(7.5),
  },
  addditionalFilesContainer: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: theme.spacing(4.33),
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
