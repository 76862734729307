import { authFetch } from './';
import {
  IManageRequirements,
  IMonitoredEntityRequirements,
  IMonitoredEntityRequirementsPost,
  IEntityRequirement,
  ILinkedRequirementsResponse,
  IEntityRequirementHistory,
  ILineOfCoverage,
  IFile,
  IEnum,
  IResponse,
  ManageRequirementsSortBy,
  IBulkUpdateMonitoredEntityRequirementsPost,
} from '../types';
import { IHttpPostMessageResponse } from 'http-post-message';
import axios, { AxiosResponse } from 'axios';
import { IBulkUpdateData, IMonitoredEntitySelectableIds } from '../types/bulkUpdate';

const baseUrl = '/api/requirements';

export const getManageRequirements = async (id: number): Promise<IMonitoredEntityRequirements> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting the requirements. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getMonitoredEntityRequirements = async (id: number): Promise<IMonitoredEntityRequirements> => {
  try {
    const res = await authFetch.get(`${baseUrl}/MonitoredEntity/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error saving the monitored entity. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getDefaultRequirementsForEntity = async (id: number): Promise<IEntityRequirement[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/DefaultRequirements/?ClientId=${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting the requirements. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateRequirements = async (
  postModel: IMonitoredEntityRequirementsPost
): Promise<
  {
    isWholeSetCompliant: boolean;
    shouldOnDemandSummaryTrigger: boolean;
  } & IMonitoredEntityRequirements
> => {
  try {
    const res = await authFetch.post(`${baseUrl}/UpdateRequirements`, postModel);

    if (res) {
      return res.data;
    }
    const error = { Detail: 'Error updating the requirement. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const bulkUpdateRequirements = async (
  postModel: IBulkUpdateMonitoredEntityRequirementsPost
): Promise<
  {
    isWholeSetCompliant: boolean;
    shouldOnDemandSummaryTrigger: boolean;
  } & Pick<IMonitoredEntityRequirements, 'requirements' | 'associatedFiles'>
> => {
  try {
    const res = await authFetch.post(`${baseUrl}/BulkUpdateRequirements`, postModel);

    if (res) {
      return res.data;
    }
    const error = { Detail: 'Error updating the requirement. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getRequirements = async (filters?: { selectedClientId?: number }): Promise<IManageRequirements[]> => {
  let query = '';
  if (filters) {
    if (filters.selectedClientId) {
      query += `?SelectedClientId=${filters.selectedClientId}`;
    }
  }
  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getPagedRequirements = async (params: {
  page: number;
  perPage: number;
  sortBy: ManageRequirementsSortBy;
  sortDirection: string;
  name?: string;
  client?: string;
  unitNumber?: string;
  location?: string;
  contact?: string;
  contactGroup?: string;
  contactEmail?: string;
  isCompliant?: boolean;
  isActive?: boolean;
  selectedClientId?: number;
  letterRunDetailsId?: number;
}): Promise<IResponse<IManageRequirements[]>> => {
  // Remove unused filters, send only the used ones.
  if (params.name === undefined) delete params.name;
  if (params.client === undefined) delete params.client;
  if (params.unitNumber === undefined) delete params.unitNumber;
  if (params.location === undefined) delete params.location;
  if (params.contact === undefined) delete params.contact;
  if (params.contactGroup === undefined) delete params.contactGroup;
  if (params.contactEmail === undefined) delete params.contactEmail;
  if (params.isCompliant === undefined) delete params.isCompliant;
  if (params.isActive === undefined) delete params.isActive;
  if (params.letterRunDetailsId === undefined) delete params.letterRunDetailsId;
  if (params.selectedClientId === undefined) delete params.selectedClientId;

  try {
    const response: AxiosResponse<IResponse<IManageRequirements[]>> = await authFetch.get(
      `/api/MonitoredEntity/PagedList`,
      { params }
    );

    // Check if status is in the 2XX range
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const getAllIdsForQuery = async (params: {
  name?: string;
  client?: string;
  location?: string;
  contact?: string;
  contactEmail?: string;
  isCompliant?: boolean;
  isActive?: boolean;
  selectedClientId?: number;
  letterRunDetailsId?: number;
}): Promise<IMonitoredEntitySelectableIds[]> => {
  try {
    const response: AxiosResponse<IMonitoredEntitySelectableIds[]> = await authFetch.get(
      `/api/MonitoredEntity/GetAllIdsForQuery`,
      {
        params,
      }
    );

    // Check if status is in the 2XX range
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const getRequirementHistory = async (requirementId): Promise<IEntityRequirementHistory[]> => {
  try {
    const response = await authFetch.get(`${baseUrl}/RequirementsHistory?requirementId=${requirementId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRequirementsForMonitoredEntities = async (
  monitoredEntityIds: string[]
): Promise<{
  requirements: IEntityRequirement[];
  isWholeSetCompliant: boolean;
  isCrossContact: boolean;
}> => {
  try {
    const response = await authFetch.post(`${baseUrl}/MonitoredEntity`, { monitoredEntityIds: monitoredEntityIds });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBulkUpdateData = async (monitoredEntityIds: string[]): Promise<IBulkUpdateData> => {
  try {
    const response = await authFetch.post(`${baseUrl}/BulkUpdateData`, { monitoredEntityIds: monitoredEntityIds });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkForInUseDefaultRequirements = async (id: number): Promise<ILinkedRequirementsResponse> => {
  try {
    const res = await authFetch.get(`${baseUrl}/LinkedRequirements/?DefaultRequirementId=${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error checking requirements. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const bulkUpdateAdditionalInsuredStatuses = async (
  linesOfCoverage: ILineOfCoverage[]
): Promise<IHttpPostMessageResponse<any>> => {
  try {
    const postModel = { additionalInsuredLinesOfCoverage: linesOfCoverage };
    await authFetch.post(`${baseUrl}/BulkUpdateAdditionalInsuredStatuses`, postModel);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const importRequirements = async (clientId: number, fileInfo: IFile): Promise<IHttpPostMessageResponse<any>> => {
  try {
    await authFetch.post(`${baseUrl}/ImportRequirements`, { clientId, fileInfo });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getRequirementComplianceStatusEnums = async (): Promise<IEnum[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/RequirementComplianceStatuses`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting the requirement compliance statuses. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};