import { authFetch } from './';
import { IFile, ILocation } from '../types';

const baseUrl = '/api/Location';

export const updateLocation = async (data: ILocation): Promise<ILocation[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/`, data);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating location. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const importLocations = async (clientId: number, fileInfo: IFile): Promise<ILocation[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Import`, { clientId, fileInfo});

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error importing locations. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
