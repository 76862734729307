import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AttachFile } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Table } from '../../components';
import { routes } from '../../constants';
import { getByLetterRunDetailToMonitoredEntityId, getLetterRunDetailSourceTypes } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IEnum, IFile, ILetterRun, ILetterRunDetailToMonitoredEntity, INotificationsTableFilters } from '../../types';
import { NotificationsTableFilters } from './NotificationsTableFilters';

export const NotificationsTable = () => {
  const classes = useStyles();
  const { showToast } = useToastContext();
  // @ts-ignore
  const { monitoredEntityId } = useParams();

  const [letterRuns, setLetterRuns] = useState<ILetterRunDetailToMonitoredEntity[]>([]);
  const [filteredLetterRuns, setFilteredLetterRuns] = useState<ILetterRunDetailToMonitoredEntity[]>([]);
  const [sourceTypeEnums, setSourceTypeEnums] = useState<IEnum[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<INotificationsTableFilters>({});

  // This page needs a serious overhaul and sorting/filtering on the BE. Currently it's being done entirely on the Frontend
  // This is a quick hack to make the child component reset the paged value. We are adding resetPage as a dependency in the
  // Use state in the table. It needs to be a distinct value. If it changes, it will reset the table to page 1. I've currently
  // got it firing only after sorting the header, and adding 1 to the previous value. This is not particularly robust, but will
  // have to do for now.
  const [resetPageKey, setResetPageKey] = useState<number>(0);
  const fetchLetterRuns = async () => {
    setIsLoading(true);

    try {
      const [letterRunDetailToMonitoredEntityResponse, letterRunDetailSourceTypesResponse] = await Promise.all([
        getByLetterRunDetailToMonitoredEntityId(monitoredEntityId),
        getLetterRunDetailSourceTypes(),
      ]);

      setLetterRuns(letterRunDetailToMonitoredEntityResponse);
      setSourceTypeEnums(letterRunDetailSourceTypesResponse);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLetterRuns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Handle filtering
  useEffect(() => {
    setFilteredLetterRuns(
      filters.sourceType ? letterRuns.filter(r => r.sourceType === filters.sourceType) : letterRuns
    );

    // setFilteredLetterRuns(filteredData);
  }, [filters.sourceType, letterRuns]);

  const handleGetFile = async (original: ILetterRunDetailToMonitoredEntity) => {
    try {
      await IFile.downloadFileById(original.fileId);
    } catch (_) {
      showToast('error', 'Failed to download the file. Please try again.');
    }
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name',
        handleClickColumn: () => {
          setResetPageKey(prev => prev + 1);
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILetterRunDetailToMonitoredEntity } };
        }) => {
          // If `original.status` is undefined / null, the letterRunRoute will
          // be non-sensical, So this displays an error message instead of
          // taking them to an invalid route.
          const onClick = () => {
            if (original.status) return;
            showToast('error', 'Unable to navigate to invalid URL.');
          };
          // Ensure non-void status
          if (!original.status) {
            console.error('TypeError: Missing "status" field on ILetterRunDetailToMonitoredEntity instance:', original);
          }
          const letterRunRoute = `${ILetterRun.getRoute(original.status)}/letterRunId=${
            original.letterRunId
          }`;
          return (
            <div>
              {/* CLIENT CELL */}
              {original && (
                <a href={letterRunRoute} target='_blank' rel='noreferrer' onClick={onClick}>
                  {original.clientName}
                </a>
              )}

              {/* CONTACT CELL */}
              {original && original.contactId && (
                <a href={`${routes.manageContacts}/${original.contactId}`} target='_blank' rel='noreferrer'>
                  {original.contactName}
                </a>
              )}
            </div>
          );
        },
      },
      {
        id: 'letterId',
        accessor: original => original.letterRunDetailId,
        Header: 'Letter ID',
        handleClickColumn: () => {
          setResetPageKey(prev => prev + 1);
        },
      },
      {
        id: 'dateTime',
        accessor: original => format(new Date(original.dateTime), "M/d/yyyy ' - ' h:mm a"),
        Header: 'Date/Time',
        handleClickColumn: () => {
          setResetPageKey(prev => prev + 1);
        },
      },
      {
        id: 'sourceType',
        Header: 'Type',
        accessor: original => sourceTypeEnums?.find(_ => _.value === original.sourceType)?.description ?? '',
        handleClickColumn: () => {
          setResetPageKey(prev => prev + 1);
        },
      },
      {
        Header: 'Details',
        accessor: 'details',
        handleClickColumn: () => {
          setResetPageKey(prev => prev + 1);
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original } };
        }) => {
          return (
            <IconButton onClick={() => handleGetFile(original)}>
              <AttachFile />
            </IconButton>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTypeEnums, filters]);

  return (
    <Grid container>
      {/* FILTER BAR */}
      <NotificationsTableFilters isLoading={isLoading} handleFilter={setFilters} sourceTypes={sourceTypeEnums} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          columns={columns}
          data={filteredLetterRuns}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
          resetPageKey={resetPageKey}
        />
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
  };
});
