import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, AttachFile, Cancel, Edit, History, Save } from '@material-ui/icons';
import { FC, useEffect, useMemo, useState } from 'react';

import { ITableColumn, PageTitle, Select, Separator, Table } from '../../components';
import { colors } from '../../styles';
import { CommentType, IEntityRequirement, IEnum, ILineOfCoverage } from '../../types';
import { AddRequirementModal } from './AddRequirementModal';
import { OnDemandSummaryModal } from './OnDemandSummaryModal';
import { RequirementFileHistoryModal } from './RequirementFileHistoryModal';
import { RequirementHistoryModal } from './RequirementHistoryModal';
import {
  ActualAmountCell,
  CarrierCell,
  CoverageTypeCell,
  DocumentDateCell,
  ExpirationDateCell,
  RequiredAmountCell,
  StatusCell,
} from './tableCells';
import RequirementFileAddModal from './RequirementFileAddModal';

interface IRequirementsTableProps {
  clientId: number;
  complianceStatusEnums?: IEnum[];
  contactGroupId: number | null;
  dirty: boolean;
  editing: boolean;
  handleCancel: () => void;
  handleClone: () => Promise<void>;
  linesOfCoverage: ILineOfCoverage[];
  load: () => Promise<void>;
  loading: boolean;
  monitoredEntityId: number;
  onUpdate: (values: IEntityRequirement[]) => void;
  requirements: IEntityRequirement[];
  displayedRequirements: IEntityRequirement[];
  resetForm: Function;
  setEditing: (b: boolean) => void;
  setLoading: (value: boolean) => void;
  showWaived: boolean;
  submitting: boolean;
  submitUpdates: () => Promise<{ shouldOnDemandSummaryTrigger: boolean }>;
  toggleWaived: () => void;
  unwaivedRequirements: IEntityRequirement[];
  associatedFiles: IEnum[];
  setAssociatedFiles: (files: IEnum[]) => void;
  setInitialActiveDocument: (file: IEnum) => void;
  initialActiveDocument: null | IEnum;
  isEntityDeficient: boolean;
  fetchRequirements: () => void;
  hasValidFields: boolean;
  setHasChanged: (b: boolean) => void;
  hasChanged: boolean;
  isAdditionalInsuredEditing: boolean;
  setIsAdditionalInsuredEditing: (b: boolean) => void;
  isEntityActive: boolean;
}

/**
 * This is the MOST important grid/table in the entire application.
 */
export const RequirementsTable: FC<IRequirementsTableProps> = ({
  requirements,
  displayedRequirements,
  unwaivedRequirements,
  associatedFiles,
  setAssociatedFiles,
  setInitialActiveDocument,
  initialActiveDocument,
  onUpdate,
  linesOfCoverage,
  handleClone,
  loading,
  setLoading,
  dirty,
  submitting,
  submitUpdates,
  handleCancel,
  showWaived,
  toggleWaived,
  clientId,
  monitoredEntityId,
  resetForm,
  contactGroupId,
  load,
  complianceStatusEnums,
  editing,
  setEditing,
  isEntityDeficient,
  fetchRequirements,
  hasValidFields,
  setHasChanged,
  hasChanged,
  isAdditionalInsuredEditing,
  setIsAdditionalInsuredEditing,
  isEntityActive
}) => {
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState<number | null>(null);
  const [hasCloned, setHasCloned] = useState<boolean>(false);
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
  const [showOnDemandSummary, setShowOnDemandSummary] = useState<boolean>(false);
  const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);
  const [showAddRequirementModal, setShowAddRequirementModal] = useState<boolean>(false);
  const [activeDocument, setActiveDocument] = useState<null | IEnum>(null);

  const classes = useStyles();

  /**
   * Whether at least one of Expiration Date, Document Date, or Carrier field
   * has been focused or not. These three Cells use this state to trigger custom
   * validation.
   */
  const [touched, setTouched] = useState(false);

  // When a new document is added, set it as the selected document
  useEffect(() => {
    if (initialActiveDocument) {
      setActiveDocument(initialActiveDocument);
    }
  }, [initialActiveDocument]);

  const updateData = (field: keyof IEntityRequirement, newValue: any, original: IEntityRequirement) => {
    let updatedValues = editing ? [...requirements] : [...displayedRequirements];
    setHasChanged(true);
    const reqIndexToUpdate = updatedValues.findIndex(v => v.requirementId === original.requirementId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      // If any field is changed, set the checkbox for that row to checked
      associatedDocumentIds:
        !!activeDocument && field !== 'associatedDocumentIds'
          ? original.associatedDocumentIds.concat(activeDocument.value)
          : original.associatedDocumentIds,
      [`${field}`]: newValue,
    };

    onUpdate(updatedValues);
  };

  const onCancel = () => {
    setEditing(false);
    setIsAdditionalInsuredEditing(false);
    setHasChanged(false);
    handleCancel();
  };

  const columns = useMemo(() => {
    return [
      {
        disableSortBy: true,
        accessor: 'lineOfCoverage',
        Header: 'Line of Coverage',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IEntityRequirement } };
        }) => {
          const lineOfCoverage = linesOfCoverage?.find(l => l.lineOfCoverageId === original.lineOfCoverageId);

          let isEndDated = false;
          if (original.defaultRequirementEndDate) {
            isEndDated = new Date(original.defaultRequirementEndDate) < new Date();
          }

          let locLabel = lineOfCoverage?.name;
          if (isEndDated) {
            locLabel = locLabel + ' (End Dated)';
          }

          return <div>{locLabel}</div>;
        },
      },
      {
        disableSortBy: true,
        Header: 'Type',
        id: 'coverageType',
        overrideWidth: 150,
        Cell: CoverageTypeCell,
      },
      {
        disableSortBy: true,
        Header: 'Status',
        overrideWidth: 160,
        Cell: StatusCell,
      },
      {
        disableSortBy: true,
        Header: 'Requirement',
        columnAlignment: 'right',
        overrideWidth: 110,
        Cell: RequiredAmountCell,
      },
      {
        disableSortBy: true,
        Header: 'Actual Fulfilled',
        columnAlignment: 'right',
        overrideWidth: 110,
        Cell: ActualAmountCell,
      },
      {
        disableSortBy: true,
        Header: 'Expiration',
        overrideWidth: 165,
        Cell: ExpirationDateCell,
      },
      {
        disableSortBy: true,
        Header: 'Document Date',
        overrideWidth: 165,
        Cell: DocumentDateCell,
      },
      {
        disableSortBy: true,
        Header: () => <AttachFile fontSize='small' style={{ marginLeft: '0.5rem' }} />,
        id: 'checkboxes',
        overrideWidth: 65,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IEntityRequirement; index: number } };
        }) => {
          const activeDocId = activeDocument?.value;
          return (
            <div key={original.requirementId}>
              <Checkbox
                size='small'
                disabled={associatedFiles?.length === 0 || !activeDocId}
                checked={original?.associatedDocumentIds?.includes(activeDocId)}
                onChange={event => {
                  if (!original?.associatedDocumentIds) return;
                  const newIsChecked: boolean = event.target.checked;
                  if (newIsChecked) {
                    // add
                    const newDocIds = original.associatedDocumentIds.concat(activeDocId);
                    updateData('associatedDocumentIds', newDocIds, original);
                  } else {
                    // remove
                    const without = original.associatedDocumentIds.filter(id => id !== activeDocId);
                    updateData('associatedDocumentIds', without, original);
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        accessor: 'carrier',
        disableSortBy: true,
        Header: 'Carrier',
        overrideWidth: 265,
        Cell: CarrierCell,
      },
      {
        accessor: (requirement: IEntityRequirement) =>
          complianceStatusEnums?.find(compliance => compliance.value === requirement?.complianceStatus)?.description,
        disableSortBy: true,
        Header: 'Compliance',
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IEntityRequirement; index: number } };
        }) => {
          return (
            <div key={original.requirementId} className={classes.gridButtonContainer}>
              {/* SHOW HISTORY BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRequirementId(original.requirementId);
                  setShowHistoryModal(true);
                }}
              >
                <History />
              </IconButton>

              {/* FILES BUTTON */}
              {original.requirementId && (
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    setSelectedRequirementId(original.requirementId);
                    setShowFilesModal(true);
                  }}
                >
                  <AttachFile />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ] as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirements, editing, activeDocument?.value, associatedFiles]);

  const useTableProps = {
    updateData,
    onUpdate,
    requirements,
    editing,
    touched,
    setTouched,
  };

  const handleCloneDefaultRequirements = async () => {
    setLoading(true);
    try {
      handleClone();
      setHasCloned(true);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div key={JSON.stringify(requirements)}>
      <div className={classes.header}>
        <PageTitle title='Requirements' marginOff />

        {/* IMPORT DEFAULT BUTTON */}
        {!requirements.length && !hasCloned && (
          <Button
            className={classes.importButton}
            size='small'
            variant='contained'
            id='import-default-reqs'
            color='primary'
            onClick={handleCloneDefaultRequirements}
          >
            Import Default
          </Button>
        )}

        {/* SHOW INACTIVE BUTTON */}
        <FormControl classes={{ root: classes.toggle }}>
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={showWaived} onChange={() => toggleWaived()} />}
              label='Show Inactive'
            />
          </FormGroup>
        </FormControl>

        <Separator />

        <>
          {/* SAVE BUTTON */}
          <IconButton
            className={classes.saveButton}
            color='primary'
            size='medium'
            disabled={(!dirty && !hasChanged) || submitting || !hasValidFields}
            onClick={async () => {
              try {
                const { shouldOnDemandSummaryTrigger } = await submitUpdates();
                await fetchRequirements();
                if (shouldOnDemandSummaryTrigger) {
                  setShowOnDemandSummary(true);
                }
                setEditing(false);
                setIsAdditionalInsuredEditing(false);
              } catch (error) {
                // Skip.
              }
            }}
          >
            <Save />
          </IconButton>

          <Separator />

          <IconButton
            className={classes.cancelButton}
            classes={{ root: classes.cancelIcon }}
            color='primary'
            size='medium'
            disabled={!(editing || isAdditionalInsuredEditing) && !(dirty || hasChanged)}
            onClick={() => onCancel()}
          >
            <Cancel />
          </IconButton>

          <Separator />

          <IconButton color='primary' size='medium' disabled={editing} onClick={() => setEditing(true)}>
            <Edit />
          </IconButton>
        </>

        <Separator />

        {/* ADD REQUIREMENTS BUTTON */}
        <Tooltip title='Add Requirements'>
          <IconButton
            color='primary'
            size='medium'
            disabled={loading || dirty || submitting || !hasValidFields || hasChanged}
            onClick={() => setShowAddRequirementModal(true)}
          >
            <Add />
          </IconButton>
        </Tooltip>

        <Separator />

        {/* ADD DOCUMENT BUTTON */}
        <Button
          className={classes.onDemandSummaryButton}
          color='primary'
          size='medium'
          onClick={() => setShowAddDocumentModal(true)}
          disabled={loading || dirty || submitting || !hasValidFields || hasChanged}
        >
          Add Document
        </Button>

        <Separator />

        {/* ON DEMAND SUMMARY BUTTON */}
        <Button
          className={classes.onDemandSummaryButton}
          color='primary'
          size='medium'
          onClick={() => setShowOnDemandSummary(true)}
          disabled={loading || dirty || submitting || !hasValidFields || hasChanged || !isEntityDeficient || !isEntityActive} //if not deficient, then disable ODS button
        >
          On Demand Summary
        </Button>

        <Box display='flex' justifyContent='flex-end' flex={1} paddingRight='1rem'>
          {/* ACTIVE DOCUMENT SELECTOR */}
          <Box width='16rem'>
            <Select
              disabled={dirty || loading || editing || associatedFiles?.length === 0}
              showReset={false}
              label='Active Document'
              id='active-document-select'
              value={activeDocument?.value ?? ''}
              onChange={event => {
                const newActiveDocument = associatedFiles.find(_ => _.value === +event.target.value);
                if (!newActiveDocument) {
                  console.error('Selected document not found.');
                  setActiveDocument(null);
                  return;
                }
                setActiveDocument(newActiveDocument);
              }}
              options={
                associatedFiles?.length > 0
                  ? associatedFiles?.map(({ value, text }) => ({ key: value, value: value, label: text }))
                  : [{ key: -1, value: -1, label: 'No documents found.' }]
              }
            />
          </Box>
        </Box>
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={loading}
          useTableProps={useTableProps}
          loadingPageSize={requirements.length || 3}
          columns={columns}
          data={displayedRequirements}
          headerClasses={classes.tableHeader}
          hidePagination
          stickyHeader
        />
      </div>

      {/* REQUIREMENT FILE HISTORY MODAL */}
      {showFilesModal && typeof selectedRequirementId === 'number' && (
        <RequirementFileHistoryModal
          open
          onClose={() => setShowFilesModal(false)}
          requirementId={selectedRequirementId}
        />
      )}

      {/* REQUIREMENT HISTORY MODAL */}
      {showHistoryModal && typeof selectedRequirementId === 'number' && (
        <RequirementHistoryModal
          open
          onClose={() => setShowHistoryModal(false)}
          selectedRequirementId={selectedRequirementId}
        />
      )}

      {/* ADD DOCUMENTS MODAL */}
      <RequirementFileAddModal
        open={showAddDocumentModal}
        onClose={() => setShowAddDocumentModal(false)}
        reloadRequirementsGrid={() => load()}
        linesOfCoverage={linesOfCoverage}
        inUseLinesOfCoverage={requirements.map(loc => loc.lineOfCoverageId)}
        monitoredEntityId={monitoredEntityId}
        setAssociatedFiles={(files: IEnum[]) => {
          setAssociatedFiles(files);
        }}
        setInitialActiveDocument={(file: IEnum) => {
          setInitialActiveDocument(file);
        }}
        overrideRequirementRows={(newRequirementList: IEntityRequirement[]) => {
          onUpdate(newRequirementList);
        }}
      />

      {/* ON DEMAND SUMMARY MODAL */}
      <OnDemandSummaryModal
        open={showOnDemandSummary}
        onClose={() => setShowOnDemandSummary(false)}
        clientId={clientId}
        contactGroupId={contactGroupId}
        monitoredEntityId={monitoredEntityId}
        commentType={CommentType.Entities}
      />

      {/* ADD REQUIREMENT MODAL */}
      <AddRequirementModal
        open={showAddRequirementModal}
        onClose={() => setShowAddRequirementModal(false)}
        clientId={clientId}
        linesOfCoverage={linesOfCoverage}
        monitoredEntityId={monitoredEntityId}
        resetForm={resetForm}
        unwaivedRequirements={unwaivedRequirements ?? []}
        load={load}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    importButton: {
      marginLeft: theme.spacing(1),
    },
    saveButton: {
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.common.white,
      },
    },
    cancelButton: {
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
      },
    },
    cancelIcon: {
      fontSize: '2rem',
    },
    toggle: {
      marginLeft: theme.spacing(2),
    },
    onDemandSummaryButton: {
      marginTop: '4px',
    },
  };
});
