import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { Loader, Page, Pagination, Table } from '../../components';
import { routes, userRoles } from '../../constants';
import { UserContext } from '../../context';
import { getLetterRunPaged, requeueFailed } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { ILetterRun, ILetterRunFilters, ILetterRunHistory } from '../../types';
import { ContactLetterFilters } from './contactLetterFilters';
import { useHistory } from 'react-router-dom';
import { formatError } from '../../helpers/errors';

// Currently the backend is giving us numbers instead of strings for the status, sort Direction, and Columns
enum SourceType {
  LetterRun = 1,
  OnDemandSummary = 2,
  ContactLetters = 3,
}

enum SortDirection {
  Asc = 1,
  Desc = 2,
}

enum Columns {
  Name = 1,
  DateTime = 2,
  LettersGenerated = 3,
  Status = 4,
}
export const ContactLetters = () => {
  const [letterRuns, setLetterRuns] = useState<ILetterRunHistory[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<ILetterRunFilters>({});
  // const history = useHistory();
  const classes = useStyles();
  const { showToast } = useToastContext();
  const { userContext } = useContext(UserContext);
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const history = useHistory();

  const [selectedSort, setSelectedSort] = useState<number | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<{
    [Columns.Name]?: number;
    [Columns.DateTime]?: number;
    [Columns.LettersGenerated]?: number;
    [Columns.Status]?: number;
  }>({});

  const handleClickColumn = (column: number) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
    });
  };

  const fetchLetterRuns = async () => {
    setLoading(true);

    try {
      const res = await getLetterRunPaged({
        sourceType: SourceType.ContactLetters,
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort],
        clientName: filters.name,
        ...filters,
      });

      setLetterRuns(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error) {
      formatError(error, showToast);
    } finally {
      setLoading(false);
    }
  };

  const requeueLetterRunRequeue = async (letterRunId: number, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    try {
      setIsLoading(true);
      await requeueFailed(letterRunId);
      showToast('success', 'Requeued skipped entities!');
    } catch (err) {
      console.error(err);
      showToast('error', err instanceof Error ? err.message : 'Failed to requeue skipped entities.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLetterRuns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, sortDirection, filters.name]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Letter Run Name',
        isServerSorted: selectedSort === Columns.Name,
        isServerSortedDesc: sortDirection?.[Columns.Name] === SortDirection.Desc,
        handleClickColumn: () => handleClickColumn(Columns.Name),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILetterRun } };
        }) => {
          // Ensure non-void status
          if (!original.statusValue) {
            const err = TypeError('Missing "statusValue" field on ILetterRun instance.');
            console.error(err, original);
            showToast('error', 'Unable to navigate to invalid URL.');
          }
          const href = `${ILetterRun.getContactLetterRoute(original?.statusValue)}/letterRunId=${original.letterRunId}`;
          return (
            <div>
              <a href={href} target='_blank' rel='noreferrer'>
                {original.clientName}
              </a>
            </div>
          );
        },
      },
      {
        id: 'dateTime',
        accessor: original => format(new Date(original.dateTime), "M/d/yyyy ' - ' h:mm a"),
        Header: 'Date/Time',
        isServerSorted: selectedSort === Columns.DateTime,
        isServerSortedDesc: sortDirection?.[2] === SortDirection.Desc,
        handleClickColumn: () => handleClickColumn(Columns.DateTime),
      },
      {
        accessor: 'lettersGenerated',
        Header: 'Number of Letters Generated',
        isServerSorted: selectedSort === Columns.LettersGenerated,
        isServerSortedDesc: sortDirection?.[Columns.LettersGenerated] === SortDirection.Desc,
        handleClickColumn: () => handleClickColumn(Columns.LettersGenerated),
      },
      {
        Header: 'Status',
        accessor: 'status',
        isServerSorted: selectedSort === Columns.Status,
        isServerSortedDesc: sortDirection?.[Columns.Status] === SortDirection.Desc,
        handleClickColumn: () => handleClickColumn(Columns.Status),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILetterRun } };
        }) => {
          const [isLoading, setIsLoading] = useState<boolean>(false);
          const shouldShow = original.status === 'Generating Letters';

          return isLoading ? (
            <Loader type={'inline'} />
          ) : (
            shouldShow && (
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  requeueLetterRunRequeue(original.letterRunId, setIsLoading);
                }}
              >
                Requeue Skipped Entities
              </Button>
            )
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection, letterRuns]).filter(column =>
    userContext.role === userRoles.ADMIN ? true : column.accessor !== 'actions'
  );

  return (
    <Page title='Client Letters'>
      <Grid container alignItems={'center'} justify={'space-between'}>
        {/* FILTER BAR */}
        <ContactLetterFilters handleFilter={setFilters} isLoading={isLoading} />

        {/* START NEW LETTER RUN BUTTON */}
        <Button color='primary' onClick={() => history.push(routes.newContactLetterRun)}>
          Start New Client Letter Run
        </Button>
      </Grid>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          columns={columns}
          data={letterRuns}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
          hidePagination
        />
        {!isLoading && (
          <Pagination
            page={page}
            count={recordCount}
            rowsPerPage={perPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
    </Page>
  );
};

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
  };
});
