export const colors = {
  primary: {
    navyBlue: '#002855',
    accentRed: '#BA2031'
  },
  secondary: {
    royalBlue: '#194F90',
    lightBlue: '#7BA5DE',
    cheetahYellow: '#F9B746',
    black: '#000000',
    darkGray: '#6D6E71',
    lightGray: '#C7C8CA',
    lightestGray: '#d0d7e0',
    lightLavendar: '#e9eef3',
    white: '#ffffff',
    catskillWhite: '#E8EDF3'
  }
};
