import { Fab, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete, Edit } from '@material-ui/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ConfirmationDialogue, ITableColumn, Page, Pagination, Table, Toast } from '../../components';
import { ClientType, ClientTypeItems, routes, screenSizes, userRoles } from '../../constants';
import { ClientSelectorContext, UserContext } from '../../context';
import { getClients, updateClient } from '../../fetch';
import { colors } from '../../styles';
import { ASC_SORT, DESC_SORT, IClient, IManageClientsFilters, SortOptions } from '../../types';
import { ManageClientsFilters } from './ManageClientsFilters';

const getClientTypeLabel = (id: ClientType) => {
  if (id) {
    const find = ClientTypeItems.find(x => x.value === id);
    return find ? find.label : '';
  }
  return '';
};

export const ManageClients: FC = () => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const [sortDirection, setSortDirection] = useState<{
    Code?: SortOptions;
    Name?: SortOptions;
    ClientType?: SortOptions;
  }>({
    Code: ASC_SORT,
  });
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [selectedClient, setClient] = useState<IClient>(null);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>('Code');
  const [clients, setClients] = useState<IClient[]>([]);
  const [filters, setFilters] = useState<IManageClientsFilters>({});
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const { userContext } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters, clientSelectorContext]);

  const fetchClients = async () => {
    setLoading(true);

    try {
      const res = await getClients({
        page: page + 1,
        // Remove limit when on the first page
        perPage: page === 0 ? 9999 : perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        code: filters.code || clientSelectorContext?.code,
        name: filters.name || clientSelectorContext?.name,
        clientType: filters.clientType || clientSelectorContext?.clientType?.toString(),
      });

      // Filter the data by what is selected in the global client selector
      let clientData = res.records;
      if (clientSelectorContext?.clientId) {
        clientData = res.records.filter(client => client.clientId === clientSelectorContext.clientId);
      }
      setClients(clientData);
      setRecordCount(res.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async client => {
    try {
      setLoading(true);
      client.isDeleted = true;
      await updateClient(client);
      fetchClients();
      showSuccess(true);
    } catch (error) {
      console.log(error);
      showError(true);
    }
  };

  const hasData = clients && clients.length > 0;

  const columns = useMemo(() => {
    return [
      {
        id: 'code',
        Header: 'Code',
        isServerSorted: selectedSort === 'Code',
        isServerSortedDesc: sortDirection.Code === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Code');
          setSortDirection({
            ...sortDirection,
            Code: sortDirection.Code === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClient } };
        }) => <div>{original.code}</div>,
      },
      {
        id: 'name',
        Header: 'Name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Name');
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClient } };
        }) => <div>{original.name}</div>,
      },
      {
        id: 'clientType',
        Header: 'Client Type',
        isServerSorted: selectedSort === 'ClientType',
        isServerSortedDesc: sortDirection.ClientType === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('ClientType');
          setSortDirection({
            ...sortDirection,
            ClientType: sortDirection.ClientType === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClient } };
        }) => <div>{getClientTypeLabel(original.clientType)}</div>,
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClient } };
        }) => {
          return (
            <div key={original.clientId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  history.push(`${routes.manageClients}/${original.clientId}`);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              {userContext.role === userRoles.ADMIN && (
                <IconButton
                  color='secondary'
                  size='small'
                  onClick={() => {
                    setClient(original);
                    setShowConfirmDeleteDialogue(true);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Manage Clients'>
      {/* FILTER BAR */}
      <ManageClientsFilters handleFilter={setFilters} isLoading={isLoading} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          hidePagination
          columns={columns}
          data={clients}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
        />
        {!isLoading && hasData && (
          <Pagination
            page={page}
            count={recordCount}
            rowsPerPage={perPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      {/* CONFIRM DELETE MODAl */}
      {selectedClient && (
        <ConfirmationDialogue
          id={`${selectedClient}`}
          title={'Delete Client'}
          text={'Are you sure you want to delete this client?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedClient);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab
        color='secondary'
        className={isMobile ? classes.mobileAddButton : classes.addButton}
        aria-label='add'
        onClick={() => {
          history.push(`${routes.manageClients}/-1`);
        }}
      >
        <Add />
      </Fab>

      {/* SUCCESS/FAIL MESSAGING */}
      <Toast
        id='client-type-success'
        message={`Client Deleted!`}
        open={isSuccess}
        onClose={() => showSuccess(false)}
        variant='success'
      />
      <Toast
        id='client-type-error'
        autoHideDuration={6000}
        message={
          'We were unable to delete this client at this time. Please try again later. Please contact support if this issue continues.'
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      backgroundColor: colors.secondary.catskillWhite,
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem',
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem',
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
