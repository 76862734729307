import { Button, Fab, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete, Edit } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { ConfirmationDialogue, ITableColumn, PageTitle, Table } from '../../components';
import { ProgressLoader } from '../../components/loader/ProgressLoader';
import { userRoles } from '../../constants';
import { UserContext } from '../../context';
import {
  addFileForBulkData as addFile,
  createBlanketCertificate,
  createBulkUpdateQueue,
  deleteBlanketCertificate,
  getBulkUpdateProgress,
  runBulkUpdateQueue,
  updateBlanketCertificate,
} from '../../fetch';
import { convertToUTC, formatShortFriendlyDateWithTime } from '../../helpers';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IBulkUpdateStatus, IBlanketCertificate, IContact, INewBlanketCertificate } from '../../types';
import { AddEditBlanketCertificate } from './add-edit-blanket-certs-modal';

interface IBlanketCertificateTable {
  blanketCertificates: IBlanketCertificate[];
  isLoading: boolean;
  fetchContact: () => Promise<void>;
  contact: IContact;
  setContactData: React.Dispatch<React.SetStateAction<IContact>>;
  setContactDataClone: React.Dispatch<React.SetStateAction<IContact>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EMPTY_CONTACT: IContact = {
  contactId: -1,
  name: '',
  contactGroups: [],
  blanketCertificates: [],
};

export const BlanketCertificateTable: FC<IBlanketCertificateTable> = ({
  blanketCertificates = [],
  isLoading,
  contact,
  setContactData,
  setContactDataClone,
  setLoading,
}) => {
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [blanketCertificateBeingEdited, setBlanketCertificateBeingEdited] = useState<IBlanketCertificate | null>(null);
  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [totalNumber, setTotalNumber] = useState<number | null>(null);
  const [bulkUpdateRunId, setBulkUpdateRunId] = useState<number | null>(null);
  const createBulkUpdate = async blanketCertId => {
    try {
      const res = await createBulkUpdateQueue(blanketCertId);

      if (res.data.total === 0) {
        return showToast('error', `Bulk Update will not run. ${res.data.total} Entities will be affected.`);
      }
      setTotalNumber(res.data.total);
      setBulkUpdateRunId(res.data.bulkUpdateRunId);
    } catch (error) {
      showToast('error', 'Bulk update was not run. Please make sure you have a file associated.');
    }
  };

  const [isLoadingBarShowing, setIsLoadingBarShowing] = useState(false);
  const [isRunningBulkUpdate, setisRunningBulkUpdate] = useState(false);
  const runBulkUpdate = async () => {
    setisRunningBulkUpdate(true);
    runBulkUpdateQueue(bulkUpdateRunId).catch(() => showToast('error', 'Bulk update encountered an Error.'));
    setIsLoadingBarShowing(true);
    setIsConfirmationModalOpen(false);
    setTotalNumber(null);
  };

  const [bulkUpdateStatus, setBulkUpdateStatus] = useState<IBulkUpdateStatus>(null);
  const getBulkUpdateStatus = async () => {
    try {
      const res = await getBulkUpdateProgress(bulkUpdateRunId);

      setBulkUpdateStatus(JSON.parse(JSON.stringify(res)));
    } catch (error) {
      showToast('error', 'Bulk update was not run.');
      setBulkUpdateRunId(null);
      setisRunningBulkUpdate(false);
      setIsLoadingBarShowing(false);
      setTotalNumber(null);
    }
  };

  useEffect(() => {
    let interval;
    if (bulkUpdateRunId && isLoadingBarShowing) {
      interval = setInterval(() => {
        if (bulkUpdateStatus && bulkUpdateStatus.entitiesRemaining === 0) {
          setIsLoadingBarShowing(false);
          setisRunningBulkUpdate(false);
          setTotalNumber(null);
          setBulkUpdateStatus(null);
          showToast('info', 'All entities have processed');
          return clearInterval(interval);
        }
        return getBulkUpdateStatus();
      }, 500);
    }
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingBarShowing, bulkUpdateRunId, bulkUpdateStatus]);

  useEffect(() => {
    if (typeof totalNumber !== 'number') return;
    setIsConfirmationModalOpen(true);
  }, [totalNumber]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Client',
        accessor: 'clientName',
      },
      {
        Header: 'Entered Date',
        accessor: 'enteredDate',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBlanketCertificate } };
        }) => {
          return (
            <div key={original.blanketCertificateId}>
              <span>
                {original?.enteredDate
                  ? formatShortFriendlyDateWithTime(convertToUTC(original?.enteredDate?.toString()))
                  : ''}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'User',
        accessor: 'user',
        id: 'address1',
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBlanketCertificate } };
        }) => {
          return (
            <div key={original.blanketCertificateId}>
              <span>{!!original.expirationDate ? format(new Date(original.expirationDate), 'MM/dd/yyyy') : null}</span>
            </div>
          );
        },
      },
      {
        Header: 'Document Date',
        accessor: 'documentDate',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBlanketCertificate } };
        }) => {
          return (
            <div key={original.blanketCertificateId}>
              <span>{!!original.documentDate ? format(new Date(original.documentDate), 'MM/dd/yyyy') : null}</span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBlanketCertificate } };
        }) => {
          return (
            <div key={original.blanketCertificateId} className={classes.gridButtonContainer}>
              <Button
                onClick={() => {
                  createBulkUpdate(original.blanketCertificateId);
                }}
              >
                UPDATE ENTITIES
              </Button>
              {/* EDIT BUTTON */}
              <IconButton
                classes={{ root: classes.edit }}
                size='small'
                onClick={() => {
                  setBlanketCertificateBeingEdited(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              {userContext.role === userRoles.ADMIN && (
                <IconButton
                  classes={{ root: classes.delete }}
                  size='small'
                  onClick={() => {
                    setBlanketCertificateBeingEdited(original);
                    setShowConfirmDeleteDialogue(true);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blanketCertificates]);

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Blanket Certificates' marginOff />
      </div>
      {blanketCertificates && blanketCertificates?.length > 0 ? (
        <div>
          {/* GRID */}
          <div className={classes.gridContainer}>
            <Table
              isLoading={isLoading}
              loadingPageSize={
                !Array.isArray(blanketCertificates) || blanketCertificates?.length === 0
                  ? 3
                  : blanketCertificates?.length
              }
              key={JSON.stringify(blanketCertificates.filter(contactGroup => !contactGroup?.isDeleted))}
              columns={columns}
              data={blanketCertificates}
              headerClasses={classes.tableHeader}
              hidePagination
              stickyHeader
            />
          </div>
        </div>
      ) : null}

      <Fab className={`${classes.addButton} `} onClick={() => setShowAddEditModal(true)}>
        <Add />
      </Fab>
      {bulkUpdateStatus && isLoadingBarShowing && (
        <ProgressLoader
          className={classes.progressBar}
          position={'centered'}
          type={'overlay'}
          value={Math.round((bulkUpdateStatus.entitiesCompleted / bulkUpdateStatus.entitiesTotal) * 100)}
        />
      )}

      <AddEditBlanketCertificate
        blanketCertificateBeingEdited={blanketCertificateBeingEdited}
        open={showAddEditModal}
        onClose={() => {
          setBlanketCertificateBeingEdited(null);
          setShowAddEditModal(false);
        }}
        contactId={contact.contactId}
        onSave={async (blanketCertification, associatedFiles) => {
          try {
            setLoading(true);
            if (blanketCertification?.blanketCertificateId) {
              if (!!associatedFiles) {
                const data = new FormData();
                data.append('Files', associatedFiles[0]);
                data.append(`Mappings[0].id`, `${blanketCertification.blanketCertificateId}`);
                data.append(`Mappings[0].filename`, `${associatedFiles[0].name}`);
                const fileData = await addFile(data);

                blanketCertification.fileId = fileData[0].beId;
              }

              const res = await updateBlanketCertificate(blanketCertification);
              setContactData(prev => {
                const updatedCert =
                  prev?.blanketCertificates?.find(cert => cert?.blanketCertificateId === res?.blanketCertificateId) ??
                  null;
                prev.blanketCertificates = [
                  ...prev?.blanketCertificates?.filter(
                    cert => cert?.blanketCertificateId !== updatedCert?.blanketCertificateId
                  ),
                  res,
                ];
                const newBlanketCertificates = JSON.parse(JSON.stringify(prev));

                return newBlanketCertificates;
              });
              setContactDataClone(prev => {
                const updatedCert =
                  prev?.blanketCertificates?.find(cert => cert?.blanketCertificateId === res?.blanketCertificateId) ??
                  null;
                prev.blanketCertificates = [
                  ...prev?.blanketCertificates?.filter(
                    cert => cert?.blanketCertificateId !== updatedCert?.blanketCertificateId
                  ),
                  res?.value,
                ];
                const newBlanketCertificates = JSON.parse(JSON.stringify(prev));
                return newBlanketCertificates;
              });
              showToast('success', 'Blanket Certificates Updated!');
            } else {
              if (!!associatedFiles) {
                const data = new FormData();
                data.append('Files', associatedFiles[0]);
                data.append(`Mappings[0].id`, `${blanketCertification.blanketCertificateId}`);
                data.append(`Mappings[0].filename`, `${associatedFiles[0].name}`);
                const fileData = await addFile(data);

                blanketCertification.fileId = fileData[0].beId;
              } else {
                blanketCertification.fileId = null;
                blanketCertification.fileName = null;
              }
              const res = await createBlanketCertificate(blanketCertification as INewBlanketCertificate);
              setContactData(prev => {
                prev.blanketCertificates = [...prev?.blanketCertificates, res];
                const newBlanketCertificates = JSON.parse(JSON.stringify(prev));
                return newBlanketCertificates ?? [EMPTY_CONTACT];
              });
              setContactDataClone(prev => {
                prev.contactGroups = [...prev.contactGroups, res];
                const newContactGroup = JSON.parse(JSON.stringify(prev));

                return newContactGroup ?? [EMPTY_CONTACT];
              });
              showToast('success', 'Blanket Certificates Created!');
            }
          } catch (error) {
            showToast('error', 'Blanket Certificates were not updated.');
          } finally {
            setLoading(false);
          }

          setShowAddEditModal(false);
        }}
      />

      {/* CONFIRM DELETE MODAl */}
      {blanketCertificateBeingEdited && (
        <ConfirmationDialogue
          id='confirm-blanket-certificates-delete'
          title='Delete Blanket Certificate'
          text='Are you sure you want to delete this Blanket Certificate?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setBlanketCertificateBeingEdited(null);
          }}
          onConfirm={async () => {
            setLoading(true);
            const blanketCertifications = JSON.parse(JSON.stringify(blanketCertificates));
            const deleteRowIndex = blanketCertifications.findIndex(
              cert => cert.blanketCertificateId === blanketCertificateBeingEdited.blanketCertificateId
            );

            let blanketCertificationToDelete;

            blanketCertifications[deleteRowIndex].isDeleted = true;
            blanketCertificationToDelete = blanketCertifications[deleteRowIndex] as IBlanketCertificate;

            try {
              await deleteBlanketCertificate(blanketCertificationToDelete.blanketCertificateId);

              setContactData(prev => {
                prev.blanketCertificates = [
                  ...prev?.blanketCertificates?.filter(
                    cert => cert.blanketCertificateId !== blanketCertificationToDelete.blanketCertificateId
                  ),
                ];
                const newBlanketCertificates = JSON.parse(JSON.stringify(prev));

                return newBlanketCertificates ?? [EMPTY_CONTACT];
              });
              setContactDataClone(prev => {
                prev.blanketCertificates = [
                  ...prev?.blanketCertificates?.filter(
                    cert => cert.blanketCertificateId !== blanketCertificationToDelete.blanketCertificateId
                  ),
                ];
                const newBlanketCertificates = JSON.parse(JSON.stringify(prev));

                return newBlanketCertificates ?? [EMPTY_CONTACT];
              });
              showToast('success', 'Blanket Certificates Updated!');

              setShowConfirmDeleteDialogue(false);
              setBlanketCertificateBeingEdited(null);
            } catch (error) {
              console.log(error);
              showToast('error', 'Blanket Certificate was not deleted.');
            } finally {
              setLoading(false);
            }
          }}
        />
      )}
      <ConfirmationDialogue
        open={isConfirmationModalOpen}
        id={`confirm-update-entities`}
        title='Update Entities'
        text={isRunningBulkUpdate ? 'Loading...' : `This will update ${totalNumber} monitored entities`}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setTotalNumber(null);
          setBulkUpdateRunId(null);
        }}
        onConfirm={() => {
          runBulkUpdate();
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9,
    },
    floatButton: {
      position: 'absolute',
      right: theme.spacing(2),
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    edit: { color: theme.palette.primary.main },
    delete: { color: theme.palette.secondary.main },
    progressBar: {
      height: theme.spacing(2),
      width: '40%',
      marginLeft: theme.spacing(1),
      borderRadius: '0.8rem',
    },
  };
});
