import { authFetch } from './';
import { IError, ILineOfCoverage } from '../types';

const baseUrl = '/api/LinesOfCoverage';

export const getLineOfCoverage = async (id: number): Promise<ILineOfCoverage> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting line of coverage. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteLineOfCoverage = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting line of coverage. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateLineOfCoverage = async (lineOfCoverage: ILineOfCoverage): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, lineOfCoverage);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating line of coverage. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createLineOfCoverage = async (lineOfCoverage: ILineOfCoverage): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Create`, lineOfCoverage);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating line of coverage. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getLinesOfCoverage = async (): Promise<ILineOfCoverage[]> => {

  try {
    const res = await authFetch.get(`${baseUrl}/List`);
    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};
