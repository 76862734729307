import React, { FC } from 'react';
import { SkeletonLoader } from '../../components';
import { Button, Grid, Paper } from '@material-ui/core';
import { screenSizes } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { useMedia } from 'react-use';

interface IReportsLoadingStateProps {
  count?: number;
}

export const ReportsLoadingState: FC<IReportsLoadingStateProps> = ({ count = 6 }) => {
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={`${classes.gridContainer} ${isMobile ? classes.columnOrientation : ''}`}>
      {[...(Array(count).keys() as any)].map((x, i) => {
        return (
          <Grid key={i} item xs={4} className={`${classes.reportContainer}`}>
            <Paper elevation={3}>
              {/* REPORT HEADER */}
              <SkeletonLoader className={classes.header} />

              {/* REPORT DESCRIPTION */}
              <div className={classes.body}>
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </div>

              {/* VIEW BUTTON */}
              <div className={classes.footer}>
                <Button variant='contained' color='primary'>
                  View
                </Button>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  columnOrientation: {
    flexDirection: 'column'
  },
  reportContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    '& > *': {
      width: theme.spacing(32),
      height: theme.spacing(16),
      backgroundColor: colors.secondary.lightLavendar
    }
  },
  header: {
    width: '50%',
    padding: theme.spacing(1),
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightLavendar
  },
  body: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: theme.spacing(10),
    overflow: 'hidden',
    backgroundColor: colors.secondary.lightLavendar
  },
  footer: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightestGray
  }
}));
