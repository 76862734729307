import {
  Button,
  CardActions,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Check } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { deepEqual } from 'fast-equals';
import { Form, Formik } from 'formik';
import orderBy from 'lodash/orderBy';
import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Comments, Loader, Modal, Select } from '../../components';
import { ClientProvider } from '../../context';
import {
  getClientsForSelector,
  getContactTypes,
  getCountriesDropdown,
  getUnitedStatesDropdown,
  ICommentsAPI,
} from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  CommentType,
  IAddress,
  IClient,
  IClientThin,
  IContactGroup,
  IContactGroupDistributionList,
  IContactTypeValue,
  IEnum,
  IEnumDropdown,
  IMonitoredEntityComment,
} from '../../types';

const EMPTY_ADDRESS: IAddress = {
  address1: '',
  address2: null,
  addressId: 0,
  city: '',
  /**
   * Default country is United States.
   */
  country: 'United States',
  postalCode: '',
  state: '',
};

const isUnitedStates = (str: string): str is 'United States' | 'USA' => str === 'United States' || str === 'USA';

interface IAddEditContactGroupProps {
  open: boolean;
  onClose: () => void;
  onSave: (group: IContactGroup) => void;
  /**
   * The current entry under edit. If null, this modal is in add mode.
   */
  contactGroupBeingEdited: IContactGroup | null;
}

const createContactGroupSchema = (states: IEnumDropdown[]) =>
  Yup.object().shape({
    name: Yup.string().required('Required').typeError('Required'),
    phoneNumber: Yup.string().nullable(),
    attention: Yup.string().nullable(),
    address: Yup.object().shape({
      address1: Yup.string().required('Required').typeError('Required'),
      address2: Yup.string().nullable(),
      city: Yup.string().required('Required').typeError('Required'),
      country: Yup.string().required('Required').typeError('Required'),
      state: Yup.string().when('country', {
        is: (country: string) => isUnitedStates(country),
        // prettier-ignore
        then: Yup.string().oneOf(states.map(_ => _.text), 'Invalid state.').required('Required').typeError('Required'),
        otherwise: Yup.string(),
      }),
      postalCode: Yup.string().when('country', {
        is: (country: string) => isUnitedStates(country),
        then: Yup.string().required('Required').typeError('Required'),
        otherwise: Yup.string(),
      }),
    }),
    sendNotifications: Yup.boolean(),
    distributionLists: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email('Enter a valid email'),
      })
    ),
  });

export const AddEditContactGroup: FC<IAddEditContactGroupProps> = ({
  open,
  onClose,
  contactGroupBeingEdited,
  onSave,
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();
  const [isEdit, setIsEdit] = useState(!!contactGroupBeingEdited);

  // Local state
  const [newDistributionListId, setNewDistributionListId] = useState<number>(-1);
  const [newDistributionListIndex, setNewDistributionListIndex] = useState(-1);

  // Network "state" that is really just data
  const [contactTypes, setContactTypes] = useState<IEnum[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [countries, setCountries] = useState<IEnumDropdown[]>([]);
  /** A list of abbreviations of the states of the US. */
  const [states, setStates] = useState<IEnumDropdown[]>([]);

  const getContactGroupTypeComments = useCallback(async (): Promise<IMonitoredEntityComment[]> => {
    const modalIsInAddMode = contactGroupBeingEdited === null;
    if (modalIsInAddMode) {
      return [];
    }
    const fetched = await ICommentsAPI.getAllByType(contactGroupBeingEdited.contactGroupId, CommentType.ContactGroups);
    return orderBy(fetched, ['dateCreated'], ['desc']);
  }, [contactGroupBeingEdited]);

  // Fetch and set local state from network data, skipping if the user exits the component
  useEffect(() => {
    let cancel = false;
    Promise.all([getContactTypes(), getClientsForSelector(), getCountriesDropdown(), getUnitedStatesDropdown()])
      .then(([contactType, client, countries, states]) => {
        if (cancel) {
          return;
        }
        setContactTypes(contactType);
        setClients(client);
        setCountries(countries);
        setStates(states);
      })
      .catch(err => {
        if (cancel) {
          return;
        }
        if (process.env.NODE_ENV !== 'production') {
          showToast('error', err instanceof Error ? err.message : 'Failed to load modal data.');
        }
      });
    // Exists to avoid state updates on an unmounted component (a no-no).
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsEdit(!!contactGroupBeingEdited);
  }, [contactGroupBeingEdited, open]);

  return (
    <>
      <Formik<IContactGroup>
        enableReinitialize
        initialValues={{
          contactGroupId: 0,
          name: '',
          phoneNumber: '',
          attention: '',
          address: EMPTY_ADDRESS,
          distributionLists: [],
          sendNotifications: true,
          clientId: null,
          ...contactGroupBeingEdited,
        }}
        validateOnMount={isEdit}
        validationSchema={states.length > 0 ? createContactGroupSchema(states) : undefined}
        onSubmit={(values, actions) => {
          const contactGroup: IContactGroup = {
            contactGroupId: values.contactGroupId,
            clientName: clients.find(_ => _.clientId === values.clientId)?.name ?? '',
            name: values.name,
            phoneNumber: values.phoneNumber,
            attention: values.attention,
            address: values.address,
            sendNotifications: values.sendNotifications,
            // Filter out email fields that weren't filled out
            distributionLists: values.distributionLists.filter(listItem => listItem.email),
            clientId: values.clientId,
          };

          onClose();
          onSave(contactGroup);
          actions.resetForm();
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              maxWidth='lg'
              open={open}
              title={contactGroupBeingEdited ? 'Edit Contact Group' : 'Add New Contact Group'}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Grid container spacing={2} direction='row'>
                    <Grid item container xs={12} md={6}>
                      <Grid container spacing={1}>
                        {/* CONTACT GROUP NAME */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            autoComplete='nope'
                            label='Contact Group Name'
                            name='name'
                            value={values.name}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('name', e.target.value)}
                            error={Boolean((isEdit || touched.name) && errors.name)}
                            helperText={(isEdit || touched.name) && errors.name}
                          />
                        </Grid>
                        {/* PHONE NUMBER */}
                        <Grid item xs={12} md={6}>
                          <TextField
                            autoComplete='nope'
                            label='Phone Number'
                            name='phoneNumber'
                            value={values.phoneNumber}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('phoneNumber', e.target.value)}
                          />
                        </Grid>
                        {/* ATTENTION */}
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='nope'
                            label='Attention'
                            name='attention'
                            value={values.attention}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('attention', e.target.value)}
                          />
                        </Grid>
                        {/* ADDRESS 1 */}
                        <Grid item xs={12}>
                          <TextField
                            required
                            autoComplete='nope'
                            label='Address 1'
                            name='address1'
                            value={values.address && values.address.address1}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('address.address1', e.target.value)}
                            // @ts-ignore
                            error={Boolean((isEdit || touched.address1) && errors.address?.address1)}
                            // @ts-ignore
                            helperText={(isEdit || touched.address1) && errors.address?.address1}
                          />
                        </Grid>
                        {/* ADDRESS 2 */}
                        <Grid item xs={12}>
                          <TextField
                            autoComplete='nope'
                            label='Address 2'
                            name='address2'
                            value={values.address && values.address.address2}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('address.address2', e.target.value)}
                            // @ts-ignore
                            error={Boolean((isEdit || touched.address2) && errors.address?.address2)}
                            // @ts-ignore
                            helperText={(isEdit || touched.address2) && errors.address?.address2}
                          />
                        </Grid>
                        {/* CITY */}
                        <Grid item xs={12}>
                          <TextField
                            required
                            autoComplete='nope'
                            label='City'
                            name='city'
                            value={values.address && values.address.city}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('address.city', e.target.value)}
                            // @ts-ignore
                            error={Boolean((isEdit || touched.city) && errors.address?.city)}
                            // @ts-ignore
                            helperText={(isEdit || touched.city) && errors.address?.city}
                          />
                        </Grid>
                        {/* STATE */}
                        <Grid item xs={6} md={4}>
                          {isUnitedStates(values.address.country) ? (
                            <Autocomplete
                              selectOnFocus
                              clearOnBlur
                              disableClearable
                              includeInputInList
                              value={values.address.state}
                              id='us-states-dropdown'
                              onChange={(_event, newValue) => {
                                setFieldValue('address.state', newValue);
                              }}
                              options={states.map(_ => _.text)}
                              noOptionsText='No States'
                              renderInput={params => (
                                <TextField
                                  fullWidth
                                  required
                                  label='State'
                                  name='state'
                                  ref={params.InputProps.ref}
                                  inputProps={params.inputProps}
                                  InputProps={{
                                    ...params.InputProps,
                                    // autoComplete new-password disables browser autofill
                                    autoComplete: 'new-password',
                                    // Renders the clear-the-input button which resets state to empty string
                                    // instead of null which would cause an odd validation error the user sees.
                                    type: 'search',
                                  }}
                                  className={classes.formTextField}
                                  onBlur={handleBlur}
                                  // @ts-ignore
                                  error={Boolean((isEdit || touched.state) && errors.address?.state)}
                                  // @ts-ignore
                                  helperText={(isEdit || touched.state) && errors.address?.state}
                                />
                              )}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              required={isUnitedStates(values.address.country)}
                              label='State'
                              name='state'
                              value={values.address.state}
                              onChange={event => {
                                setFieldValue('address.state', event.target.value);
                              }}
                              className={classes.formTextField}
                              onBlur={handleBlur}
                              // @ts-ignore
                              error={Boolean((isEdit || touched.state) && errors.address?.state)}
                              // @ts-ignore
                              helperText={(isEdit || touched.state) && errors.address?.state}
                            />
                          )}
                        </Grid>

                        {/* POSTAL CODE */}
                        <Grid item xs={6} md={4}>
                          <TextField
                            required={isUnitedStates(values.address.country)}
                            autoComplete='nope'
                            label='Postal Code'
                            name='postalCode'
                            value={values.address.postalCode}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('address.postalCode', e.target.value)}
                            // @ts-ignore
                            error={Boolean((isEdit || touched.postalCode) && errors.address?.postalCode)}
                            // @ts-ignore
                            helperText={(isEdit || touched.postalCode) && errors.address?.postalCode}
                          />
                        </Grid>

                        {/* COUNTRY */}
                        <Grid item xs={6} md={4}>
                          <Autocomplete
                            id='countries-dropdown'
                            disableClearable
                            value={values.address.country}
                            options={countries.map(_ => _.text).concat(values.address.country)}
                            noOptionsText='No Countries'
                            onChange={(_event, country) => {
                              setFieldValue('address.country', country);
                            }}
                            renderInput={params => (
                              <TextField
                                fullWidth
                                required
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                // autoComplete new-password disables browser autofill
                                InputProps={{ ...params.InputProps, autoComplete: 'new-password', type: 'search' }}
                                label='Country'
                                name='country'
                                onBlur={handleBlur}
                                className={classes.formTextField}
                                // onChange={e => setFieldValue('address.country', e.target.value)}
                                // @ts-ignore
                                error={Boolean((isEdit || touched?.country) && errors.address?.country)}
                                // @ts-ignore
                                helperText={(isEdit || touched?.country) && errors.address?.country}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid container alignItems={'center'} className={classes.sectionContainer}>
                        {/* CLIENT DROPDOWN */}
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            id='searchable-dropdown'
                            options={clients}
                            value={clients?.find(client => client.clientId === values.clientId)}
                            getOptionLabel={option => option.name}
                            noOptionsText='No Clients'
                            onChange={(e, item: IClient) => {
                              setFieldValue('clientId', item?.clientId ?? null);
                              setFieldValue(
                                'clientName',
                                clients.find(client => client.clientId === item?.clientId)?.name ?? ''
                              );
                            }}
                            renderInput={params => <TextField label='Client' fullWidth {...params} />}
                          />
                        </Grid>

                        {/* SEND NOTIFICATIONS */}
                        <Grid item xs={12} sm={5} className={classes.checkbox}>
                          <FormControlLabel
                            label='Send Notifications'
                            control={
                              <Checkbox
                                checked={values.sendNotifications}
                                onClick={() => setFieldValue('sendNotifications', !values.sendNotifications)}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} md={6}>
                      {/* DISTRIBUTION LIST */}
                      <Grid item xs={12}>
                        <Paper variant='outlined'>
                          {/* HEADER */}
                          <Typography className={classes.distributionListHeader}>
                            Distribution List
                            <IconButton
                              color='primary'
                              onClick={() => {
                                const newDistributionList = [...values.distributionLists];
                                newDistributionList.push({
                                  contactGroupDistributionListId: newDistributionListId,
                                  email: '',
                                  contactType: IContactTypeValue.Contact,
                                });
                                setFieldValue('distributionLists', newDistributionList);
                                // Set a new value for next use
                                setNewDistributionListId(newDistributionListId - 1);
                                // Set the index so the new TextField can autofocus
                                setNewDistributionListIndex(newDistributionList.filter(l => !l.isDeleted).length);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Typography>

                          {errors && errors.distributionLists && values.distributionLists.length === 0 && (
                            <Typography className={classes.distributionField} color='error'>
                              {errors.distributionLists}
                            </Typography>
                          )}

                          {/* EMAIL LIST */}
                          <Grid item xs={12} className={classes.distributionListContainer}>
                            {values.distributionLists
                              ?.filter(obj => !obj.isDeleted)
                              .map((listItem, index) => (
                                <Grid container key={listItem.contactGroupDistributionListId} wrap='nowrap'>
                                  <Grid item xs={8}>
                                    <TextField
                                      autoFocus={newDistributionListIndex === index + 1}
                                      name={`email_${listItem.contactGroupDistributionListId}`}
                                      autoComplete='nope'
                                      placeholder={'E-Mail'}
                                      value={listItem.email}
                                      className={classes.distributionField}
                                      onBlur={handleBlur}
                                      onChange={({ target: { value } }) => {
                                        const deepCopy = JSON.parse(JSON.stringify(values.distributionLists));
                                        if (deepCopy.length) {
                                          const updateIndex = values.distributionLists.findIndex(
                                            obj =>
                                              obj.contactGroupDistributionListId ===
                                              listItem.contactGroupDistributionListId
                                          );
                                          deepCopy[updateIndex].email = value;
                                        }

                                        setFieldValue('distributionLists', deepCopy);
                                      }}
                                      error={
                                        (isEdit ||
                                          (touched &&
                                            // @ts-ignore
                                            touched[`email_${listItem.contactGroupDistributionListId}`])) &&
                                        errors &&
                                        errors.distributionLists &&
                                        // @ts-ignore
                                        errors.distributionLists[index]?.email
                                      }
                                      helperText={
                                        (isEdit ||
                                          (touched &&
                                            // @ts-ignore
                                            touched[`email_${listItem.contactGroupDistributionListId}`])) &&
                                        errors &&
                                        errors.distributionLists &&
                                        // @ts-ignore
                                        errors.distributionLists[index]?.email
                                      }
                                    />
                                  </Grid>
                                  {/* CONTACT TYPE DROPDOWN */}
                                  <Grid item xs={3}>
                                    <Select
                                      name='Contact Type'
                                      id='contact-type'
                                      showReset={false}
                                      value={listItem.contactType}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const deepCopy: IContactGroupDistributionList[] = JSON.parse(
                                          JSON.stringify(values.distributionLists)
                                        );
                                        // Convert string to number with unary `+`
                                        deepCopy[index].contactType = +e.target.value;
                                        setFieldValue('distributionLists', deepCopy);
                                      }}
                                      options={contactTypes.map(ct => ({
                                        key: ct.value,
                                        label: `${ct.text} Email`,
                                        value: ct.value,
                                      }))}
                                    />
                                  </Grid>
                                  <Grid item className={classes.deletePadding}>
                                    <IconButton
                                      size='small'
                                      className={classes.clearIcon}
                                      onClick={() => {
                                        const deepCopy: IContactGroupDistributionList[] = JSON.parse(
                                          JSON.stringify(values.distributionLists)
                                        );
                                        if (deepCopy[index].contactGroupDistributionListId > 0) {
                                          deepCopy[index].isDeleted = true;
                                        } else {
                                          deepCopy.splice(index, 1);
                                        }
                                        setFieldValue('distributionLists', deepCopy);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/** Provide a sufficient shape for client context for display of comment types (based on entity type). */}
                  <ClientProvider client={{ entityType: 'Units' } as IClientThin}>
                    {/** LIST OF COMMENTS */}
                    <Comments getComments={getContactGroupTypeComments} />
                  </ClientProvider>
                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              onClose();
                            } else {
                              return;
                            }
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid}
                        type='submit'
                        startIcon={contactGroupBeingEdited ? <Check /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {contactGroupBeingEdited ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  sectionContainer: {
    marginBottom: theme.spacing(1),
  },
  distributionField: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  deletePadding: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  distributionListHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  distributionListContainer: {
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
  },
  clearIcon: {
    color: theme.palette.grey[400],
    cursor: 'pointer',
  },
}));
