import React, { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import isFunction from 'lodash/isFunction';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
// Components`
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
  Table as MaUTable,
} from '@material-ui/core';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, Column, Row } from 'react-table';
import Skeleton from '@material-ui/lab/Skeleton';
import { Pagination } from './Pagination';
import { dateFormatter, formatPercent } from '../../helpers';

export interface ITableColumn extends Column {
  id?: string;
  Header: string;
  disableSortBy?: boolean;
  accessor?: (() => string | number) | ((item: unknown) => string | number) | string;
  canFilter?: boolean;
  className?: string;
  sort?: boolean;
  isServerSorted?: boolean;
  isServerSortedDesc?: boolean;
  handleClickColumn?: (columnId: any) => void;
  hideLoad?: boolean;
  overrideWidth?: number;
  filterType?: 'input' | 'autocomplete';
  isDate?: boolean;
  isNumber?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  isCentered?: boolean;
  columnAlignment?: string;
}

interface ITableProps {
  data: any[];
  columns: ITableColumn[];
  noResultsText?: string;
  isLoading?: boolean;
  rowOnClick?: (val: any, index?: number) => void;
  hideDeleted?: boolean;
  headerClasses?: string;
  cellClasses?: any;
  rowClasses?: any;
  /** These fields are passed to the provided `ITableColumn.Cell`. */
  useTableProps?: { [key: string]: any };
  hidePagination?: boolean;
  stickyHeader?: boolean;
  ResponsiveComponent?: FC<any>;
  ResponsiveComponentLoader?: FC<any>;
  containerClasses?: string;
  tableSize?: 'medium' | 'small' | undefined;
  onRowsPerPageChange?: (rows: number) => void;
  LoadMoreComponent?: FC<any>;
  mobileProps?: any;
  loadingPageSize?: number;
  centerPagination?: boolean;
  resetPageKey?: number;
  activeRowIndex?: number;
  rowClassActive?: any;
}

export const Table: FC<ITableProps> = ({
  columns,
  data,
  isLoading,
  noResultsText = 'No Results',
  hideDeleted,
  headerClasses,
  cellClasses = '',
  rowClasses,
  useTableProps = {},
  hidePagination,
  stickyHeader = false,
  rowOnClick,
  ResponsiveComponent,
  containerClasses = '',
  ResponsiveComponentLoader,
  tableSize = 'small',
  onRowsPerPageChange,
  LoadMoreComponent,
  mobileProps,
  loadingPageSize,
  centerPagination,
  resetPageKey,
  activeRowIndex,
  rowClassActive,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      userPageCount: 1, // ignored if manualPagination is false
      manualPagination: hidePagination,
      autoResetPage: isLoading, // reset page when loading
      autoResetSortBy: isLoading, // reset sort when loading
      ...useTableProps,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const handleChangePage = (page: number) => {
    gotoPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(value);
    }
  };

  // update page size if we hide pagination
  useEffect(() => {
    if (hidePagination && Array.isArray(data)) {
      setPageSize(Number(data.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // always start on first page if data length changes, ie; filter was applied
  useEffect(() => {
    handleChangePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, resetPageKey]);

  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const getCellStyle = (column: ITableColumn, type: 'header' | 'cell') => {
    let style: any = { cursor: 'inherit' };

    if (column.isDate) {
      style = {
        ...style,
      };
    } else if (column.isNumber) {
      style = {
        ...style,
        textAlign: 'right',
        paddingRight: '3rem',
      };
    } else if (column.isCurrency) {
      style = {
        ...style,
      };
    } else if (column.isCentered) {
      style = {
        ...style,
        textAlign: 'center',
      };
    }

    if (column.overrideWidth) {
      style = {
        ...style,
        width: column.overrideWidth,
        maxWidth: column.overrideWidth,
        wordWrap: 'break-word', // IE11
        overflowWrap: 'break-word',
      };
    }

    if (type === 'header') {
      style = {
        ...style,
        fontWeight: 'bold',
        cursor: column.handleClickColumn ? 'pointer' : 'inherit',
        color: '#11a5c5',
      };
    }

    return style;
  };

  const formatCell = (cell: any): React.ReactNode => {
    if (cell.value === null || cell.value === '') return;
    if (cell.column.isDate) return dateFormatter(cell.value);
    if (cell.column.isCurrency)
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
        +cell.value
      );
    if (cell.column.isPercent) return formatPercent(cell.value);
    return cell.render('Cell');
  };

  return (
    <>
      <TableContainer className={clsx(containerClasses, stickyHeader ? classes.stickyHeader : '')}>
        <MaUTable
          stickyHeader={stickyHeader}
          size={tableSize}
          {...getTableProps()}
          style={ResponsiveComponent && !isLoading ? { display: 'block' } : undefined}
        >
          {isDesktop || !ResponsiveComponent ? (
            <>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell
                        align={column?.columnAlignment || 'left'}
                        {...((column as ITableColumn).sort !== false
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : column.getHeaderProps())}
                        title=''
                        className={headerClasses}
                        onClick={e => {
                          const { handleClickColumn, sort } = column as ITableColumn;
                          const sortProps: any = column.getHeaderProps(column.getSortByToggleProps());

                          // If the column is sortable and there's an onClick handler, then call it
                          const { onClick } = sortProps || {};
                          sort !== false && onClick && onClick(e);

                          // Also run column click handler if passed
                          handleClickColumn && handleClickColumn(column.id);
                        }}
                      >
                        {(column as ITableColumn).sort !== false || (column as ITableColumn).isServerSorted ? (
                          <TableSortLabel
                            active={column.isSorted || (column as ITableColumn).isServerSorted}
                            direction={
                              column.isSortedDesc || (column as ITableColumn).isServerSortedDesc ? 'desc' : 'asc'
                            }
                          >
                            {column.render('Header')}
                          </TableSortLabel>
                        ) : (
                          column.render('Header')
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody className={classes.alternating}>
                {isLoading
                  ? new Array(loadingPageSize || pageSize || 3).fill('').map((x, j) => (
                      <TableRow key={`table-row-skeleton-${j}`} {...(rowOnClick ? { onClick: rowOnClick } : {})}>
                        {columns.map((column, i) => (
                          <TableCell
                            key={`skeleton-cell-${i}`}
                            style={getCellStyle(column as ITableColumn, 'cell')}
                            className={isFunction(cellClasses) ? cellClasses({}) : cellClasses}
                          >
                            {column.hideLoad ? null : <Skeleton />}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  : page.map((row: Row, index) => {
                      prepareRow(row);
                      return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                        <TableRow
                          {...row.getRowProps()}
                          className={clsx(
                            isFunction(rowClasses) ? rowClasses(row.original) : rowClasses,
                            index === activeRowIndex ? rowClassActive : null
                          )}
                          {...(rowOnClick ? { onClick: () => rowOnClick(row.original, index) } : {})}
                        >
                          {row.cells.map(cell => {
                            return (
                              <TableCell
                                align={cell?.column?.columnAlignment || 'left'}
                                {...cell.getCellProps()}
                                className={clsx(
                                  (cell.column as ITableColumn).className ?? '',
                                  isFunction(cellClasses) ? cellClasses(row.original) : cellClasses,
                                  cell.column.className || '',
                                  classes.row
                                )}
                                style={getCellStyle(cell.column as ITableColumn, 'cell')}
                              >
                                {formatCell(cell)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ) : null;
                    })}
              </TableBody>
            </>
          ) : (
            <TableBody className={isLoading ? undefined : classes.mobileTable}>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    className={clsx(classes.mobileCell, isFunction(cellClasses) ? cellClasses({}) : cellClasses)}
                  >
                    {ResponsiveComponentLoader ? <ResponsiveComponentLoader /> : <Skeleton />}
                  </TableCell>
                </TableRow>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                    <TableRow
                      {...row.getRowProps()}
                      className={isFunction(rowClasses) ? rowClasses(row.original) : rowClasses}
                      {...(rowOnClick ? { onClick: () => rowOnClick(row.original) } : {})}
                      style={ResponsiveComponent ? { display: 'block' } : undefined}
                    >
                      <TableCell
                        key={`row-${i}-mobile-cell`}
                        className={clsx(
                          classes.mobileCell,
                          isFunction(cellClasses) ? cellClasses(row.original) : cellClasses
                        )}
                        style={ResponsiveComponent ? { display: 'block' } : undefined}
                      >
                        <ResponsiveComponent
                          key={`responsive-row-${i}`}
                          {...row}
                          {...(useTableProps ? useTableProps : {})}
                          {...mobileProps}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null;
                })
              )}
            </TableBody>
          )}
        </MaUTable>

        {!isLoading && Array.isArray(data) && data.length === 0 && (
          <Grid container justify='center'>
            <Typography className={classes.noResults} variant='body1' gutterBottom>
              {noResultsText}
            </Typography>
          </Grid>
        )}

        {LoadMoreComponent && <LoadMoreComponent />}
      </TableContainer>

      {!isLoading && Array.isArray(data) && data.length > 0 && !hidePagination && (
        <div className={centerPagination ? classes.centerPagination : ''}>
          <Pagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
            count={isLoading ? 0 : data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  alternating: {
    '& > :nth-child(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
  row: {
    border: 'none',
  },
  noResults: {
    marginTop: '1rem',
  },
  stickyHeader: {
    flexGrow: 1,
    maxHeight: '100%',
  },
  borderNone: {
    border: 'none',
  },
  mobileTable: {
    display: 'block',
  },
  mobileCell: {
    padding: 0,
    border: 0,
  },
  centerPagination: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
