import { AxiosRequestConfig } from 'axios';
import { axios } from '../helpers';

// keep axios syntax for simplicity
export const authFetch = {
  getBlob: async (url: string) => {
    return await axios.get(url, {
      responseType: 'blob'
    });
  },
  get: async (url: string, opts: AxiosRequestConfig = {}) => {
    return await axios.get(url, opts);
  },
  put: async (url: string, data: any, options?: any) => {
    const routeOptions = { ...options };
    return await axios.put(url, data, routeOptions);
  },
  post: async (url: string, data: any, options?: any) => {
    return await axios.post(url, data, options);
  },
  delete: async (url: string, data?) => {
    return await axios.delete(url, data, {
    });
  }
};
