import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import ReactQuill from 'react-quill';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useMedia } from 'react-use';
import * as Yup from 'yup';
import qs from 'qs';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// components
import { UserContext } from '../../context';
import { Loader, PageTitle, Select, Page, UnsavedChanges } from '../../components';
import { ManageLocations } from './LocationsTable';
import { MonitoredEntities } from './MonitoredEntitiesTable';
import { AdditionalInsured } from './AdditionalInsuredTable';
import { DefaultRequirements } from './DefaultRequirementsTable';
import { Portfolios } from './PortfoliosTable';
import { UnitTypes } from './UnitTypesTable';
// constants
import { routes, screenSizes, ClientTypeItems, ClientType, userRoles } from '../../constants';
// types
import {
  IClient,
  ILineOfCoverage,
  ICoverageType,
  ILocation,
  IPortfolio,
  IDefaultRequirement,
  IAdditionalInsured,
  IMonitoredEntityType,
  IClientThin,
} from '../../types';
// fetch
import { updateClient, getLinesOfCoverage, getCoverageTypes, getThinClient } from '../../fetch';
// icons
import { Save, ArrowBackIos, FileCopyOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { ClientCertificateHolder } from './ClientCertificateHolder';
import { IClientCertificateHolder } from '../../types/clientCertificateHolders';
import { getClientCertificateHolders } from '../../fetch/clientCertificateHolders';
import { formatError } from '../../helpers/errors';
import { useToastContext } from '../../hooks';

const MAX_CHARACTERS = 240;
const tabProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const ClientSchema = Yup.object().shape({
  code: Yup.string().required('Client Code is required.'),
  name: Yup.string().required('Client Name is required.'),
  clientType: Yup.number().required('Client Type is required.'),
  useLocations: Yup.boolean(),
  requireUnitCodes: Yup.boolean(),
  showNotificationBanner: Yup.boolean(),
  lettersPerYear: Yup.number().nullable(),
  aboutToExpireNotice: Yup.number().nullable(),
  onDemandEmailBody: Yup.string(),
  jobLabel: Yup.string(),
  showSupplementalCode: Yup.boolean(),
  letterContent: Yup.string(),
  notificationBannerContent: Yup.string(),
  letterEmailSubject: Yup.string(),
  defaultLetterEmailSubject: Yup.string(),
  useDefaultLetterEmailSubject: Yup.boolean(),
  defaultLetterContent: Yup.string(),
  certificateContactEmail: Yup.string().nullable(true).email('Please provide a valid e-mail address.'),
  certificateContactName: Yup.string().nullable(true),
  contactLetterSubject: Yup.string(),
  contactLetterContent: Yup.string(),
});

export const ClientForm = () => {
  // @ts-ignore
  let { clientId } = useParams();
  const { userContext } = useContext(UserContext);

  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>('locations');
  const [clientData, setClientData] = useState<IClientThin>({
    aboutToExpireNotice: 0,
    additionalInsureds: [],
    certificateContactEmail: null,
    certificateContactName: null,
    clientId: 0,
    clientType: 0,
    code: '',
    defaultLetterContent: '',
    defaultLetterEmailSubject: '',
    defaultOnDemandEmailContent: '',
    defaultOnDemandEmailSubject: '',
    defaultRequirements: [],
    entities: [],
    entityType: 'Units',
    isDeleted: false,
    jobLabel: '',
    letterContent: '',
    notificationBannerContent: '',
    letterEmailSubject: '',
    lettersPerYear: 0,
    locations: [],
    monitoredEntityTypes: [],
    name: '',
    onDemandAppendedComments: '',
    onDemandEmailContent: '',
    onDemandEmailSubject: '',
    onDemandOpeningComments: '',
    clientCertificateHolders: [],
    portfolios: [],
    requireUnitCodes: false,
    showNotificationBanner: false,
    showSupplementalCode: false,
    useClientStoreNumber: false,
    useDefaultLetter: false,
    useDefaultLetterEmailSubject: false,
    useDefaultOnDemandEmailContent: false,
    useDefaultOnDemandEmailSubject: false,
    useLocations: false,
    includeInComplianceJob: false,
    contactLetterSubject: '',
    contactLetterContent: '',
  });
  const formRef = useRef();

  const location = useLocation();
  const { entityId } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [linesOfCoverage, setLinesOfCoverage] = useState<ILineOfCoverage[]>([]);
  const [coverageTypes, setCoverageTypes] = useState<ICoverageType[]>([]);

  const [additionalInsureds, setAdditionalInsureds] = useState<IAdditionalInsured[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [portfolios, setPortfolios] = useState<IPortfolio[]>([]);
  const [clientCertificateHolders, setClientCertificateHolders] = useState<IClientCertificateHolder[]>([]);
  const [defaultRequirements, setDefaultRequirements] = useState<IDefaultRequirement[]>([]);
  const [monitoredEntityTypes, setMonitoredEntityTypes] = useState<IMonitoredEntityType[]>([]);
  const { showToast } = useToastContext();

  const fetchClient = async () => {
    setLoading(true);

    try {
      const [linesOfCoverageResponse, coverageTypesResponse] = await Promise.all([
        getLinesOfCoverage(),
        getCoverageTypes(),
      ]);
      setLinesOfCoverage(linesOfCoverageResponse);
      setCoverageTypes(coverageTypesResponse);

      if (typeof clientId === 'undefined') {
        setAddMode(true);
      } else {
        const response = await getThinClient(clientId);
        const clientCertificateHolderResponse = await getClientCertificateHolders(clientId);

        setClientData(response);
        setAdditionalInsureds(response.additionalInsureds);
        setLocations(response.locations);
        setPortfolios(response.portfolios);
        setDefaultRequirements(response.defaultRequirements);
        setClientCertificateHolders(clientCertificateHolderResponse);

        if (response.monitoredEntityTypes) {
          const filteredMonitoredEntityTypes = response.monitoredEntityTypes.filter(
            monitoredEntityType => !monitoredEntityType.isDeleted
          );
          setMonitoredEntityTypes(filteredMonitoredEntityTypes);
        }
        if (entityId) {
          setSelectedTab('monitoredEntities');
        } else {
          setSelectedTab(response.useLocations ? 'locations' : 'monitoredEntities');
        }
      }
    } catch (error) {
      formatError(error, showToast, 'Failed to fetch client data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchClientCertificationHolders = async () => {
    try {
      setLoading(true);
      const clientCertificateHolderResponse = await getClientCertificateHolders(clientId);
      setClientCertificateHolders(clientCertificateHolderResponse);
    } catch (error) {
      formatError(error, showToast, 'Failed to fetch client certificate holders.');
    } finally {
      setLoading(false);
    }
  };

  function findOffendingValues(text: string): string[] {
    if (!text) return [];
    const regex = /{{[^{}]*[<>][^{}]*}}/g;
    const matches = text.match(regex) || [];
    return matches;
  }

  function fixOffendingValues(values) {
    const fixedValues = values.map(value => {
      return value.replace(/<\/?(?:em|strong|u)>/g, '');
    });

    return fixedValues;
  }

  function generateErrorToast(values, section) {
    const errorMessage = `${section} - Please apply Bolding, Italics, or Underlines to the entire dynamic field including the brackets. Issues Found:`;
    const fixedValues = fixOffendingValues(values);
    const numberedList = fixedValues.map((value, index) => `${index + 1}. ${value}`).join('\n');
    const fullMessage = `${errorMessage}\n${numberedList}`;
    return showToast('error', fullMessage);
  }

  useEffect(() => {
    if (selectedTab === 'clientCertificateHolder') fetchClientCertificationHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const formikResetObjectFormat = response => ({
    aboutToExpireNotice: response.aboutToExpireNotice,
    certificateContactEmail: response.certificateContactEmail,
    certificateContactName: response.certificateContactName,
    clientId: response.clientId,
    clientType: response.clientType,
    code: response.code,
    defaultLetterContent: response.defaultLetterContent,
    isDeleted: response.isDeleted,
    jobLabel: response.jobLabel,
    letterContent: response.letterContent,
    lettersPerYear: response.lettersPerYear,
    monitoredEntityTypes: response.monitoredEntityTypes,
    name: response.name,
    requireUnitCodes: response.requireUnitCodes,
    showSupplementalCode: response.showSupplementalCode,
    showNotificationBanner: response.showNotificationBanner,
    useClientStoreNumber: response.useClientStoreNumber,
    useDefaultLetter: response.useDefaultLetter,
    useLocations: response.useLocations,
    onDemandEmailSubject: response.onDemandEmailSubject,
    onDemandOpeningComments: response.onDemandOpeningComments,
    includeInComplianceJob: response.includeInComplianceJob,
  });

  const getInitialLetterContent = () => {
    if (!clientData) {
      return '';
    }

    if (clientData.useDefaultLetter && clientData.defaultLetterContent && !clientData.letterContent) {
      return clientData.defaultLetterContent;
    } else {
      return clientData.letterContent;
    }
  };

  const copyToClipboard = useCallback(
    async (content: string) => {
      try {
        await navigator.clipboard.writeText(content);
      } catch (error) {
        formatError(error, showToast, `Failed to copy ${content} to clipboard.`);
      }
    },
    [showToast]
  );

  return (
    <Page customPageHeader title={addMode ? 'Add Client' : 'Edit Client'}>
      <Formik<IClient>
        innerRef={formRef}
        enableReinitialize={true}
        // TODO: Fix IClient interface on this form
        // @ts-ignore
        initialValues={{
          aboutToExpireNotice: clientData && clientData.aboutToExpireNotice ? clientData.aboutToExpireNotice : null,
          certificateContactEmail: clientData && clientData.certificateContactEmail,
          certificateContactName: clientData && clientData.certificateContactName,
          clientId: clientData && clientData.clientId ? clientData.clientId : -1,
          clientType: clientData && clientData.clientType ? clientData.clientType : ClientType.None,
          code: clientData && clientData.code ? clientData.code : '',
          defaultLetterContent: clientData && clientData.defaultLetterContent ? clientData.defaultLetterContent : '',
          defaultLetterEmailSubject: clientData?.defaultLetterEmailSubject ?? '',
          defaultOnDemandEmailContent: clientData?.defaultOnDemandEmailContent ?? '',
          defaultOnDemandEmailSubject: clientData?.defaultOnDemandEmailSubject ?? '',
          isDeleted: clientData && clientData.isDeleted ? clientData.isDeleted : false,
          jobLabel: clientData && clientData.jobLabel ? clientData.jobLabel : '',
          letterContent: getInitialLetterContent(),
          letterEmailSubject:
            clientData.useDefaultLetterEmailSubject && clientData.defaultLetterEmailSubject
              ? clientData.defaultLetterEmailSubject
              : clientData.letterEmailSubject,
          lettersPerYear: clientData && clientData.lettersPerYear ? clientData.lettersPerYear : null,
          monitoredEntityTypes: clientData && clientData.monitoredEntityTypes ? clientData.monitoredEntityTypes : [],
          name: clientData && clientData.name ? clientData.name : '',
          onDemandAppendedComments: clientData?.onDemandAppendedComments ?? '',
          onDemandEmailSubject:
            clientData.useDefaultOnDemandEmailSubject && clientData.defaultOnDemandEmailSubject
              ? clientData.defaultOnDemandEmailSubject
              : clientData.onDemandEmailSubject,
          onDemandEmailContent:
            clientData.useDefaultOnDemandEmailContent && clientData.defaultOnDemandEmailContent
              ? clientData.defaultOnDemandEmailContent
              : clientData.onDemandEmailContent,

          requireUnitCodes: clientData && clientData.requireUnitCodes ? clientData.requireUnitCodes : false,
          showSupplementalCode: clientData && clientData.showSupplementalCode ? clientData.showSupplementalCode : false,
          notificationBannerContent: clientData?.notificationBannerContent ?? '',
          showNotificationBanner:
            clientData && clientData.showNotificationBanner ? clientData.showNotificationBanner : false,
          useClientStoreNumber: clientData && clientData.useClientStoreNumber ? clientData.useClientStoreNumber : false,
          useDefaultLetter: clientData && clientData.useDefaultLetter ? clientData.useDefaultLetter : false,
          useDefaultLetterEmailSubject: clientData?.useDefaultLetterEmailSubject ?? false,
          useLocations: clientData && clientData.useLocations ? clientData.useLocations : false,
          includeInComplianceJob:
            clientData && clientData.includeInComplianceJob ? clientData.includeInComplianceJob : false,
          contactLetterSubject: clientData.contactLetterSubject ?? '',
          contactLetterContent: clientData.contactLetterContent ?? '',
        }}
        validationSchema={ClientSchema}
        onSubmit={async (values, actions) => {
          const client = {
            aboutToExpireNotice: values.aboutToExpireNotice,
            certificateContactEmail: values.certificateContactEmail,
            certificateContactName: values.certificateContactName,
            clientId: values.clientId,
            clientType: values.clientType,
            code: values.code,
            defaultLetterEmailSubject: values.defaultLetterEmailSubject,
            defaultOnDemandEmailContent: values.defaultOnDemandEmailContent,
            defaultOnDemandEmailSubject: values.defaultOnDemandEmailSubject,
            isDeleted: values.isDeleted,
            jobLabel: values.jobLabel,
            letterEmailSubject:
              values.letterEmailSubject !== values.defaultLetterEmailSubject
                ? values.letterEmailSubject
                : values.defaultLetterEmailSubject,
            lettersPerYear: values.lettersPerYear,
            name: values.name,
            onDemandAppendedComments: values.onDemandAppendedComments,
            onDemandEmailSubject: values.onDemandEmailSubject,
            onDemandEmailContent: values.onDemandEmailContent,
            requireUnitCodes: values.requireUnitCodes,
            showSupplementalCode: values.showSupplementalCode,
            showNotificationBanner: values.showNotificationBanner,
            notificationBannerContent: values.notificationBannerContent,
            useClientStoreNumber: values.useClientStoreNumber,
            useDefaultLetterEmailSubject: values.useDefaultLetterEmailSubject,
            useLocations: values.useLocations,
            includeInComplianceJob: values.includeInComplianceJob,
            contactLetterSubject: values.contactLetterSubject,
            contactLetterContent: values.contactLetterContent,
          } as IClient;

          if (values.letterContent !== values.defaultLetterContent) {
            client.letterContent = values.letterContent;
          }

          const offendingValuesLetterContent = findOffendingValues(client.letterContent);
          const offendingValuesEmail = findOffendingValues(client.onDemandEmailContent);

          if (offendingValuesEmail.length > 0) {
            return generateErrorToast(offendingValuesEmail, 'Email Body');
          }

          if (offendingValuesLetterContent.length > 0) {
            return generateErrorToast(offendingValuesLetterContent, 'Letter Content');
          }

          let confirmationResult = true;
          if (clientData?.includeInComplianceJob !== client.includeInComplianceJob) {
            confirmationResult = window.confirm(
              'Warning, enabling/disabling the Compliance job may result in incorrect data and compliance calculations. Please confirm you would like to proceed'
            );
          }

          if (confirmationResult) {
            try {
              const response = await updateClient(client);
              // @ts-ignore Should we update the IClientPost interface (w/ the new fields) to make this work?
              setClientData(response);
              showToast('success', 'Client Updated!');
              // @ts-ignore Why does `formikResetObjectFormat` take the fields that it does?
              actions.resetForm(formikResetObjectFormat(response));

              if (addMode) {
                history.replace({ pathname: `/manage-clients/${response.clientId}` });
                setAddMode(false);
              }
            } catch (error) {
              console.log(error);
              if (
                error &&
                error.Errors &&
                Object.values(error.Errors)[0] &&
                Object.values(Object.values(error.Errors)[0])[0]
              ) {
                formatError(error, showToast, Object.values(Object.values(error.Errors)[0])[0] as string);
              }
            }
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <div>
              <UnsavedChanges dirty={dirty && !isSubmitting} />
              <div className={classes.headerContainer}>
                {/* BACK BUTTON */}
                <IconButton
                  className={classes.backButton}
                  color='primary'
                  onClick={() => {
                    history.push(routes.manageClients);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                {/* HEADER */}
                <PageTitle title={addMode ? 'Add Client' : 'Edit Client'} marginOff />

                {/* SAVE */}
                <Fab
                  className={classes.saveButton}
                  disabled={!dirty || isSubmitting || !isValid}
                  // @ts-ignore
                  onClick={() => formRef.current.handleSubmit()}
                >
                  <Save />
                </Fab>
              </div>

              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' title={'Saving...'} />}
              <Form onSubmit={handleSubmit} autoComplete='none'>
                {/* SKELETON LOADER */}
                {isLoading && (
                  <div className={isMobile ? classes.mobileSkeletonLoaderContainer : classes.skeletonLoaderContainer}>
                    <Skeleton className={`${classes.formTextField} ${classes.codeField}`} />
                    <Skeleton className={`${classes.formTextField} ${classes.nameField}`} />
                    <Skeleton className={classes.formTextField} width='64px' />
                  </div>
                )}

                {/* CODE */}
                {!isLoading && (
                  <TextField
                    required
                    autoComplete='nope'
                    label='Code'
                    name='code'
                    value={values.code}
                    className={`${classes.formTextField} ${classes.codeField}`}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('code', e.target.value)}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                )}

                {/* NAME */}
                {!isLoading && (
                  <TextField
                    required
                    fullWidth
                    autoComplete='nope'
                    label='Name'
                    name='name'
                    value={values.name}
                    className={`${classes.formTextField} ${classes.nameField}`}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('name', e.target.value)}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                )}

                {/* CLIENT TYPE */}
                {!isLoading && (
                  <Select
                    disabled={!addMode}
                    name='clientType'
                    required
                    id='client-type-select'
                    label='Type *'
                    value={values.clientType}
                    onChange={e => setFieldValue('clientType', e.target.value)}
                    showReset={false}
                    className={`${classes.formTextField} ${classes.select}`}
                    options={ClientTypeItems}
                  />
                )}

                {/* USE LOCATIONS */}
                {!isLoading && (
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!addMode}
                        checked={values.useLocations}
                        onChange={() => {
                          // Clear out states when toggling since a lot of logic
                          // is tied to this switch
                          setFieldValue('locations', []);
                          setFieldValue('entities', []);

                          // If they have the locations tab selected and toggle use locations is going to be set to off,
                          // update the selected tab since contractors don't have access to the locations tab
                          if (values.useLocations && selectedTab === 'locations') {
                            setSelectedTab('monitoredEntities');
                          }

                          setFieldValue('useLocations', !values.useLocations);
                        }}
                      />
                    }
                    label='Use Locations'
                  />
                )}
                {!isLoading && userContext.role === userRoles.ADMIN && !addMode && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.includeInComplianceJob}
                        onChange={() => {
                          setFieldValue('includeInComplianceJob', !values.includeInComplianceJob);
                        }}
                      />
                    }
                    label='Include in Compliance Job'
                  />
                )}

                {/* TAB SELECTOR */}
                {values.clientId !== -1 ? (
                  <>
                    <Tabs
                      value={selectedTab}
                      className={classes.tabContainer}
                      onChange={handleTabChange}
                      indicatorColor='secondary'
                      textColor='primary'
                      variant='scrollable'
                      scrollButtons='auto'
                      aria-label='scrollable auto tabs example'
                    >
                      {values.useLocations && <Tab label='LOCATIONS' {...tabProps(0)} value={'locations'} />}
                      <Tab label='CLIENT CONFIGURATIONS' {...tabProps(1)} value={'clientConfigurations'} />
                      <Tab
                        label={values.clientType === ClientType.Retail ? 'UNIT TYPES' : 'CONTRACTOR TYPES'}
                        {...tabProps(2)}
                        value={'unitTypes'}
                      />
                      <Tab label='DEFAULT REQUIREMENTS' {...tabProps(3)} value={'defaultRequirements'} />
                      <Tab label='ADDITIONAL INSURED' {...tabProps(4)} value={'additionalInsureds'} />
                      <Tab
                        label={values.clientType === ClientType.Retail ? 'UNITS' : 'CONTRACTORS'}
                        {...tabProps(5)}
                        value={'monitoredEntities'}
                      />
                      <Tab label='PORTFOLIOS' {...tabProps(5)} value={'portfolios'} />
                      <Tab label='CERTIFICATE HOLDERS' {...tabProps(6)} value={'clientCertificateHolder'} />
                    </Tabs>

                    {/* LOCATIONS GRID */}
                    {
                      // eslint-disable-next-line
                      selectedTab === 'locations' && values.useLocations && clientId && (
                        <ManageLocations
                          isLoading={isLoading}
                          locations={locations}
                          updateLocations={updated => setLocations(updated)}
                          clientId={clientId}
                        />
                      )
                    }

                    {/* DEFAULT REQUIREMENTS */}
                    {selectedTab === 'defaultRequirements' && (
                      <DefaultRequirements
                        isLoading={isLoading}
                        entities={additionalInsureds}
                        defaultRequirements={defaultRequirements}
                        linesOfCoverage={linesOfCoverage}
                        coverageTypes={coverageTypes}
                        clientType={values.clientType}
                        clientId={clientId}
                        updateDefaultRequirements={updated => setDefaultRequirements(updated)}
                      />
                    )}

                    {/* MONITORED ENTITIES */}
                    {selectedTab === 'monitoredEntities' && (
                      <MonitoredEntities
                        clientType={values.clientType}
                        useLocations={values.useLocations}
                        showSupplementalCode={values.showSupplementalCode}
                        locations={locations}
                        jobLabel={values.jobLabel}
                        defaultOpenEntityId={entityId}
                        clientId={Number(clientId)}
                        monitoredEntityTypes={monitoredEntityTypes}
                        requireUnitCodes={values.requireUnitCodes}
                      />
                    )}

                    {/* UNIT TYPES */}
                    {selectedTab === 'unitTypes' && (
                      <UnitTypes
                        monitoredEntityTypes={monitoredEntityTypes}
                        clientType={values.clientType}
                        isLoading={isLoading}
                        useLocations={values.useLocations}
                        clientId={Number(clientId)}
                        updateMonitoredEntityTypes={updated => setMonitoredEntityTypes(updated)}
                        locations={locations}
                      />
                    )}

                    {/* ADDITIONAL INSURED ENTITIES */}
                    {selectedTab === 'additionalInsureds' && (
                      <AdditionalInsured
                        isLoading={isLoading}
                        additionalInsureds={additionalInsureds}
                        clientType={values.clientType}
                        useLocations={values.useLocations}
                        locations={locations}
                        linesOfCoverage={linesOfCoverage}
                        filteredLinesOfCoverage={linesOfCoverage.filter(loc =>
                          defaultRequirements.some(dr => dr.lineOfCoverageId === loc.lineOfCoverageId)
                        )}
                        clientId={clientId}
                        updateAdditionalInsureds={updated => setAdditionalInsureds(updated)}
                        entityType={clientData?.entityType}
                      />
                    )}

                    {/* PORTFOLIOS */}
                    {selectedTab === 'portfolios' && (
                      <Portfolios
                        isLoading={isLoading}
                        portfolios={portfolios}
                        entityType={clientData.entityType}
                        useLocations={values.useLocations}
                        locations={locations}
                        clientId={Number(clientId)}
                        updatePortfolios={updated => setPortfolios(updated)}
                      />
                    )}
                    {/* CLIENT CERTIFICATE HOLDER */}
                    {selectedTab === 'clientCertificateHolder' && (
                      <ClientCertificateHolder
                        isLoading={isLoading}
                        clientCertificateHolders={clientCertificateHolders}
                        entityType={clientData.entityType}
                        useLocations={values.useLocations}
                        locations={locations}
                        clientId={Number(clientId)}
                        updateCertificateHolders={updated => setClientCertificateHolders(updated)}
                      />
                    )}

                    {/* CLIENT CONFIGURATIONS */}
                    {selectedTab === 'clientConfigurations' && (
                      <>
                        {!isLoading ? (
                          <Grid>
                            <div className={classes.header}>
                              <PageTitle title='Client Configurations' marginOff />
                            </div>

                            <FormGroup row>
                              {/* LETTERS PER YEAR */}
                              <TextField
                                autoComplete='nope'
                                label='Letters Per Year'
                                name='lettersPerYear'
                                value={values.lettersPerYear}
                                className={`${classes.formTextField} ${classes.numberTypeTextField}`}
                                onBlur={handleBlur}
                                type='number'
                                onChange={e => setFieldValue('lettersPerYear', e.target.value)}
                                error={Boolean(touched.lettersPerYear && errors.lettersPerYear)}
                                helperText={touched.lettersPerYear && errors.lettersPerYear}
                              />

                              {/* ABOUT TO EXPIRE DAYS */}
                              <TextField
                                autoComplete='nope'
                                label='About to Expire Days'
                                name='aboutToExpireNotice'
                                value={values.aboutToExpireNotice}
                                className={`${classes.formTextField} ${classes.numberTypeTextField}`}
                                onBlur={handleBlur}
                                type='number'
                                onChange={e => setFieldValue('aboutToExpireNotice', e.target.value)}
                                error={Boolean(touched.aboutToExpireNotice && errors.aboutToExpireNotice)}
                                helperText={touched.aboutToExpireNotice && errors.aboutToExpireNotice}
                                style={{
                                  width: '11rem',
                                }}
                              />

                              {/* JOB LABEL */}
                              {!isLoading && !values.useLocations && (
                                <TextField
                                  autoComplete='nope'
                                  label='Job Label'
                                  name='jobLabel'
                                  value={values.jobLabel}
                                  className={classes.formTextField}
                                  onBlur={handleBlur}
                                  onChange={e => setFieldValue('jobLabel', e.target.value)}
                                  error={Boolean(touched.jobLabel && errors.jobLabel)}
                                  helperText={touched.jobLabel && errors.jobLabel}
                                />
                              )}

                              {/* REQUIRE UNIT CODES */}
                              <FormControlLabel
                                className={classes.toggle}
                                control={
                                  <Switch
                                    checked={values.requireUnitCodes}
                                    onChange={() => setFieldValue('requireUnitCodes', !values.requireUnitCodes)}
                                  />
                                }
                                label='Require Reference Number'
                              />

                              {/* SUPPLEMENTAL CODE */}
                              <FormControlLabel
                                className={classes.toggle}
                                control={
                                  <Switch
                                    checked={values.showSupplementalCode}
                                    onChange={() => setFieldValue('showSupplementalCode', !values.showSupplementalCode)}
                                  />
                                }
                                label='Show Supplemental Code'
                              />

                              {/* SHOW NOTIFICATION BANNER */}
                              <FormControlLabel
                                className={classes.toggle}
                                control={
                                  <Switch
                                    checked={values.showNotificationBanner}
                                    onChange={() =>
                                      setFieldValue('showNotificationBanner', !values.showNotificationBanner)
                                    }
                                  />
                                }
                                label='Show Notification Banner'
                              />
                            </FormGroup>

                            {/* LETTER SETTINGS */}
                            <div className={clsx(classes.header, classes.marginTop)}>
                              <PageTitle title='Letter Settings' marginOff />
                            </div>
                            <Typography variant='subtitle2' className={classes.subtitle}>
                              If you are utilizing Bolding, Italics, or Underlines on a dynamic field such as{' '}
                              {'{{ContactGroupName}}'}, apply the formatting to the brackets as well as the text.
                              Example: <strong>{'{{ContactGroupName}}'}</strong>
                            </Typography>

                            <FormGroup row>
                              <Grid container spacing={1}>
                                <Grid item lg={3}>
                                  {/* CERTIFICATE CONTACT NAME */}
                                  <TextField
                                    fullWidth
                                    label='Certificate Contact Name'
                                    variant='outlined'
                                    value={values.certificateContactName}
                                    className={classes.formTextField}
                                    onBlur={handleBlur}
                                    onChange={e => setFieldValue('certificateContactName', e.target.value)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <Tooltip
                                            title={
                                              'Use the tag {{CertificateContactName}} to insert this value into the letter content.'
                                            }
                                          >
                                            <IconButton onClick={() => copyToClipboard('{{CertificateContactName}}')}>
                                              <FileCopyOutlined />
                                            </IconButton>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  {/* CERTIFICATE CONTACT EMAIL */}
                                  <TextField
                                    fullWidth
                                    label='Certificate Contact Email'
                                    variant='outlined'
                                    name={'certificateContactEmail'}
                                    value={values.certificateContactEmail}
                                    className={classes.formTextField}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.certificateContactEmail && errors.certificateContactEmail)}
                                    helperText={touched.certificateContactEmail && errors.certificateContactEmail}
                                    onChange={e => setFieldValue('certificateContactEmail', e.target.value)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <Tooltip
                                            title={
                                              'Use the tag {{CertificateContactEmail}} to insert this value into the letter content.'
                                            }
                                          >
                                            <IconButton onClick={() => copyToClipboard('{{CertificateContactEmail}}')}>
                                              <FileCopyOutlined />
                                            </IconButton>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  {/* CONTACT GROUP NAME */}
                                  <Typography className={classes.clientPortfolioHelpLabel}>
                                    Contact Group Name
                                    <Tooltip
                                      title={
                                        'Use the tag {{ContactGroupName}} to insert this value into the letter content.'
                                      }
                                    >
                                      <IconButton onClick={() => copyToClipboard('{{ContactGroupName}}')}>
                                        <FileCopyOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <Typography className={classes.clientPortfolioHelpLabel}>
                                    Certificate Holder
                                    <Tooltip
                                      title={
                                        'Use the tag {{CertificateHolderName}} to insert this value into the letter content.'
                                      }
                                    >
                                      <IconButton onClick={() => copyToClipboard('{{CertificateHolderName}}')}>
                                        <FileCopyOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </Grid>

                                <Grid item lg={6}>
                                  {/** ON DEMAND NOTIFICATION SUBJECT */}
                                  <Grid item>
                                    <TextField
                                      fullWidth
                                      label='On Demand Notification Subject'
                                      variant='outlined'
                                      value={values.onDemandEmailSubject}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('onDemandEmailSubject', e.target.value)}
                                    />
                                  </Grid>

                                  {/* ON DEMAND EMAIL BODY */}
                                  <Grid container alignItems='flex-end'>
                                    <Grid item>
                                      <Typography className={clsx(classes.marginTop, classes.quillHeader)}>
                                        On Demand Email Body
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <ReactQuill
                                    modules={{
                                      toolbar: [['bold', 'italic', 'underline']],
                                    }}
                                    theme='snow'
                                    value={values?.onDemandEmailContent}
                                    onChange={content => setFieldValue('onDemandEmailContent', content)}
                                  />

                                  {/** LETTER EMAIL SUBJECT */}
                                  <Grid item>
                                    <TextField
                                      fullWidth
                                      label='Compliance Letter Email Subject'
                                      variant='outlined'
                                      value={values.letterEmailSubject}
                                      className={classes.emailFieldContainer}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('letterEmailSubject', e.target.value)}
                                    />
                                  </Grid>

                                  {/* LETTER CONTENT */}
                                  <Grid container alignItems='flex-end'>
                                    <Grid item>
                                      <Typography className={clsx(classes.marginTop, classes.quillHeader)}>
                                        Compliance Letter Content
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <ReactQuill
                                    modules={{
                                      toolbar: [['bold', 'italic', 'underline']],
                                    }}
                                    theme='snow'
                                    value={values?.letterContent}
                                    onChange={content => setFieldValue('letterContent', content)}
                                  />
                                  {/* NOTIFICATION BANNER CONTENT */}
                                  <Grid container alignItems='flex-end'>
                                    <Grid item>
                                      <Typography className={clsx(classes.marginTop, classes.quillHeader)}>
                                        Notification Banner Content (Max {MAX_CHARACTERS} Characters)
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <ReactQuill
                                    modules={{
                                      toolbar: [['bold', 'italic', 'underline']],
                                    }}
                                    theme='snow'
                                    value={values?.notificationBannerContent}
                                    onChange={(content, delta, source, editor) => {
                                      // we need to count all characters that are not HTML elements, otherwise we will get the length of the HTML elements
                                      // If we don't, if a user underlines or bolds a word, it will count the HTML elements as characters, effectively limiting
                                      // the amount of text they can enter.
                                      const plaintext = content.replace(/(<([^>]+)>)/gi, '');

                                      if (plaintext.length <= MAX_CHARACTERS) {
                                        setFieldValue('notificationBannerContent', content);
                                      } else {
                                        const totalLengthWithHTML = values.notificationBannerContent.length - 1;
                                        const maxValueContent = values.notificationBannerContent.slice(
                                          0,
                                          totalLengthWithHTML
                                        );
                                        setFieldValue('notificationBannerContent', maxValueContent);
                                        formatError(
                                          `Content must be ${MAX_CHARACTERS} characters or less.`,
                                          showToast,
                                          `Content must be ${MAX_CHARACTERS} characters or less.`
                                        );
                                      }
                                    }}
                                  />

                                  {/** CONTACT LETTER */}
                                  {userContext.role === userRoles.ADMIN && (
                                    <Grid item>
                                      <TextField
                                        fullWidth
                                        label='Client Letter Subject'
                                        variant='outlined'
                                        value={values.contactLetterSubject}
                                        className={classes.emailFieldContainer}
                                        onBlur={handleBlur}
                                        onChange={e => setFieldValue('contactLetterSubject', e.target.value)}
                                      />
                                      <Grid container alignItems='flex-end'>
                                        <Grid item>
                                          <Typography className={clsx(classes.marginTop, classes.quillHeader)}>
                                            Client Letter Content
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <ReactQuill
                                        modules={{
                                          toolbar: [['bold', 'italic', 'underline']],
                                        }}
                                        theme='snow'
                                        value={values?.contactLetterContent}
                                        onChange={content => {
                                          const plaintext = content.replace(/(<([^>]+)>)/gi, '');
                                          if (plaintext.length > 0) {
                                            setFieldValue('contactLetterContent', content);
                                          } else {
                                            setFieldValue('contactLetterContent', '');
                                          }
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </FormGroup>
                          </Grid>
                        ) : (
                          <div>
                            <FormGroup row>
                              <Skeleton className={`${classes.formTextField} ${classes.codeField}`} />
                              <Skeleton className={`${classes.formTextField} ${classes.codeField}`} />
                              <Skeleton className={classes.formTextField} width='64px' />
                            </FormGroup>
                            <FormGroup row>
                              <Skeleton className={classes.formTextField} variant='rect' width={210} height={118} />
                              <Skeleton className={classes.formTextField} variant='rect' width={210} height={118} />
                            </FormGroup>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  // PRE-CLIENT SAVE MESSAGE
                  !isLoading && (
                    <Grid container>
                      <Grid item xs={12} sm={6} classes={{ root: classes.newClient }}>
                        <Typography variant='h4'>
                          After saving your client you will be able to add further details such as Locations,
                          Units/Contractors, Default Requirements, Additional Insureds, and Client Configurations
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </Form>
            </div>
          );
        }}
      </Formik>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  backButton: {
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  skeletonLoaderContainer: {
    display: 'flex',
  },
  mobileSkeletonLoaderContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  formTextField: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toggle: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  codeField: {
    width: '100px',
  },
  nameField: {
    width: '250px',
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  numberTypeTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  select: {
    maxWidth: '125px',
  },
  newClient: {
    margin: '1rem 0',
  },
  contactContainer: {
    display: 'flex',
  },
  quillHeader: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  emailFieldContainer: {
    marginTop: theme.spacing(1.5),
  },
  previewButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5),
  },
  clientPortfolioHelpLabel: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    fontWeight: 400,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  tabContainer: {
    marginBottom: theme.spacing(1),
  },
}));
