import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

import { colors } from '../../styles';

interface IMenuItemProps {
  handleMenuItemClick(url: string): void;
  route: string;
  image: JSX.Element;
  label: string;
  isSelected: boolean;
}

export const MenuItem: FC<IMenuItemProps> = ({ handleMenuItemClick, route, image, label, isSelected }) => {
  const classes = useStyles();

  return (
    <Button
      size='large'
      className={clsx(classes.navButton, classes.navLink, isSelected ? classes.navLinkActive : '')}
      onClick={() => handleMenuItemClick(route)}
    >
      {image}
      <span className={classes.navButtonText}>{label}</span>
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  navLink: {
    display: 'flex',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: colors.secondary.catskillWhite,
      color: colors.primary.navyBlue,
      '& button': {
        color: colors.primary.navyBlue,
      },
      '& button svg': {
        fill: colors.primary.navyBlue,
      }
    }
  },
  navLinkActive: {
    backgroundColor: colors.secondary.catskillWhite,
    borderRight: '4px solid #B91F31',
    color: colors.primary.navyBlue,
    '& button': {
      color: colors.primary.navyBlue
    },
    '& button svg': {
      fill: colors.primary.navyBlue
    }
  },
  navButton: {
    borderRadius: '0',
    color: colors.secondary.darkGray,
    fill: colors.secondary.darkGray,
    fontSize: '14px',
    justifyContent: 'flex-start',
    padding: '1rem 1.25rem',
    width: '100%'
  },
  navButtonText: {
    fontWeight: 'normal',
    lineHeight: '1.3',
    paddingLeft: '0.75rem',
    textAlign: 'left',
    textTransform: 'none'
  }
}));
