export enum RequirementStatusEnums {
  Active = 1,
  Waived = 2,
  NotRequired = 3
}

export const RequirementStatusDropdownItems = [
  {
    key: RequirementStatusEnums.Active,
    label: 'Active',
    value: RequirementStatusEnums.Active
  },
  {
    key: RequirementStatusEnums.Waived,
    label: 'Waived',
    value: RequirementStatusEnums.Waived
  },
  {
    key: RequirementStatusEnums.NotRequired,
    label: 'Not Required',
    value: RequirementStatusEnums.NotRequired
  }
];
