import React, { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { Edit, Delete, Add } from '@material-ui/icons';
import { Fab, IconButton } from '@material-ui/core';
// Components
import { Page } from '../../components';
import { Table, ITableColumn, Pagination, Toast, ConfirmationDialogue } from '../../components';
import AddEditUsers from './AddEditUsers';
import { ManageUsersFilters } from './ManageUsersFilters';
// styles
import { colors } from '../../styles';
// constants
import { screenSizes } from '../../constants';
// fetch
import { getUsers, getUser, deleteUser } from '../../fetch';
// types
import { SortOptions, DESC_SORT, ASC_SORT, IUser, IUserGrid, IManageUsersFilters } from '../../types';
// hooks
import { useToastContext } from '../../hooks';

export const ManageUsers = () => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const { showToast } = useToastContext();

  const [sortDirection, setSortDirection] = useState<{
    FirstName?: SortOptions;
    LastName?: SortOptions;
    Username?: SortOptions;
    Email?: SortOptions;
    Role?: SortOptions;
    LastLogin?: SortOptions;
  }>({
    FirstName: ASC_SORT,
  });
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IUser | null>(null);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>('FirstName');
  const [users, setUsers] = useState<IUserGrid[]>([]);
  const [filters, setFilters] = useState<IManageUsersFilters>({});

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters]);

  const fetchUsers = async () => {
    setLoading(true);

    try {
      const res = await getUsers({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        firstName: filters.firstName,
        lastName: filters.lastName,
        username: filters.userName,
        email: filters.email,
        role: filters.role,
      });
      setUsers(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async id => {
    try {
      const res = await getUser(id);
      setSelectedRowItem(res);
      setShowAddEditModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteUser(id);
      fetchUsers();
      showSuccess(true);
    } catch (error) {
      console.log(error);
      showError(true);
    }
  };

  const hasData = users && users.length > 0;

  const columns = useMemo(() => {
    return [
      {
        accessor: 'firstName',
        Header: 'First Name',
        isServerSorted: selectedSort === 'FirstName',
        isServerSortedDesc: sortDirection.FirstName === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('FirstName');
          setSortDirection({
            ...sortDirection,
            FirstName: sortDirection.FirstName === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'lastName',
        Header: 'Last Name',
        isServerSorted: selectedSort === 'LastName',
        isServerSortedDesc: sortDirection.LastName === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('LastName');
          setSortDirection({
            ...sortDirection,
            LastName: sortDirection.LastName === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'username',
        Header: 'Username',
        isServerSorted: selectedSort === 'Username',
        isServerSortedDesc: sortDirection.FirstName === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Username');
          setSortDirection({
            ...sortDirection,
            Username: sortDirection.Username === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'email',
        Header: 'Email Address',
        isServerSorted: selectedSort === 'Email',
        isServerSortedDesc: sortDirection.Email === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Email');
          setSortDirection({
            ...sortDirection,
            Email: sortDirection.Email === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        id: 'role',
        Header: 'Role',
        isServerSorted: selectedSort === 'Role',
        isServerSortedDesc: sortDirection.Role === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Role');
          setSortDirection({
            ...sortDirection,
            Role: sortDirection.Role === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        accessor: original => {
          switch (original.role) {
            case 'ExternalUser':
              return 'Client';
            case 'User':
              return 'Employee';
            case 'Administrator':
              return 'Administrator';
            default:
              return '';
          }
        },
      },
      {
        id: 'lastLogin',
        Header: 'Last Login',
        isServerSorted: selectedSort === 'LastLogin',
        isServerSortedDesc: sortDirection.LastLogin === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('LastLogin');
          setSortDirection({
            ...sortDirection,
            LastLogin: sortDirection.LastLogin === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        accessor: original => (original.lastLogin ? format(new Date(original.lastLogin), "M/d/yyyy ' at ' h:mma") : ''),
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IUserGrid } };
        }) => {
          return (
            <div key={original.userId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={async () => {
                  await handleEdit(original.userId);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedId(original.userId);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Manage Users'>
      {/* FILTER BAR */}
      <ManageUsersFilters handleFilter={setFilters} isLoading={isLoading} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          hidePagination
          columns={columns}
          data={users}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
        />
        {!isLoading && hasData && (
          <Pagination
            page={page}
            count={recordCount}
            rowsPerPage={perPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      {/* ADD/EDIT MODAL */}
      <AddEditUsers
        initialValues={selectedRowItem}
        open={showAddEditModal}
        onClose={() => {
          setShowAddEditModal(false);
          setSelectedRowItem(null);
        }}
        onSave={() => fetchUsers()}
        showToast={showToast}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedId && (
        <ConfirmationDialogue
          id={`${selectedId}`}
          title={'Delete User'}
          text={'Are you sure you want to delete this user?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedId);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab
        color='secondary'
        className={isMobile ? classes.mobileAddButton : classes.addButton}
        aria-label='add'
        onClick={() => {
          setSelectedRowItem(null);
          setShowAddEditModal(true);
        }}
      >
        <Add />
      </Fab>

      {/* SUCCESS/FAIL MESSAGING */}
      <Toast
        id='user-type-success'
        message={`User Deleted!`}
        open={isSuccess}
        onClose={() => showSuccess(false)}
        variant='success'
      />
      <Toast
        id='user-type-error'
        autoHideDuration={6000}
        message={
          'We were unable to delete this user at this time. Please try again later. Please contact support if this issue continues.'
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      backgroundColor: colors.secondary.catskillWhite,
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem',
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem',
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
