import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactsIcon from '@material-ui/icons/Contacts';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PieChartIcon from '@material-ui/icons/PieChart';
import clsx from 'clsx';
import { FC, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { routes, screenSizes, userRoles } from '../../constants';
import { useNavMenuState, UserContext } from '../../context';
// import { colors } from '../../styles';
import { MenuItem } from './MenuItem';
import { Mail } from '@material-ui/icons';

interface IMenuItem {
  id: number;
  label: string;
  route: (typeof routes)[keyof typeof routes];
  image: JSX.Element;
  roles: userRoles[];
}

/**
 * Application-wide navigation menu. Displays links based on the authenticated
 * users' permissions. Open/closed state is stored locally.
 *
 * @see Page
 */
export const Menu: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMobile = useMedia(screenSizes.mobile);
  const { userContext } = useContext(UserContext);
  const { showMenu, setShowMenu } = useNavMenuState();

  // List of all the side nav menu items
  const menuItems: IMenuItem[] = [
    {
      id: 1,
      label: 'Manage Requirements',
      route: routes.manageRequirements,
      image: <AssignmentIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 2,
      label: 'Manage Clients',
      route: routes.manageClients,
      image: <PeopleIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 3,
      label: 'Compliance Letters',
      route: routes.letterRuns,
      image: <DescriptionIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 4,
      label: 'Client Letters',
      route: routes.contactLetterRuns,
      image: <Mail className={classes.navIcon} />,
      roles: [userRoles.ADMIN],
    },
    {
      id: 5,
      label: 'Reports',
      route: routes.reports,
      image: <PieChartIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL, userRoles.EXTERNAL],
    },
    {
      id: 6,
      label: 'Manage Users',
      route: routes.manageUsers,
      image: <PersonAddIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN],
    },
    {
      id: 7,
      label: 'Manage Carriers',
      route: routes.manageCarriers,
      image: <BusinessCenterIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN],
    },
    {
      id: 8,
      label: 'Manage Location Types',
      route: routes.manageLocationTypes,
      image: <LocationCityIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 9,
      label: 'Manage Contacts',
      route: routes.manageContacts,
      image: <ContactsIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 10,
      label: 'Lines of Coverage',
      route: routes.linesOfCoverage,
      image: <CreditCardIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN, userRoles.INTERNAL],
    },
    {
      id: 11,
      label: 'System',
      route: routes.adminSystem,
      image: <SettingsIcon className={classes.navIcon} />,
      roles: [userRoles.ADMIN],
    },
  ];

  const handleMenuItemClick = useCallback(
    (url: string) => {
      if (isMobile) {
        setShowMenu(false);
      }
      history.push(url);
    },
    [history, isMobile, setShowMenu]
  );

  return (
    <div
      className={clsx({
        [classes.mobileNavContainer]: isMobile,
        [classes.navContainer]: !isMobile,
        [classes.navContainerActive]: showMenu,
      })}
    >
      <div className={classes.innerNav}>
        <div className={classes.navigation}>
          <Grid container direction='column' justify='space-between' alignItems='center' className={classes.grid}>
            {/* MENU ITEMS */}
            <div className={classes.menuItemContainer}>
              {menuItems
                .filter(_ => userContext && _.roles.includes(userContext.role))
                .map(item => (
                  <MenuItem
                    key={item.id}
                    handleMenuItemClick={handleMenuItemClick}
                    route={item.route}
                    image={item.image}
                    label={item.label}
                    isSelected={pathname.split('/')[1] === item.route.split('/')[1]}
                  />
                ))}
            </div>

            {/* GET SUPPORT BUTTON */}
            {/* <Button size='large' className={classes.supportButton} onClick={() => {}}>
              <HelpIcon className={classes.navIcon} />
              <span className={classes.supportButtonText}>Get Support</span>
            </Button> */}
          </Grid>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  navContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 4px rgba(0,0,0,.25)',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    transition: 'width .3s ease-in-out',
    width: '0', // starts at zero
    zIndex: 2,
  },
  mobileNavContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 4px rgba(0,0,0,.25)',
    height: 'calc(100% - 172px)', // Subtract mobile header & footer height
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'width .3s ease-in-out',
    width: '0', // starts at zero
    zIndex: 2,
    position: 'absolute',
  },
  navContainerActive: {
    width: '215px',
  },
  innerNav: {
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100%',
    width: '215px',
  },
  navigation: {
    color: theme.palette.common.black,
    height: '100%',
    textDecoration: 'none',
  },
  grid: {
    height: '100%',
  },
  navIcon: {
    fill: 'inherit',
  },
  // supportButton: {
  //   borderRadius: '0',
  //   color: colors.primary.accentRed,
  //   fill: colors.primary.navyBlue,
  //   backgroundColor: colors.secondary.catskillWhite,
  //   fontSize: '14px',
  //   display: 'flex',
  //   textDecoration: 'none',
  //   justifyContent: 'flex-start',
  //   padding: '1rem 1.25rem',
  //   width: '100%',
  //   '&:hover': {
  //     backgroundColor: colors.secondary.catskillWhite,
  //     color: colors.primary.accentRed,
  //     '& button': {
  //       color: colors.primary.accentRed
  //     },
  //     '& button svg': {
  //       fill: colors.primary.navyBlue
  //     }
  //   }
  // },
  // supportButtonText: {
  //   fontWeight: 'normal',
  //   lineHeight: '1.3',
  //   paddingLeft: '0.75rem',
  //   textAlign: 'left',
  //   textTransform: 'none'
  // },
  menuItemContainer: {
    width: '100%',
  },
}));
