import { IHttpPostMessageResponse } from 'http-post-message';

import {
  IAdditionalInsured,
  IAdditionalInsuredForMonitoredEntity,
  IEnum,
  IError,
  ILineOfCoverage,
  IReplaceAdditionalInsured,
} from '../types';
import { authFetch } from './fetch';
import { logWarn } from '../services';

const baseUrl = '/api/AdditionalInsured';

export const getNamedPolicyStatuses = async (): Promise<IEnum[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/NamedPolicyStatuses`);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Returns a collection of additional insured by clientId
export const getAdditionalInsured = async (clientId: number): Promise<IAdditionalInsured[]> => {
  try {
    const response = await authFetch.get(`${baseUrl}/?clientId=${clientId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting additional insured. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

// This returns a collection of default additional insured from the client
export const getDefaultAdditionalInsured = async (
  clientId: number,
  locationId?: number
): Promise<IAdditionalInsured[]> => {
  try {
    let url = `${baseUrl}/DefaultAdditionalInsured/?clientId=${clientId}`;
    if (locationId) {
      url += `&locationId=${locationId}`;
    }
    const response = await authFetch.get(url);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting default additional insured. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

interface IAdditionalInsuredUpdateData extends Required<Omit<IAdditionalInsured, 'linesOfCoverage'>> {
  linesOfCoverage: Pick<ILineOfCoverage, 'lineOfCoverageId'>[];
}

export const updateAdditionalInsured = async (data: IAdditionalInsuredUpdateData): Promise<IAdditionalInsured[]> => {
  try {
    const response = await authFetch.post(`${baseUrl}/`, data);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error creating the additional insured. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    if (error.response) {
      //errors from the server
      return Promise.reject(error.response.data);
    } else if (error.request) {
      //axios errors are typically due to long awaits but does not indicate a server error
      logWarn(error, 'axios.js request failed'); //seq log
    }

    return Promise.reject(error);
  }
};

// todo: make this an actual delete i.e. authFetch.delete
export const deleteAdditionalInsuredRequirementStatus = async (
  additionalInsuredRequirementStatusIds: number[]
): Promise<IHttpPostMessageResponse<any>> => {
  try {
    await authFetch.post(`${baseUrl}/DeleteAdditionalInsuredRequirementStatus`, {
      id: additionalInsuredRequirementStatusIds,
    });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

// Updates an additional insured for a monitored entity.
export const updateAdditionalInsuredForMonitoredEntity = async (
  additionalInsured: IAdditionalInsuredForMonitoredEntity
): Promise<IAdditionalInsured[]> => {
  try {
    const response = await authFetch.post(`${baseUrl}/UpdateAdditionalInsuredForMonitoredEntity`, additionalInsured);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error creating the additional insured. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

// Updates an additional insured for a monitored entity.
export const replaceAdditionalInsured = async (
  replacementAdditionalInsured: IReplaceAdditionalInsured
): Promise<IHttpPostMessageResponse<any> | IError> => {
  try {
    await authFetch.post(`${baseUrl}/ReplaceAdditionalInsured`, replacementAdditionalInsured);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getCoveredPoliciesByMonitoredEntities = async (
  monitoredEntityIds: string
): Promise<IAdditionalInsuredForMonitoredEntity[]> => {
  try {
    const mappedEntities = monitoredEntityIds.split(',');
    const intEntityIds = mappedEntities.map(x=>parseInt(x));
    const result = await authFetch.post(`${baseUrl}/GetCoveredPoliciesByMonitoredEntities`,{
      monitoredEntityIds: intEntityIds
    })
    return result.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const associateDefaultAdditionalInsureds = async (monitoredEntityId: number) => {
  try {
    await authFetch.post(`${baseUrl}/ImportDefault/${monitoredEntityId}`, null);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
