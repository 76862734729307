import { FormControlLabel, Switch } from '@material-ui/core';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApplicationSettings, ApplicationSettingsNameLookup, ApplicationSettingsWarningLookup } from '../../types/applicationSettings';
import { updateApplicationSetting, getApplicationSetting } from '../../fetch/applicationSettings';

interface IApplicationSettingEditor {
    setting: ApplicationSettings;
}

export const ApplicationSettingEditor: FC<IApplicationSettingEditor> = (data: IApplicationSettingEditor) => {
    const [value, setValue] = useState<boolean>(false);

    useEffect(() => {
        getApplicationSetting(data.setting)
            .then(res => {
                setValue(res);
            });
    }, [data.setting]);

    const handleToggleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const confirmationResult = window.confirm(ApplicationSettingsWarningLookup(data.setting));
        if (confirmationResult) {
            updateApplicationSetting(data.setting, checked)
                .then(() => {
                    setValue(checked);
                });
        }
    };

    return (
        <FormControlLabel
            label={ApplicationSettingsNameLookup(data.setting)}
            control={
                <Switch
                    checked={value}
                    onChange={handleToggleChange} />
            }
        />
    );
};