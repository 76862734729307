import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { Typography } from '@material-ui/core';
// Helpers
import { colors } from '../../styles';

interface IPageTitle {
  title?: string;
  marginOff?: boolean;
  centerHeader?: boolean;
}

export const PageTitle: FC<IPageTitle> = ({ centerHeader, title, marginOff = false }) => {
  const classes = useStyles({ marginOff });
  return (
    <Typography className={`${classes.header} ${centerHeader ? classes.centerHeader : ''}`} variant={'h6'}>
      {title}
    </Typography>
  );
};

const useStyles = makeStyles<Theme, IPageTitle>(theme => ({
  header: {
    color: colors.primary.navyBlue,
    fontWeight: 600,
    marginBottom: ({ marginOff }) => (marginOff ? 0 : theme.spacing(2))
  },
  centerHeader: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
