import React, { FC, useState } from 'react';
import { Button, CardActions, Grid } from '@material-ui/core';
import { BasicFileUploader, Modal } from '../index';
import { Add } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IFile } from '../../types';
import { colors } from '../../styles';

export interface IImportModalProps {
  title: string;
  onClose: () => void;
  open: boolean;
  handleImport: (file: IFile) => void;
  allowedFiles: string[];
  isLoading: boolean;
  children?: React.ReactNode;
}

export const ImportModal: FC<IImportModalProps> = ({
  title,
  onClose,
  open,
  handleImport,
  allowedFiles,
  isLoading,
  children
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<IFile>(null);

  return (
    <Modal
      isLoading={isLoading}
      open={open}
      fullWidth={false}
      maxWidth={'md'}
      title={title}
      onClose={onClose}
    >
      <Grid item xs={12}>
        <BasicFileUploader
          allowedFiles={allowedFiles}
          setFileInParent={setFile}
        />
        {children}
      </Grid>

      {/* FORM BUTTONS */}
      <CardActions>
        <div className={classes.buttonContainer}>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.saveButton}
            type='submit'
            startIcon={<Add />}
            variant='contained'
            color='primary'
            onClick={() => handleImport(file)}
          >
            Import
          </Button>
        </div>
      </CardActions>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    buttonContainer: {
      width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1)
    },
    saveButton: {
      marginLeft: theme.spacing(1),
        backgroundColor: colors.primary.navyBlue
    }
  };
});