import { Fade, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { Loader, Modal, Table } from '../../components';
import { getRequirementHistory } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IEntityRequirementHistory } from '../../types';

interface IRequirementHistoryModalProps {
  open: boolean;
  onClose: () => void;
  selectedRequirementId: number;
}

export const RequirementHistoryModal: FC<IRequirementHistoryModalProps> = ({
  open,
  onClose,
  selectedRequirementId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requirementHistoryData, setRequirementHistoryData] = useState<IEntityRequirementHistory[]>([]);

  const classes = useStyles();
  const { showToast } = useToastContext();

  const fetchRequirementHistory = async () => {
    setIsLoading(true);
    try {
      setRequirementHistoryData(await getRequirementHistory(selectedRequirementId));
    } catch (error) {
      showToast('error', 'There was a problem loading the requirement history. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequirementHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'lineOfCoverageName',
        Header: 'Line of Coverage Name',
      },
      {
        accessor: 'coverageType',
        Header: 'Coverage Type',
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        id: 'requiredAmount',
        columnAlignment: 'right',
        accessor: original =>
          original?.requiredAmount
            ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                Number(original.requiredAmount)
              )
            : '',
        Header: 'Required Amount',
      },
      {
        id: 'actualAmount',
        columnAlignment: 'right',
        accessor: original =>
          original?.actualAmount
            ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
                Number(original.actualAmount)
              )
            : '',
        Header: 'Actual Amount',
      },
      {
        id: 'expirationDate',
        accessor: original => original?.expirationDate && format(new Date(original.expirationDate), 'M/d/yyyy'),
        Header: 'Expiration Date',
      },
      {
        id: 'documentDate',
        accessor: original => original?.documentDate && format(new Date(original.documentDate), 'M/d/yyyy'),
        Header: 'Document Date',
      },
      {
        accessor: 'carrierName',
        Header: 'Carrier Name',
      },
      {
        accessor: 'hasEvidence',
        Header: 'Evidence',
      },
      {
        accessor: 'updatedBy',
        Header: 'Updated By',
      },
      {
        id: 'changeTrackingDate',
        accessor: original => {
          if (!original?.changeTrackingDate) return '';
          const d = new Date(original.changeTrackingDate);
          const temp = Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds()
          );
          const date = new Date(temp);
          return format(date, "M/d/yyyy ' at ' h:mm a");
        },
        Header: 'Change Tracking Date',
      },
      {
        accessor: 'complianceStatus',
        Header: 'Compliance',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementHistoryData]);

  return (
    <Modal open={open} title={'Requirement History'} onClose={() => onClose()}>
      {isLoading && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Grid container spacing={1} className={classes.gridContainer}>
          {/* GRID */}
          <Table
            isLoading={isLoading}
            columns={columns}
            data={requirementHistoryData}
            headerClasses={`${classes.tableHeader} ${classes.shrinkCellPadding}`}
            centerPagination
            stickyHeader
            cellClasses={classes.shrinkCellPadding}
          />
        </Grid>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  shrinkCellPadding: {
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
  },
}));
