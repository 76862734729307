import { authFetch } from './';
import { IError, IUser, IUserGridRes } from '../types';

const baseUrl = '/api/user';

export const getUser = async (id: number): Promise<IUser> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting user. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteUser = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting user. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateUser = async (user: IUser): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, user);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating user. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createUser = async (user: IUser): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}`, user);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating user. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getUsers = async (filters?: {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  role?: string;
}): Promise<IUserGridRes> => {
  let query = '';
  if (filters) {
    query = `?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`;

    if (filters.firstName) {
      query += `&firstName=${filters.firstName}`;
    }

    if (filters.lastName) {
      query += `&lastName=${filters.lastName}`;
    }

    if (filters.username) {
      query += `&username=${filters.username}`;
    }

    if (filters.email) {
      query += `&email=${filters.email}`;
    }

    if (filters.role) {
      query += `&role=${filters.role}`;
    }
  }
  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return {
    records: [],
    totalRecordCount: 0
  };
};
