import React, { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Select } from '../../components';
// Constants
import { screenSizes } from '../../constants';
// Models
import { ILocation, IMonitoredEntityFilters } from '../../types';

interface IMonitoredEntitiesFilterProps {
  handleFilter: (filters: IMonitoredEntityFilters) => void;
  locations?: ILocation[];
}

export const MonitoredEntityFilters: FC<IMonitoredEntitiesFilterProps> = ({ handleFilter, locations }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);

  const [locationId, setLocationId] = useState<number>(null);

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce(locationIdFilter => {
      handleFilter({
        locationId: locationIdFilter
      });
    }, 500),
    []
  );

  useEffect(() => {
    filterCallback(locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <div className={!isMobile ? classes.filterBarContainer : classes.mobileFilterBarContainer}>
      <Select
        name='locationId'
        id='location-select'
        value={locationId}
        onChange={({ target: { value } }) => setLocationId(value as number)}
        displayEmpty
        defaultValue='Location'
        resetValue={null}
        className={`${classes.filter} ${locationId === null ? classes.placeholder : ''}`}
        options={[
          { key: 'undefined', value: null, label: 'Location' },
          ...locations.map(item => ({
            key: item.locationId,
            label: item.name,
            value: item.locationId
          }))
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(1)
    },
    mobileFilterBarContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1)
    },
    filter: {
      marginRight: theme.spacing(1),
      width: theme.spacing(15)
    },
    codeField: {
      width: '100px'
    },
    nameField: {
      width: '250px'
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1)
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    placeholder: {
      '& > div': {
        color: theme.palette.grey[400]
      }
    }
  };
});
