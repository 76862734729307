import { Box, FormControlLabel, Grid, IconButton, Switch } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmationDialogue, ITableColumn, PageTitle, Table } from '../../components';
import { deleteMonitoredEntityFile, getFilesByMonitoredEntityId, getFileAssociationsCount } from '../../fetch';
import { convertToUTC, formatShortFriendlyDateWithTime } from '../../helpers';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IFile, ILineOfCoverage, IMonitoredEntityFile, IMonitoredEntityFileDelete } from '../../types';
import { RequirementFileEditModal } from './RequirementFileEditModal';

const statusOptions = [
  { label: 'Applied', value: 1 },
  { label: 'Not Accepted', value: 2 },
];

interface IDocumentsProps {
  monitoredEntityId: number;
  linesOfCoverage: ILineOfCoverage[];
  inUseLinesOfCoverage: number[];
  fetchRequirements: () => Promise<void>;
}

export const Documents: FC<IDocumentsProps> = ({
  monitoredEntityId,
  linesOfCoverage,
  inUseLinesOfCoverage,
  fetchRequirements,
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();

  const [documentList, setDocumentList] = useState<IMonitoredEntityFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<IMonitoredEntityFile | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  // The current record attached to the delete icon, if it is opened
  const [showConfirmDelete, setShowConfirmDelete] = useState<false | IMonitoredEntityFile>(false);
  const [showDeleteFileFromAllEntitiesToggle, setShowDeleteFileFromAllEntitiesToggle] = useState<boolean>(false);
  const [deleteFileFromAllEntities, setDeleteFileFromAllEntities] = useState<boolean>(false);
  const [deleteFileModalTitle, setDeleteFileModalTitle] = useState<string>('');
  const [deleteFileModalPrompt, setDeleteFileModalPrompt] = useState<string>('');

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getFilesByMonitoredEntityId(monitoredEntityId);
      setDocumentList(response);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [monitoredEntityId]);

  const deleteDocument = async (file: IMonitoredEntityFile): Promise<void> => {
    try {
      const deleteFile: IMonitoredEntityFileDelete = {
        monitoredEntityFileId: file.monitoredEntityFileId,
        deleteAllEntitiesFromFile: deleteFileFromAllEntities,
      };
      await deleteMonitoredEntityFile({ ...deleteFile });
      // Remove deleted row from list state
      setDocumentList(list => {
        const index = list.indexOf(file);
        const newList = list.slice();
        newList.splice(index, 1);
        return newList;
      });
      // After successful delete, refetch requirements
      await fetchRequirements();
    } catch (error) {
      const message = error instanceof Error && error.message;
      showToast('error', message || 'Failed to delete document.');
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetFile = async (monitoredEntityFile: IMonitoredEntityFile) => {
    if (!monitoredEntityFile?.file) {
      showToast('error', 'Unable to download file because the file does not exist.');
      return;
    }
    setLoading(true);
    try {
      await IFile.download(monitoredEntityFile.file);
    } catch (error) {
      showToast('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditFile = (file: IMonitoredEntityFile) => {
    setSelectedFile(file);
    setShowEditModal(true);
  };

  const handleDeleteFile = async (file: IMonitoredEntityFile) => {
    try {
      setDeleteFileModalTitle(`Delete Document`);
      setDeleteFileModalPrompt('Are you sure you want to delete this document?');

      const response = await getFileAssociationsCount(file.file.fileId);

      //Modify modal text if there are multiple file associations
      if (response > 1) {
        setDeleteFileModalTitle(`Document applied to ${response} entities`);
        setDeleteFileModalPrompt('Delete document from this entity?');
      }

      setShowDeleteFileFromAllEntitiesToggle(response > 1);
      setShowConfirmDelete(file);
    } catch (error) {
      console.log('error', error);
      setShowDeleteFileFromAllEntitiesToggle(false);
    }
  };

  const onCloseModal = useCallback(
    (shouldUpdate: boolean) => {
      if (shouldUpdate) {
        loadData();
      }
      setShowEditModal(false);
      setSelectedFile(null);
    },
    [loadData]
  );

  const columns: ITableColumn[] = useMemo(() => {
    return [
      {
        id: 'name',
        Header: 'Name',
        Cell: ({
          cell: {
            row: { original, index },
          },
        }: {
          cell: { row: { original; index } };
        }) => {
          return (
            <div style={{ cursor: 'pointer', color: '#002855' }} onClick={() => handleGetFile(original)}>
              {original.file.name}
            </div>
          );
        },
      },
      {
        id: 'requirementFileStatus',
        Header: 'Status',
        Cell: ({
          cell: {
            row: { original, index },
          },
        }: {
          cell: { row: { original; index } };
        }) => {
          return statusOptions.find(s => Number(s.value) === Number(original.fileStatus)).label || '';
        },
      },
      {
        id: 'linesOfCoverage',
        Header: 'Lines of Coverage',
        Cell: ({
          cell: {
            row: { original, index },
          },
        }: {
          cell: { row: { original; index } };
        }) => {
          return (
            <div className={classes.coverageList}>
              {original.lineOfCoverageIds.map(id => (
                <div>{linesOfCoverage.find(l => l.lineOfCoverageId === id).name || ''}</div>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Entered Date',
        id: 'dateCreated',
        accessor: (d: IMonitoredEntityFile) => new Date(convertToUTC(d.file.dateCreated)).getTime(),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMonitoredEntityFile; index: number } };
        }) => {
          return <span>{formatShortFriendlyDateWithTime(convertToUTC(original.file.dateCreated))}</span>;
        },
      },
      {
        Header: 'Expiration Date',
        accessor: (x: IMonitoredEntityFile) => (x?.expirationDate ? new Date(x.expirationDate).getTime() : ''),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMonitoredEntityFile; index: number } };
        }) => {
          return <span>{original?.expirationDate ? format(new Date(original.expirationDate), 'M/d/yyyy') : ''}</span>;
        },
      },
      {
        Header: 'Document Date',
        accessor: (x: IMonitoredEntityFile) => (x?.documentDate ? new Date(x.documentDate).getTime() : ''),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMonitoredEntityFile; index: number } };
        }) => {
          return <span>{original?.documentDate ? format(new Date(original?.documentDate), 'M/d/yyyy') : ''}</span>;
        },
      },
      {
        Header: 'User',
        accessor: 'user',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMonitoredEntityFile; index: number } };
        }) => {
          return (
            <Box display='flex' flex={1} justifyContent='flex-end'>
              <div key={original.file.fileId}>
                <IconButton color='primary' size='small' onClick={() => handleEditFile(original)}>
                  <Edit />
                </IconButton>
              </div>
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  handleDeleteFile(original);
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentList]);

  return (
    <Grid container>
      {/* HEADER */}
      <div className={classes.header}>
        <PageTitle title='Documents' marginOff />
      </div>

      {/* DOCUMENTS GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={loading}
          columns={columns}
          data={documentList}
          headerClasses={classes.tableHeader}
          stickyHeader
          centerPagination
        />
      </div>

      {/* EDIT DOCUMENT MODAL */}
      {!!selectedFile && (
        <RequirementFileEditModal
          open={showEditModal}
          onClose={onCloseModal}
          selectedFile={selectedFile}
          linesOfCoverage={linesOfCoverage}
          inUseLinesOfCoverage={inUseLinesOfCoverage}
          monitoredEntityId={monitoredEntityId}
        />
      )}
      {/* DELETE DOCUMENT MODAL */}
      {!!showConfirmDelete && (
        <ConfirmationDialogue
          open
          id={`confirm-delete-document-${showConfirmDelete.monitoredEntityId}`}
          title={deleteFileModalTitle}
          text={deleteFileModalPrompt}
          customContent={
            showDeleteFileFromAllEntitiesToggle && (
              <FormControlLabel
                control={
                  <Switch
                    checked={deleteFileFromAllEntities}
                    onChange={() => setDeleteFileFromAllEntities(!deleteFileFromAllEntities)}
                  />
                }
                label='Delete document from all entities'
              />
            )
          }
          onClose={() => {
            setShowConfirmDelete(false);
            setDeleteFileFromAllEntities(false);
          }}
          onConfirm={() => {
            // Close the modal
            setShowConfirmDelete(false);
            deleteDocument(showConfirmDelete);
          }}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
      overflowX: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem',
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem',
    },
    coverageList: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});
