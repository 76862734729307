import React, { FC, useState, useEffect } from 'react';
import { FormControl, FormGroup } from '@material-ui/core';
// types
import { IEntityRequirement } from '../../../types';
import { Select } from '../../../components';
import { RequirementStatusDropdownItems } from '../../../constants/RequirementStatuses';

interface IStatusCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
}

export const StatusCell: FC<IStatusCellProps> = ({
  cell: { row: original },
  cell,
  row: { index },
  column: { id },
  editing,
  updateData,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.status);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.status);
  }, [original.original.status]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.status) {
      updateData('status', value, original.original);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return editing ? (
    <FormControl fullWidth>
      <FormGroup row>
        <Select
          showReset={false}
          name='status-dropdown'
          id='status-dropdown'
          value={value}
          options={RequirementStatusDropdownItems}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setValue(event.target.value ?? null)}
          onBlur={() => updateData('status', value ?? null, original.original)}
        />
      </FormGroup>
    </FormControl>
  ) : (
    <div>{RequirementStatusDropdownItems?.find(item => item.value === original.original.status)?.label}</div>
  );
};
