import { FormControl } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import { DatePicker } from '../../../components';
import { RequirementStatusEnums } from '../../../constants';
import { IEntityRequirement } from '../../../types';

interface IExpirationDateCellProps {
  cell: { row: { original: IEntityRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
  touched: boolean;
  setTouched: (arg: boolean) => void;
}

export const ExpirationDateCell: FC<IExpirationDateCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateData,
  touched,
  setTouched
}) => {
  const [err, setErr] = useState(false);

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.expirationDate ?? null);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.expirationDate);
  }, [original.original.expirationDate]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.expirationDate) {
      updateData('expirationDate', value, original.original);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const req: IEntityRequirement = original.original;
    if (req.status !== RequirementStatusEnums.Active) {
      return;
    }

    // For each requirement line, Exp Date, Doc Date, & Carrier are required
    // We display a "Field required" validation error on these fields if one is
    // filled out and the others are not.
    const fields = {
      expirationDate: req.expirationDate,
      documentDate: req.documentDate,
      carrierId: req.carrierId
    };
    const entries = Object.entries(fields);
    const missing = entries.filter(([_, v]) => !v);
    const present = entries.filter(([_, v]) => !!v);

    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (missing.length > 0 && present.length > 0) {
      if (missing.some(([key, _]) => key === 'expirationDate')) {
        setErr(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original.original.expirationDate, original.original.documentDate, original.original.carrierId]);

  return (
    <FormControl fullWidth>
      <DatePicker
        clearable
        id='document-date-picker'
        // Includes focusing the input field AND focusing the datepicker *icon*
        onFocusCapture={() => {
          if (touched) {
            return;
          }
          setTouched(true);
        }}
        fullWidth
        placeholder=''
        autoComplete='nope'
        name='expirationDate'
        value={value ? new Date(value) : null}
        onChange={date => setValue(date?.toISOString() ?? null)}
        // When the user clicks Clear, actually update the local state so the
        // effect can fire which updates values.expirationDate which is used to
        // control other values/behavior.
        onClear={() => setValue(null)}
        label={touched && err ? 'Field required.' : undefined}
        error={touched && err}
        InputLabelProps={{ shrink: touched && err }}
      />
    </FormControl>
  );
};
