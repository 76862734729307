import { Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useMedia } from 'react-use';
import { Select } from '../../components';
import { screenSizes } from '../../constants';
import { IManageRequirementsFilters } from '../../types';
import { numbersOnlyRegExp } from '../../helpers/format';

const complianceOptions = [
  { value: 'Evidence of Requirement Received', label: 'Evidence of Requirement Received' },
  { value: 'Deficient', label: 'Deficient' },
];

interface IMonitoredEntitiesFilterProps {
  handleFilter: (filters: IManageRequirementsFilters) => void;
  isLoading: boolean;
}

export const ManageRequirementsFilters: FC<IMonitoredEntitiesFilterProps> = ({ handleFilter, isLoading }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const isTablet = useMedia(screenSizes.tablet);

  const [locationName, setLocationName] = useState<string>('');
  const [monitoredEntityName, setMonitoredEntityName] = useState<string>('');
  const [unitNumber, setUnitNumber] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactGroup, setContactGroup] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [complianceStatus, setComplianceStatus] = useState<string>('');
  // If null, filter is disabled
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [letterRunDetailsId, setLetterRunDetailsId] = useState<string>('');

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce(
      (
        locationFilter,
        monitoredEntityFilter,
        unitNumber,
        contactNameFilter,
        contactEmailFilter,
        contactGroup,
        complianceStatusFilter,
        isActiveFilter,
        letterRunDetailsIdFilter
      ) => {
        handleFilter({
          locationName: locationFilter,
          monitoredEntityName: monitoredEntityFilter,
          unitNumber: unitNumber,
          contactName: contactNameFilter,
          contactEmail: contactEmailFilter,
          contactGroupName: contactGroup,
          complianceStatus: complianceStatusFilter,
          isActive: isActiveFilter,
          letterRunDetailsId: letterRunDetailsIdFilter,
        });
      },
      500
    ),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(
        locationName,
        monitoredEntityName,
        unitNumber,
        contactName,
        contactEmail,
        contactGroup,
        complianceStatus,
        isActive,
        letterRunDetailsId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationName,
    monitoredEntityName,
    unitNumber,
    contactName,
    contactEmail,
    contactGroup,
    complianceStatus,
    isActive,
    letterRunDetailsId,
  ]);

  return (
    <div className={classes.filterBarContainer}>
      <TextField
        placeholder='Location'
        className={`${classes.filter} ${classes.mediumWidthField}`}
        value={locationName}
        onChange={({ target: { value } }) => setLocationName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!locationName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setLocationName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Monitored Entity'
        className={`${classes.filter} ${classes.mediumWidthField}`}
        value={monitoredEntityName}
        onChange={({ target: { value } }) => setMonitoredEntityName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!monitoredEntityName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setMonitoredEntityName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Unit Number'
        className={`${classes.filter} ${classes.smallWidthField}`}
        value={unitNumber}
        onChange={({ target: { value } }) => setUnitNumber(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!contactName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setUnitNumber('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Contact'
        className={`${classes.filter} ${classes.mediumWidthField}`}
        value={contactName}
        onChange={({ target: { value } }) => setContactName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!contactName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setContactName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Contact Email'
        className={`${classes.filter} ${classes.mediumWidthField}`}
        value={contactEmail}
        onChange={({ target: { value } }) => setContactEmail(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!contactEmail && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setContactEmail('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Contact Group'
        className={`${classes.filter} ${classes.mediumWidthField}`}
        value={contactGroup}
        onChange={({ target: { value } }) => setContactGroup(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!contactGroup && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setContactGroup('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.selectContainer}>
        {!complianceStatus && (
          <Typography className={`${classes.placeholder} ${classes.selectLabel}`}>Compliance</Typography>
        )}
        <Select
          className={`${classes.filter} ${classes.select}`}
          name='complianceStatusSelector'
          id='complianceStatusSelector'
          value={complianceStatus}
          autoWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComplianceStatus(e.target.value)}
          options={complianceOptions.map(s => ({ key: s.value, label: s.label, value: s.value }))}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {!!complianceStatus && (
                  <IconButton size='small' className={classes.clearIcon} onClick={() => setComplianceStatus('')}>
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.selectContainer}>
        {isActive === null && (
          <Typography className={`${classes.placeholder} ${classes.selectLabel}`}>Status</Typography>
        )}
        <Select
          autoWidth
          showReset
          customClearFunction={() => setIsActive(null)}
          className={`${classes.filter} ${classes.select}`}
          name='isActiveSelector'
          id='isActiveSelector'
          // If null show placeholder otherwise map boolean to isActive value display
          value={isActive === null ? '' : !!isActive ? 'Active' : 'Inactive'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newIsActiveValue: string = event.target.value;
            if (newIsActiveValue === 'Active') setIsActive(true);
            if (newIsActiveValue === 'Inactive') setIsActive(false);
            if (newIsActiveValue === null) setIsActive(null);
          }}
          options={[
            { value: 'Active', label: 'Active', key: 'Active' },
            { value: 'Inactive', label: 'Inactive', key: 'Inactive' },
          ]}
        />
      </div>
      <TextField
        placeholder='Letter ID'
        className={`${classes.filter} ${classes.smallWidthField}`}
        value={letterRunDetailsId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newLetterRunDetailsIdValue: string = event.target.value;
          if (newLetterRunDetailsIdValue === '' || numbersOnlyRegExp.test(newLetterRunDetailsIdValue)) {
            setLetterRunDetailsId(newLetterRunDetailsIdValue);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!letterRunDetailsId && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setLetterRunDetailsId('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {(!!locationName ||
        !!monitoredEntityName ||
        !!unitNumber ||
        !!complianceStatus ||
        !!contactName ||
        !!contactEmail ||
        !!contactGroup ||
        !!letterRunDetailsId ||
        typeof isActive === 'boolean') && (
        <Button
          startIcon={<Clear />}
          className={isMobile || isTablet ? classes.mobileResetButton : classes.resetButton}
          onClick={() => {
            setLocationName('');
            setMonitoredEntityName('');
            setUnitNumber('');
            setContactName('');
            setContactEmail('');
            setContactGroup('');
            setComplianceStatus('');
            setIsActive(null);
            setLetterRunDetailsId('');
          }}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      flexFlow: 'row wrap',
    },
    mobileFilterBarContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
    },
    filter: {
      marginRight: theme.spacing(1),

      marginBottom: theme.spacing(1),
    },
    largeWidthField: {
      width: theme.spacing(12),
    },
    mediumWidthField: {
      width: theme.spacing(10),
    },
    smallWidthField: {
      width: theme.spacing(8),
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(-0.75),
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer',
    },
    placeholder: {
      color: theme.palette.grey[400],
    },
    selectLabel: {
      fontSize: theme.typography.fontSize,
      position: 'absolute',
      left: 0,
      bottom: theme.spacing(1.2),
    },
    selectContainer: {
      position: 'relative',
    },
    select: {
      width: theme.spacing(10),
    },
  };
});
