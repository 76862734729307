import { Checkbox, FormControl, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { CoverageTypeEnums } from '../../../constants/CoverageTypeEnums';
import { IEntityRequirement } from '../../../types';

interface IActualAmountCellProps {
  cell: { row: { original: IEntityRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
}

export const ActualAmountCell: FC<IActualAmountCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateData
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.actualAmount);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.actualAmount);
  }, [original.original.actualAmount]);

  return original.original.coverageType === CoverageTypeEnums.EVIDENCE ? (
    <Checkbox checked={!!original.original.expirationDate} onClick={function noop() {}} style={{ display: 'none' }} />
  ) : (
    <FormControl fullWidth>
      <NumberFormat
        customInput={TextField}
        onValueChange={values => setValue(values.value)}
        inputProps={{ style: { textAlign: 'right' } }}
        decimalScale={0}
        thousandSeparator
        isNumericString
        prefix='$'
        fullWidth
        name='actualAmount'
        value={value}
        onBlur={() => updateData('actualAmount', value ? value : '', original.original)}
      />
    </FormControl>
  );
};
