import { Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { Table } from '../../components';

import { colors } from '../../styles';
import { IAdditionalInsuredForMonitoredEntity, IEnum } from '../../types';

import { IBulkDataDefaultRequirement } from '../../types/bulkUpdate';

import {
  CoverageTypeCell,
  ActualAmountCell,
  ExpirationDateCell,
  DocumentDateCell,
  CarrierCell,
} from './bulkUpdateTableCells';

interface IBulkUpdateLOCTable {
  // This is the props.location.state.<t> that gets passed from React Router
  isLoading: boolean;
  requirements: IBulkDataDefaultRequirement[];
  setRequirements: React.Dispatch<React.SetStateAction<IBulkDataDefaultRequirement[]>>;
  selectedRequirements: number[];
  setSelectedRequirements: (value: React.SetStateAction<number[]>) => void;
  updateData: (
    field: string,
    newValue: any,
    original: IBulkDataDefaultRequirement,
    values: any,
    setFieldValue: any,
    secondField?: string,
    secondNewValue?: any
  ) => void;
  carriers: Omit<IEnum, 'description'>[];
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  reactTouched: boolean;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
  valuesAreNotValid: (values: any) => any;
  selectedCreditForAll: number[];
  setSelectedCreditForAll: (value: React.SetStateAction<number[]>) => void;
  setEntitiesIsDirty(isChanged: boolean): void;
  additionalInsuredData: IAdditionalInsuredForMonitoredEntity[];
  setAdditionalInsuredData: React.Dispatch<React.SetStateAction<IAdditionalInsuredForMonitoredEntity[]>>;
  previousValues: any;
}

export const BulkUpdateLOCTable: FC<PropsWithChildren<IBulkUpdateLOCTable>> = ({
  isLoading,
  requirements,
  setRequirements,
  selectedRequirements,
  setSelectedRequirements,
  updateData,
  carriers,
  values,
  setFieldValue,
  reactTouched,
  setTouched,
  valuesAreNotValid,
  selectedCreditForAll,
  setSelectedCreditForAll,
  setEntitiesIsDirty,
  additionalInsuredData,
  setAdditionalInsuredData,
  previousValues,
}) => {
  const classes = useStyles();
  const bulkUpdateColumns = useMemo(() => {
    return [
      {
        id: 'checkbox',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBulkDataDefaultRequirement } };
        }) => {
          // eslint-disable-next-line
          {
            /* MONITORED ENTITY CHECKBOX FOR BULK UPDATE */
          }
          return (
            <Checkbox
              size='small'
              checked={selectedRequirements.includes(original.defaultRequirementId)}
              onClick={() => {
                if (selectedRequirements.includes(original.defaultRequirementId)) {
                  setSelectedRequirements(prev => prev.filter(req => req !== original.defaultRequirementId));
                  setTouched(true);
                  setEntitiesIsDirty(true);
                } else {
                  setSelectedRequirements(prev => [...prev, original.defaultRequirementId]);
                  setTouched(true);
                  setEntitiesIsDirty(true);
                }
              }}
            />
          );
        },
      },
      {
        accessor: 'lineOfCoverageName',
        Header: 'Line of Coverage',
      },
      {
        Header: 'Type',
        id: 'coverageType',
        overrideWidth: 150,
        Cell: CoverageTypeCell,
      },
      {
        Header: 'Actual Fulfilled',
        overrideWidth: 165,
        Cell: ActualAmountCell,
      },
      {
        Header: 'Expiration',
        overrideWidth: 165,
        Cell: ExpirationDateCell,
      },
      {
        Header: 'Document Date',
        overrideWidth: 165,
        Cell: DocumentDateCell,
      },
      {
        accessor: 'carrier',
        Header: 'Carrier',
        overrideWidth: 185,
        Cell: CarrierCell,
      },
      {
        id: 'checkbox-credit',
        Header: 'Apply Credit for All Additional Insured',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBulkDataDefaultRequirement } };
        }) => {
          // eslint-disable-next-line
          {
            /* MONITORED ENTITY CHECKBOX FOR BULK UPDATE */
          }
          return (
            <Checkbox
              size='small'
              checked={selectedCreditForAll.includes(original.lineOfCoverageId)}
              onClick={() => {
                if (selectedCreditForAll.includes(original.lineOfCoverageId)) {
                  setSelectedCreditForAll(prev => prev.filter(req => req !== original.lineOfCoverageId));
                  setTouched(true);
                  setEntitiesIsDirty(true);
                } else {
                  setSelectedCreditForAll(prev => [...prev, original.lineOfCoverageId]);
                  setSelectedRequirements(prev => [...prev, original.defaultRequirementId]);
                  setTouched(true);
                  setEntitiesIsDirty(true);
                }
              }}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirements, selectedCreditForAll]);

  useEffect(() => {
    const locArray = JSON.parse(JSON.stringify(values.additionalInsureds));

    locArray?.forEach(entity => {
      if (entity?.additionalInsureds) {
        entity?.additionalInsureds?.forEach(addIns =>
          addIns.linesOfCoverage.forEach(loc => {
            if (selectedCreditForAll.includes(loc.lineOfCoverageId)) {
              loc.namedPolicyStatus = 1;
            }
          })
        );
      }
    });

    setFieldValue('additionalInsureds', locArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditForAll]);

  const updateSelectedRequirements = row => {
    const requirementsCopy = JSON.parse(JSON.stringify(values.requirements));

    const updateIndex = requirementsCopy.findIndex(req => req.defaultRequirementId === row.defaultRequirementId);

    requirementsCopy[updateIndex].applyRequirementChanges = true;

    if (!selectedRequirements.includes(row.defaultRequirementId)) {
      setSelectedRequirements(prev => [...prev, row.defaultRequirementId]);
    }

    setFieldValue('requirements', requirementsCopy);
  };

  useEffect(() => {
    let updatedValues = [...additionalInsuredData];

    updatedValues.forEach(addIns =>
      addIns.additionalInsureds.forEach(additional => {
        additional.linesOfCoverage.forEach(loc => {
          previousValues.forEach(previousValue => {
            selectedCreditForAll.forEach(selection => {
              if (loc.lineOfCoverageId === selection) {
                loc.namedPolicyStatus = 1;
              }
            });
          });
        });
      })
    );

    setAdditionalInsuredData(updatedValues);
    setFieldValue('additionalInsureds', updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditForAll.length, previousValues]);

  return (
    <>
      <Table
        isLoading={isLoading}
        useTableProps={{
          requirements,
          setRequirements,
          setSelectedRequirements,
          selectedRequirements,
          updateData,
          carriers,
          values,
          setFieldValue,
          touched: reactTouched,
          setTouched,
          valuesAreNotValid,
          updateSelectedRequirements,
          setEntitiesIsDirty,
        }}
        loadingPageSize={
          !Array.isArray(values.requirements) || values.requirements.length === 0 ? 3 : values.requirements.length
        }
        columns={bulkUpdateColumns}
        data={values.requirements}
        headerClasses={classes.tableHeader}
        hidePagination
        stickyHeader
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
}));
