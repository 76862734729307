export const LetterRunSourceEnums = {
  LetterRun: 1,
  OnDemandSummary: 2,
  ContactLetterRun: 3
};

export const NotificationStatusEnum = {
  Sent: 1,
  NotSent: 2,
  Failed: 3
};

export enum LetterRunStatusEnums {
  LetterRunCreated = 1,
  GeneratingLetters = 2,
  LetterGenerationCompleted = 3,
  SendingEmails = 4,
  LetterRunCompletedWithFailures = 5,
  LetterRunCompleted = 6
};
