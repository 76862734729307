import React, { FC } from 'react';

const coverageTypes = [
  { value: 1, label: 'Amount' },
  { value: 2, label: 'Evidence' },
  { value: 3, label: 'Excess' },
];

interface ICoverageTypeCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
}

export const CoverageTypeCell: FC<ICoverageTypeCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
}) => {
  return (
    <div>
      {coverageTypes.find(c => c.value === original.original.coverageType)
        ? coverageTypes.find(c => c.value === original.original.coverageType).label
        : null}
    </div>
  );
};
