import * as localForage from 'localforage';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import { screenSizes } from '../constants';


const navMenuStateStorageKey = 'nav-menu-state';

interface INavMenuStateContext {
  showMenu: boolean;
  setShowMenu(showMenu: boolean): void;
}

// Skip providing an initial context value. This works because the provider will
// provide an initial value and the hook will be called only within a child
// component of the provider.
const NavMenuStateContext = createContext(null as unknown as INavMenuStateContext);

/**
 * NOTE: MUST be used within NavMenuStateProvider.
 */
export const useNavMenuState = () => useContext(NavMenuStateContext);

/**
 * Handles loading the open/closed state of the menu. Helps prevent the menu
 * from visually flickering when a new tab is opened.
 */
export const NavMenuStateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useMedia(screenSizes.mobile);

  const [showMenu, _setShowMenu] = useState(!isMobile);

  /**
   * Set the navigation menu to open or closed and persist that position until modified.
   */
  const setShowMenu = useCallback(async (state: boolean) => {
    _setShowMenu(state);
    try {
      // Save the new position of the menu to local storage
      await localForage.setItem<boolean>(navMenuStateStorageKey, state);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to save menu position.';
      console.warn(message);
    }
  }, []);

  const navMenuState: INavMenuStateContext = useMemo(
    () => ({ showMenu, setShowMenu }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMenu]
  );

  // Load the initial state of the open/closed nav menu
  useEffect(() => {
    localForage
      .getItem<boolean>(navMenuStateStorageKey)
      .then(stored => {
        if (stored === null) return;
        _setShowMenu(stored);
      })
      .catch(() => {
        /* Skip. */
      });
  }, []);

  return <NavMenuStateContext.Provider value={navMenuState}>{children}</NavMenuStateContext.Provider>;
};
