import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { ConfirmationDialogue, ITableColumn, Page, Table } from '../../components';
import { Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddEditLinesOfCoverage from './AddEditLinesOfCoverage';
import { LinesOfCoverageFilters } from './LinesOfCoverageFilters';
// Models
import { ASC_SORT, DESC_SORT, ILineOfCoverage, ILinesOfCoverageFilters, SortOptions } from '../../types';
// Fetch
import { deleteLineOfCoverage, getLineOfCoverage, getLinesOfCoverage } from '../../fetch';
// Icons
import { Delete, Edit } from '@material-ui/icons';
// Helpers
import { screenSizes } from '../../constants';
import { colors } from '../../styles';
// hooks
import { useToastContext } from '../../hooks';

export const LinesOfCoverage = () => {
  const [linesOfCoverage, setLinesOfCoverage] = useState<ILineOfCoverage[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<ILineOfCoverage | null>(null);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [filters, setFilters] = useState<ILinesOfCoverageFilters>({});
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
  }>({
    Name: ASC_SORT,
  });
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const { showToast } = useToastContext();

  // api
  const fetchLinesOfCoverage = async () => {
    setLoading(true);

    try {
      // Filter results using user input
      let response = await getLinesOfCoverage();
      if (filters?.name) {
        response = response.filter(loc => loc.name?.toLowerCase().includes(filters.name.toLowerCase()));
      }

      // Only doing this on the frontend. If more columns are added, this will need to be updated to use BE sorting
      setLinesOfCoverage(
        sortDirection.Name === ASC_SORT
          ? response.sort((a, b) => (a.name < b.name ? -1 : 1))
          : response.sort((a, b) => (a.name > b.name ? -1 : 1))
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async id => {
    try {
      const res = await getLineOfCoverage(id);
      setSelectedRowItem(res);
      setShowAddEditModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteLineOfCoverage(id);
      await fetchLinesOfCoverage();
      showToast('success', 'Line of Coverage Deleted!');
    } catch (error) {
      showToast(
        'error',
        'We were unable to delete the line of coverage at this time. Please try again later. Please contact support if this issue continues.'
      );
    }
  };

  useEffect(() => {
    fetchLinesOfCoverage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, filters]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: 'Name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Name');
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILineOfCoverage } };
        }) => {
          return <div>{original.name}</div>;
        },
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILineOfCoverage } };
        }) => {
          return (
            <div key={original.lineOfCoverageId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={async () => {
                  await handleEdit(original.lineOfCoverageId);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedId(original.lineOfCoverageId);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Lines of Coverage'>
      {/* FILTER BAR */}
      <LinesOfCoverageFilters handleFilter={setFilters} isLoading={isLoading} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          columns={columns}
          data={linesOfCoverage}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
        />
      </div>

      {/* ADD/EDIT MODAL */}
      <AddEditLinesOfCoverage
        initialValues={selectedRowItem}
        open={showAddEditModal}
        showToast={showToast}
        onClose={() => {
          setShowAddEditModal(false);
          setTimeout(() => {
            setSelectedRowItem(null);
          }, 500);
        }}
        onSave={() => fetchLinesOfCoverage()}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedId && (
        <ConfirmationDialogue
          id={`${selectedId}`}
          title={'Delete Line of Coverage'}
          text={'Are you sure you want to delete this line of coverage?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedId);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab
        color='secondary'
        className={isMobile ? classes.mobileAddButton : classes.addButton}
        aria-label='add'
        onClick={() => setShowAddEditModal(true)}
      >
        <AddIcon />
      </Fab>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem',
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem',
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
