import { Button, CardActions, Fade, Grid, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Refresh, Save } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { deepEqual } from 'fast-equals';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { CheckboxList, Loader, Modal } from '../../components';
import { getAdditionalInsured, updateAdditionalInsuredForMonitoredEntity } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  IAdditionalInsured,
  IAdditionalInsuredForMonitoredEntity,
  ILineOfCoverage,
  INamedPolicyStatus,
} from '../../types';

interface IAddEditAdditionalInsuredProps {
  addMode: boolean;
  clientId: number;
  selectedAdditionalInsured: IAdditionalInsured;
  inUseAdditionalInsured: IAdditionalInsured[];
  inUseLinesOfCoverage: number[];
  linesOfCoverage?: ILineOfCoverage[];
  locationId?: number;
  onClose: () => void;
  open: boolean;
  reloadData: Function;
}

const AdditionalInsuredSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  linesOfCoverage: Yup.array().nullable(),
  lineOfCoverageIds: Yup.array().nullable(),
  isDefault: Yup.boolean(),
});

export const AddEditAdditionalInsured: FC<IAddEditAdditionalInsuredProps> = ({
  addMode,
  clientId,
  selectedAdditionalInsured,
  inUseAdditionalInsured,
  inUseLinesOfCoverage,
  linesOfCoverage,
  locationId,
  onClose,
  open,
  reloadData,
}) => {
  const { showToast } = useToastContext();
  const classes = useStyles();
  // @ts-ignore
  const { monitoredEntityId } = useParams();

  const [additionalInsuredNameList, setAdditionalInsuredNameList] = useState<IAdditionalInsured[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAdditionalInsuredForAutocomplete = async () => {
    if (!clientId) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await getAdditionalInsured(clientId);
      setAdditionalInsuredNameList(
        response.filter(
          additionalInsured =>
            !(inUseAdditionalInsured || []).some(
              inUseAI => inUseAI.additionalInsuredId === additionalInsured.additionalInsuredId
            )
        )
      );
    } catch (error) {
      showToast('error', 'We were unable to retrieve the additional insured names. Please refresh and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (addMode) {
      getAdditionalInsuredForAutocomplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, open]);

  // Do not render if the modal is not open and data-supplied when in edit mode.
  if (!selectedAdditionalInsured && addMode === false) return null;

  return (
    <>
      <Formik<IAdditionalInsured>
        enableReinitialize
        initialValues={{
          additionalInsuredId: 0,
          name: inUseAdditionalInsured?.[0]?.name ?? '',
          linesOfCoverage: [],
          lineOfCoverageIds: selectedAdditionalInsured?.linesOfCoverage?.map(loc => loc.lineOfCoverageId) ?? [],
          isDefault: false,
          defaultAdditionalInsuredId: 0,
          ...selectedAdditionalInsured,
        }}
        validationSchema={AdditionalInsuredSchema}
        onSubmit={(values, actions) => {
          let linesOfCoverageMapping = linesOfCoverage.map(loc => {
            if (values.lineOfCoverageIds.some(lineOfCoverageId => lineOfCoverageId === loc.lineOfCoverageId)) {
              return loc;
            }
            return null;
          });
          // Remove null/undefined
          linesOfCoverageMapping = linesOfCoverageMapping.filter(loc => loc);

          // Use the initialValue set to find the original status of any previously set lines of coverage
          let mappedLineObjectSet = linesOfCoverageMapping.map(loc => {
            const initialValueData =
              selectedAdditionalInsured &&
              selectedAdditionalInsured.linesOfCoverage?.find(line => line.lineOfCoverageId === loc.lineOfCoverageId);

            // Build the object with the found status, otherwise default status to missing
            if (initialValueData) {
              return {
                lineOfCoverageId: loc.lineOfCoverageId,
                name: loc.name,
                namedPolicyStatus: initialValueData.namedPolicyStatus,
              };
            } else {
              return {
                lineOfCoverageId: loc.lineOfCoverageId,
                name: loc.name,
                namedPolicyStatus: INamedPolicyStatus.Missing,
              };
            }
          });

          const additionalInsured = {
            monitoredEntityId: Number(monitoredEntityId),
            additionalInsureds: [
              {
                additionalInsuredId: values.additionalInsuredId,
                defaultAdditionalInsuredId: values.defaultAdditionalInsuredId,
                name: values.name,
                linesOfCoverage: mappedLineObjectSet,
                isDefault: values.isDefault,
                clientId: clientId,
                locationIds: locationId ? [locationId] : [],
              },
            ],
          } as IAdditionalInsuredForMonitoredEntity;

          // Update the AIs and refresh the available AIs for the autocomplete
          Promise.all([
            updateAdditionalInsuredForMonitoredEntity(additionalInsured),
            getAdditionalInsuredForAutocomplete(),
          ]);

          reloadData();
          onClose();
          actions.resetForm();
          showToast('success', `Successfully ${addMode ? 'added' : 'edited'} the additional insured!`);
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, handleSubmit, dirty, touched, errors }) => {
          return (
            <Modal
              maxWidth={'sm'}
              open={open}
              title={`${addMode ? 'Add' : 'Edit'} Required Additional Insured`}
              customButtonIcon={<Refresh />}
              customButtonHandler={() => getAdditionalInsuredForAutocomplete()}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isLoading && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Grid container direction={'column'}>
                    {/* ADDITIONAL INSURED NAME */}
                    <Grid item xs={12}>
                      <Autocomplete
                        id='additional-insured-name'
                        options={additionalInsuredNameList}
                        disabled={addMode === false}
                        getOptionLabel={option => option.name}
                        onChange={(_event, item: IAdditionalInsured) => {
                          if (item?.additionalInsuredId && item?.name) {
                            setFieldValue('additionalInsuredId', item.additionalInsuredId);
                            setFieldValue('name', item.name);
                            setFieldValue('isDefault', item?.isDefault);
                          }
                        }}
                        onInputChange={(_event, value) => setFieldValue('name', value)}
                        renderInput={params => (
                          <TextField
                            {...(addMode ? params : {})}
                            fullWidth
                            required
                            disabled={addMode === false}
                            value={addMode === false ? selectedAdditionalInsured.name : values.name}
                            ref={params.InputProps.ref}
                            InputProps={params.InputProps}
                            label='Additional Insured Name'
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        )}
                      />
                    </Grid>

                    {/* LINES OF COVERAGE CHECKLIST */}
                    <Grid item xs={12} className={classes.checkListContainer}>
                      <CheckboxList
                        header={'Lines of Coverage*'}
                        saveCheckboxSelectionToState={selectedLinesOfCoverage =>
                          setFieldValue('lineOfCoverageIds', selectedLinesOfCoverage)
                        }
                        checkboxItems={linesOfCoverage
                          .filter(line => inUseLinesOfCoverage.includes(line.lineOfCoverageId))
                          .map(lineOfCoverage => {
                            return { id: lineOfCoverage.lineOfCoverageId, name: lineOfCoverage.name };
                          })}
                        checkedValuesList={values.lineOfCoverageIds}
                        enableSelectAll
                      />
                    </Grid>
                  </Grid>

                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              onClose();
                            } else {
                              return;
                            }
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        disabled={
                          !dirty ||
                          isSubmitting ||
                          (!values.additionalInsuredId && addMode) ||
                          !values.lineOfCoverageIds?.length
                        }
                        type='submit'
                        startIcon={<Save />}
                        variant='contained'
                        color='primary'
                      >
                        {'Save'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  checkListContainer: {
    marginTop: theme.spacing(1),
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));
