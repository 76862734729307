import axios from 'axios';

import { authFetch } from './';
import { IError, ICarrier, ICarrierGridRes, IEnum } from '../types';

const baseUrl = '/api/Carrier';

export const getCarrier = async (id: number): Promise<ICarrier> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting carrier. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteCarrier = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting carrier. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateCarrier = async (carrier: ICarrier): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, carrier);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating carrier. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createCarrier = async (carrier: ICarrier): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Create`, carrier);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating carrier. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getCarriers = async (filters?: {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  name?: string;
  bestNumber?: string;
  bestRating?: string;
}): Promise<ICarrierGridRes> => {
  let query = '';
  if (filters) {
    query = `?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`;

    if (filters.name) {
      query += `&name=${filters.name}`;
    }
    if (filters.bestNumber) {
      query += `&bestNumber=${filters.bestNumber}`;
    }
    if (filters.bestRating) {
      query += `&bestRating=${filters.bestRating}`;
    }
  }

  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return {
    records: [],
    totalRecordCount: 0
  };
};

export const getCarriersDropdown = async (): Promise<Omit<IEnum, 'description'>[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/Lookup`);

    if (res.status === 200) {
      return res.data;
    }

    const error = { Detail: 'Error getting carriers. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
}

/**
 * Handles GETting carrier dropdown data via search query.
 */
export const getCarriersBySearch = async (searchTerm: string): Promise<Omit<IEnum, 'description'>[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/Lookup/${searchTerm}`);

    if (res?.status !== 200) {
      throw Error(res.statusText);
    }

    if (res?.data) {
      return res.data;
    }

  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.toJSON());
    }
  }
}
