import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Page } from '../../components';
import { Fab, IconButton } from '@material-ui/core';
import { Table, ITableColumn, Pagination, Toast, ConfirmationDialogue } from '../../components';
import AddEditLocationTypes from './AddEditLocationTypes';
import { ManageLocationTypesFilters } from './ManageLocationTypesFilters';
// Icons
import AddIcon from '@material-ui/icons/Add';
// Fetch
import { getLocationTypes, getLocationType, deleteLocationType } from '../../fetch';
import { Edit, Delete } from '@material-ui/icons';
// Helpers
import { colors } from '../../styles';
import { screenSizes } from '../../constants';
// Models
import { SortOptions, DESC_SORT, ASC_SORT, ILocationType, ILocationTypeGrid, IManageLocationTypesFilters } from '../../types';
// hooks
import { useToastContext } from '../../hooks';

export const ManageLocationTypes = () => {
  const [locationTypes, setLocationTypes] = useState<ILocationTypeGrid[]>([]);
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>('Description');
  const [showLocationTypeModal, setShowLocationTypeModal] = useState<boolean>(false);
  const [currentLocationType, setCurrentLocationType] = useState<ILocationType | null>(null);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [filters, setFilters] = useState<IManageLocationTypesFilters>({});
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<{
    Description?: SortOptions;
  }>({
    Description: ASC_SORT
  });
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const { showToast } = useToastContext();

  // api
  const fetchLocationTypes = async () => {
    setLoading(true);

    try {
      const res = await getLocationTypes({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        description: filters.description
      });
      setLocationTypes(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async id => {
    try {
      const res = await getLocationType(id);
      setCurrentLocationType(res);
      setShowLocationTypeModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteLocationType(id);
      fetchLocationTypes();
      showSuccess(true);
    } catch (error) {
      console.log(error);
      showError(true);
    }
  };

  useEffect(() => {
    fetchLocationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters]);

  const hasData = locationTypes && locationTypes.length > 0;

  const columns = useMemo(() => {
    return [
      {
        id: 'description',
        Header: 'Description',
        isServerSorted: selectedSort === 'Description',
        isServerSortedDesc: sortDirection.Description === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Description');
          setSortDirection({
            ...sortDirection,
            Description: sortDirection.Description === ASC_SORT ? DESC_SORT : ASC_SORT
          });
        },
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ILocationTypeGrid } };
        }) => <div>{original.description}</div>
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ILocationTypeGrid } };
        }) => {
          return (
            <div key={original.locationTypeId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={async () => {
                  await handleEdit(original.locationTypeId);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedId(original.locationTypeId);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      }
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Manage Location Types'>
      {/* FILTER BAR */}
      <ManageLocationTypesFilters handleFilter={setFilters} isLoading={isLoading} />

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table hidePagination columns={columns} data={locationTypes} isLoading={isLoading} headerClasses={classes.tableHeader} stickyHeader />
        {!isLoading && hasData && <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />}
      </div>

      {/* ADD/EDIT MODAL */}
      <AddEditLocationTypes
        initialValues={currentLocationType}
        open={showLocationTypeModal}
        showToast={showToast}
        onClose={() => {
          setShowLocationTypeModal(false);
          setCurrentLocationType(null);
        }}
        onSave={() => fetchLocationTypes()}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedId && (
        <ConfirmationDialogue
          id={`${selectedId}`}
          title={'Delete Location Type'}
          text={'Are you sure you want to delete this location type?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedId);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab color='secondary' className={isMobile ? classes.mobileAddButton : classes.addButton} aria-label='add' onClick={() => setShowLocationTypeModal(true)}>
        <AddIcon />
      </Fab>

      {/* SUCCESS/FAIL MESSAGING */}
      <Toast id='location-type-success' message={`Location Type Deleted!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='location-type-error'
        autoHideDuration={6000}
        message={'We were unable to delete the location type at this time. Please try again later. Please contact support if this issue continues.'}
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      color: colors.primary.navyBlue,
      fontWeight: 600,
      marginBottom: '56px'
    },
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden'
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem'
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem'
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5)
      }
    }
  };
});
