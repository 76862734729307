import { createContext, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarProps } from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../styles';
import { useLocation } from 'react-router-dom';
import { logError } from '../services';

const DEFAULT_DURATION = 4000;

// helpful example
//
// import useToastContext from '../hooks/useToastContext';
// ...
// const { showToast, hideToast } = useToastContext();
// ...
// show a success toast with 'Good Job!' message for 8 seconds, then run log
// showToast('success', 'Good Job!', 8000, () => console.log('banana'));
// ...
// programatically kill the toast if desired
// hideToast();

interface IToastConfig extends SnackbarProps {
  variant: 'error' | 'info' | 'success' | 'warning';
  message: string;
  customDuration?: number;
  showUntilClose?: boolean;
  onClose?: () => void;
}

type ToastContextType = {
  toastConfig: IToastConfig | null;
  showToast: (
    type: IToastConfig['variant'],
    message: string,
    customDuration?: number,
    showUntilClose?: boolean,
    onClose?: () => void
  ) => void;
  hideToast: () => void;
};

// default toastContext
export const ToastContext = createContext<ToastContextType | null>(null);

export const ToastContextProvider = ({ children }) => {
  const classes = useStyles();
  // control the toast
  // toastConfig is null by default - toast is hidden
  const [toastConfig, setToastConfig] = useState<IToastConfig | null>(null);
  const location = useLocation();
  // make toast
  const showToast = (
    variant: 'error' | 'info' | 'success' | 'warning',
    message: string,
    customDuration?: number,
    showUntilClose?: boolean,

    onClose?: () => void
  ) => {
    if (variant === 'error') {
      logError({ Location: location.pathname }, message);
      showUntilClose = true;
    }
    setToastConfig({ variant, message, customDuration, showUntilClose, onClose });
  };

  // trash the toast
  const hideToast = () => {
    // run callback if it exists
    if (toastConfig.onClose) {
      toastConfig.onClose();
    }
    setToastConfig(null);
  };

  return (
    <ToastContext.Provider value={{ toastConfig, showToast, hideToast }}>
      {children}
      {toastConfig && (
        <Snackbar
          key={toastConfig.message}
          style={{ bottom: '68px' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          autoHideDuration={toastConfig.showUntilClose ? null : toastConfig.customDuration || DEFAULT_DURATION}
          onClose={() => hideToast()}
          open={true}
        >
          <Alert
            classes={{ filledInfo: classes.info }}
            onClose={() => hideToast()}
            severity={toastConfig.variant}
            variant='filled'
          >
            {toastConfig.message}
          </Alert>
        </Snackbar>
      )}
    </ToastContext.Provider>
  );
};
const useStyles = makeStyles((theme: Theme) => {
  return {
    info: {
      backgroundColor: colors.primary.navyBlue,
    },
  };
});
