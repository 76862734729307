import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMedia } from 'react-use';
import { deepEqual } from 'fast-equals';
import { Formik, Form } from 'formik';
import {
  Fade,
  CardActions,
  Button,
  TextField,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Typography,
} from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { Modal, Loader } from '../../components';
// styles
import { colors } from '../../styles';
// constants
import { screenSizes } from '../../constants';
// types
import { IClient, IMonitoredEntity, IPortfolio } from '../../types';
// fetch
import { getClientMonitoredEntityLookup, updatePortfolio } from '../../fetch';

interface IAddEditPortfolioProps extends Pick<IClient, 'entityType' | 'useLocations' | 'clientId' | 'locations'> {
  open: boolean;
  onClose: () => void;
  onSave: (values: IPortfolio[]) => void;
  selectedRowItem: IPortfolio | null;
  showToast: (type: string, message: string) => void;
}

const PortfolioSchema = Yup.object().shape({
  portfolioId: Yup.number().nullable(),
  name: Yup.string().required('Required'),
  locationsIds: Yup.array(),
  entityIds: Yup.array(),
});

export const AddEditPortfolio: FC<IAddEditPortfolioProps> = ({
  open,
  onClose,
  useLocations,
  selectedRowItem,
  onSave,
  locations,
  clientId,
  entityType,
  showToast,
}) => {
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();

  const [entities, setEntities] = useState<Pick<IMonitoredEntity, 'name' | 'entityId'>[]>([]);

  useEffect(() => {
    fetchEntitiesOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchEntitiesOnMount() {
    try {
      const response = await getClientMonitoredEntityLookup(clientId);
      setEntities(response.map(_ => ({ entityId: _.value, name: _.text })));
    } catch (error) {
      showToast('error', error instanceof Error ? error.message : 'Failed to fetch modal data.');
    }
  }

  const handleCheckboxClick = (idList, value, setFieldValue, field) => () => {
    const currentIndex = idList.indexOf(value);
    const newChecked = [...idList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFieldValue(field, newChecked);
  };

  const header = useLocations ? 'Locations' : entityType;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          portfolioId: selectedRowItem?.portfolioId ?? null,
          clientCertificateHolderId: 0,
          name: '',
          locationIds: [],
          entityIds: [],
          ...selectedRowItem,
        }}
        validationSchema={PortfolioSchema}
        onSubmit={async (values, actions) => {
          try {
            const updated: IPortfolio = {
              portfolioId: values.portfolioId,
              clientCertificateHolderId: values.clientCertificateHolderId,
              name: values.name,
              locationIds: values.locationIds,
              entityIds: values.entityIds,
              // @ts-ignore
              clientId: clientId,
            };

            const response = await updatePortfolio(updated);
            showToast('success', values.portfolioId ? 'Portfolio Updated' : 'Portfolio Created!');
            onSave(response);
            actions.resetForm();
          } catch (error) {
            showToast(
              'error',
              'We were unable to create the portfolio at this time. Please try again later. Please contact support if this issue continues.'
            );
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              maxWidth={'sm'}
              open={open}
              title={selectedRowItem ? 'Edit Portfolio' : 'Add New Portfolio'}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <div className={isMobile ? classes.mobileContent : classes.content}>
                    <div className={classes.formColumn}>
                      {/* PORTFOLIO NAME */}
                      <TextField
                        fullWidth
                        required
                        autoComplete='nope'
                        label='Portfolio Name'
                        name='name'
                        value={values.name}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />

                      {/* MULTI-SELECT LIST */}
                      <Typography variant='h6'>{header}</Typography>

                      {/* EMPTY STATE */}
                      {locations.length === 0 && entities.length === 0 && (
                        <Typography>
                          A {header.slice(0, header.length - 1).toLowerCase()} has not been added yet.
                        </Typography>
                      )}

                      {/* CHECK-BOX LIST */}
                      <List className={classes.listContainer}>
                        {useLocations
                          ? locations.map(location => (
                              <ListItem
                                key={location.locationId}
                                role={undefined}
                                dense
                                button
                                onClick={handleCheckboxClick(
                                  values.locationIds,
                                  location.locationId,
                                  setFieldValue,
                                  'locationIds'
                                )}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={values.locationIds.indexOf(location.locationId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': location.name }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={location.name} primary={location.name} />
                              </ListItem>
                            ))
                          : entities.map(entity => (
                              <ListItem
                                key={entity.entityId}
                                role={undefined}
                                dense
                                button
                                onClick={handleCheckboxClick(
                                  values.entityIds,
                                  entity.entityId,
                                  setFieldValue,
                                  'entityIds'
                                )}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={values.entityIds.indexOf(entity.entityId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': entity.name }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={entity.name} primary={entity.name} />
                              </ListItem>
                            ))}
                      </List>
                    </div>
                  </div>

                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              onClose();
                            } else {
                              return;
                            }
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid}
                        type='submit'
                        startIcon={selectedRowItem ? <Check /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {selectedRowItem ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px',
  },
  formTextField: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: '31px',
    display: 'flex',
  },
  mobileContent: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
  menuOptions: {
    color: theme.palette.common.black,
  },
  filter: {
    marginRight: '14px',
    marginBottom: '37px',
  },
  columnPadding: {
    paddingRight: theme.spacing(1),
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toggle: {
    marginBottom: theme.spacing(1),
  },
  listContainer: {
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}));
