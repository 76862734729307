import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useMedia } from 'react-use';
// Components
import { Grid, InputBase, Popper, Theme, Tooltip, Button, IconButton } from '@material-ui/core';
import { Loader } from '../loader';
import { Autocomplete } from '@material-ui/lab';
// Icons
import { FilterList, Clear } from '@material-ui/icons';
// Constants
import { screenSizes, userRoles } from '../../constants';
// Types
import { IClientSelector, IClient } from '../../types';
// Fetch
import { getClientsForSelector } from '../../fetch';
// Helpers
import { UserContext, ClientSelectorContext } from '../../context';

export const ClientSelector = () => {
  // Context
  const { userContext, isFetching } = useContext(UserContext);
  const { clientSelectorContext, setClientSelectorContext } = useContext(ClientSelectorContext);

  // State
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClientSelector | undefined>(undefined);

  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    setLoading(true);

    getClientsForSelector()
      .then(data => {
        setClients(data);
      })
      .finally(() => {
        setLoading(false);
      });

    // If a selection has been made in this session, get it
    setSelectedClient(clientSelectorContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if user is an external user and assigned a client we need to set it
  useEffect(() => {
    if (userContext && userContext.role === userRoles.EXTERNAL && !isFetching && clients.length === 1) {
      setSelectedClient(clients[0]);
      setClientSelectorContext(clients[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, isFetching, clients]);

  return (
    <Grid container alignContent='center' className={classes.switcherWrapper}>
      {/* DROPDOWN BUTTON */}
      <Tooltip arrow placement='top' title={''}>
        <span className={classes.selectButtonWrapper}>
          <Button
            size='medium'
            className={clsx(
              classes.headerButton,
              classes.filterButton,
              isLoading || (!isFetching && userContext.role === userRoles.EXTERNAL) ? classes.disabledButton : ''
            )}
            disableRipple
            // lock down if loading or if we have an external user
            disabled={isLoading || (!isFetching && userContext.role === userRoles.EXTERNAL)}
            aria-describedby='searchable-dropdown'
            onClick={e => {
              setAnchorEl(e.currentTarget);
            }}
          >
            {/** LOADING INDICATOR */}
            {isLoading ? (
              <Loader size='medium' position='centered' />
            ) : (
              <div className={classes.buttonText}>
                <FilterList className={classes.iconWhite} />
                <span className={classes.buttonText}>{selectedClient?.name ?? 'Select Client'}</span>
              </div>
            )}

            {/* CLEAR BUTTON */}
            {selectedClient?.name && (
              <IconButton
                className={classes.clearButton}
                onClick={event => {
                  // Avoid clicking the parent link
                  event.stopPropagation();
                  setClientSelectorContext(undefined);
                  setSelectedClient(undefined);
                }}
              >
                <Clear />
              </IconButton>
            )}
          </Button>
        </span>
      </Tooltip>

      {/* SEARCHABLE MENU */}
      <Popper
        id={isOpen ? 'searchable-dropdown' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
        className={`${classes.popper} ${isMobile ? classes.mobilePopperWidth : classes.popperWidth}`}
      >
        <Autocomplete
          id='searchable-dropdown'
          open
          onClose={() => {
            anchorEl?.focus?.();
            setAnchorEl(null);
          }}
          options={clients}
          getOptionLabel={option => option.name}
          noOptionsText='No Clients'
          classes={{ option: classes.option }}
          onChange={(e, item: IClient) => {
            setClientSelectorContext(item);
            setSelectedClient(item);
          }}
          renderInput={params => (
            <InputBase
              className={classes.inputBase}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              placeholder='Find clients...'
            />
          )}
        />
      </Popper>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  switcherWrapper: {
    width: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  selectButtonWrapper: {
    width: '100%'
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    zIndex: 10,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa'
  },
  mobilePopperWidth: {
    width: '250px'
  },
  popperWidth: {
    width: '400px'
  },
  option: {
    color: theme.palette.primary.main,
    '&[aria-selected="true"]': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main
    },
    '&[data-focus="true"]': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main
    }
  },
  headerButton: {
    color: theme.palette.common.white,
    display: 'flex',
    height: '45px',
    marginBottom: 'auto',
    marginTop: 'auto',
    justifyContent: 'space-between'
  },
  filterButton: {
    backgroundColor: '#CE626F',
    '&:hover': {
      backgroundColor: darken('#CE626F', 0.4)
    },
    marginRight: '1rem',
    '& circle': {
      color: `${theme.palette.common.white} !important`
    },
    '& p': {
      color: `${theme.palette.common.white} !important`
    }
  },
  disabledButton: {
    backgroundColor: lighten('#CE626F', 0.4),
    cursor: 'not-allowed'
  },
  iconWhite: {
    fill: theme.palette.common.white,
    marginRight: theme.spacing(0.5)
  },
  buttonText: {
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: 'normal',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    display: 'flex'
  },
  clearButton: {
    color: theme.palette.common.white
  }
}));
