import axios, { AxiosRequestConfig, Method } from 'axios';
import { logInfo, logError } from '../services';

let buildConfig = require('../buildSettings.json');

const axiosInstance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

export {axiosInstance};

const axiosRequest = async (method: Method, url: string, data: object | null, options: object) => {
  logInfo(null, `axios.js request started: ${url}`);
  try {
    return await axiosInstance({
      method,
      url,
      ...(data ? { data } : {}),
      ...options
    });
  } catch (err) {
    logError(err, 'axios.js request failed');
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (url: string, options: AxiosRequestConfig = {}) => {
    return await axiosRequest('get', url, null, { ...options });
  },
  post: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('post', url, data, { ...options });
  },
  put: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('put', url, data, { ...options });
  },
  delete: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('delete', url, data, { ...options });
  }
};
