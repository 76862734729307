import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import { debounce, uniqBy, sortBy, flow } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import { IOptionType, Select } from '../../components';
import { screenSizes } from '../../constants';
import { IContact, IContactGroup, INewLetterRunFilters } from '../../types';

const organizeContactsForContactNameFilter = flow(
  (array: IContact[]) => uniqBy(array, 'name'),
  uniq => sortBy(uniq, 'name', 'asc')
);

const organizeContactsForContactGroupFilter = flow(
  (array: IContact[]) => uniqBy(array, _ => _.name + _.contactGroups?.[0]?.name ?? ''),
  uniq => sortBy(uniq, 'name', 'asc')
);

interface INewLetterRunFiltersProps {
  handleFilter: (filters: INewLetterRunFilters) => void;
  isLoading: boolean;
  contacts: IContact[];
}

export const NewLetterRunFilters: FC<INewLetterRunFiltersProps> = ({ handleFilter, isLoading, contacts }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const isTablet = useMedia(screenSizes.tablet);

  const [contactName, setContactName] = useState<string>('');
  const [contactId, setContactId] = useState<number | null>(null);
  const [contactGroupName, setContactGroupName] = useState<string>('');
  const [contactGroupId, setContactGroupId] = useState<number | null>(null);
  const [monitoredEntityName, setMonitoredEntityName] = useState<string>('');
  const [unitNumber, setUnitNumber] = useState<string>('');

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce((contactFilter, contactGroupFilter, monitoredEntityFilter, unitNumberFilter) => {
      handleFilter({
        contactName: contactFilter,
        contactGroupName: contactGroupFilter,
        monitoredEntityName: monitoredEntityFilter,
        unitNumber: unitNumberFilter,
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(contactName, contactGroupName, monitoredEntityName, unitNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactName, contactGroupName, monitoredEntityName, unitNumber]);

  const resetFilters = () => {
    setContactName('');
    setContactId(null);
    setContactGroupId(null);
    setContactGroupName('');
    setMonitoredEntityName('');
    setUnitNumber('');
    filterCallback('', '', '', '');
  };

  useEffect(() => {
    if (contacts.length < 1) {
      resetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  // If ContactName filter exists, filter contacts by that chosen contactId
  const filteredContacts = useMemo(() => {
    if (contactId === null) return contacts;
    return contacts.filter(_ => _.contactId === contactId);
  }, [contactId, contacts]);

  return (
    <div className={!isMobile ? classes.filterBarContainer : ''}>
      {/* CONTACT NAME */}
      <Select
        InputLabelProps={{ shrink: !!contactId }}
        className={`${classes.filter} ${classes.select}`}
        label='Contact Name'
        name='contactNameSelector'
        id='contactNameSelector'
        value={contactId ?? ''}
        autoWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value) return;
          setContactId(parseInt(e.target.value));
          setContactName(contacts.find(contact => contact.contactId === parseInt(e.target.value))?.name);

          // Clear the Contact Group filter
          if (contactGroupId) {
            setContactGroupName('');
            setContactGroupId(null);
          }
        }}
        options={
          contacts.length === 0
            ? [{ key: null, label: 'No Contacts found.', value: '' }]
            : organizeContactsForContactNameFilter(contacts).map(
                (_: IContact): IOptionType => ({ key: _.contactId, label: _.name, value: _.contactId })
              )
        }
        customClearFunction={() => {
          setContactName('');
          setContactId(null);
        }}
      />

      {/* CONTACT GROUP NAME */}
      <Select
        InputLabelProps={{ shrink: !!contactGroupId }}
        // Contact group filter is disabled until the user filters by Contact Name
        disabled={contactId === null || contactName === ''}
        label='Contact Group'
        className={`${classes.filter} ${classes.select}`}
        name='contactGroupNameSelector'
        id='contactGroupNameSelector'
        value={contactGroupId ?? ''}
        autoWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value) return;
          const newContactGroupId = parseInt(e.target.value, 10);
          setContactGroupId(newContactGroupId);

          // Given we have newContactGroupId, get the name of this contact group for filtering
          const newContactGroupName = filteredContacts
            .find(contact => contact.contactGroups.some(cg => cg.contactGroupId === newContactGroupId))
            ?.contactGroups?.find(contactGroup => contactGroup.contactGroupId === newContactGroupId)?.name;
          if (newContactGroupName) setContactGroupName(newContactGroupName);
        }}
        options={
          filteredContacts.length === 0
            ? [{ key: null, label: 'No Contact Groups found.', value: '' }]
            : organizeContactsForContactGroupFilter(filteredContacts)
                .flatMap(_ => _.contactGroups)
                .map(
                  (_: IContactGroup): IOptionType => ({ key: _.contactGroupId, label: _.name, value: _.contactGroupId })
                )
        }
        customClearFunction={() => {
          setContactGroupName('');
          setContactGroupId(null);
        }}
      />

      {/* ENTITY NAME */}
      <TextField
        placeholder='Entity Name'
        className={classes.filter}
        value={monitoredEntityName}
        onChange={({ target: { value } }) => setMonitoredEntityName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!monitoredEntityName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setMonitoredEntityName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder='Store #'
        className={classes.filter}
        value={unitNumber}
        onChange={({ target: { value } }) => setUnitNumber(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!unitNumber && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setUnitNumber('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      {(!!contactName || !!contactGroupName || !!monitoredEntityName || !!unitNumber) && (
        <Button
          startIcon={<Clear />}
          className={isMobile || isTablet ? classes.mobileResetButton : classes.resetButton}
          onClick={() => {
            resetFilters();
          }}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    filter: {
      marginRight: theme.spacing(1),
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer',
    },
    notifications: {
      marginRight: theme.spacing(1),
      width: '175px',
    },
    select: {
      width: theme.spacing(10),
    },
  };
});
