import React, { FC, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { isBefore } from 'date-fns';
// components
import { Select } from '../../../components';
// types
import { IAdditionalInsuredForMonitoredEntity, IEntityRequirement, IEnum, ILineOfCoverage } from '../../../types';

interface INamedPolicyStatusCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  updateAdditionalInsuredData: (
    columnId: string,
    value: any,
    original: IEntityRequirement,
    values: any,
    setFieldValue: Function
  ) => void;
  linesOfCoverage: ILineOfCoverage[];
  requirements: IEntityRequirement[];
  namedPolicyStatusEnums: IEnum[];
  values: any;
  setFieldValue: Function;
  disabled: boolean;
  selectedCreditForAll?: number[];
  additionalInsuredData: IAdditionalInsuredForMonitoredEntity[];
  copyAdditionalInsuredData: IAdditionalInsuredForMonitoredEntity[];
  updateCopyAdditionalInsuredData: (
    columnId: string,
    value: any,
    original: IEntityRequirement,
    values: any,
    setFieldValue: Function
  ) => void;
  previousValues: any;
  setPreviousValues?: (val: any) => void;
}

export const NamedPolicyStatusCell: FC<INamedPolicyStatusCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateAdditionalInsuredData,
  linesOfCoverage,
  requirements,
  namedPolicyStatusEnums,
  values,
  setFieldValue,
  disabled,
  selectedCreditForAll,
  additionalInsuredData,
  copyAdditionalInsuredData,
  updateCopyAdditionalInsuredData,
  previousValues,
  setPreviousValues,
}) => {
  const classes = useStyles();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.linesOfCoverage);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.linesOfCoverage);
  }, [original.original.linesOfCoverage]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.linesOfCoverage) {
      updateAdditionalInsuredData('linesOfCoverage', value, original.original, values, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getMatchingLinesOfCoverage = additionalInsuredEntity => {
    let coveredPoliciesList = [];
    if (
      additionalInsuredEntity &&
      additionalInsuredEntity.linesOfCoverage &&
      additionalInsuredEntity.linesOfCoverage.length > 0
    ) {
      // Find the matching lines of coverage the insured entity has selected for it
      coveredPoliciesList = additionalInsuredEntity.linesOfCoverage.filter((line: ILineOfCoverage) => {
        const matchingLOC = linesOfCoverage?.find(loc => loc.lineOfCoverageId === line.lineOfCoverageId);

        // The linesOfCoverage have the name we need to display, so find the matching row and add the name
        // to our return object
        if (matchingLOC) {
          line.name = matchingLOC.name;
          return matchingLOC;
        }
        return null;
      });
    }

    return coveredPoliciesList.filter(loc => loc);
  };

  const getMatchingRequirement = lineOfCoverage => {
    return requirements.find(requirement => lineOfCoverage.lineOfCoverageId === requirement.lineOfCoverageId);
  };

  const isExpired = coveredPolicy => {
    const matchingRequirement = getMatchingRequirement(coveredPolicy);

    return (
      matchingRequirement &&
      matchingRequirement.expirationDate &&
      isBefore(new Date(matchingRequirement.expirationDate.split('T')[0]), new Date())
    );
  };

  const setLineOfCoverageStatus = (coveredPoliciesList, coveredPolicy, value) => {
    coveredPolicy.namedPolicyStatus = value;

    const index = coveredPoliciesList.indexOf(coveredPolicy);
    coveredPoliciesList[index] = coveredPolicy;
    setValue(coveredPoliciesList);
  };

  const getStatusOptions = coveredPolicy => {
    const mappedStatuses = namedPolicyStatusEnums.map(c => ({ value: c.value, label: c.description, key: value }));

    // Add this to our menu options so we can display to the user 'Expired' in our select component, even though they can't pick it
    if (isExpired(coveredPolicy)) {
      mappedStatuses.push({ value: 4, label: 'Expired', key: 4 });
    }

    return mappedStatuses;
  };

  const getCoveredPolicyValue = (coveredPolicy: any) => {
    const allLOCs = additionalInsuredData.flatMap(addIns =>
      addIns.additionalInsureds.flatMap(additional => additional.linesOfCoverage)
    );
    let previous;
    if (previousValues && previousValues.length > 0) {
      previous = previousValues.find(
        p => p.addInId === coveredPolicy?.additionalInsuredId && p.locId === coveredPolicy?.lineOfCoverageId
      )?.status;
    }
    const namedPolicyStatus = !selectedCreditForAll.includes(coveredPolicy.lineOfCoverageId)
      ? allLOCs.find(
          loc =>
            loc.lineOfCoverageId === coveredPolicy.lineOfCoverageId &&
            coveredPolicy.additionalInsuredRequirementStatusId === loc.additionalInsuredRequirementStatusId
        )?.namedPolicyStatus
      : coveredPolicy?.namedPolicyStatus;

    if (isExpired(coveredPolicy)) {
      // 4 is "Expired" and doesn't actually exist
      return 4;
    } else if (isCreditForAllSelected(coveredPolicy) && coveredPolicy?.namedPolicyStatus !== 1) {
      //  is "Compliant" and doesn't actually exist
      setLineOfCoverageStatus(getMatchingLinesOfCoverage(original.original), coveredPolicy, 1);
      return 1;
    } else {
      if (
        !isCreditForAllSelected(coveredPolicy) &&
        previous.status &&
        previous.status !== namedPolicyStatus &&
        previous.requirementId === coveredPolicy.requirementId
      ) {
        setTimeout(() => {
          setLineOfCoverageStatus(getMatchingLinesOfCoverage(original.original), coveredPolicy, previous.status);
        }, 400);

        return previous.status;
      }

      return namedPolicyStatus;
    }
  };

  const isCreditForAllSelected = (coveredPolicy: any) => {
    const matchingRequirement = getMatchingRequirement(coveredPolicy);
    if (selectedCreditForAll.includes(matchingRequirement?.lineOfCoverageId)) {
      return true;
    }
    return false;
  };

  const getCoveredPoliciesForEditing = additionalInsuredEntity => {
    const coveredPoliciesList = getMatchingLinesOfCoverage(additionalInsuredEntity);

    return coveredPoliciesList.map((coveredPolicy, index) => {
      return (
        <div key={`${coveredPolicy.lineOfCoverageId}_${index}`} className={classes.policyContainer}>
          <Select
            showReset={false}
            disabled={disabled || isExpired(coveredPolicy) || isCreditForAllSelected(coveredPolicy)}
            name='linesOfCoverage'
            id={`${coveredPolicy.lineOfCoverageId}_${index}`}
            className={classes.statusDropdown}
            value={getCoveredPolicyValue(coveredPolicy)}
            options={getStatusOptions(coveredPolicy)}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setLineOfCoverageStatus(
                coveredPoliciesList,
                coveredPolicy,
                event.target.value ? event.target.value : null
              );
              const prev = [...previousValues];
              prev.find(
                p => p.addInId === coveredPolicy?.additionalInsuredId && p.locId === coveredPolicy?.lineOfCoverageId
              ).status = event.target.value;
              setPreviousValues(prev);
              if (updateCopyAdditionalInsuredData) {
                updateCopyAdditionalInsuredData('linesOfCoverage', value, original.original, values, setFieldValue);
              }
            }}
          />
          <Typography>{coveredPolicy?.name}</Typography>
        </div>
      );
    });
  };

  return <>{getCoveredPoliciesForEditing(original.original)}</>;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    policyContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
    missingIcon: {
      color: theme.palette.error.main,
    },
    incorrectlyIcon: {
      color: theme.palette.warning.main,
    },
    alarmIcon: {
      color: theme.palette.error.main,
    },
    statusDropdown: {
      width: theme.spacing(10),
      marginRight: theme.spacing(1),
    },
  };
});
