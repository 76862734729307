import { Button, CardActions, Checkbox, Fade, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Check } from '@material-ui/icons';
import { deepEqual } from 'fast-equals';
import { Form, FormikProps, withFormik } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';

import { CheckboxList, Loader, Modal } from '../../components';
import { updateAdditionalInsured } from '../../fetch';
import { colors } from '../../styles';
import { IAdditionalInsured, IClient, ILineOfCoverage } from '../../types';

interface IAddEditAdditionalInsuredProps extends Pick<IClient, 'entityType' | 'locations' | 'clientId'> {
  addMode: boolean;
  initialValues: IAdditionalInsured;
  linesOfCoverage: ILineOfCoverage[];
  onClose: () => void;
  onSave: (values: IAdditionalInsured[]) => void;
  open: boolean;
  showToast: (type: string, message: string) => void;
  useLocations: boolean;
}

const AddEditAdditionalInsured: FC<IAddEditAdditionalInsuredProps & FormikProps<IAdditionalInsured>> = ({
  open,
  onClose,
  useLocations,
  locations,
  linesOfCoverage,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur,
  addMode,
  entityType
}) => {
  const classes = useStyles();
  const removedLinesOfCoverage = initialValues.lineOfCoverageIds.filter(x => !values.lineOfCoverageIds.includes(x))

  return (
    <Modal
      maxWidth='md'
      open={open}
      title={values.additionalInsuredId ? 'Edit Additional Insured' : 'Add New Additional Insured'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <Grid container justify='space-between'>
            {/** LEFT SIDE */}
            <Grid item container xs={12} md={6} direction='column' spacing={1}>
              {/* ADDITIONAL INSURED NAME */}
              <Grid item>
                <TextField
                  fullWidth
                  required
                  autoComplete='nope'
                  label='Additional Insured Name'
                  name='name'
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('name', e.target.value)}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              {/** CHECKBOX - APPLY CHANGES TO ALL EXISTING ENTITIES */}
              {addMode ? (
                // ADD - ALL MONITORED ENTITIES, only displayed if "Default" checkbox is checked.
                values.isDefault && (
                  <>
                    <Grid item>
                      <FormControlLabel
                        label={`Apply changes to existing ${entityType}`}
                        control={<Checkbox checked={values.addApplyToAll} onChange={() => setFieldValue('addApplyToAll', !values.addApplyToAll)} />}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        label='Apply with Credit?'
                        disabled={!values.addApplyToAll}
                        control={<Checkbox checked={values.withCredit} onChange={() => setFieldValue('withCredit', !values.withCredit)} />}
                      />
                    </Grid>
                  </>
                )
              ) : (
                // EDIT - ALL MONITORED ENTITIES
                <>
                  <Grid item>
                    <FormControlLabel
                      label={`Apply changes to all ${entityType} where already required`}
                      control={
                        <Checkbox
                          checked={values.replaceExisting}
                          onChange={() => {
                            if (values.editApplyToAll) setFieldValue('editApplyToAll', false);
                            if (values.applyAssociationRemoval) setFieldValue('applyAssociationRemoval', false);
                            setFieldValue('replaceExisting', !values.replaceExisting);
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label={`Apply changes to all ${entityType} and add where not already required`}
                      control={
                        <Checkbox
                          checked={values.editApplyToAll}
                          onChange={() => {
                            if (values.replaceExisting) setFieldValue('replaceExisting', false);
                            if (values.applyAssociationRemoval) setFieldValue('applyAssociationRemoval', false);
                            setFieldValue('editApplyToAll', !values.editApplyToAll);
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      disabled={!(values.editApplyToAll || values.replaceExisting)}
                      label='Apply with Credit?'
                      control={<Checkbox checked={values.withCredit} onChange={() => setFieldValue('withCredit', !values.withCredit)} />}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label={`Apply changes to all ${entityType} and remove where in use.`}
                      disabled={removedLinesOfCoverage.length === 0}
                      control={
                        <Checkbox
                          checked={values.applyAssociationRemoval}
                          onChange={() => {
                            if (values.replaceExisting) setFieldValue('replaceExisting', false);
                            if (values.editApplyToAll) setFieldValue('editApplyToAll', false);
                            if (values.withCredit) setFieldValue('withCredit', false);
                            setFieldValue('applyAssociationRemoval', !values.applyAssociationRemoval);
                          }}
                        />
                      }
                    />
                  </Grid>
                </>
              )}

              {/* LIST OF LINES OF COVERAGE */}
              <Grid item>
                <CheckboxList
                  header='Lines of Coverage'
                  saveCheckboxSelectionToState={selectedLinesOfCoverage => setFieldValue('lineOfCoverageIds', selectedLinesOfCoverage)}
                  checkboxItems={linesOfCoverage.map(_ => ({ id: _.lineOfCoverageId, name: _.name }))}
                  checkedValuesList={values.lineOfCoverageIds}
                  enableSelectAll
                />
              </Grid>
            </Grid>

            {/** RIGHT SIDE */}
            <Grid item container xs={12} md={6} direction='column' spacing={1} style={{ marginTop: '1rem' }}>
              <FormControlLabel
                label='Default'
                control={
                  <Checkbox
                    checked={values.isDefault}
                    onClick={() => {
                      // AC: Un-select all locations when unchecking default
                      if (values.isDefault) {
                        setFieldValue('locationIds', []);
                      }
                      setFieldValue('isDefault', !values.isDefault);
                    }}
                    style={{ marginLeft: '0.75rem' }}
                  />
                }
              />

              {useLocations && values.isDefault && (
                <Grid item>
                  <CheckboxList
                    header='Default Locations'
                    saveCheckboxSelectionToState={selectedItems => setFieldValue('locationIds', selectedItems)}
                    checkboxItems={locations.map(_ => ({ id: _.locationId, name: _.name }))}
                    checkedValuesList={values.locationIds}
                    enableSelectAll
                    selectAllLabel='Select All'
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.additionalInsuredId ? <Check /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.additionalInsuredId ? 'Update' : 'Add'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const AdditionalInsuredSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

export default withFormik<IAddEditAdditionalInsuredProps, IAdditionalInsured>({
  enableReinitialize: true,
  validationSchema: AdditionalInsuredSchema,
  mapPropsToValues: ({ initialValues = {} }): IAdditionalInsured => {
    return {
      additionalInsuredId: 0,
      defaultAdditionalInsuredId: 0,
      name: '',
      clientId: 0,
      locationIds: [],
      // NOTE: `entityIds` is not part of IAdditionalInsured. Keep this here.
      // @ts-ignore 
      entityIds: [],
      linesOfCoverage: [],
      lineOfCoverageIds: initialValues?.linesOfCoverage?.map(loc => loc.lineOfCoverageId) ?? [],
      addApplyToAll: false,
      editApplyToAll: false,
      replaceExisting: false,
      isDefault: false,
      applyAssociationRemoval: false,
      withCredit: false,
      ...initialValues
    };
  },
  handleSubmit: async (values, { resetForm, props: { onSave, onClose, showToast, clientId } }) => {
    try {
      const response = await updateAdditionalInsured({
        linesOfCoverage: values?.lineOfCoverageIds?.map(id => ({ lineOfCoverageId: id })) ?? [],
        clientId: Number(clientId),
        additionalInsuredId: values.additionalInsuredId,
        name: values.name,
        isDefault: values.isDefault,
        isDeleted: false,
        locationIds: values.locationIds,
        defaultAdditionalInsuredId: values.defaultAdditionalInsuredId,
        addApplyToAll: values.addApplyToAll,
        editApplyToAll: values.editApplyToAll,
        replaceExisting: values.replaceExisting,
        locationsToRemove: values.locationsToRemove,
        removeFromLocations: values.removeFromLocations,
        removeFromMonitoredEntity: values.removeFromMonitoredEntity,
        withCredit: values.withCredit,
        lineOfCoverageIds: values.lineOfCoverageIds,
        applyAssociationRemoval: values.applyAssociationRemoval,
      });

      onSave(response);
      showToast('success', values.additionalInsuredId ? 'Additional Insured Updated' : 'Additional Insured Created!');
      resetForm();
    } catch (error) {
      if (error.request) {
        //axios errors are typically due to long awaits but does not indicate a server error
        showToast(
          'info',
          'This update is taking awhile to process. Please confirm changes in a few minutes and contact support if this issue continues.'
        );
        onClose();
      } else {
        showToast(
          'error',
          'We were unable to create the additional insured at this time. Please try again later. Please contact support if this issue continues.'
        );
      }

    }
  }
})(AddEditAdditionalInsured);

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  },
  listContainer: {
    width: '100%',
    height: theme.spacing(12),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  toggle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
