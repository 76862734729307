import { Button, CardActions, Fade, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { Loader, Modal, Table } from '../../components';
import { getFilesByRequirementId } from '../../fetch';
import { convertToUTC, formatShortFriendlyDateWithTime } from '../../helpers';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IFile, IFileObject, IMonitoredEntityFile, IRequirementFilesResponseItem } from '../../types';

const statusOptions = [
  { label: 'Applied', value: 1 },
  { label: 'Not Accepted', value: 2 },
];

interface IRequirementFileHistoryModalProps {
  open: boolean;
  onClose: () => void;
  requirementId: number;
}

export const RequirementFileHistoryModal: FC<IRequirementFileHistoryModalProps> = ({
  open,
  onClose,
  requirementId,
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<IRequirementFilesResponseItem[]>([]);

  const fetchRequirementFilesOnMount = async () => {
    setLoading(true);

    try {
      const filesResponse = await getFilesByRequirementId(requirementId);
      setFiles(filesResponse);
    } catch (error) {
      console.log('error', error);
      showToast('error', 'There was a problem loading associated files.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequirementFilesOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          cell: {
            row: { original, index },
          },
        }: {
          cell: { row: { original: IFileObject; index: number } };
        }) => {
          return (
            <div style={{ cursor: 'pointer', color: '#002855' }} onClick={() => handleGetFile(original)}>
              {original.file.name}
            </div>
          );
        },
      },
      { Header: 'Type', accessor: 'file.fileType' },
      {
        id: 'requirementFileStatus',
        Header: 'Status',
        Cell: ({
          cell: {
            row: { original, index },
          },
        }: {
          cell: { row: { original: IFileObject; index: number } };
        }) => {
          return statusOptions.find(s => Number(s.value) === Number(original.requirementFileStatus))?.label;
        },
      },
      {
        Header: 'Entered Date',
        id: 'dateCreated',
        accessor: (d: any) => formatShortFriendlyDateWithTime(convertToUTC(d.file.dateCreated)),
      },
      {
        Header: 'Expiration Date',
        accessor: (x: IMonitoredEntityFile) =>
          x?.expirationDate ? format(new Date(x.expirationDate), 'M/d/yyyy') : '',
      },
      {
        Header: 'Document Date',
        accessor: (x: IMonitoredEntityFile) => (x?.documentDate ? format(new Date(x.documentDate), 'M/d/yyyy') : ''),
      },
      {
        Header: 'User',
        accessor: 'user',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleGetFile = async (original: IFileObject) => {
    setLoading(true);
    try {
      await IFile.download(original?.file);
    } catch (error) {
      showToast('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal maxWidth='lg' open={open} title='Document History' onClose={() => onClose()}>
      {loading && <Loader type='overlay' position='centered' />}

      <Fade in={open}>
        <div>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={12}>
              <Table
                isLoading={loading}
                loadingPageSize={files.length === 0 ? 3 : files.length}
                key={JSON.stringify(files)}
                columns={columns}
                data={files}
                headerClasses={classes.tableHeader}
                centerPagination
                stickyHeader
              />
            </Grid>
          </Grid>
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button variant='contained' className={classes.closeButton} onClick={() => onClose()}>
                Close
              </Button>
            </div>
          </CardActions>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    backgroundColor: colors.primary.navyBlue,
    color: 'white',
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
}));
