import { ApplicationSettings } from '../types/applicationSettings';
import { authFetch } from './';

const baseUrl = '/api/applicationsettings';

export const updateApplicationSetting = async (setting: ApplicationSettings, value: boolean): Promise<void> => {
    try {
      await authFetch.put(`${baseUrl}/${setting}`, {
        newValue: value
      });
  
    } catch(error) {
      return Promise.reject(error);
    }
};

export const getApplicationSetting = async (setting: ApplicationSettings): Promise<boolean> => {
    try {
      const res = await authFetch.get(`${baseUrl}/${setting}`, null);
      return res.data.value
    } catch(error) {
      return Promise.reject(error);
    }
};