import { authFetch } from './';
import { IError, ILocationType, ILocationTypeGridRes } from '../types';

const baseUrl = '/api/locationType';

export const getLocationType = async (id: number): Promise<ILocationType> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting location type. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteLocationType = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting location type. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateLocationType = async (locationType: ILocationType): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, locationType);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating location type. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createLocationType = async (locationType: ILocationType): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Create`, locationType);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating location type. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getLocationTypes = async (filters?: {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  description?: string;
}): Promise<ILocationTypeGridRes> => {
  let query = '';
  if (filters) {
    query = `?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`;

    if (filters.description) {
      query += `&description=${filters.description}`;
    }
  }
  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return {
    records: [],
    totalRecordCount: 0
  };
};

export const getClientLocationTypes = async (): Promise<Array<ILocationType>> => {
  try {
    const res = await authFetch.get(`${baseUrl}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting location types. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
