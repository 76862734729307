import React, { FC, useState, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Edit, Delete, Add } from '@material-ui/icons';
import { Fab, IconButton } from '@material-ui/core';
// components
import { Table, ITableColumn, ConfirmationDialogue, PageTitle } from '../../components';

// helpers
import { colors } from '../../styles';
// types
import { IClient } from '../../types';
// hooks
import { useToastContext } from '../../hooks';
// fetch
import { AddEditClientCertificateHolders } from './AddEditClientCertificateHolder';
import { getClientCertificateHolders, deleteClientCertificateHolder } from '../../fetch/clientCertificateHolders';
import { IClientCertificateHolder } from '../../types/clientCertificateHolders';

interface IClientCertificateHolderTemplate
  extends Pick<
    IClient,
    'entityType' | 'portfolios' | 'locations' | 'useLocations' | 'clientId' | 'clientCertificateHolders'
  > {
  isLoading: boolean;
  updateCertificateHolders: (values: any[]) => void;
}

export const ClientCertificateHolder: FC<IClientCertificateHolderTemplate> = ({
  useLocations,
  clientCertificateHolders = [],
  locations,
  entityType,
  isLoading,
  clientId,
  updateCertificateHolders,
}) => {
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IClientCertificateHolder | null>(null);
  const classes = useStyles();
  const { showToast } = useToastContext();
  const fabShouldBeHidden = !useLocations && clientCertificateHolders.length > 0;

  const columns = useMemo(() => {
    const columnDefs = [
      {
        accessor: 'name',
        Header: 'Certificate Holder Name',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClientCertificateHolder } };
        }) => {
          return (
            <div key={original.clientId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];

    columnDefs.splice(1, 0, {
      id: 'locationName',
      Header: 'Location',
      accessor: (original: IClientCertificateHolder) => {
        if (!!original?.locationIds?.length) {
          if (locations.filter(_ => !_.isDeleted).length === original.locationIds.length) {
            return 'ALL';
          }
          return getLocationNames(original.locationIds);
        }
        return '';
      },
    });

    return columnDefs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCertificateHolders, locations]);

  const getLocationNames = locationIds => {
    const locationNames = [];

    locationIds.forEach(locationId => {
      const location = locations.find(location => location.locationId === locationId);

      if (location && location.name) {
        locationNames.push(location.name);
      }
    });
    return locationNames.join(', ');
  };

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Certificate Holders' marginOff />
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          loadingPageSize={
            !Array.isArray(clientCertificateHolders) || clientCertificateHolders.length === 0
              ? 3
              : clientCertificateHolders.length
          }
          key={JSON.stringify(clientCertificateHolders)}
          columns={columns}
          data={clientCertificateHolders}
          headerClasses={classes.tableHeader}
          centerPagination
          stickyHeader
        />
      </div>

      {/* ADD NEW BUTTON */}
      {!fabShouldBeHidden && (
        <Fab className={classes.addButton} onClick={() => setShowAddEditModal(true)}>
          <Add />
        </Fab>
      )}
      {/* ADD/EDIT MODAL */}
      {showAddEditModal && (
        <AddEditClientCertificateHolders
          open
          selectedRowItem={selectedRowItem}
          locations={locations}
          entityType={entityType}
          useLocations={useLocations}
          clientId={clientId}
          showToast={showToast}
          onClose={() => {
            setSelectedRowItem(null);
            setShowAddEditModal(false);
          }}
          onSave={clientCertificatePortfolios => {
            updateCertificateHolders(clientCertificatePortfolios);
            setSelectedRowItem(null);
            setShowAddEditModal(false);
          }}
        />
      )}

      {/* CONFIRM DELETE MODAl */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-client-certificate-holder-delete'
          title='Delete Certificate Holder'
          text='Are you sure you want to delete this Certificate Holder?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            const updated = {
              ...selectedRowItem,
              isDeleted: true,
            };
            try {
              await deleteClientCertificateHolder(updated?.certificateHolderId);
              const clientCertificateHolder = await getClientCertificateHolders(clientId);
              updateCertificateHolders(clientCertificateHolder);
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
            } catch (error) {
              showToast('error', 'There was an error deleting the Certificate Holder. Please try again.');
            }
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
