import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

interface IFooterProps {}

export const Footer: FC<IFooterProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      Brown & Brown, Inc. {`${new Date().getFullYear()}`}. All Rights Reserved.
    </div>
  );
};

const useStyles = makeStyles(() => {
  return {
    footerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '62px',
      backgroundColor: colors.secondary.lightGray,
      borderBottom: `6px solid ${colors.primary.accentRed}`,
      color: colors.primary.navyBlue,
    },
  };
});
