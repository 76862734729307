import { Divider, Fab, IconButton, TextField, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, Save } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { deepEqual } from 'fast-equals';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import { Loader, Page, PageTitle, UnsavedChanges } from '../../components';
import { routes, screenSizes } from '../../constants';
import { getContact, createContact, updateContact } from '../../fetch';
import { useToastContext } from '../../hooks';
import { IContact, INewContact, IUpdateContact, IContactForm } from '../../types';
import { ContactGroupsTable } from './ContactGroupsTable';
import { BlanketCertificateTable } from './blanket-cert-table';

// const EMPTY_CONTACT_FOMR: IContactForm = {
//   contactId: -1,
//   name: '',
// };

const EMPTY_CONTACT: IContact = {
  contactId: -1,
  name: '',
  contactGroups: [],
  blanketCertificates: [],
};

const formikResetObjectFormat = (response: IContactForm) => ({
  contactId: response.contactId,
  name: response.name,
});

const ContactFormSchema = Yup.object().shape({
  name: Yup.string().required('Client Name is required.'),
  contactGroups: Yup.array().optional(),
});

export const ContactsForm = () => {
  const formRef = useRef<null | FormikProps<IContact>>(null);
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useToastContext();
  // @ts-ignore
  const { contactId } = useParams();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingContactGroups, setLoadingContactGroups] = useState<boolean>(false);
  const [isLoadingBlanketCertificates, setLoadingBlanketCertificates] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [contactData, setContactData] = useState<IContact>(EMPTY_CONTACT);
  const [contactDataClone, setContactDataClone] = useState(EMPTY_CONTACT);

  const fetchContact = async () => {
    setLoading(true);

    try {
      if (typeof contactId === 'undefined') {
        setAddMode(true);
      } else {
        const response = await getContact(contactId);
        setContactData(response);
        setContactDataClone(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page customPageHeader title={addMode ? 'Add Contact Group' : 'Edit Contact Group'}>
      <Formik<IContactForm>
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          ...EMPTY_CONTACT,
          name: contactData.name,
          contactId: contactData.contactId,
        }}
        validationSchema={ContactFormSchema}
        onSubmit={async (values, actions) => {
          const contact: IContactForm = {
            contactId: values.contactId,
            name: values.name,
          };

          try {
            if (addMode) {
              const contactToAdd: INewContact = {
                name: contact.name,
              };
              const response = await createContact(contactToAdd);
              setContactData(response);
              // Reset the form and reload so user can now edit the client they just added
              actions.resetForm({ values: formikResetObjectFormat(response) });
              history.replace({ pathname: `${routes.manageContacts}/${response.contactId}` });
              setAddMode(false);

              // Success toast
              showToast('success', 'Contact Created!');
            } else {
              const contactToUpdate: IUpdateContact = {
                contactId: contact.contactId,
                name: contact.name,
              };
              const response = await updateContact(contactToUpdate);
              setContactData(response);
              setContactDataClone(response);

              // Reset the form with the updated model
              actions.resetForm({ values: formikResetObjectFormat(response) });

              if (!deepEqual(values.name, contactDataClone.name)) {
                // Success toast
                showToast('success', 'Contact Updated!');
                return;
              }
            }
          } catch (error: any) {
            error?.title ? showToast('error', error?.title) : showToast('error', "Couldn't update the contact");
          }
        }}
      >
        {({ isSubmitting, handleSubmit, dirty, isValid, values, touched, errors, handleBlur, setFieldValue }) => {
          return (
            <div>
              <UnsavedChanges dirty={dirty && !isSubmitting} />
              <div className={classes.headerContainer}>
                {/* BACK BUTTON */}
                <IconButton
                  className={classes.backButton}
                  color='primary'
                  onClick={() => {
                    history.push(routes.manageContacts);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                {/* HEADER */}
                <PageTitle title={addMode ? 'Add Contact' : 'Edit Contact'} marginOff />

                {/* SAVE */}
                {!values.name ? (
                  <Tooltip title={'Contact must have a name!'}>
                    <Fab
                      className={classes.disabledSaveButton}
                      // @ts-ignore
                      onClick={() => {}}
                    >
                      <Save />
                    </Fab>
                  </Tooltip>
                ) : (
                  <Fab
                    className={classes.saveButton}
                    disabled={!dirty || isSubmitting || !isValid}
                    onClick={() => formRef.current?.handleSubmit?.()}
                  >
                    <Save />
                  </Fab>
                )}
              </div>

              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' title={'Saving...'} />}
              <Form onSubmit={handleSubmit} autoComplete='none'>
                {/* SKELETON LOADER */}
                {isLoading && (
                  <div className={isMobile ? classes.mobileSkeletonLoaderContainer : classes.skeletonLoaderContainer}>
                    <Skeleton className={classes.formTextField} />
                  </div>
                )}

                {/* CONTACT NAME */}
                <TextField
                  required
                  autoComplete='nope'
                  label='Contact Name'
                  name='name'
                  value={values.name}
                  className={classes.formTextField}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('name', e.target.value)}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  style={{ width: '300px' }}
                />

                <Divider className={classes.tabDivider} />

                {/* CONTACT GROUPS TABLE */}
                {!addMode && (
                  <>
                    <ContactGroupsTable
                      isLoading={isLoading || isLoadingContactGroups}
                      contactGroups={contactData.contactGroups}
                      handleSubmit={formRef.current?.handleSubmit}
                      contact={contactData}
                      setContactData={setContactData}
                      setContactDataClone={setContactDataClone}
                      setLoading={setLoadingContactGroups}
                    />
                    <BlanketCertificateTable
                      isLoading={isLoading || isLoadingBlanketCertificates}
                      blanketCertificates={
                        contactData.blanketCertificates?.sort((a, b) => {
                          return new Date(a?.documentDate).getTime() >= new Date(b?.documentDate).getTime() ? -1 : 1;
                        }) ?? []
                      }
                      fetchContact={fetchContact}
                      contact={contactData}
                      setContactData={setContactData}
                      setContactDataClone={setContactDataClone}
                      setLoading={setLoadingBlanketCertificates}
                    />
                  </>
                )}
              </Form>
            </div>
          );
        }}
      </Formik>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  backButton: {
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  skeletonLoaderContainer: {
    display: 'flex',
  },
  mobileSkeletonLoaderContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  formTextField: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  disabledSaveButton: {
    backgroundColor: '#e0e0e0',
    color: '#a6a6a6',
    boxShadow: 'none',
    cursor: 'default',
  },
  tabDivider: {
    marginBottom: theme.spacing(1),
  },
}));
