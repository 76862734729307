import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Fab, Grid, IconButton } from '@material-ui/core';
import { useMedia } from 'react-use';
import { Edit, Delete, Add } from '@material-ui/icons';
// components
import { ImportModal, Page, Pagination } from '../../components';
import { Table, ITableColumn, ConfirmationDialogue } from '../../components';
import { ManageContactsFilters } from './ManageContactsFilters';
// types
import { SortOptions, DESC_SORT, ASC_SORT, IContact, IManageContactsFilters, IError } from '../../types';
// Fetch
import { getContacts, deleteContact, importContacts } from '../../fetch';
// constants
import { routes, screenSizes, userRoles } from '../../constants';
import { colors } from '../../styles';
// hooks
import { useToastContext } from '../../hooks';
import { ClientSelectorContext, UserContext } from '../../context';

export const ManageContacts = () => {
  const [recordCount, setRecordCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [filters, setFilters] = useState<IManageContactsFilters>({});
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const [showImportContactsModal, setShowImportContactsModal] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
  }>({
    Name: ASC_SORT
  });

  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const { showToast } = useToastContext();
  const { userContext } = useContext(UserContext);
  const history = useHistory();

  // api
  const fetchContacts = async () => {
    setLoading(true);

    try {
      const res = await getContacts({
        name: filters.name,
        email: filters.email,
        clientId: clientSelectorContext?.clientId ?? null,
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort]
      });
      setContacts(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteContact(id);
      await fetchContacts();
      showToast('success', 'Contact Deleted!');
    } catch (error) {
      showToast('error', error);
    }
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters, clientSelectorContext]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: 'Name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setSelectedSort('Name');
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT
          });
        },
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IContact } };
        }) => {
          return <div>{original.name}</div>;
        }
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IContact } };
        }) => {
          return (
            <div key={original.contactId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  history.push(`${routes.manageContacts}/${original.contactId}`);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              {userContext.role === userRoles.ADMIN && (
                <IconButton
                  color='secondary'
                  size='small'
                  onClick={() => {
                    setSelectedId(original.contactId);
                    setShowConfirmDeleteDialogue(true);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        }
      }
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort]);

  return (
    <Page title='Manage Contacts'>
      {/* FILTER BAR */}
      <Grid container>
        <Grid item>
          <ManageContactsFilters handleFilter={setFilters} isLoading={isLoading} />
        </Grid>
        {/* IMPORT CONTACTS BUTTON */}
        {userContext.role === userRoles.ADMIN && (
          <Grid item>
            <Button color='primary' size='small' onClick={() => setShowImportContactsModal(true)} className={classes.importButton}>
              Import Contacts
            </Button>
          </Grid>
        )}
      </Grid>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table hidePagination columns={columns} data={contacts} isLoading={isLoading} headerClasses={classes.tableHeader} stickyHeader />
        {!isLoading && contacts && (
          <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
        )}
      </div>

      {/* CONFIRM DELETE MODAl */}
      {selectedId && (
        <ConfirmationDialogue
          id={`${selectedId}`}
          title={'Delete Contact'}
          text={'Are you sure you want to delete this contact?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedId);
          }}
        />
      )}

      {/* ADD BUTTON */}
      <Fab
        color='secondary'
        className={isMobile ? classes.mobileAddButton : classes.addButton}
        aria-label='add'
        onClick={() => history.push(`${routes.manageContacts}/-1`)}
      >
        <Add />
      </Fab>

      {/* IMPORT CONTACTS */}
      <ImportModal
        isLoading={isLoading}
        title='Import Contacts'
        onClose={() => setShowImportContactsModal(false)}
        open={showImportContactsModal}
        allowedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        handleImport={file => {
          setLoading(true);
          importContacts(file)
            .then(() => {
              showToast('success', 'Contacts are being imported, you will receive an email when the import has completed.', null, true)
            })
            .catch(error => {
              let message = 'There was a problem importing the contacts. Please try again.';
              if (IError.is(error)) {
                const bulk = error?.BulkProblems?.filter(str => !!str)?.join('\n') ?? ''
                message = `Error Status ${error.Status}: ${(!!error.Title ? error.Title + '.' : '' )} ${error.Detail} ${bulk}`;
              }
              showToast('error', message, null, true);
            })
            .finally(() => {
              setShowImportContactsModal(false);
              setLoading(false);
            });
        }}
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden'
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite
    },
    addButton: {
      position: 'fixed',
      bottom: 'calc(68px + 2rem)',
      right: '2rem'
    },
    mobileAddButton: {
      position: 'fixed',
      bottom: '44px',
      right: '1rem'
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5)
      }
    },
    importButton: {
      marginLeft: theme.spacing(0.5)
    }
  };
});
