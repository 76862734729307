import { Badge, Button, Fab, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, InsertDriveFileOutlined, Save } from '@material-ui/icons';

import { Form, Formik } from 'formik';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DatePicker, Loader, Page, PageTitle, UnsavedChanges } from '../../components';
import { MultiFileUpload } from '../../components/files/multi-file-upload';
import { ProgressLoader } from '../../components/loader/ProgressLoader';
import { routes } from '../../constants';
import {
  // bulkUpdateAdditionalInsuredStatuses,
  getRequirementsForMonitoredEntities,
  getCarriersDropdown,
  getCoveredPoliciesByMonitoredEntities,
  getLinesOfCoverage,
  getMonitoredEntityRequirements,
  getBulkUpdateData,
  bulkUpdateRequirements,
  addFileForBulkData,
} from '../../fetch';
import { BulkUpdateHelper } from '../../helpers/bulkUpdate';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { CommentType, IAdditionalInsuredForMonitoredEntity, IEnum, ILineOfCoverage } from '../../types';
import { Sync } from '../../types';
import {
  IBulkDataDefaultRequirement,
  IBulkUpdateData,
  IBulkUpdateExistingRequirementMapping,
} from '../../types/bulkUpdate';
import { BulkUpdateLOCTable } from './bulk-update-loc';
import { BulkUpdateMonitoredEntitiesColumns } from './bulk-update-monitored-entities';
import { BulkUpdateAdditionalInsuredTable } from './BulkUpdateAdditionalInsuredTable';

import { OnDemandSummaryModal } from './OnDemandSummaryModal';

interface IBulkUpdateRequirementsForm {
  // This is the props.location.state.<t> that gets passed from React Router
  location: any;
}

enum BulkUpdateTabs {
  LOC,
  ME,
}

export const BulkUpdateRequirementsForm: FC<PropsWithChildren<IBulkUpdateRequirementsForm>> = ({ location }) => {
  /** Modal is closed when false. When not false, modal is open and depends on the provided data. */
  const [showOnDemandSummaryModal, setShowOnDemandSummaryModal] = useState<
    false | { clientId?: number; contactGroupId?: number }
  >(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [carriers, setCarriers] = useState<Omit<IEnum, 'description'>[]>([]);
  const [requirements, setRequirements] = useState<IBulkDataDefaultRequirement[]>([]);
  const [linesOfCoverage, setLinesOfCoverage] = useState<ILineOfCoverage[]>([]);
  const [additionalInsuredData, setAdditionalInsuredData] = useState<IAdditionalInsuredForMonitoredEntity[]>([]);
  const [copyAdditionalInsuredData, setCopyAdditionalInsuredData] = useState<IAdditionalInsuredForMonitoredEntity[]>(
    []
  );
  const [clientName, setClientName] = useState<string>('');
  const [existingRequirementsMap, setExistingRequirementsMap] = useState<IBulkUpdateExistingRequirementMapping>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [contactGroupId, setContactGroupId] = useState<number | null>(null);

  // prettier-ignore
  const [bulkUpdateAlerts, setBulkUpdateAlerts] = useState<null | Pick<
    Sync<ReturnType<typeof getRequirementsForMonitoredEntities>>,
    'isCrossContact'
  >>(null);

  const [selectedTab, setSelectedTab] = useState<BulkUpdateTabs>(BulkUpdateTabs.LOC);
  // Simple work-around for tracking changes to values.additionalInsureds (since it does not work as it should for some reason)
  const [entitiesIsDirty, setEntitiesIsDirty] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useToastContext();

  // @ts-ignore
  // Create a string to support current implementation
  let monitoredEntityIds = `${location.state.selectedIds.map(entity => entity.entityId).join(',')}`;

  let monitoredEntityIdArrays = location.state.selectedIds.map(entity => entity.entityId) as string[] & number[];

  const [bulkUpdateData, setBulkUpdateData] = useState<IBulkUpdateData>(null);
  const fetchRequirements = async (): Promise<{ showModal: boolean; clientId?: number; contactGroupId?: number }> => {
    if (!isLoading) {
      setIsLoading(true);
    }

    try {
      const [linesOfCoverageResponse] = await Promise.all([
        getLinesOfCoverage(),
        getCarriersDropdown().then(setCarriers),
        getCoveredPoliciesByMonitoredEntities(monitoredEntityIds).then(data => {
          setAdditionalInsuredData(data);
          setCopyAdditionalInsuredData(data);
        }),
      ]);

      const res = await getBulkUpdateData(monitoredEntityIdArrays);
      setBulkUpdateData(res);
      // Set the lines of coverage
      setLinesOfCoverage(linesOfCoverageResponse);

      setBulkUpdateAlerts({ isCrossContact: res.isCrossContact });

      // Set the initial state for requirements
      setRequirements(res.defaultRequirements);

      setExistingRequirementsMap(res.existingRequirementsMapping);

      // Display client name in header
      setClientName(res.clientName);
      setClientId(res.clientId);
      setContactGroupId(res.contactGroupId);

      // Build a list of unique lines of coverage
      const uniqueEntityRequirements = [];
      const map = new Map();
      for (const item of res.defaultRequirements) {
        if (!map.has(item.defaultRequirementId)) {
          map.set(item.defaultRequirementId, true);
          uniqueEntityRequirements.push(item);
        }
      }

      // Only display the ODS modal if edits span only one client and one contact and compliance is deficient

      // TODO: ALM make sure this displays properly!
      // const showModal = !isCrossContact && isWholeSetCompliant === false;
      const showModal = false;

      // Get clientId and contactGroupId from monitoredEntityId if the On Demand Summary modal is to be shown.
      const ids =
        showModal && typeof requirements?.[0]?.defaultRequirementId === 'number'
          ? await getMonitoredEntityRequirements(requirements[0].defaultRequirementId).then(_ => ({
              clientId: _.clientId,
              contactGroupId: _.contactGroupId,
            }))
          : null;
      return { showModal, ...ids };
    } catch (error) {
      const message =
        error instanceof Error && process.env.NODE_ENV !== 'production'
          ? error.message
          : 'Failed to fetch requirements.';

      showToast('error', message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequirements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredEntityIds]);

  const getAllIndexes = (updatedRequirementsList, changedLineOfCoverageId) => {
    return updatedRequirementsList
      .map((req, i) => (req.defaultRequirementId === changedLineOfCoverageId ? i : -1))
      .filter(index => index !== -1);
  };

  const updateData = (
    field: string,

    newValue: any,

    original: IBulkDataDefaultRequirement,
    values: any,
    setFieldValue,
    secondField?: string,
    secondNewValue?: any
  ) => {
    let updatedValues = [...values.requirements];

    const reqIndexToUpdate = updatedValues.findIndex(v => v.defaultRequirementId === original.defaultRequirementId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      [`${field}`]: newValue,
    };

    if (secondField && secondNewValue) {
      updatedValues[reqIndexToUpdate] = {
        ...updatedValues[reqIndexToUpdate],
        [`${secondField}`]: secondNewValue,
      };
    }

    // Display the uniquely changed rows
    setFieldValue('requirements', updatedValues);

    // Update every field cell where the line of coverage id === the loc id of the one that was changed
    let updatedRequirementsList = [...values.updatedRequirements];
    const indexes = getAllIndexes(updatedRequirementsList, original.defaultRequirementId);

    indexes.forEach(index => {
      updatedRequirementsList[index] = {
        ...updatedRequirementsList[index],
        [`${field}`]: newValue,
      };
    });

    // Store every row that was affected for the final update
    setFieldValue('updatedRequirements', updatedRequirementsList);
  };

  const [selectedRequirements, setSelectedRequirements] = useState<number[]>([]);
  const [selectedCreditForAll, setSelectedCreditForAll] = useState<number[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [finishedCount, setFinishedCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [associatedFiles, setAssociatedFiles] = useState<File[]>([]);
  const [fileAssociations, setFileAssociations] = useState([]);
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    // Important notes about FormData
    // 1. It is the best way to send image data to the BE from the FE otherwise, we waste a lot of data converting the image to Base 64
    // 2. console.log(formData) will not show you the data you need. You need to use the getter to access the data you want
    // 3. formData doesn't support directly adding arrays of data to the formData. You have to iterate over the files and append them to the same named property

    const data = new FormData();

    if (associatedFiles.length < 1 || fileAssociations.length < 1) return;

    for (let i = 0; i < monitoredEntityIdArrays.length; i++) {
      data.append(`Mappings[${i}].id`, `${monitoredEntityIdArrays[i]}`);
      data.append(
        `Mappings[${i}].filename`,
        `${
          fileAssociations.find(file => file?.hasOwnProperty(monitoredEntityIdArrays[i]))?.[monitoredEntityIdArrays[i]]
        }`
      );
    }

    for (const file of associatedFiles) {
      data.append('Files', file);
    }

    setFileData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedFiles, fileAssociations]);

  const valuesAreNotValid = values => {
    if (selectedRequirements.length > 0) {
      // find requirement
      const required = values.requirements.filter(req => selectedRequirements.includes(req.defaultRequirementId));

      const shouldBeDisabled = required.reduce((prev: boolean, curr: IBulkDataDefaultRequirement) => {
        return (
          prev ||
          !curr.carrierId ||
          !curr.carrierName ||
          !curr.documentDate ||
          !curr.expirationDate ||
          (!curr.actualAmount && (curr.coverageType === 1 || curr.coverageType === 3))
        );
      }, false);

      return shouldBeDisabled;
    }
    return false;
  };

  const [reactTouched, setTouched] = useState(false);
  const [selectedMonitoredEntityId, setSelectedMonitoredEntityId] = useState<number | null>(null);
  const [namedPolicyStatusEnums, setNamedPolicyStatusEnums] = useState<IEnum[]>([]);
  const [documentDate, setDocumentDate] = useState<string | null>(null);
  const [expirationDate, setExpirationDate] = useState<string | null>(null);

  useEffect(() => {
    // File Associations Check is necessary to prevent rerendering infinite loop caused by setting the state of the parent.
    if (associatedFiles.length === 1 && monitoredEntityIdArrays.length > 0 && fileAssociations.length < 1) {
      const autoAssociations = monitoredEntityIdArrays.map(monitoredEntityId => {
        return { [monitoredEntityId]: associatedFiles?.[0]?.name };
      });
      setFileAssociations(autoAssociations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedFiles]);

  // These represent the values the AI grid has before any automatic updates are made
  // It is updated only when the user selects a value from the dropdown
  // This is also passed all the way down to NamedPolicyStatusCell
  const [previousValues, setPreviousValues] = useState([]);
  useEffect(() => {
    if (previousValues.length === 0) {
      const prev = copyAdditionalInsuredData.flatMap(addIns =>
        addIns.additionalInsureds.flatMap(additional =>
          additional?.linesOfCoverage?.flatMap(loc => {
            return {
              name: additional.name,
              requirementId: loc.requirementId,
              addInId: additional.additionalInsuredId,
              locId: loc.lineOfCoverageId,
              status: loc.namedPolicyStatus,
            };
          })
        )
      );

      setPreviousValues(prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyAdditionalInsuredData]);

  return (
    <>
      {isLoading && <Loader type='overlay' position='centered' />}
      <Formik
        enableReinitialize
        initialValues={{
          monitoredEntityId: null,
          requirements: requirements, // Unique requirements for display purposes
          updatedRequirements: requirements, // Used to store every requirement entry for use in the final update
          additionalInsureds: [...additionalInsuredData], // Data for the additonal insured grid
          documentDate: documentDate ?? null,
          expirationDate: expirationDate ?? null,
        }}
        onSubmit={async (values, actions) => {
          let anyEntityIsDeficient = false;
          try {
            setTotalCount(monitoredEntityIdArrays.length);
            setIsSaving(true);
            const fileMapping = await addFileForBulkData(fileData);

            const objectsToSend = BulkUpdateHelper.mapBulkUpdateDataToPersistableData(
              monitoredEntityIdArrays,
              values.requirements.filter(x => selectedRequirements.includes(x.defaultRequirementId)),
              existingRequirementsMap,
              fileMapping,
              values.documentDate,
              values.expirationDate,
              values.additionalInsureds
            );

            for (let body of objectsToSend) {
              const result = await bulkUpdateRequirements(body);
              setFinishedCount(prev => ++prev);
              anyEntityIsDeficient = anyEntityIsDeficient || result.shouldOnDemandSummaryTrigger;
            }
          } catch (error) {
            console.error(error);
          } finally {
            if (anyEntityIsDeficient && !bulkUpdateAlerts.isCrossContact) {
              setShowOnDemandSummaryModal({ clientId, contactGroupId });
            } else {
              setSelectedRequirements([]);
              setExpirationDate(null);
              setDocumentDate(null);
              actions.resetForm();
              history.push(routes.manageRequirements);
            }
            setIsSaving(false);
            setFinishedCount(0);
          }
        }}
      >
        {({
          initialValues,
          values,
          setFieldValue,
          handleBlur,
          dirty,
          isSubmitting,
          isValid,
          resetForm,
          submitForm,
          errors,
          touched,
        }) => {
          return (
            <Page customPageHeader title={'Bulk Updates'}>
              {isSaving && (
                <div className={classes.progressBarContainer}>
                  <ProgressLoader
                    position={'centered'}
                    className={classes.progressBar}
                    type={'overlay'}
                    value={Math.round((finishedCount / totalCount) * 100)}
                  />
                </div>
              )}
              <div>
                <UnsavedChanges dirty={dirty && !isSubmitting} />
                <div className={classes.headerContainer}>
                  {/* BACK BUTTON */}
                  <IconButton
                    className={classes.backButton}
                    color='primary'
                    onClick={() => {
                      history.push(routes.manageRequirements);
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  {/* HEADER */}
                  {clientName && <PageTitle title={`Bulk Updates | ${clientName}`} marginOff />}
                  {/* SAVE */}
                  <Fab
                    className={classes.saveButton}
                    disabled={
                      (!entitiesIsDirty && !dirty) ||
                      isSubmitting ||
                      !isValid ||
                      !expirationDate ||
                      !documentDate ||
                      selectedRequirements.length < 1 ||
                      fileAssociations.length < 1 ||
                      valuesAreNotValid(values) ||
                      monitoredEntityIdArrays.length !== fileAssociations.length ||
                      isSaving
                    }
                    type='submit'
                    onClick={() => submitForm()}
                  >
                    <Save />
                  </Fab>
                </div>
                {/* FORM */}
                {isSubmitting && <Loader type='overlay' position='centered' title={'Saving...'} />}
                <Form autoComplete='none'>
                  {/* MONITORED ENTITY COUNT MESSAGE */}
                  {values.requirements && values.requirements.length > 0 && (
                    <Grid container direction='column'>
                      <Grid container direction='row' spacing={2} alignItems={'center'} style={{ marginBottom: '4px' }}>
                        <Grid item>
                          <Typography>
                            {monitoredEntityIds.split(',').length} Monitored Entit
                            {monitoredEntityIds.split(',').length === 1 ? 'y' : 'ies'} selected.
                          </Typography>
                        </Grid>
                        {bulkUpdateAlerts?.isCrossContact && (
                          <Grid item>
                            <Typography color='error'>NOTE: Multiple Contacts selected.</Typography>
                          </Grid>
                        )}
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <DatePicker
                            id='expiration-date-date-picker'
                            fullWidth
                            label='Expiration Date'
                            placeholder=''
                            autoComplete='nope'
                            name='expirationDateMain'
                            value={values.expirationDate ? new Date(values.expirationDate) : null}
                            onBlur={handleBlur}
                            onClear={() => {
                              setFieldValue('expirationDate', null);
                              setAssociatedFiles([]);
                            }}
                            onChange={date => {
                              setFieldValue('expirationDate', date?.toISOString());
                              setExpirationDate(date?.toISOString());
                            }}
                            error={Boolean(touched.expirationDate && errors.expirationDate)}
                            helperText={touched.expirationDate && errors.expirationDate}
                          />
                        </Grid>
                        <Grid item>
                          <DatePicker
                            id='documentDate-date-date-picker'
                            fullWidth
                            label='Document Date'
                            placeholder=''
                            autoComplete='nope'
                            name='documentDateMain'
                            value={values.documentDate ? new Date(values.documentDate) : null}
                            onBlur={handleBlur}
                            onClear={() => {
                              setFieldValue('documentDate', null);
                              setAssociatedFiles([]);
                            }}
                            onChange={date => {
                              setFieldValue('documentDate', date?.toISOString());
                              setDocumentDate(date?.toISOString());
                            }}
                            error={Boolean(touched.documentDate && errors.documentDate)}
                            helperText={touched.documentDate && errors.documentDate}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            {associatedFiles.length > 0 && (
                              <Button
                                onClick={() => {
                                  setAssociatedFiles([]);
                                  setFileAssociations([]);
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <MultiFileUpload
                              uploadText={'Add Documents'}
                              handleFileChange={files => setAssociatedFiles(prev => [...prev, ...files])}
                            />
                            <Badge
                              badgeContent={associatedFiles.length}
                              showZero
                              max={99}
                              color={values.documentDate && values.expirationDate ? 'primary' : 'default'}
                            >
                              <InsertDriveFileOutlined
                                fontSize='large'
                                color={values.documentDate && values.expirationDate ? 'primary' : 'disabled'}
                              />
                            </Badge>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Tabs
                      value={selectedTab}
                      onChange={(_event, newValue) => {
                        setSelectedTab(newValue);
                      }}
                    >
                      <Tab label={'Lines of Coverage'.toUpperCase()} value={BulkUpdateTabs.LOC} />
                      <Tab label={'Monitored Entities'.toUpperCase()} value={BulkUpdateTabs.ME} />
                    </Tabs>
                  </Grid>
                  {/* BULK UPDATE GRID */}
                  {selectedTab === BulkUpdateTabs.LOC && (
                    <div className={classes.gridContainer}>
                      {bulkUpdateData && bulkUpdateData?.defaultRequirements && values.requirements && (
                        <BulkUpdateLOCTable
                          isLoading={isLoading}
                          requirements={requirements}
                          setRequirements={setRequirements}
                          selectedRequirements={selectedRequirements}
                          setSelectedRequirements={setSelectedRequirements}
                          updateData={updateData}
                          carriers={carriers}
                          values={values}
                          setFieldValue={setFieldValue}
                          reactTouched={reactTouched}
                          setTouched={setTouched}
                          valuesAreNotValid={valuesAreNotValid}
                          selectedCreditForAll={selectedCreditForAll}
                          setSelectedCreditForAll={setSelectedCreditForAll}
                          setEntitiesIsDirty={setEntitiesIsDirty}
                          additionalInsuredData={additionalInsuredData}
                          setAdditionalInsuredData={setAdditionalInsuredData}
                          previousValues={previousValues}
                        />
                      )}
                    </div>
                  )}
                  {selectedTab === BulkUpdateTabs.ME && bulkUpdateData && bulkUpdateData?.defaultRequirements && (
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={12} sm={6}>
                        <BulkUpdateMonitoredEntitiesColumns
                          isLoading={isLoading}
                          requirements={requirements}
                          updateData={updateData}
                          carriers={carriers}
                          values={values}
                          setFieldValue={setFieldValue}
                          touched={reactTouched}
                          setTouched={setTouched}
                          valuesAreNotValid={valuesAreNotValid}
                          bulkUpdateData={bulkUpdateData}
                          associatedFiles={associatedFiles.map(file => ({
                            key: file.name,
                            label: `${file.name}`,
                            value: `${file.name}`,
                          }))}
                          monitoredEntityIdArrays={monitoredEntityIdArrays}
                          setFileAssociations={setFileAssociations}
                          fileAssociations={fileAssociations}
                          setSelectedMonitoredEntityId={setSelectedMonitoredEntityId}
                        />
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <BulkUpdateAdditionalInsuredTable
                          requirements={requirements}
                          setRequirements={setRequirements}
                          linesOfCoverage={linesOfCoverage}
                          coveredPolicies={additionalInsuredData}
                          values={values}
                          setFieldValue={setFieldValue}
                          setEntitiesIsDirty={setEntitiesIsDirty}
                          selectedMonitoredEntityId={selectedMonitoredEntityId}
                          setSelectedMonitoredEntityId={setSelectedMonitoredEntityId}
                          namedPolicyStatusEnums={namedPolicyStatusEnums}
                          setNamedPolicyStatusEnums={setNamedPolicyStatusEnums}
                          selectedCreditForAll={selectedCreditForAll}
                          additionalInsuredData={additionalInsuredData}
                          setAdditionalInsuredData={setAdditionalInsuredData}
                          previousValues={previousValues}
                          setCopyAdditionalInsuredData={setCopyAdditionalInsuredData}
                          copyAdditionalInsuredData={copyAdditionalInsuredData}
                          setPreviousValues={setPreviousValues}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Form>
                {/* ADDITIONAL INSURED GRID */}
              </div>
              {/* ON DEMAND SUMMARY MODAL */}
              {typeof showOnDemandSummaryModal === 'object' && (
                <OnDemandSummaryModal
                  open
                  onClose={() => {
                    setIsSaving(false);
                    setFinishedCount(0);
                    setSelectedRequirements([]);
                    setExpirationDate(null);
                    setDocumentDate(null);
                    resetForm();
                    history.push(routes.manageRequirements);
                    setShowOnDemandSummaryModal(false);
                  }}
                  clientId={showOnDemandSummaryModal?.clientId}
                  contactGroupId={showOnDemandSummaryModal?.contactGroupId}
                  monitoredEntityId={requirements?.[0]?.defaultRequirementId}
                  commentType={CommentType.ContactGroups}
                  documentForBulkUpdate={associatedFiles}
                  isBulkUpdate
                />
              )}
            </Page>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  backButton: {
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  progressBar: {
    height: theme.spacing(2),
    width: '40%',
    marginLeft: theme.spacing(1),
    borderRadius: '0.8rem',
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  entitySelectorContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));
