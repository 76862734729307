import { Button, CardActions, Fade, Grid, MenuItem, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Check } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { deepEqual } from 'fast-equals';
import { Form, Formik } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Loader, Modal, Select } from '../../components';
import { UserContext } from '../../context';
import { getCountriesDropdown, getUnitedStatesDropdown, updateLocation } from '../../fetch';
import { getClientCertificateHolders } from '../../fetch/clientCertificateHolders';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IEnum, IEnumDropdown, ILocation, ILocationType } from '../../types';
import { IClientCertificateHolder } from '../../types/clientCertificateHolders';

const isUnitedStates = (str: string): str is 'United States' | 'USA' => str === 'United States' || str === 'USA';

interface IAddEditLocations {
  open: boolean;
  onClose: () => void;
  onSave: (values: ILocation[]) => void;
  selectedRowItem: ILocation | null;
  locationTypes: ILocationType[];
  locationStatuses: IEnum[];
  clientId: number;
}

const createLocationSchema = (states: IEnumDropdown[]) =>
  Yup.object().shape({
    name: Yup.string().nullable().required('Name is required'),
    locationTypeId: Yup.number().nullable().required('Location Type is required'),
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().when('country', {
      is: (country: string) => isUnitedStates(country),
      // prettier-ignore
      then: Yup.string().oneOf(states.map(_ => _.text), 'Invalid state.').required('Required'),
      otherwise: Yup.string(),
    }),
    country: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    email: Yup.string().nullable(),
    clientLocationCode: Yup.string().nullable(),
    certificateHolderId: Yup.string().required('Certificate Holder is Required'),
  });

export const AddEditLocations: FC<IAddEditLocations> = ({
  open,
  onClose,
  selectedRowItem = {},
  onSave,
  locationTypes,
  locationStatuses,
  clientId,
}) => {
  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();

  const [countries, setCountries] = useState<IEnumDropdown[]>([]);
  /** A list of abbreviations of the states of the US. */
  const [states, setStates] = useState<IEnumDropdown[]>([]);
  const [clientCertificateHolders, setClientCertificateHolders] = useState<IClientCertificateHolder[]>([]);

  useEffect(() => {
    let unmounted = false;
    Promise.all([getCountriesDropdown(), getUnitedStatesDropdown(), getClientCertificateHolders(clientId)])
      .then(([countries, states, clientCertHolders]) => {
        if (unmounted) return;
        setCountries(countries);
        setStates(states);
        setClientCertificateHolders(clientCertHolders);
      })
      .catch(err => {
        if (unmounted) return;
        showToast('error', err instanceof Error ? err.message : 'Failed to load address dropdown data.');
      });
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          locationId: 0,
          name: '',
          locationTypeId: null,
          address: '',
          city: '',
          state: '',
          country: 'United States',
          zipCode: '',
          email: '',
          clientLocationCode: '',
          certificateHolderId: null,
          isDeleted: false,
          ...selectedRowItem,
          statuses:
            !selectedRowItem || !Array.isArray(selectedRowItem.statuses) || selectedRowItem.statuses.length === 0
              ? [
                  {
                    id: null,
                    userDisplayName: userContext.name,
                    effectiveDate: new Date().toISOString(),
                    status: Array.isArray(locationStatuses) && locationStatuses.length ? locationStatuses[0].value : 1,
                  },
                ]
              : selectedRowItem.statuses,
        }}
        validationSchema={states.length > 0 ? createLocationSchema(states) : undefined}
        onSubmit={async (values, actions) => {
          try {
            const updated = {
              clientId: clientId,
              ...values,
            };

            const response = await updateLocation(updated);
            onSave(response);
            actions.resetForm();
          } catch (error) {
            console.log('error', error);
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              fullWidth={false}
              maxWidth={'sm'}
              open={open}
              title={selectedRowItem ? 'Edit Location' : 'Add New Location'}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        autoComplete='nope'
                        label='Location Name'
                        name='name'
                        value={values.name}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Select
                        name='locationType'
                        id='location-type'
                        value={values.locationTypeId}
                        label='Location Type *'
                        className={classes.inputLabel}
                        showReset={false}
                        onChange={e => setFieldValue('locationTypeId', e.target.value)}
                        options={locationTypes.map(locationType => ({
                          key: locationType.locationTypeId,
                          label: locationType.description,
                          value: locationType.locationTypeId,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Select
                        name='locationStatus'
                        id='location-status'
                        value={values.statuses[0].status}
                        label='Location Status *'
                        className={classes.inputLabel}
                        showReset={false}
                        onChange={({ target: { value } }) => {
                          // Add to top of the list and cut out any value that hasn't been saved
                          // to prevent a bunch of status history items
                          let statuses = values.statuses ? [...values.statuses] : [];
                          if (statuses.length && !statuses[0].id) {
                            statuses[0] = {
                              id: 0,
                              userDisplayName: userContext.name,
                              effectiveDate: new Date().toISOString(),
                              status: value as number,
                            };
                          } else {
                            statuses.unshift({
                              id: 0,
                              userDisplayName: userContext.name,
                              effectiveDate: new Date().toISOString(),
                              status: value as number,
                            });
                          }
                          setFieldValue('statuses', statuses);
                        }}
                        options={locationStatuses.map(status => ({
                          key: status.value,
                          label: status.description,
                          value: status.value,
                        }))}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        autoComplete='nope'
                        label='Address'
                        name='address'
                        value={values.address}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('address', e.target.value)}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        autoComplete='nope'
                        label='City'
                        name='city'
                        value={values.city}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('city', e.target.value)}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      {/** States */}
                      {/** If **country** is United States, input has autocomplete. */}
                      {isUnitedStates(values.country) ? (
                        <Autocomplete
                          selectOnFocus
                          clearOnBlur
                          disableClearable
                          includeInputInList
                          value={values.state}
                          id='us-states-dropdown'
                          onChange={(_event, newState) => {
                            setFieldValue('state', newState);
                          }}
                          options={states.map(_ => _.text)}
                          noOptionsText='No States'
                          renderInput={params => (
                            <TextField
                              label='State'
                              name='state'
                              required
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              InputProps={{
                                ...params.InputProps,
                                // autoComplete new-password disables browser autofill
                                autoComplete: 'new-password',
                                // Renders the clear-the-input button which resets state to empty string
                                // instead of null which would cause an odd validation error the user sees.
                                type: 'search',
                              }}
                              className={classes.formTextField}
                              onBlur={handleBlur}
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          autoComplete='nope'
                          label='State'
                          name='state'
                          value={values.state}
                          className={classes.formTextField}
                          onBlur={handleBlur}
                          onChange={e => setFieldValue('state', e.target.value)}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        fullWidth
                        autoComplete='nope'
                        label='Postal Code'
                        name='zipCode'
                        value={values.zipCode}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('zipCode', e.target.value)}
                        error={Boolean(touched.zipCode && errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        freeSolo={!isUnitedStates(values.country)}
                        selectOnFocus
                        disableClearable
                        includeInputInList
                        value={values.country}
                        id='country-dropdown'
                        onChange={(_event, newCountry) => {
                          setFieldValue('country', newCountry);
                        }}
                        options={countries.map(_ => _.text)}
                        noOptionsText='No Countries'
                        renderInput={params => (
                          <TextField
                            label='Country'
                            name='country'
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            InputProps={{
                              ...params.InputProps,
                              // autoComplete new-password disables browser autofill
                              autoComplete: 'new-password',
                              // Renders the clear-the-input button which resets state to empty string
                              // instead of null which would cause an odd validation error the user sees.
                              type: 'search',
                            }}
                            className={classes.formTextField}
                            onBlur={handleBlur}
                            error={Boolean(touched.country && errors.country)}
                            helperText={touched.country && errors.country}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        required
                        size='small'
                        label='Certificate Holder'
                        className={classes.formTextField}
                        placeholder='Certificate Holder'
                        value={values.certificateHolderId}
                        onChange={e => {
                          setFieldValue('certificateHolderId', e.target.value);
                        }}
                        error={!clientCertificateHolders}
                      >
                        {clientCertificateHolders &&
                          clientCertificateHolders.map((certHolder, index) => {
                            return (
                              <MenuItem key={`${index + 1}`} value={certHolder.certificateHolderId}>
                                {certHolder.name}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete='nope'
                        label='Contact Email'
                        name='email'
                        value={values.email}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('email', e.target.value)}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        autoComplete='nope'
                        label='Client Location Code'
                        name='clientLocationCode'
                        value={values.clientLocationCode}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => {
                          // Must be alphanumeric
                          if (/[^0-9a-zA-Z]/.test(e.target.value)) {
                            return;
                          }
                          setFieldValue('clientLocationCode', e.target.value);
                        }}
                        error={Boolean(touched.clientLocationCode && errors.clientLocationCode)}
                        helperText={touched.clientLocationCode && errors.clientLocationCode}
                      />
                    </Grid>
                  </Grid>

                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              onClose();
                            } else {
                              return;
                            }
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid}
                        type='submit'
                        startIcon={selectedRowItem ? <Check /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {selectedRowItem ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    width: '100%',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowFieldWidth: {
    marginRight: theme.spacing(2),
    width: theme.spacing(6),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  inputLabel: {
    whiteSpace: 'nowrap',
  },
}));
