import React, { FC, useState, useEffect } from 'react';
import { Checkbox, FormControl, TextField } from '@material-ui/core';
// types
import { IEntityRequirement } from '../../../types';
import { CoverageTypeEnums } from '../../../constants/CoverageTypeEnums';
import NumberFormat from 'react-number-format';

interface IActualAmountCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  updateData: (
    columnId: string,
    value: any,
    original: IEntityRequirement,
    values: any[],
    setFieldValue: Function
  ) => void;
  values: any;
  setFieldValue: Function;
  alwaysHideCheckbox?: boolean;
  selectedRequirements?: number[];
  setSelectedRequirements?: (value: React.SetStateAction<number[]>) => void;
  touched: boolean;
  setTouched: (arg: boolean) => void;
  updateSelectedRequirements?: (row: any) => void;
  setEntitiesIsDirty(isChanged: boolean): void;
}

export const ActualAmountCell: FC<IActualAmountCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  updateData,
  values,
  setFieldValue,
  selectedRequirements,
  touched,
  setTouched,
  setSelectedRequirements,
  updateSelectedRequirements,
  setEntitiesIsDirty,
}) => {
  // We need to keep and update the state of the cell normally
  const [actualAmount, setActualAmount] = useState(original.original.actualAmount);
  const [hasEvidence, setHasEvidence] = useState(original.original.hasEvidence);
  const [hasEvidenceHasChanged, setHasEvidenceHasChanged] = useState<boolean>(false);

  const [err, setErr] = useState(false);

  // See if the row is selected
  const [isSelected, setIsSelected] = useState(selectedRequirements.includes(original.original.defaultRequirementId));

  useEffect(() => {
    setIsSelected(selectedRequirements.includes(original.original.defaultRequirementId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirements]);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setActualAmount(Number(original.original.actualAmount));
  }, [original.original.actualAmount]);

  useEffect(() => {
    setHasEvidence(original.original.hasEvidence);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original.original.hasEvidence]);

  useEffect(() => {
    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (isSelected) {
      setErr(true);
    }
    if (!isSelected) {
      setErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (actualAmount !== original.original.actualAmount) {
      updateData('actualAmount', Number(actualAmount), original.original, values, setFieldValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualAmount]);

  useEffect(() => {
    if (hasEvidence !== original.original.hasEvidence) {
      setHasEvidenceHasChanged(true);
      updateData('hasEvidence', hasEvidence, original.original, values, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEvidence]);
  return original.original.coverageType !== CoverageTypeEnums.EVIDENCE ? (
    <FormControl fullWidth>
      <NumberFormat
        customInput={TextField}
        onValueChange={amountValues => {
          setActualAmount(amountValues.value);
          if (setEntitiesIsDirty) setEntitiesIsDirty(true);
          if (updateSelectedRequirements) {
            updateSelectedRequirements(original.original);
          }
        }}
        inputProps={{ style: { textAlign: 'right' } }}
        thousandSeparator
        isNumericString
        decimalScale={0}
        prefix='$'
        fullWidth
        onFocusCapture={() => {
          if (touched) {
            return;
          }
          setTouched(true);
        }}
        name='actualAmount'
        value={!!actualAmount ? Number(actualAmount) : null}
        label={!actualAmount && err ? 'Field required.' : undefined}
        error={!actualAmount && err}
        InputLabelProps={{ shrink: !actualAmount && err }}
      />
    </FormControl>
  ) : (
    <Checkbox
      style={{ display: 'none' }}
      checked={hasEvidenceHasChanged ? hasEvidence : false}
      onClick={() => {
        setHasEvidence(!hasEvidence);
        setSelectedRequirements(prev => [...prev, original.original.defaultRequirementId]);
      }}
    />
  );
};
