import { axios, setUserAccessTokenCookies, setuserRefreshTokenCookies } from '../helpers';
import { ILoginPost, ILoginUser, IError, IRequestPasswordResetPost, IResetPasswordPost, IForgotUsernamePost } from '../types';

/**
 * auths a user on login
 * @param data ILoginPost
 * @returns user or error
 */
export const login = async (data: ILoginPost): Promise<ILoginUser | IError> => {
  try {
    const res = await axios.post('Api/User/Login', data, {skipTokens:true});

    if (res && res.data && res.data.accessToken) {
      console.info('login successful');
      setUserAccessTokenCookies(res.data.accessToken, res.data.expiresAt);
      setuserRefreshTokenCookies(res.data.refreshToken, res.data.refreshTokenExpiresAt);

      const resMe = await axios.get('Api/User/Me');
      return {
        ...res.data,
        ...resMe.data
      };
    }
    const error = { Detail: 'Error logging in. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};


export const requestPasswordReset = async (data: IRequestPasswordResetPost): Promise<null | IError> => {
  try {
    const res = await axios.post('Api/User/password-reset-email', data, {skipTokens:true});

    if (res.status >= 200 && res.status < 300) {
      return res.data;
    }

    const error = { Detail: 'Error resetting password, please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};


export const resetPassword = async (data: IResetPasswordPost): Promise<null | IError> => {
  try {
    const res = await axios.post('Api/User/reset-password', data);

    if (res.status >= 200 && res.status < 300) {
      return res.data;
    }

    const error = { Detail: 'Error resetting password, please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};


export const forgotUsernameEmail = async (data: IForgotUsernamePost): Promise<null | IError> => {
  try {
    const res = await axios.post('Api/User/forgot-username-email', data, {skipTokens:true});

    if (res.status >= 200 && res.status < 300) {
      return res.data;
    }

    const error = { Detail: 'Error sending username, please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};