import { makeStyles } from '@material-ui/core/styles';

const passwordRegex =
  // eslint-disable-next-line no-useless-escape
  /(?=^.{12,64}$)((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[@#$%^&*()_+|~=`{}\[\]:";\'<>?,./\\!-])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[@#$%^&*()_+|~=`{}\[\]:";\'<>?,./\\!-])|(?=.*\d)(?=.*[@#$%^&*()_+|~=`{}\[\]:";\'<>?,./\\!-]))/;

export const isValidPassword = (value: string = ''): boolean | string => passwordRegex.test(value);

export const PasswordRequirements = ({ value, color = 'red' }: { value: string; color?: string }): JSX.Element => {
  const classes = useStyles();
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasDigit = /\d/.test(value);
  const hasSpecialChar = /[@#$%^&*()_+|~\-=`{}[\]:";'<>/]/.test(value);
  const isLengthValid = value.length >= 12 && value.length <= 64;

  const characterTypesMet = [hasUpperCase, hasLowerCase, hasDigit, hasSpecialChar].filter(Boolean).length;

  return (
    <>
      {value.length > 0 && (
        <ul className={classes.list} style={{ color: color }}>
          {characterTypesMet < 2 && (
            <li>Must include at least two of the following: uppercase, lowercase, numeric, special character</li>
          )}
          {!isLengthValid && <li>Must be between 12 and 64 characters long</li>}
        </ul>
      )}
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    list: {
      marginTop: 0,
      marginBottom: 0,
      color: 'red',
    },
  };
});

// Tests if the provided string is in an acceptable email format
export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
