import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
import { Add, Edit, Delete, MoreVert } from '@material-ui/icons';
import { Button, Fab, Grid, Paper, Typography, Menu, MenuItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
// components
import { ConfirmationDialogue, Page, Toast } from '../../components';
import AddEditReports from './AddEditReports';
import { ReportsLoadingState } from './ReportsLoadingState';
// constants
import { screenSizes, userRoles, routes } from '../../constants';
// context
import { ClientSelectorContext, UserContext } from '../../context';
// types
import { IReport } from '../../types';
// fetch
import { deleteReport, getReport, getReports } from '../../fetch';
// styles
import { colors } from '../../styles';
// hooks
import { useToastContext } from '../../hooks';
import { useHistory } from 'react-router-dom';

export const Reports = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [reports, setReports] = useState<IReport[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const history = useHistory();

  const fetchReports = async () => {
    setLoading(true);

    try {
      const response = await getReports(clientSelectorContext);
      if (response) {
        setReports(response);
        setLoading(false);
      } else {
        setReports([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = async () => {
    handleKebabClose();

    try {
      const response = await getReport(selectedReport.reportId);
      setSelectedReport(response);
      setShowAddEditModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await deleteReport(id);
      await fetchReports();
      showSuccess(true);
    } catch (error) {
      console.log(error);
      showError(true);
    }
  };

  const handleKebabClick = (event, report) => {
    setAnchorEl(event.currentTarget);
    setSelectedReport(report);
  };

  const handleKebabClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectorContext]);

  return (
    <Page title='Reports'>
      {/* REPORTS LIST */}
      {isLoading ? (
        <ReportsLoadingState count={Array.isArray(reports) && reports.length ? reports.length : 6} />
      ) : (
        <Grid container spacing={2} className={`${classes.gridContainer} ${isMobile ? classes.columnOrientation : ''}`}>
          {reports &&
            reports.map(report => (
              <Grid key={report.reportId} item className={`${classes.reportContainer}`}>
                <Paper elevation={3}>
                  {/* REPORT HEADER */}
                  <div className={classes.header}>
                    {/* TITLE */}
                    <Typography variant='h4'>{report.name}</Typography>

                    {/* 'KABOB' MENU */}
                    {userContext.role === userRoles.ADMIN && (
                      <IconButton
                        aria-label='more'
                        aria-controls={`long-menu-${report.reportId}`}
                        aria-haspopup='true'
                        onClick={e => handleKebabClick(e, report)}
                      >
                        <MoreVert />
                      </IconButton>
                    )}
                    <Menu
                      key={`kebab-${report.reportId}`}
                      id={`long-menu-${report.reportId}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleKebabClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch'
                        }
                      }}
                    >
                      <MenuItem key={`edit-${report.reportId}`} onClick={() => handleEdit()}>
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        <ListItemText primary='Edit' />
                      </MenuItem>
                      <MenuItem
                        key={`delete-${report.reportId}`}
                        onClick={() => {
                          handleKebabClose();
                          setShowConfirmDeleteDialogue(true);
                        }}
                      >
                        <ListItemIcon>
                          <Delete />
                        </ListItemIcon>
                        <ListItemText primary='Delete' />
                      </MenuItem>
                    </Menu>
                  </div>

                  {/* REPORT DESCRIPTION */}
                  <div className={classes.body}>{report.description}</div>

                  {/* VIEW BUTTON */}
                  <div className={classes.footer}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => history.replace({ pathname: `${routes.powerBi}/${report.reportId}/${report.powerBIReportId}/${report.reportSecurityType}` })}
                    >
                      View
                    </Button>
                  </div>
                </Paper>
              </Grid>
            ))}
        </Grid>
      )}

      {/* ADD BUTTON */}
      {userContext.role === userRoles.ADMIN && (
        <Fab
          color='secondary'
          disabled={isLoading}
          className={isMobile ? classes.mobileAddButton : classes.addButton}
          aria-label='add'
          onClick={() => {
            setSelectedReport(null);
            setShowAddEditModal(true);
          }}
        >
          <Add />
        </Fab>
      )}

      {/* ADD/EDIT MODAL */}
      <AddEditReports
        initialValues={selectedReport}
        open={showAddEditModal}
        onClose={() => {
          setShowAddEditModal(false);
          setTimeout(() => {
            setSelectedReport(null);
          }, 500);
        }}
        onSave={() => fetchReports()}
        showToast={showToast}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedReport && selectedReport.reportId && (
        <ConfirmationDialogue
          id={`${selectedReport.reportId}`}
          title={'Delete Report'}
          text={'Are you sure you want to delete this report?'}
          open={showConfirmDeleteDialogue}
          onClose={() => setShowConfirmDeleteDialogue(false)}
          onConfirm={async () => {
            setShowConfirmDeleteDialogue(false);
            await handleDelete(selectedReport.reportId);
          }}
        />
      )}

      {/* SUCCESS/FAIL MESSAGING */}
      <Toast id='report-success' message={`Report Deleted!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='report-error'
        autoHideDuration={6000}
        message={'We were unable to delete the report at this time. Please try again later. Please contact support if this issue continues.'}
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  columnOrientation: {
    flexDirection: 'column'
  },
  reportContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(2),
    '& > *': {
      width: theme.spacing(30),
      height: theme.spacing(16)
    }
  },
  header: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightLavendar,
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  body: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: theme.spacing(10),
    overflow: 'hidden',
    backgroundColor: colors.secondary.lightLavendar
  },
  footer: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    backgroundColor: colors.secondary.lightestGray
  },
  addButton: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(3)
  },
  mobileAddButton: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(3)
  }
}));
