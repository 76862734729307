import { LetterRunStatusEnums, routes } from '../constants';
import { IFile } from './files';

export interface ILetterRunHistory {
  letterRunHistoryId: number;
  clientId?: number;
  clientName?: string;
  contactId?: number;
  contactName?: string;
  dateTime: string;
  lettersGenerated?: number;
  status: string;
}

export interface ILetterRunFilters {
  name?: string;
}

export interface INewLetterRunFilters {
  contactName?: string;
  contactGroupName?: string;
  monitoredEntityName?: string;
  unitNumber?: string;
  contactGroupNotifications?: 'unselected' | 'on' | 'off';
}

export interface IMissingRequirementEntity {
  clientId: number;
  monitoredEntityId: number;
  monitoredEntityName: string;
  locationId: number;
  locationName: string;
  contactId: number;
  contactName: string;
  contactGroupId: number;
  contactGroupName: string;
  contactGroupAllowsNotifications: boolean;
  deficiencies: string;
  unitNumber: string;
}

export interface IDeficientEntity {
  clientId: number;
  monitoredEntityId: number;
  monitoredEntityName: string;
  locationId: number;
  locationName: string;
  contactId: number;
  contactName: string;
  contactGroupId: number;
  contactGroupName: string;
  contactGroupAllowsNotifications: boolean;
  deficiencies: string;
  unitNumber: string;
}

export interface IDeficiencies {
  clientId?: number;
  certificateHolderId?: number;
  contactId?: number;
  missingRequirementEntities: IMissingRequirementEntity[];
  deficientEntities: IDeficientEntity[];
}

export interface ILetterGenerationProgress {
  letterRunName: string;
  letterRunStatus: LetterRunStatusEnums;
  completedLetters?: number;
  totalLetters?: number;
  utcStartTime: string;
}

export interface IGeneratedLetter {
  letterRunDetailsId: number;
  letterRunId: number;
  contactName: string;
  contactGroupName: string;
  letter: IFile;
  notificationStatus: number;
  failureReason: string;
}

export interface ILetterRun {
  letterRunId: number;
  clientId: number;
  clientName: string;
  dateTime: string;
  lettersGenerated?: number;
  status?: string;
  statusValue?: LetterRunStatusEnums;
  emailLetters?: IGeneratedLetter[];
  mailLetters?: IGeneratedLetter[];
}

export interface IDistributionList {
  contactGroupDistributionListId: number;
  email: string;
  contactType: number;
  isDeleted: boolean;
  addToContactGroup?: boolean;
}

export interface IOnDemandSummary {
  onDemandEmailContent: string;
  letterRunId: number;
  clientId: number;
  contactGroupId: number;
  dateTime: string;
  lettersGenerated: number;
  sourceType: number;
  status: string;
  distributionList: IDistributionList[];
  letter: IFile;
}

// Created from SummaryNotificationViewModel.cs
export interface IOnDemandSummaryNotification {
  letterRunId: number;
  summaryNotification: {
    monitoredEntityId: number;
    /**
     * Files sent with the email.
     */
    attachments: IFile[];
    /**
     * The (HTML) content of the email.
     */
    emailBody: string;
    emailSubject: string;
    letterRunId: number;
    recipients: string[];
    /**
     * Whether or not to attach the latest COI document (for proof of insurance).
     */
    attachLatestDocument: boolean;
  };
}

export interface INotificationStatus {
  letterRunName: string;
  processedEmails: number;
  totalEmails: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ILetterRun = {
  getRoute: (variant: LetterRunStatusEnums) =>
    variant === LetterRunStatusEnums.GeneratingLetters ? routes.letterGenerationProgress : routes.lettersGenerated,

  getContactLetterRoute: (variant: LetterRunStatusEnums) =>
    variant === LetterRunStatusEnums.GeneratingLetters ? routes.contactLetterGenerationProgress : routes.contactLettersGenerated,

  displayStatusEnum: (variant: LetterRunStatusEnums) => {
    if (variant === LetterRunStatusEnums.LetterRunCreated) return 'Letter Run Created';
    if (variant === LetterRunStatusEnums.GeneratingLetters) return 'Generating Letters';
    if (variant === LetterRunStatusEnums.LetterGenerationCompleted) return 'Letter Generation Completed';
    if (variant === LetterRunStatusEnums.SendingEmails) return 'Sending Emails';
    if (variant === LetterRunStatusEnums.LetterRunCompletedWithFailures) return 'Letter Run Completed With Failures';
    if (variant === LetterRunStatusEnums.LetterRunCompleted) return 'Letter Run Completed';
    return '';
  },
};
