import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Fade, CardActions, Button, Grid, Tab, Tabs } from '@material-ui/core';
// components
import { Modal, Loader, Select, BasicFileUploader, CheckboxList, DatePicker } from '../../components';
// types
import { IEntityRequirement, IEnum, IError, ILineOfCoverage } from '../../types';
// fetch
import { addFileToMonitoredEntity, addFileWithAccordProcessing } from '../../fetch';
import { Add } from '@material-ui/icons';
import { useToastContext } from '../../hooks';
import { RequirementAddModalTabEnums as TabEnums } from '../../constants/RequirementAddModalTabEnums';

const statusOptions = [
  { label: 'Applied', value: 1 },
  { label: 'Not Accepted', value: 2 },
];

interface IRequirementFileAddModalProps {
  open: boolean;
  onClose: () => void;
  linesOfCoverage: ILineOfCoverage[];
  monitoredEntityId: number;
  inUseLinesOfCoverage: number[];
  overrideRequirementRows: (newRequirementList: IEntityRequirement[]) => void;
  reloadRequirementsGrid: () => void;
  setAssociatedFiles: (files: IEnum[]) => void;
  setInitialActiveDocument: (file: IEnum) => void;
}

const RequirementFileAddModal: FC<IRequirementFileAddModalProps> = ({
  open,
  onClose,
  linesOfCoverage,
  monitoredEntityId,
  inUseLinesOfCoverage,
  overrideRequirementRows,
  reloadRequirementsGrid,
  setAssociatedFiles,
  setInitialActiveDocument,
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | null>(null);
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [documentDate, setDocumentDate] = useState<string>('');
  const [coverageIds, setCoverageIds] = useState<number[] | []>([]);
  const [selectedTab, setSelectedTab] = useState<TabEnums>(TabEnums.Manual);

  const clearFields = () => {
    setFile(null);
    setStatus(null);
    setExpirationDate('');
    setDocumentDate('');
    setCoverageIds([]);
    setSelectedTab(TabEnums.Manual);
  };

  const handleSaveChanges = async () => {
    setLoading(true);

    try {
      const postData = {
        fileStatus: status,
        monitoredEntityId: monitoredEntityId,
        lineOfCoverageIds: coverageIds.map(c => Number(c)),
        file: file,
        expirationDate: expirationDate,
        documentDate: documentDate,
      };

      if (selectedTab === TabEnums.Manual) {
        const newActiveDocument = await addFileToMonitoredEntity(postData);
        setInitialActiveDocument(newActiveDocument);
        overrideRequirementRows([]);
        reloadRequirementsGrid();
      }

      if (selectedTab === TabEnums.Acord) {
        const processedAccordValues = await addFileWithAccordProcessing(postData);
        overrideRequirementRows(processedAccordValues?.requirements);
        setAssociatedFiles(processedAccordValues?.associatedFiles);
      }

      setStatus(null);
      setLoading(false);
      showToast('success', 'File uploaded successfully.');
      onClose();
    } catch (error) {
      let message = 'Failed to upload file.';
      if (IError.is(error)) {
        message = `Error status ${error.Status}. ${error.Title}. ${error.Detail}`;
      }
      showToast('error', message);
    } finally {
      setLoading(false);
    }
  };

  const lineOfCoverageOptions = linesOfCoverage
    .filter(l => inUseLinesOfCoverage.indexOf(l.lineOfCoverageId) > -1)
    .map(c => ({ id: c.lineOfCoverageId, name: c.name }));

  return (
    <Modal
      maxWidth={'xs'}
      open={open}
      title='Add Document'
      onClose={() => {
        clearFields();
        onClose();
      }}
    >
      {loading && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <div>
          <Grid container spacing={1} className={classes.gridContainer} direction={'column'}>
            <Tabs
              centered
              value={selectedTab}
              onChange={(event: React.ChangeEvent<{}>, newValue: TabEnums) => {
                setSelectedTab(newValue);
              }}
            >
              <Tab label='Manual' value={TabEnums.Manual} />
              <Tab label={'ACORD OCR'} value={TabEnums.Acord} />
            </Tabs>
            <Grid item xs={12}>
              <BasicFileUploader allowedFiles={['application/pdf']} setFileInParent={setFile} />
            </Grid>
            <Grid item xs={12} className={classes.documentStatusContainer}>
              <Select
                name='status'
                id='status'
                value={status}
                label='Document Status'
                showReset={false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus(Number(e.target.value));
                }}
                options={statusOptions.map(s => ({ key: s.value, label: s.label, value: s.value }))}
              />
            </Grid>
            {selectedTab === TabEnums.Manual && (
              <>
                <Grid container spacing={1} className={classes.dateContainer}>
                  {/* EXPIRATION DATE */}
                  <Grid item xs={6}>
                    <DatePicker
                      label='Expiration Date'
                      id='expiration-date-picker'
                      fullWidth
                      required
                      placeholder='Expiration Date'
                      autoComplete='nope'
                      name='expirationDate'
                      value={expirationDate ? new Date(expirationDate) : null}
                      onChange={date => setExpirationDate(date?.toISOString())}
                      onClear={() => setExpirationDate(null)}
                    />
                  </Grid>
                  {/* DOC. DATE */}
                  <Grid item xs={6}>
                    <DatePicker
                      label='Document Date'
                      id='document-date-picker'
                      fullWidth
                      required
                      placeholder='Document Date'
                      autoComplete='nope'
                      name='documentDate'
                      value={documentDate ? new Date(documentDate) : null}
                      onChange={date => setDocumentDate(date?.toISOString())}
                      onClear={() => setDocumentDate(null)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} classes={{ root: classes.list }}>
                  <CheckboxList
                    header='Applicable Lines of Coverage'
                    checkedValuesList={coverageIds}
                    saveCheckboxSelectionToState={updatedCheckedValuesList => setCoverageIds(updatedCheckedValuesList)}
                    checkboxItems={lineOfCoverageOptions}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  clearFields();
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!file || !status || !expirationDate || !documentDate}
                onClick={() => handleSaveChanges()}
                startIcon={<Add />}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </div>
          </CardActions>
        </div>
      </Fade>
    </Modal>
  );
};

export default RequirementFileAddModal;

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
  },
  list: {
    marginTop: theme.spacing(1),
  },
  documentStatusContainer: {
    marginBottom: theme.spacing(1),
  },
  dateContainer: {
    marginTop: theme.spacing(-1),
  },
}));
