import {
  Button,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Edit, History } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { ImportModal, ITableColumn, Modal, PageTitle, Table } from '../../components';
import { userRoles } from '../../constants';
import { UserContext } from '../../context';
import { getClientLocationTypes, getLocationStatuses, importLocations } from '../../fetch';
import { convertToUTC } from '../../helpers';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IEnum, IError, ILocation, ILocationType } from '../../types';
import { AddEditLocations } from './AddEditLocations';


interface IManageLocationsProps {
  locations: ILocation[];
  isLoading: boolean;
  updateLocations: (values: ILocation[]) => void;
  clientId: number;
}

export const ManageLocations: FC<IManageLocationsProps> = ({ locations, updateLocations, isLoading, clientId }) => {
  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();
  const classes = useStyles();

  // const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<ILocation | null>(null);
  const [locationTypes, setLocationType] = useState<ILocationType[]>([]);
  const [locationStatuses, setLocationStatuses] = useState<IEnum[]>([]);
  const [locationStatusMap, setLocationStatusMap] = useState<Record<string, number>>({});
  const [isLocationLoading, setIsLocationLoading] = useState<boolean>(true);
  const [showDivested, setShowDivested] = useState<boolean>(false);
  const [isHistoryModalShowing, setIsHistoryModalShowing] = useState<boolean>(false);
  const [showImportLocationsModal, setShowImportLocationsModal] = useState<boolean>(false);

  const load = async () => {
    setIsLocationLoading(true);
    try {
      const [locationTypeResponse, locationStatusesResponse] = await Promise.all([getClientLocationTypes(), getLocationStatuses()]);
      setLocationType(locationTypeResponse);
      setLocationStatuses(locationStatusesResponse);

      // map location status for simplicity and performance in filtering
      const statusMap = {};
      locationStatusesResponse.forEach(x => (statusMap[x.text] = x.value));
      setLocationStatusMap(statusMap);
    } catch (error) {
      console.error(error);
    }
    setIsLocationLoading(false);
  };
  useEffect(() => {
    load();
  }, []);

  const getLocationTypeDescription = id => {
    if (id) {
      const find = locationTypes.find(x => x.locationTypeId === id);
      return find ? find.description : '';
    }
    return '';
  };

  const shouldShowDivested = (location: ILocation) => {
    // if we shouldn't show divested, and we have statuses, and the first one (most recent) is divested -> return false
    if (!showDivested && location.statuses.length && location.statuses[0].status === locationStatusMap.Divested) {
      return false;
    }

    return true;
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name'
      },
      {
        id: 'locationType',
        accessor: (d: ILocation) => getLocationTypeDescription(d.locationTypeId),
        Header: 'Location Type',
        overrideWidth: 200
      },
      {
        accessor: 'clientLocationCode',
        Header: 'Client Location Code',
        overrideWidth: 160
      },
      {
        accessor: 'address',
        Header: 'Address'
      },
      {
        accessor: 'city',
        Header: 'City'
      },
      {
        accessor: 'state',
        Header: 'State',
        overrideWidth: 100
      },
      {
        accessor: 'status',
        Header: 'Status',
        overrideWidth: 120,
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return (
            <>
              {Array.isArray(original.statuses) && original.statuses.length > 0 && (
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    setSelectedRowItem(original);
                    setIsHistoryModalShowing(true);
                  }}
                >
                  <History />
                </IconButton>
              )}
              {original.statuses.length > 0
                ? locationStatuses.find(({ value }) => value === original.statuses[0].status)?.description ?? null
                : 'Active'}
            </>
          );
        }
      },
      {
        id: 'actions',
        Header: '',
        overrideWidth: 50,
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return (
            <div key={original.locationId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON - This has been commented out in case we want to add it back */}
              {/* <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton> */}
            </div>
          );
        }
      }
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, locationTypes, locationStatuses]);

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title={'Locations'} marginOff />

        <FormGroup row className={classes.divestedToggle}>
          <FormControlLabel control={<Switch checked={showDivested} onChange={() => setShowDivested(!showDivested)} />} label='Show Divested' />
        </FormGroup>

        {userContext?.role === userRoles.ADMIN && (
          <Button color='primary' size='small' onClick={() => setShowImportLocationsModal(true)}>
            Import Locations
          </Button>
        )}
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          loadingPageSize={!Array.isArray(locations) || locations.length === 0 ? 3 : locations.length}
          key={JSON.stringify(locations.filter(location => !location.isDeleted))}
          isLoading={isLocationLoading || isLoading}
          columns={columns}
          data={locations.filter(location => !location.isDeleted && shouldShowDivested(location))}
          headerClasses={classes.tableHeader}
          centerPagination
          stickyHeader
        />
      </div>

      {/* ADD NEW BUTTON */}
      <Fab className={classes.addButton} onClick={() => setShowAddEditModal(true)}>
        <Add />
      </Fab>

      {/* IMPORT LOCATIONS */}
      <ImportModal
        isLoading={isLocationLoading}
        title='Import Locations'
        onClose={() => setShowImportLocationsModal(false)}
        open={showImportLocationsModal}
        allowedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        handleImport={file => {
          setIsLocationLoading(true);
          importLocations(clientId, file)
            .then(response => {
              updateLocations(response);
              showToast('success', 'Successfully imported the locations!', null, true);
            })
            .catch(error => {
              let message = 'There was a problem importing the locations. Please try again.';
              if (IError.is(error)) {
                const bulk = error?.BulkProblems?.filter(str => !!str)?.join(' ') ?? ''
                message = `Error status ${error.Status}. ${error.Title}. ${error.Detail} ${bulk}`;
              }
              showToast('error', message, null, true);
            })
            .finally(() => {
              setShowImportLocationsModal(false);
              setIsLocationLoading(false);
            });
        }}
      />

      {/* ADD/EDIT MODAL */}
      <AddEditLocations
        locationTypes={locationTypes}
        locationStatuses={locationStatuses}
        selectedRowItem={selectedRowItem}
        open={showAddEditModal}
        clientId={clientId}
        onClose={() => {
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
        onSave={locations => {
          updateLocations(locations);
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
      />

      {/* CONFIRM DELETE MODAL - This has been commented out in case we want to add it back  */}
      {/* {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-location-delete'
          title='Delete Location'
          text='Are you sure you want to delete this location?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={() => {
            const deepCopy = JSON.parse(JSON.stringify(locations));
            const deleteRowIndex = deepCopy.findIndex(x => x.locationId === selectedRowItem.locationId);
            if (selectedRowItem.locationId > 0) {
              deepCopy[deleteRowIndex].isDeleted = true;
            } else {
              deepCopy.splice(deleteRowIndex, 1);
            }
            setFieldValue('locations', deepCopy);
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
        />
      )} */}

      {selectedRowItem !== null && Array.isArray(selectedRowItem.statuses) && (
        <Modal
          fullWidth={false}
          maxWidth={'sm'}
          open={isHistoryModalShowing}
          title='Location Status History'
          onClose={() => {
            setSelectedRowItem(null);
            setIsHistoryModalShowing(false);
          }}
        >
          <TableContainer>
            <MUITable size='small' aria-label='location-status-history'>
              <TableBody>
                {selectedRowItem.statuses.map(({ status, effectiveDate, userDisplayName }, i) => (
                  <TableRow key={`${status}-${effectiveDate}-${userDisplayName}-${i}`}>
                    <TableCell align='left'>{locationStatuses.find(_ => _.value === status)?.description ?? null}</TableCell>
                    <TableCell align='left'>{!!effectiveDate && format(new Date(convertToUTC(effectiveDate)), "M/d/yyyy ' at ' h:mma")}</TableCell>
                    <TableCell align='left'>{userDisplayName || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MUITable>
          </TableContainer>
        </Modal>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    },
    divestedToggle: {
      marginLeft: theme.spacing(1)
    },
    gridContainer: {
      width: '100%'
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark
      },
      zIndex: 9999
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5)
      }
    },
    historyList: {
      maxHeight: '60vh'
    }
  };
});
