import { FormControl, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RequirementStatusEnums } from '../../../constants';

import { getCarriersBySearch } from '../../../fetch';
import { useToastContext } from '../../../hooks';
import { IEntityRequirement, IEnum } from '../../../types';

/**
 * Amount of time to wait for new inputs to stop coming in.
 */
// TODO: Constant-ify all `debounce` usages with this constant.
const DEBOUNCE_MS = 500;

interface ICarrierCellProps {
  cell: { row: { original: IEntityRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
  onUpdate: (values: IEntityRequirement[]) => void;
  requirements: IEntityRequirement[];
  touched: boolean;
  setTouched: (arg: boolean) => void;
}

export const CarrierCell: FC<ICarrierCellProps> = ({
  cell: { row: original },
  requirements,
  updateData,
  onUpdate,
  touched,
  setTouched,
}) => {
  const { showToast } = useToastContext();

  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  // Set the initial selected carrier to the data of the rendered row if that data is available
  const [selectedCarrier, setSelectedCarrier] = useState<Omit<IEnum, 'description'> | null>(() => {
    const { carrierName, carrierId } = original.original;
    // carrierId may be void if this Cell is being cleared
    if (!!carrierId) {
      return {
        value: carrierId,
        text: carrierName,
      };
    }
    return null;
  });

  // We need to keep and update the state of the cell normally
  // This tracks the controlled state of the input
  const [searchValue, setSearchValue] = useState('');

  // The list of carriers fetched due to the inputted `searchTerm` from the user
  // Includes the previously fetched results if the user searches multiple times
  const [searchedCarriers, setSearchedCarriers] = useState<Omit<IEnum, 'description'>[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCarriersByName = useCallback(
    debounce(async (searchTerm: string) => {
      // Only allow words of 3 characters or more to trigger the search
      if (searchTerm.length <= 2) {
        return;
      }
      setLoading(true);
      try {
        setSearchedCarriers(await getCarriersBySearch(searchTerm));
      } catch (err) {
        // Show error or fallback message if NOT in prod
        if (process.env.NODE_ENV !== 'production') {
          const message = err instanceof Error && err.message;
          showToast('error', message || 'Failed to search carriers.');
        }
      } finally {
        setLoading(false);
      }
    }, DEBOUNCE_MS),
    []
  );

  useEffect(() => {
    fetchCarriersByName(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // If the row data is changed, sync it up with our local state
  useEffect(() => {
    setSelectedCarrier({
      text: original.original.carrierName,
      value: original.original.carrierId,
    });
    setSearchValue(original.original.carrierName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original.original.carrierId]);

  useEffect(() => {
    const req: IEntityRequirement = original.original;
    if (req.status !== RequirementStatusEnums.Active) {
      return;
    }

    // For each requirement line, Exp Date, Doc Date, & Carrier are required
    // We display a "Field required" validation error on these fields if one is
    // filled out and the others are not.
    const fields = {
      expirationDate: req.expirationDate,
      documentDate: req.documentDate,
      carrierId: req.carrierId,
    };
    const entries = Object.entries(fields);
    const missing = entries.filter(([_, v]) => !v);
    const present = entries.filter(([_, v]) => !!v);

    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (missing.length > 0 && present.length > 0) {
      if (missing.some(([key, _]) => key === 'carrierId')) {
        setErr(true);
      }
    }
  }, [original.original]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableClearable={false}
        id='combo-box-carrier'
        loading={loading}
        loadingText='Loading...'
        onFocus={() => {
          if (touched) {
            return;
          }
          setTouched(true);
        }}
        noOptionsText='No carriers.'
        // Hide previously searched-for carriers unless the search has been triggered
        options={searchValue.length <= 2 ? [] : searchedCarriers}
        getOptionLabel={option => {
          if (typeof option === 'string') return option;
          return option?.text;
        }}
        onChange={(_, carrier) => {
          if (typeof carrier === 'string') {
            return;
          }
          setSelectedCarrier(carrier);
        }}
        value={selectedCarrier?.text}
        renderInput={params => (
          <TextField
            fullWidth
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            onChange={event => setSearchValue(event.target.value)}
            label={touched && err ? 'Field required.' : undefined}
            error={touched && err}
            InputLabelProps={{ shrink: touched && err }}
          />
        )}
        // Write changes to values.requirements - this causes this Cell to
        // re-render with new prop data
        onBlur={() => {
          const index = requirements.findIndex(_ => _.requirementId === original.original.requirementId);
          // Copy the list
          const updatedRequirements = requirements.slice();
          updatedRequirements[index] = {
            ...updatedRequirements[index],
            carrierId: selectedCarrier?.value ?? null,
            carrierName: selectedCarrier?.text ?? null,
          };
          // Send updates to parent causing re-render in this component with new data
          onUpdate(updatedRequirements);
        }}
        PaperComponent={params => (
          <Paper
            {...params}
            style={{
              width: '160%',
              fontSize: '14px',
              visibility: searchValue.length <= 2 ? 'hidden' : 'visible',
            }}
          />
        )}
      />
    </FormControl>
  );
};
