import { RequirementStatusEnums } from "../constants/RequirementStatuses";
import { IEntityRequirement } from "../types";

class RequirementsHelper {
    /**
     * Maps requirements into an array of the in use lines of coverage
     * @param requirements 
     */
    static requirementsToActiveLinesOfCoverage(requirements: IEntityRequirement[]): number[]{
        return requirements.filter(req => req.status === RequirementStatusEnums.Active)
            .map(req => req.lineOfCoverageId)
    }
}

export {RequirementsHelper};