import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader, Page, Table } from '../../components';
import { routes, userRoles } from '../../constants';
import { UserContext } from '../../context';
import { getLetterRunHistory, requeueFailed } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { ILetterRun, ILetterRunFilters, ILetterRunHistory } from '../../types';
import { LetterFilters } from './LetterFilters';

export const Letters = () => {
  const [letterRuns, setLetterRuns] = useState<ILetterRunHistory[]>([]);
  const [filteredLetterRuns, setFilteredLetterRuns] = useState<ILetterRunHistory[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<ILetterRunFilters>({});
  const history = useHistory();
  const classes = useStyles();
  const { showToast } = useToastContext();
  const { userContext } = useContext(UserContext);

  // api
  const fetchLetterRuns = async () => {
    setLoading(true);

    try {
      const res = await getLetterRunHistory();
      const sorted = res?.sort((a, b) => {
        return b.dateTime.localeCompare(a.dateTime);
      });
      setLetterRuns(sorted);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const requeueLetterRunRequeue = async (letterRunId: number, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    try {
      setIsLoading(true);
      await requeueFailed(letterRunId);
      showToast('success', 'Requeued skipped entities!');
    } catch (err) {
      console.error(err);
      showToast('error', err instanceof Error ? err.message : 'Failed to requeue skipped entities.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLetterRuns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Compute table rows based on network-fetched letter runs data
  useEffect(() => {
    let filteredData = letterRuns;

    if (filters.name && filters.name !== '') {
      filteredData = filteredData.filter(
        requirement =>
          (requirement.clientName && requirement.clientName.toLowerCase().includes(filters.name.toLowerCase())) ||
          (requirement.contactName && requirement.contactName.toLowerCase().includes(filters.name.toLowerCase()))
      );
    }

    setFilteredLetterRuns(filteredData);
  }, [filters, letterRuns]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Letter Run Name',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILetterRun } };
        }) => {
          // Ensure non-void status
          if (!original.statusValue) {
            const err = TypeError('Missing "statusValue" field on ILetterRun instance.');
            console.error(err, original);
            showToast('error', 'Unable to navigate to invalid URL.');
          }
          const href = `${ILetterRun.getRoute(original?.statusValue)}/letterRunId=${original.letterRunId}`;
          return (
            <div>
              <a href={href} target='_blank' rel='noreferrer'>
                {original.clientName}
              </a>
            </div>
          );
        },
      },
      {
        id: 'dateTime',
        accessor: original => format(new Date(original.dateTime), "M/d/yyyy ' - ' h:mm a"),
        Header: 'Date/Time',
      },
      {
        accessor: 'lettersGenerated',
        Header: 'Number of Letters Generated',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILetterRun } };
        }) => {
          const [isLoading, setIsLoading] = useState<boolean>(false);
          const shouldShow = original.status === 'Generating Letters';

          return isLoading ? (
            <Loader type={'inline'} />
          ) : (
            shouldShow && (
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  requeueLetterRunRequeue(original.letterRunId, setIsLoading);
                }}
              >
                Requeue Skipped Entities
              </Button>
            )
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []).filter(column => (userContext.role === userRoles.ADMIN ? true : column.accessor !== 'actions'));

  return (
    <Page title='Compliance Letters'>
      <Grid container alignItems={'center'} justify={'space-between'}>
        {/* FILTER BAR */}
        <LetterFilters handleFilter={setFilters} isLoading={isLoading} />

        {/* START NEW LETTER RUN BUTTON */}
        <Button color='primary' onClick={() => history.push(routes.newLetterRun)}>
          Start New Letter Run
        </Button>
      </Grid>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          columns={columns}
          data={filteredLetterRuns}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          stickyHeader
        />
      </div>
    </Page>
  );
};

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
  };
});
