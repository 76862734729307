import { FormControl, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { CoverageTypeEnums } from '../../../constants';
import { IEntityRequirement } from '../../../types';

interface IRequiredAmountCellProps {
  cell: { row: { original: IEntityRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
}

export const RequiredAmountCell: FC<IRequiredAmountCellProps> = ({
  cell: { row: original },
  cell,
  row: { index },
  column: { id },
  editing,
  updateData
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.requiredAmount);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.requiredAmount);
  }, [original.original.requiredAmount]);

  if (original.original.coverageType === CoverageTypeEnums.EVIDENCE) {
    return null;
  }

  return editing && original.original.coverageType !== CoverageTypeEnums.EVIDENCE ? (
    <FormControl fullWidth>
      <NumberFormat
        customInput={TextField}
        onValueChange={values => setValue(values.value)}
        inputProps={{ style: { textAlign: 'right' } }}
        thousandSeparator
        decimalScale={0}
        isNumericString
        prefix='$'
        fullWidth
        name='requiredAmount'
        value={value}
        onBlur={() => updateData('requiredAmount', value ?? '', original.original)}
      />
    </FormControl>
  ) : (
    <div>
      {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
        Number(original.original.requiredAmount)
      )}
    </div>
  );
};
