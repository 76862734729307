import { authFetch } from './';

const baseUrl = '/api/AdminSystem';

export const initiateComplianceJob = async (): Promise<void> => {
    try {
      await authFetch.post(`${baseUrl}/InitiateComplianceJob`, null);
  
    } catch(error) {
      return Promise.reject(error);
    }
};