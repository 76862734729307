import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { screenSizes } from '../../constants';
import { useNavMenuState, UserContext } from '../../context';
import { userLogout } from '../../helpers';
import { ClientSelector } from '../clientSelector';
import { Logo } from './Logo';

export const Header: FC = () => {
  const { userContext, setUserContext } = useContext(UserContext);
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();
  const history = useHistory();
  const { showMenu, setShowMenu } = useNavMenuState();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <header className={isMobile ? classes.mobileHeaderContainer : classes.headerContainer}>
      <Grid container className={isMobile ? classes.mobileGridContainer : ''}>
        <Grid item sm={5} lg={3}>
          <div className={classes.innerCol}>
            {/* MENU BUTTON */}
            <Button className={classes.appMenuButton} onClick={() => setShowMenu(!showMenu)}>
              <MenuIcon className={classes.iconWhite} />
            </Button>

            {/* LOGO */}
            <Logo />
          </div>
        </Grid>
        <Grid item sm={7} lg={9} className={isMobile ? classes.mobileSearchCol : classes.searchCol}>
          <div className={clsx(classes.innerCol, classes.innerFlexEnd)}>
            {/* CLIENT SELECTOR */}
            <ClientSelector />

            {/* DROPDOWN BUTTON */}
            <Button
              onClick={handleClick}
              size='medium'
              className={clsx(classes.headerButton, classes.accountButton)}
              aria-controls='customized-menu'
              aria-haspopup='true'
            >
              <AccountCircleIcon className={clsx(classes.iconWhite, classes.accountIcon)} />
              <span className={clsx(classes.buttonText, classes.accountText)}>{userContext && userContext.name}</span>
              <ExpandMoreIcon className={classes.iconWhite} />
            </Button>

            {/* DROPDOWN MENU */}
            <Menu
              id='customized-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {/* MY ACCOUNT */}
              {/* <MenuItem className={classes.accountItem}>
                <PersonIcon /> My Account
              </MenuItem> */}

              {/* LOGOUT */}
              <MenuItem
                onClick={async () => {
                  await userLogout();
                  await setUserContext(undefined);
                  history.push('/user/login');
                }}
                className={clsx(classes.logOutItem, classes.accountItem)}
              >
                <ArrowForwardOutlinedIcon className={classes.iconRed} /> Logout
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
    </header>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      height: '62px',
      color: theme.palette.common.white
    },
    mobileHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      height: '110px',
      color: theme.palette.common.white
    },
    mobileGridContainer: {
      marginTop: '12px'
    },
    innerCol: {
      display: 'flex',
      height: '100%'
    },
    innerFlexEnd: {
      flex: '1',
      justifyContent: 'flex-end'
    },
    mobileCol: {
      margin: '12px'
    },
    searchCol: {
      paddingRight: '1rem'
    },
    mobileSearchCol: {
      padding: '12px'
    },
    appMenuButton: {},
    fieldContainer: {
      flex: '1',
      paddingRight: '1rem',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        flex: '3'
      }
    },
    headerButton: {
      color: theme.palette.common.white,
      display: 'flex',
      height: '45px',
      marginBottom: 'auto',
      marginTop: 'auto',
      justifyContent: 'space-between'
    },
    filterButton: {
      backgroundColor: '#CE626F',
      marginRight: '1rem'
    },
    iconWhite: {
      fill: theme.palette.common.white
    },
    accountButton: {
      backgroundColor: '#4C6887',
      '&:hover': {
        backgroundColor: darken('#4C6887', 0.4)
      }
    },
    buttonText: {
      color: theme.palette.common.white,
      fontSize: '14px',
      fontWeight: 'normal',
      textTransform: 'none',
      whiteSpace: 'nowrap'
    },
    accountText: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline'
      }
    },
    accountIcon: {
      height: '31px',
      width: '31px',
      marginRight: theme.spacing(0.5)
    },
    logOutItem: {
      color: '#B91F31'
    },
    iconRed: {
      fill: '#B91F31'
    },
    accountItem: {
      fontSize: '14px',
      '& > svg': {
        marginRight: theme.spacing(0.5)
      }
    }
  };
});
