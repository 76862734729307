import React, { FC } from 'react';
import { deepEqual } from 'fast-equals';
import * as Yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { Form, withFormik, FormikProps } from 'formik';
import { Fade, CardActions, Button, TextField, Grid } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
// components
import { Modal, Loader, CheckboxList } from '../../components';
// types
import { ILocation, IMonitoredEntityType } from '../../types';
// fetch
import { postUnitTypes } from '../../fetch';
import { ClientType } from '../../constants';

interface IAddEditUnitTypeFormProps {
  open: boolean;
  useLocations?: boolean;
  onClose: () => void;
  onSave?: (IMonitoredEntity) => void;
  initialValues: IMonitoredEntityType | null;
  locations?: Array<ILocation>;
  showToast?: (type: string, message: string) => void;
  clientId: number;
  clientType: number;
}

const AddEditUnitTypes: FC<IAddEditUnitTypeFormProps & FormikProps<IMonitoredEntityType>> = ({
  open,
  onClose,
  locations,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  useLocations,
  clientType
}) => {
  const classes = useStyles();

  return (
    <Modal
      maxWidth={'xs'}
      open={open}
      title={`${values.monitoredEntityTypeId ? 'Edit' : 'Add'} ${clientType === ClientType.Retail ? 'Unit Type' : 'Contractor Type'}`}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <Grid container spacing={1} className={classes.gridContainer} direction={'column'}>
            <Grid xs={12}>
              {/* UNIT TYPE NAME */}
              <TextField
                fullWidth
                required
                autoComplete='nope'
                label={clientType === ClientType.Retail ? 'Unit Type Name' : 'Contractor Type Name'}
                name='name'
                value={values.name}
                onChange={e => setFieldValue('name', e.target.value)}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            {/* LOCATIONS CHECKBOX LIST */}
            {useLocations &&
              <Grid xs={12}>
                <CheckboxList
                  header={'Locations'}
                  saveCheckboxSelectionToState={(selectedItems) => setFieldValue('locationIds', selectedItems)}
                  checkboxItems={locations.map(location => {
                    return { id: location.locationId, name: location.name };
                  })}
                  checkedValuesList={values.locationIds}
                  enableSelectAll
                />
              </Grid>
            }
          </Grid>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.monitoredEntityTypeId ? <Check /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.monitoredEntityTypeId ? 'Update' : 'Add'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const UnitTypeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  locationIds: Yup.array()
});

export default withFormik<IAddEditUnitTypeFormProps, IMonitoredEntityType>({
  enableReinitialize: true,
  validationSchema: UnitTypeSchema,
  mapPropsToValues: ({ initialValues }) => {
    return {
      monitoredEntityTypeId: 0,
      name: '',
      locationIds: [],
      clientId: null,
      isDeleted: false,
      ...initialValues,
    }
  },
  handleSubmit: async (values, { resetForm, setSubmitting, props: { onClose, onSave, showToast, clientId }}) => {
    setSubmitting(true);

    try {
      const updated = {
        clientId: clientId,
        ...values
      };

      const response = await postUnitTypes(updated);
      onSave(response);
      onClose();
      showToast('success', values.monitoredEntityTypeId ? 'Unit Type Updated' : 'Unit Type Created!');
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      showToast('error', 'We were unable to update the unit type at this time. Please try again later. Please contact support if this issue continues.');
    }
  }
})(AddEditUnitTypes);


const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75)
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  },
  placeholder: {
    '& > div': {
      color: theme.palette.grey[400]
    }
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
