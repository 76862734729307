import { authFetch } from './';
import { IPortfolio, IPortfolioListItem } from '../types';

const baseUrl = '/api/Portfolio';

export const getPortfolios = async (clientId: number): Promise<IPortfolioListItem[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/List?ClientId=${clientId}`);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};

export const updatePortfolio = async (data: IPortfolio): Promise<IPortfolio[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/`, data);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating portfolio. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};