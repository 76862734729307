import React, { FC } from 'react';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { Add, Save } from '@material-ui/icons';
import { Fade, CardActions, Button, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, withFormik, FormikProps } from 'formik';
// components
import { Modal, Loader } from '../../components';
// fetch
import { updateLocationType, createLocationType } from '../../fetch';
// styles
import { colors } from '../../styles';
// types
import { ILocationType } from '../../types';


interface IAddEditLocationTypesFormProps {
  initialValues: ILocationType | {};
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  showToast: (type: string, message: string) => void;
}

export const AddEditLocationTypes: FC<IAddEditLocationTypesFormProps & FormikProps<ILocationType>> = ({
  open,
  onClose,
  onSave,
  initialValues,
  resetForm,
  isSubmitting,
  values,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur
}) => {
  const classes = useStyles();

  return (
    <Modal
      maxWidth='sm'
      open={open}
      title={values.locationTypeId ? 'Edit Location Type' : 'Add New Location Type'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <div className={classes.content}>
            <TextField
              required
              fullWidth
              autoComplete='nope'
              label='Description'
              name='description'
              value={values.description}
              className={classes.formTextField}
              onBlur={handleBlur}
              onChange={e => setFieldValue('description', e.target.value)}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </div>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.locationTypeId ? <Save /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.locationTypeId ? 'Save' : 'Add Location Type'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const LocationTypeSchema = Yup.object().shape({
  description: Yup.string(),
  locationTypeId: Yup.number()
});

export default withFormik <IAddEditLocationTypesFormProps, ILocationType>({
  enableReinitialize: true,
  validationSchema: LocationTypeSchema,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      locationTypeId: 0,
      description: '',
      ...initialValues
    };
  },
  handleSubmit: async (values, { resetForm, props: { onClose, onSave, showToast }}) => {
    try {
      if (values.locationTypeId) {
        await updateLocationType(values);
        showToast('success', 'Location Type Added!');
        onClose();
        onSave();
        resetForm();
      } else {
        await createLocationType(values);
        showToast('success', 'Location Type Added!');
        onClose();
        onSave();
        resetForm();
      }
    } catch (error) {
      showToast('error', 'We were unable to create the location type at this time. Please try again later. Please contact support if this issue continues.');
    }
  }
})(AddEditLocationTypes);


const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    marginBottom: theme.spacing(3)
  },
  content: {
    marginTop: '31px',
  },
  columnPadding: {
    paddingRight: theme.spacing(9)
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  }
}));
