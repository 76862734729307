import { Card, CardContent, Container, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import * as Yup from 'yup';

import BBLogo from '../../../assets/logo.png';
import background from '../../../assets/background.png';
import { ArrowBack } from '@material-ui/icons';
import { LoginHeader, Loader, Toast, Button } from '../../../components';
import { screenSizes, routes } from '../../../constants';
import { forgotUsernameEmail } from '../../../fetch';
import { colors } from '../../../styles';

interface IForgotUsernameValues {
  email: string;
}

const ForgotUsernameSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
});

export const ForgotUsername: FC = () => {
  const isMobile = useMedia(screenSizes.mobile);
  const email = useRef(null);
  const history = useHistory<{ from: { pathname: string } & Record<string, unknown> }>();
  const classes = useStyles();

  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState<null | { message: string }>(null);

  useEffect(() => {
    document.title = `Forgot Username | Beecher Carlson`;
    setIsLoading(false);
  }, []);

  const handleFormSubmit = async (values: IForgotUsernameValues, actions: FormikHelpers<IForgotUsernameValues>) => {
    try {
      const payload = { emailAddress: values.email };
      await forgotUsernameEmail(payload);
      setSuccess({
        message: `We have sent an email to ${payload.emailAddress}. If this account exists, your username will be provided`,
      });
      setTimeout(() => {
        history.push(routes.login);
      }, 3000);
    } catch (error) {
      console.dir(error);
      if (error?.detail) {
        setError(error?.detail);
      } else {
        console.log('error', error);
        setError('Something went wrong. Please try again later.');
      }
      email?.current?.focus();
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <LoginHeader />
      <div className={classes.loginContainer}>
        {isLoading && (
          <Loader position='centered' type='fullscreen'>
            Loading...
          </Loader>
        )}
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={ForgotUsernameSchema}
          onSubmit={async (values: IForgotUsernameValues, actions: FormikHelpers<IForgotUsernameValues>) =>
            handleFormSubmit(values, actions)
          }
        >
          {(formikProps: FormikProps<IForgotUsernameValues>) => (
            <Form data-testid='forgot-username-form' onSubmit={formikProps.handleSubmit} className={classes.formik}>
              <Container maxWidth='sm'>
                <Grid alignItems='center' container={true} direction='column' justify='center'>
                  <Card>
                    <CardContent className={isMobile ? classes.mobileFormContainer : classes.formContainer}>
                      <Grid container={true} direction='column' alignItems='center'>
                        {/* B&B LOGO */}
                        <img src={BBLogo} alt='logo' className={isMobile ? classes.mobileLogo : classes.logo} />

                        {/* WELCOME MESSAGE */}
                        <Typography className={isMobile ? classes.mobileWelcomeMessage : classes.welcomeMessage}>
                          Forgot Username.
                        </Typography>

                        <div className={isMobile ? classes.mobileInputContainer : classes.inputContainer}>
                          <FormControl margin='normal'>
                            <Field name='email'>
                              {({ field, form }: FieldProps<IForgotUsernameValues>) => (
                                <TextField
                                  {...field}
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.email && form.errors && form.errors.email)}
                                  fullWidth={true}
                                  helperText={form.touched.email && form.errors && form.errors.email}
                                  inputRef={email}
                                  label='Email'
                                  margin='none'
                                  required={true}
                                  value={formikProps.values.email}
                                />
                              )}
                            </Field>
                          </FormControl>

                          {/* RESET BUTTONS */}
                          <div className={isMobile ? classes.mobileResetButtons : classes.resetButtons}>
                            <FormControl margin={isMobile ? 'dense' : 'normal'}>
                              <Button
                                color='secondary'
                                className={isMobile ? classes.mobileLoginButton : classes.loginButton}
                                disabled={
                                  !formikProps.dirty ||
                                  formikProps.isSubmitting ||
                                  !formikProps.isValid ||
                                  !!success?.message
                                }
                                id='submit'
                                loading={formikProps.isSubmitting ? 'Sending' : ''}
                                size='large'
                                type='submit'
                                variant='contained'
                                startIcon={<ArrowForwardOutlinedIcon />}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    formikProps.handleSubmit();
                                  }
                                }}
                              >
                                {!formikProps.isSubmitting ? 'Request' : ''}
                              </Button>
                            </FormControl>
                            <FormControl margin={isMobile ? 'dense' : 'normal'}>
                              <Button
                                color='secondary'
                                className={isMobile ? classes.mobileLoginButton : classes.loginButton}
                                id='submit'
                                disabled={formikProps.isSubmitting || !!success?.message}
                                size='large'
                                type='submit'
                                variant='contained'
                                startIcon={<ArrowBack />}
                                onClick={() => history.push('/')}
                              >
                                Back
                              </Button>
                            </FormControl>
                          </div>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>

        {/* SUCCESS */}
        <Toast
          id='reset-success'
          message={success?.message}
          onClick={() => setSuccess(null)}
          onClose={() => setSuccess(null)}
          open={!!success}
          variant='success'
        />

        {/* FAILURE */}
        <Toast
          id='reset-error'
          message={error}
          onClick={() => setError(null)}
          onClose={() => setError(null)}
          open={!!error}
          variant='error'
        />
      </div>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    loginContainer: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflow: 'auto',
    },
    formik: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainer: {
      width: '486px',
      minHeight: '564px',
      height: '100%',
      padding: '0px',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    mobileFormContainer: {
      maxWidth: '328px',
      minHeight: '386px',
      maxHeight: '100%',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    logo: {
      width: '177px',
      height: '198px',
      marginTop: '56px',
    },
    mobileLogo: {
      maxWidth: '80px',
      maxHeight: '90px',
      marginTop: '12px',
    },
    welcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '19px',
      marginBottom: '20px',
      fontWeight: 600,
    },
    mobileWelcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '9px',
      marginBottom: '10px',
      fontWeight: 600,
      textAlign: 'center',
    },
    inputContainer: {
      width: '321px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    mobileInputContainer: {
      width: '205px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    textField: {
      width: '321px',
    },
    mobileTextField: {
      width: '205px',
    },
    loginButton: {
      marginTop: '32px',
    },
    mobileLoginButton: {
      marginTop: '16px',
    },
    resetButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '321px',
    },
    mobileResetButtons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});
