import React, { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Select } from '../../components';
import { Button, InputAdornment, TextField, IconButton } from '@material-ui/core';
// Icons
import { Clear } from '@material-ui/icons';
// Constants
import { screenSizes, ClientTypeItems } from '../../constants';
// Models
import { IManageClientsFilters } from '../../types';

interface IManageClientsFiltersProps {
  handleFilter: (filters: IManageClientsFilters) => void;
  isLoading: boolean;
}

export const ManageClientsFilters: FC<IManageClientsFiltersProps> = ({ handleFilter, isLoading }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const isTablet = useMedia(screenSizes.tablet);

  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [clientType, setClientType] = useState<string>('');

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce((codeFilter, nameFilter, clientTypeFilter) => {
      handleFilter({
        code: codeFilter,
        name: nameFilter,
        clientType: clientTypeFilter
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(code, name, clientType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, name, clientType]);

  return (
    <div className={!isMobile ? classes.filterBarContainer : classes.mobileFilterBarContainer}>
      <TextField
        placeholder='Code'
        className={`${classes.filter} ${classes.codeField}`}
        value={code}
        onChange={({ target: { value } }) => setCode(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!code && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setCode('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength: 5
        }}
      />
      <TextField
        placeholder='Name'
        className={`${classes.filter} ${classes.nameField}`}
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!name && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength: 50
        }}
      />

      <Select
        name='clientType'
        id='client-type-select'
        value={clientType}
        onChange={({ target: { value } }) => setClientType(value as string)}
        displayEmpty
        defaultValue='Client Type'
        resetValue=''
        className={`${classes.filter} ${classes.select} ${clientType === '' ? classes.placeholder : ''}`}
        options={[{ key: 'undefined', value: '', label: 'Client Type' }, ...ClientTypeItems]}
      />

      {(!!code || !!name || !!clientType) && (
        <Button
          startIcon={<Clear />}
          className={isMobile || isTablet ? classes.mobileResetButton : classes.resetButton}
          onClick={() => {
            setCode('');
            setName('');
            setClientType('');
          }}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    },
    mobileFilterBarContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1)
    },
    filter: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    codeField: {
      width: '100px'
    },
    nameField: {
      width: '250px'
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    placeholder: {
      '& > div': {
        color: theme.palette.grey[400]
      }
    },
    select: {
      maxWidth: '150px'
    }
  };
});
