import { IClient } from "./clients";

export interface IMonitoredEntityComment {
  /**
   * The unique ID of this comment entry.
   */
  commentId: number;
  commentType: CommentType;
  text: string;
  dateCreated: null | string;
  userName: string;
  /**
   * The ID of the entity, the type of entity is based on commentType.
   */
  relatedObjectId: string;
}

export enum CommentType {
  Entities = 1,
  ContactGroups = 2,
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IMonitoredEntityComment = {
  /**
   * Returns the display string based on the given comment type variant.
   */
  displayCommentType(commentType: CommentType, entityType: IClient['entityType']): string {
    if (commentType === CommentType.Entities) {
      return entityType.slice(0, entityType.length - 1);
    }
    if (commentType === CommentType.ContactGroups) {
      return 'Contact Group';
    }
    throw Error('Unimplemented');
  },

  /**
   * Narrows/type-guards a value of uknown type to a CommentType variant.
   */
  isCommentType(value: unknown): value is CommentType {
    if (typeof value === 'number' && Object.keys(CommentType).includes(String(value))) {
      return true;
    }
    return false;
  }
};
