import { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { UserContext } from '../../context';
import { routes } from '../../constants';
import { logInfo } from '../../services';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ roles, ...props }) => {
  const userContext = useContext(UserContext);

  const location = useLocation();

  if (userContext.isFetching) {
    return null;
  }

  // if we aren't fetching user and have no context they need to log in
  if (!userContext.userContext && !userContext.isFetching) {
    logInfo({}, 'sending user to login');
    console.info('sending user to login');
    return (
      <Redirect
        to={{
          pathname: routes.login, // redirect to login screen
          state: { from: location },
        }}
      />
    );
  }

  // if the user role doesn't exist on the route redirect them to the route
  if (userContext.userContext && roles.indexOf(userContext.userContext.role) === -1) {
    logInfo({}, 'user redirected for not having roles');
    console.info('user redirected for not having roles');
    return (
      <Redirect
        to={{
          pathname: routes.manageRequirements,
        }}
      />
    );
  }

  logInfo({}, `sending user to ${props.path}`);
  console.info(`letting user through PrivateRoute to ${props.path}`);
  return <Route {...props} />;
};
