import { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CardActions, Fade, Grid } from '@material-ui/core';
import { Modal, Loader, CheckboxList } from '../../components';
import { Add, Refresh } from '@material-ui/icons';
import { useToastContext } from '../../hooks';
import { getDefaultRequirementsForEntity, updateRequirements } from '../../fetch';
import { IEntityRequirement, ILineOfCoverage } from '../../types';

interface IAddRequirementModalProps {
  open: boolean;
  onClose: () => void;
  clientId: number;
  linesOfCoverage: ILineOfCoverage[];
  monitoredEntityId: number;
  resetForm: Function;
  load: () => Promise<void>;
  unwaivedRequirements: IEntityRequirement[];
}

export const AddRequirementModal: FC<IAddRequirementModalProps> = ({
  open,
  onClose,
  clientId,
  linesOfCoverage,
  monitoredEntityId,
  resetForm,
  unwaivedRequirements,
  load,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linesOfCoverageIds, setLinesOfCoverageIds] = useState<number[]>([]);
  const [linesOfCoverageOptions, setLinesOfCoverageOptions] = useState<{ id: number; name: string }[]>([]);
  const [requirements, setRequirements] = useState<IEntityRequirement[]>([]);
  const { showToast } = useToastContext();
  const classes = useStyles();

  const getDefaultRequirements = async () => {
    if (!clientId) return;
    setIsLoading(true);

    try {
      // Get the default requirements
      const response = await getDefaultRequirementsForEntity(clientId);
      if (!response.length) {
        showToast('error', 'No default requirements for this client.');
        return;
      }

      // Remove requirements that have already been added
      const newRequirements = response.filter(requirement => {
        return !unwaivedRequirements?.some(req => req.lineOfCoverageId === requirement.lineOfCoverageId);
      });
      setRequirements(newRequirements);

      const locOptions = newRequirements.map(loc => {
        const matchingLineOfCoverage = linesOfCoverage?.find(l => l.lineOfCoverageId === loc.lineOfCoverageId);
        return { id: matchingLineOfCoverage.lineOfCoverageId, name: matchingLineOfCoverage.name };
      });

      setLinesOfCoverageOptions(locOptions);
    } catch (error) {
      console.log('error', error);
      if (error?.Title) showToast('error', error.Title);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDefaultRequirements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateRequirements = async () => {
    setIsLoading(true);

    try {
      const selectedRequirements = requirements.filter(requirement =>
        linesOfCoverageIds.some(locId => locId === requirement.lineOfCoverageId)
      );

      // Format the data for the post
      const updatedValues = {
        monitoredEntityId: monitoredEntityId,
        requirements: [...selectedRequirements],
      };
      const { requirements: updatedRequirements } = await updateRequirements(updatedValues);

      // Take the response and update the form with the newly saved data
      const formatted = {
        monitoredEntityId: monitoredEntityId,
        requirements: [...updatedRequirements],
      };
      resetForm({ values: formatted });
      onClose();
      showToast('success', 'Requirements successfully added!');
      setIsLoading(false);
    } catch (error) {
      showToast('error', 'Requirements were not added. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title={'Add Requirements'}
      onClose={() => onClose()}
      maxWidth={'xs'}
      customButtonHandler={async () => {
        setIsLoading(true);
        try {
          await load();
          await getDefaultRequirements();
        } catch {
          showToast('error', 'There was a problem retrieving the requirements. Please try again.');
        } finally {
          setIsLoading(false);
        }
      }}
      customButtonIcon={<Refresh />}
    >
      {isLoading && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={12}>
              <CheckboxList
                header='Requirements'
                checkedValuesList={linesOfCoverageIds}
                saveCheckboxSelectionToState={updatedCheckedValuesList =>
                  setLinesOfCoverageIds(updatedCheckedValuesList)
                }
                checkboxItems={linesOfCoverageOptions}
                emptyStateMessage='All client requirements already exist on this entity.'
              />
            </Grid>
          </Grid>

          {/* MODAL ACTIONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button
                className={classes.saveButton}
                disabled={!linesOfCoverageIds.length}
                type='button'
                onClick={() => handleUpdateRequirements()}
                variant='contained'
                color='primary'
                startIcon={<Add className={classes.addIcon} />}
              >
                Add
              </Button>
            </div>
          </CardActions>
        </>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  addIcon: {
    marginRight: theme.spacing(0.5),
  },
}));
