export interface IReport {
  reportId: number;
  name: string;
  description: string;
  enableExternalAccess: boolean;
  clientType: number;
  clientId: number;
  powerBIReportId: string;
  reportSecurityType: IReportSecurityType;
}

export interface IEmbedParams {
  type: string;
  embedReport: IEmbedReport[];
  embedToken: IEmbedToken;
}

export interface IEmbedReport {
  embedUrl: string;
  reportId: string;
  reportName: string;
}

export interface IEmbedToken {
  expiration: string;
  token: string;
  tokenId: string;
}

export enum IReportSecurityType {
  PowerBI = 1,
  PaginatedReport = 2,
  NoSecurity = 3
}
