import { isAfter } from 'date-fns';
// helpers
import { getEnvironment } from './environment';
import { setCookie, getCookie, removeCookie } from './cookies';
import { setLocalStorage, removeLocalStorage, getLocalStorage } from './local-storage';
// models
import { ILoginUser } from '../types';
import { logInfo } from '../services';

/**
 * Set user to local storage
 * @param user ILoginUser
 */
export const setUserLocalStorage = async (user: ILoginUser | undefined): Promise<void> => {
  if (user) {
    await setLocalStorage(`beechercarlson-${getEnvironment()}-user`, user);
  }
};

/**
 * Remove user from local storage
 */
export const removeUserLocalStorage = async (): Promise<void> => {
  console.info('removing user from local storage')
  await removeLocalStorage(`beechercarlson-${getEnvironment()}-user`);
};

/**
 * Get user from local storage
 */
export const getUserLocalStorage = async (): Promise<ILoginUser | undefined> => {
  return await getLocalStorage(`beechercarlson-${getEnvironment()}-user`);
};

/**
 * Set access token cookies for a user
 * @param accessToken string
 * @param tokenExpiresAt string
 */
export const setUserAccessTokenCookies = (accessToken: string, expiresAt: string): void => {
  setCookie(`beechercarlson-${getEnvironment()}-token`, {accessToken, expiresAt}, { });
  console.info('access token cookie set')
};

/**
 * Set refresh token cookies for a user
 * @param accessToken string
 * @param tokenExpiresAt string
 */
export const setuserRefreshTokenCookies = (refreshToken: string, expiresAt: string) : void => {
  setCookie(`beechercarlson-${getEnvironment()}-refresh-token`, {refreshToken, expiresAt}, { });
  console.info('refresh token cookie set')
}

/**
 * Remove access token and refresh token cookies. Used when logging out.
 */
export const removeUserTokenCookies = (): void => {
  console.info('removing auth cookies')
  removeCookie(`beechercarlson-${getEnvironment()}-token`);
  removeCookie(`beechercarlson-${getEnvironment()}-refresh-token`);
};

/**
 * Get user access token
 * @returns string | undefined
 */
export const getUserAccessTokenCookie = (): string | undefined => {
  return getCookie(`beechercarlson-${getEnvironment()}-token`);
};

/**
 * Get user access token
 * @returns string | undefined
 */
export const getUserRefreshTokenCookie = (): string | undefined => {
  return getCookie(`beechercarlson-${getEnvironment()}-refresh-token`);
};

/**
 * Check to see if a user's JWT token has expired
 * @returns boolean
 */
export const getIsUserExpired = (tokenExpiresAt?: string): boolean => {
  let isExpired = true;

  if (tokenExpiresAt) {
    const now = new Date();
    const expires = new Date(tokenExpiresAt);
    if (!isAfter(now, expires)) {
      isExpired = false;
    }
  }

  return isExpired;
};

/**
 * When logging a user out, remove their tokens and local storage
 */
export const userLogout = async () => {
  logInfo({}, 'userLogout called')
  await removeUserLocalStorage();
  removeUserTokenCookies();
  sessionStorage.clear();
};
