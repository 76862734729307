import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import { colors } from './colors';

const baseFontSize = 14; // .875rem

export const theme: Theme = createMuiTheme({
  spacing: 16, // 1rem
  palette: {
    common: {
      black: colors.secondary.black,
      white: colors.secondary.white
    },
    type: 'light',
    primary: {
      light: lighten(colors.primary.navyBlue, 0.1),
      main: colors.primary.navyBlue,
      dark: darken(colors.primary.navyBlue, 0.4),
      contrastText: colors.secondary.white
    },
    secondary: {
      light: lighten(colors.primary.accentRed, 0.1),
      main: colors.primary.accentRed,
      dark: darken(colors.primary.accentRed, 0.4),
      contrastText: colors.secondary.white
    },
    error: {
      main: colors.primary.accentRed
    },
    warning: {
      main: colors.secondary.cheetahYellow
    },
    background: {
      default: colors.secondary.white
    }
  },
  typography: {
    fontSize: baseFontSize,
    h1: {
      fontSize: baseFontSize * 2.25
    },
    h2: {
      fontSize: baseFontSize * 2
    },
    h3: {
      fontSize: baseFontSize * 1.75
    },
    h4: {
      fontSize: baseFontSize * 1.5
    },
    h5: {
      fontSize: baseFontSize * 1.25
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: baseFontSize
      }
    },
    // https://stackoverflow.com/questions/76228510/mui-textfield-outline-overlaps-label
    // Fixes the issue with the input label line cutting through the text when it is shrunk
    // The label needed to be the same font size as the input
    MuiInputLabel: {
        root: {
          fontSize: baseFontSize
        }
    },
    MuiTableCell: {
      head: {
        padding: '3px 6px !important',
      },
      body: {
        height: '48px !important',
        padding: '3px 6px !important',
      }
    },
    
  },
});
