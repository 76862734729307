import React, { FC } from 'react';
import * as Yup from 'yup';
import { useMedia } from 'react-use';
import { deepEqual } from 'fast-equals';
import { Formik, Form } from 'formik';
import { Fade, CardActions, Button, TextField } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { Modal, Loader } from '../../components';
// styles
import { colors } from '../../styles';
// constants
import { screenSizes } from '../../constants';
// types
import { IClient } from '../../types';
// fetch
import { IClientCertificateHolder } from '../../types/clientCertificateHolders';
import {
  createClientCertificateHolder,
  getClientCertificateHolders,
  updateClientCertificateHolder,
} from '../../fetch/clientCertificateHolders';

interface IAddEditClientCertificateHolders
  extends Pick<IClient, 'entityType' | 'useLocations' | 'clientId' | 'locations'> {
  open: boolean;
  onClose: () => void;
  onSave: (values: IClientCertificateHolder[]) => void;
  selectedRowItem: IClientCertificateHolder | null;
  showToast: (type: string, message: string) => void;
}

const PortfolioSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

export const AddEditClientCertificateHolders: FC<IAddEditClientCertificateHolders> = ({
  open,
  onClose,
  selectedRowItem,
  onSave,
  clientId,
  showToast,
}) => {
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          clientCertificateHolderId: 0,
          name: '',
          ...selectedRowItem,
        }}
        validationSchema={PortfolioSchema}
        onSubmit={async (values, actions) => {
          try {
            const updated: IClientCertificateHolder = {
              name: values.name,
              certificateHolderId: values.certificateHolderId,
              clientId: clientId,
            };

            if (updated?.certificateHolderId) {
              await updateClientCertificateHolder(updated);
            } else {
              await createClientCertificateHolder(updated);
            }

            showToast(
              'success',
              values.certificateHolderId ? 'Certificate Holder Updated' : 'Certificate Holder Created!'
            );

            const response = await getClientCertificateHolders(clientId);
            onSave(response);
            actions.resetForm();
          } catch (error) {
            showToast(
              'error',
              'We were unable to create the portfolio at this time. Please try again later. Please contact support if this issue continues.'
            );
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              maxWidth={'sm'}
              open={open}
              title={selectedRowItem ? 'Edit Certificate Holder' : 'Add New Certificate Holder'}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <div className={isMobile ? classes.mobileContent : classes.content}>
                    <div className={classes.formColumn}>
                      {/* Client Certificate Holder name */}
                      <TextField
                        fullWidth
                        required
                        autoComplete='nope'
                        label='Certificate Holder Name'
                        name='name'
                        value={values.name}
                        className={classes.formTextField}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </div>
                  </div>

                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              onClose();
                            } else {
                              return;
                            }
                          } else {
                            onClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid}
                        type='submit'
                        startIcon={selectedRowItem ? <Check /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {selectedRowItem ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px',
  },
  formTextField: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: '31px',
    display: 'flex',
  },
  mobileContent: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
  menuOptions: {
    color: theme.palette.common.black,
  },
  filter: {
    marginRight: '14px',
    marginBottom: '37px',
  },
  columnPadding: {
    paddingRight: theme.spacing(1),
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toggle: {
    marginBottom: theme.spacing(1),
  },
  listContainer: {
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}));
