import { Button, Fab, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { FC, useMemo, useState } from 'react';

import { ConfirmationDialogue, ITableColumn, Loader, PageTitle, Table } from '../../components';
import { updateAdditionalInsured } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IAdditionalInsured, IClient, ILineOfCoverage } from '../../types';
import AddEditAdditionalInsured from './AddEditAdditionalInsured';
import { ReplaceAdditionalInsured } from './ReplaceAdditionalInsured';

interface IAdditionalInsuredProps
  extends Pick<IClient, 'entityType' | 'clientId' | 'clientType' | 'additionalInsureds' | 'locations'> {
  isLoading: boolean;
  useLocations: boolean;
  filteredLinesOfCoverage: ILineOfCoverage[];
  updateAdditionalInsureds: (values: IAdditionalInsured[]) => void;
  linesOfCoverage: ILineOfCoverage[];
}

export const AdditionalInsured: FC<IAdditionalInsuredProps> = ({
  additionalInsureds = [],
  useLocations,
  isLoading,
  locations,
  linesOfCoverage,
  filteredLinesOfCoverage,
  clientId,
  updateAdditionalInsureds,
  entityType,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IAdditionalInsured | null>(null);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
  const classes = useStyles();
  const { showToast } = useToastContext();

  const getLocationCell = location => {
    if (!location.locationIds) {
      return;
    }

    const nameList = [];

    location.locationIds.forEach(x => {
      const matchingLine = locations.find(y => x === y.locationId);
      if (matchingLine) {
        nameList.push(matchingLine.name);
      }
    });

    return nameList.join(', ');
  };

  const getLineOfCoverageCell = lineOfCoverage => {
    if (!lineOfCoverage.linesOfCoverage) {
      return;
    }

    const nameList = [];

    lineOfCoverage.linesOfCoverage.forEach(x => {
      const matchingLine = linesOfCoverage?.find(y => x.lineOfCoverageId === y.lineOfCoverageId);
      if (matchingLine) {
        nameList.push(matchingLine.name);
      }
    });

    return nameList.join(', ');
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name',
      },
      useLocations && {
        id: 'locations',
        accessor: location => getLocationCell(location),
        Header: 'Default Locations',
      },
      {
        id: 'lineOfCoverage',
        accessor: lineOfCoverage => getLineOfCoverageCell(lineOfCoverage),
        Header: 'Lines of Coverage',
      },
      {
        accessor: ({ isDefault }) => (isDefault ? 'Yes' : 'No'),
        Header: 'Default',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IAdditionalInsured } };
        }) => {
          return (
            <div key={original.additionalInsuredId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInsureds]);

  return (
    <div>
      <div className={classes.header}>
        {/* HEADER */}
        <PageTitle title={'Additional Insured'} marginOff />
        {/* REPLACE ADDITIONAL INSURED BUTTON */}
        <Button color={'primary'} onClick={() => setShowReplaceModal(true)}>
          Replace Additional Insured
        </Button>
      </div>
      {submitting && <Loader type='overlay' position='centered' />}
      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          loadingPageSize={
            !Array.isArray(additionalInsureds) || additionalInsureds.length === 0 ? 3 : additionalInsureds.length
          }
          key={JSON.stringify(additionalInsureds.filter(insured => !insured.isDeleted))}
          columns={columns}
          data={additionalInsureds.filter(insured => !insured.isDeleted)}
          headerClasses={classes.tableHeader}
          hidePagination
          stickyHeader
        />
      </div>
      {/* ADD NEW BUTTON */}
      <Fab
        className={classes.addButton}
        onClick={() => {
          setAddMode(true);
          setShowAddEditModal(true);
        }}
      >
        <Add />
      </Fab>
      {/* ADD/EDIT MODAL */}
      <AddEditAdditionalInsured
        entityType={entityType}
        addMode={addMode}
        locations={locations}
        useLocations={useLocations}
        linesOfCoverage={filteredLinesOfCoverage}
        initialValues={selectedRowItem}
        open={showAddEditModal}
        showToast={showToast}
        clientId={clientId}
        onClose={() => {
          setSelectedRowItem(null);
          setShowAddEditModal(false);
          setAddMode(false);
        }}
        onSave={additionalInsureds => {
          updateAdditionalInsureds(additionalInsureds);
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
      />

      {/* REPLACE AI MODAL */}
      <ReplaceAdditionalInsured
        open={showReplaceModal}
        onClose={() => setShowReplaceModal(false)}
        locations={locations}
        useLocations={useLocations}
        additionalInsureds={additionalInsureds}
      />

      {/* CONFIRM DELETE MODAl */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-additional-insured-delete'
          title='Delete Additional Insured'
          text='Are you sure you want to delete this additional insured?'
          open={showConfirmDeleteDialogue}
          customContent={
            <Typography color='error'>
              WARNING: This will also remove this Additional Insured from all existing {entityType}
            </Typography>
          }
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            try {
              // @ts-ignore
              const response = await updateAdditionalInsured({
                ...selectedRowItem,
                isDeleted: true,
              });
              updateAdditionalInsureds(response);
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
              setSubmitting(false);
            } catch (error) {
              setSubmitting(false);
              showToast('error', 'There was an error deleting the additional insured. Please try again.');
            }
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      justifyContent: 'space-between',
    },
    gridContainer: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
