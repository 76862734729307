import { Fab, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { FC, useContext, useMemo, useState } from 'react';

import { ConfirmationDialogue, ITableColumn, PageTitle, Table } from '../../components';
import { userRoles } from '../../constants';
import { UserContext } from '../../context';
import { upsertContactGroup } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IContact, IContactGroup } from '../../types';
import { AddEditContactGroup } from './AddEditContactGroup';

interface IContactGroupsTableProps {
  contactGroups: IContactGroup[];
  isLoading: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  contact: IContact;
  setContactData: React.Dispatch<React.SetStateAction<IContact>>;
  setContactDataClone: React.Dispatch<React.SetStateAction<IContact>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContactGroupsTable: FC<IContactGroupsTableProps> = ({
  contactGroups = [],
  isLoading,
  handleSubmit,
  contact,
  setContactData,
  setContactDataClone,
  setLoading,
}) => {
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [contactGroupBeingEdited, setContactGroupBeingEdited] = useState<IContactGroup | null>(null);

  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        Header: 'Client',
        accessor: 'clientName',
      },
      {
        id: 'address1',
        accessor: original => (original.address ? original.address.address1 : ''),
        Header: 'Address',
      },
      {
        Header: 'City',
        accessor: (original: IContactGroup) => original.address?.city ?? '',
      },
      {
        Header: 'State',
        accessor: (original: IContactGroup) => original.address?.state ?? '',
      },
      {
        id: 'emails',
        accessor: original =>
          original.distributionLists ? original.distributionLists.filter(obj => !obj.isDeleted).length : 0,
        Header: 'Number of Emails',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IContactGroup } };
        }) => {
          return (
            <div key={original.contactGroupId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                classes={{ root: classes.edit }}
                size='small'
                onClick={() => {
                  setContactGroupBeingEdited(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              {userContext.role === userRoles.ADMIN && (
                <IconButton
                  classes={{ root: classes.delete }}
                  size='small'
                  onClick={() => {
                    setContactGroupBeingEdited(original);
                    setShowConfirmDeleteDialogue(true);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactGroups]);

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Contact Groups' marginOff />
      </div>
      {
        contactGroups && contactGroups.length > 0 ? (
          <div>
            {/* GRID */}
            <div className={classes.gridContainer}>
              <Table
                isLoading={isLoading}
                loadingPageSize={!Array.isArray(contactGroups) || contactGroups.length === 0 ? 3 : contactGroups.length}
                key={JSON.stringify(contactGroups.filter(contactGroup => !contactGroup?.isDeleted))}
                columns={columns}
                data={contactGroups.filter(contactGroup => !contactGroup?.isDeleted)}
                headerClasses={classes.tableHeader}
                centerPagination
                stickyHeader
              />
            </div>
          </div>
        ) : null
        // <Typography>Click the add button below to add a contact group. At least 1 group is required to save.</Typography>
      }

      {/* ADD NEW BUTTON */}
      <Fab
        className={`${classes.addButton} ${contactGroups && contactGroups.length > 0 ? '' : classes.floatButton}`}
        onClick={() => setShowAddEditModal(true)}
      >
        <Add />
      </Fab>

      {/* ADD/EDIT MODAL */}
      <AddEditContactGroup
        contactGroupBeingEdited={contactGroupBeingEdited}
        open={showAddEditModal}
        onClose={() => {
          setContactGroupBeingEdited(null);
          setShowAddEditModal(false);
        }}
        onSave={async contactGroup => {
          try {
            setLoading(true);
            const res = await upsertContactGroup(contact.contactId, contactGroup);
            //On EDIT
            if (contactGroup.contactGroupId) {
              const index = contactGroups.findIndex(x => x.contactGroupId === contactGroup.contactGroupId);
              contactGroups[index] = res;
            } else {
              //On ADD
              setContactData(prev => {
                prev.contactGroups = [...prev?.contactGroups, res];
                const newContactGroup = JSON.parse(JSON.stringify(prev));

                return newContactGroup;
              });
              setContactDataClone(prev => {
                prev.contactGroups = [...prev?.contactGroups, res];
                const newContactGroup = JSON.parse(JSON.stringify(prev));

                return newContactGroup;
              });
            }
            showToast('success', 'Contact Groups Updated!');
          } catch (error) {
            showToast('error', 'Contact Groups were not updated.');
          } finally {
            setLoading(false);
          }

          setShowAddEditModal(false);
        }}
      />

      {/* CONFIRM DELETE MODAL */}
      {contactGroupBeingEdited && (
        <ConfirmationDialogue
          id='confirm-contact-group-delete'
          title='Delete Contact Group'
          text='Are you sure you want to delete this contact group?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setContactGroupBeingEdited(null);
          }}
          onConfirm={async () => {
            try {
              setLoading(true);
              const deepCopy = JSON.parse(JSON.stringify(contactGroups));
              const deleteRowIndex = deepCopy.findIndex(
                x => x.contactGroupId === contactGroupBeingEdited.contactGroupId
              );

              deepCopy[deleteRowIndex].isDeleted = true;
              await upsertContactGroup(contactGroupBeingEdited.contactGroupId, deepCopy[deleteRowIndex]);
              deepCopy.splice(deleteRowIndex, 1);
              setContactData(prev => {
                prev.contactGroups = prev.contactGroups.filter(
                  group => group.contactGroupId !== contactGroupBeingEdited.contactGroupId
                );
                const newContactGroup = JSON.parse(JSON.stringify(prev));

                return newContactGroup;
              });
              setContactDataClone(prev => {
                prev.contactGroups = prev.contactGroups.filter(
                  group => group.contactGroupId !== contactGroupBeingEdited.contactGroupId
                );
                const newContactGroup = JSON.parse(JSON.stringify(prev));

                return newContactGroup;
              });
              setShowConfirmDeleteDialogue(false);
              setContactGroupBeingEdited(null);
              showToast('success', 'Contact Group was deleted!');
            } catch (error) {
              console.log('error', error);
              showToast('error', 'Contact Group was not deleted.');
            } finally {
              setLoading(false);
            }
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    gridContainer: {
      width: '100%',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9,
    },
    floatButton: {
      position: 'absolute',
      right: theme.spacing(2),
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    edit: { color: theme.palette.primary.main },
    delete: { color: theme.palette.secondary.main },
  };
});
