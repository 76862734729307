import { makeStyles } from '@material-ui/core/styles';
import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Footer } from './Footer';
import { Header } from './Header';
import { Menu } from './Menu';
import { PageTitle } from './PageTitle';


interface IPage {
  children: ReactNode;
  title: string;
  customPageHeader?: boolean;
}

export const Page: FC<IPage> = ({ children, title, customPageHeader = false }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  // Handle updating the tab/window title to the currently rendered page
  useEffect(() => {
    document.title = `${title} | Beecher Carlson`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, title]);

  return (
    <>
      <Header />

      <div className={classes.mainContainer}>
        <Menu />

        <div className={classes.contentContainer}>
          <div className={classes.innerContent}>
            {!customPageHeader && <PageTitle title={title} centerHeader />}
            {children}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    height: '100%',
    flex: '2',
    overflow: 'hidden'
  },
  contentContainer: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    position: 'relative',
    width: 'calc(100% - 215px)', // Subtract menu width
    zIndex: 1
  },
  innerContent: {
    backgroundColor: 'white',
    borderRadius: '3px',
    boxShadow: '0 0 3px rgba(0,0,0,.25)',
    margin: '1.5rem 1rem',
    padding: '1rem',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));
