import { IMonitoredEntityRequirements, IMonitoredEntityRequirementsPost } from "../types/requirements";
import { authFetch } from './';

const baseUrl = '/api/ManageRequirements';

export const updateAllRequirements = async (
    postModel: IMonitoredEntityRequirementsPost
  ): Promise<
    {
      isWholeSetCompliant: boolean;
    } & IMonitoredEntityRequirements
  > => {
    try {
      const res = await authFetch.post(`${baseUrl}`, postModel);
  
      if (res) {
        return res.data;
      }
      const error = { Detail: 'Error updating the requirement. Please try again.', status_code: 500 };
      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  };
  