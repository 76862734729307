export enum ClientType {
  None = 0,
  Retail = 1,
  Contractor = 2
}

export const ClientTypeItems = [
  { key: 1, value: 1, label: 'Retail' },
  { key: 1, value: 2, label: 'Contractor' }
];
