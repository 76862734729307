import {
  Button,
  CardActions,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Check } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { deepEqual } from 'fast-equals';
import { Form, Formik } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { DatePicker, Loader, Modal } from '../../components';
import { MultiFileUpload } from '../../components/files/multi-file-upload';
import {
  getBlanketCertificateAvailableCertificateHolders,
  getBlanketCertificateAvailableContactGroups,
  getBlanketCertificateById,
  getBlanketCertificateDefaultRequirementsByClientId,
  getClientsForSelector,
  getThinClient,
} from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  IAssociation,
  IBlanketCertificate,
  IBlanketCertificateDefaultRequirement,
  IClient,
  IClientThin,
  IPayloadBlanketCertificate,
  IUpdateBlanketCertificate,
} from '../../types';
import { IBulkDataDefaultRequirement } from '../../types/bulkUpdate';
import { Fulfilled } from './fulfilled';
import { ClientSelectorContext } from '../../context';

export enum TabEnums {
  MatchRules,
  Fulfilled,
}

interface IAddEditBlanketCertificate {
  open: boolean;
  onClose: () => void;
  onSave: (
    blanketCertificate: IPayloadBlanketCertificate | IUpdateBlanketCertificate,
    associatedFiles: File[] | null
  ) => void;
  /**
   * The current entry under edit. If null, this modal is in add mode.
   */
  blanketCertificateBeingEdited: IBlanketCertificate | null;
  contactId: number;
}

export const AddEditBlanketCertificate: FC<IAddEditBlanketCertificate> = ({
  open,
  onClose,
  blanketCertificateBeingEdited,
  onSave,
  contactId,
}) => {
  const blanketCertificateSchema = () =>
    Yup.object().shape(
      {
        clientId: !blanketCertificateBeingEdited
          ? Yup.string().required('Required').typeError('Required')
          : Yup.string().notRequired(),
        expirationDate: Yup.string().required('Required').typeError('Required'),
        documentDate: Yup.string().required('Required').typeError('Required'),
        matchAllCertificateHolders: Yup.boolean().when(
          'certificateHolderAssociations',
          (certificateHolderAssociations, field) =>
            certificateHolderAssociations.length === 0
              ? field.required('Cert holders must have at least 1 selection or it must match all cert holders')
              : field
        ),
        matchAllContactGroups: Yup.boolean().when('contactGroupAssociations', (contactGroupAssociations, field) =>
          contactGroupAssociations.length === 0
            ? field.required('Contact group must have at least 1 selection or it must match all contact groups')
            : field
        ),
        certificateHolderAssociations: Yup.array().when(
          'matchAllCertificateHolders',
          (matchAllCertificateHolders, field) =>
            !matchAllCertificateHolders
              ? field.min(1).required('Cert holders must have at least 1 selection or it must match all cert holders')
              : field
        ),
        contactGroupAssociations: Yup.array().when('matchAllContactGroups', (matchAllContactGroups, field) =>
          !matchAllContactGroups
            ? field.min(1).required('Contact group must have at least 1 selection or it must match all contact groups')
            : field
        ),
        fileId: Yup.string().required(),
      },
      [
        ['contactGroupAssociations', 'matchAllContactGroups'],
        ['certificateHolderAssociations', 'matchAllCertificateHolders'],
      ]
    );

  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const getDefaultClientId = () => {
    if (blanketCertificateBeingEdited?.clientId) {
      return blanketCertificateBeingEdited?.clientId;
    }
    if (!blanketCertificateBeingEdited) {
      if (clientSelectorContext?.clientId) {
        return clientSelectorContext?.clientId;
      }
      return null;
    }
    return null;
  };

  useEffect(() => {
    setSelectedClientId(getDefaultClientId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectorContext]);

  const classes = useStyles();
  const { showToast } = useToastContext();
  const [isEdit, setIsEdit] = useState(!!blanketCertificateBeingEdited);

  const [clients, setClients] = useState<IClient[]>([]);

  const [blanketCertData, setBlanketCertData] = useState<IUpdateBlanketCertificate | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<number | null>(getDefaultClientId());

  const [isBlanketCertDefaultRequirementsLoading, setIsBlanketCertDefaultRequirementsLoading] = useState<boolean>(true);

  const handleClose = () => {
    onClose();
    setSelectedClientId(null);
    setAvailableContactGroups([]);
    setAvailableCertificateHolders([]);
    setBlanketCertData(null);
    setAssociatedFiles(null);
    setThinSelectedClient(null);
  };

  const getBlanketCertData = async () => {
    setIsLoading(true);
    try {
      const res = await getBlanketCertificateById(blanketCertificateBeingEdited.blanketCertificateId);
      setSelectedClientId(blanketCertificateBeingEdited.clientId);
      setBlanketCertData(res);
    } catch (error) {
      showToast('error', 'There was an error retreiving the blanket certificate. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const [availableContactGroups, setAvailableContactGroups] = useState<IAssociation[]>([]);
  const getBlanketCertAvailableContactGroups = async () => {
    try {
      const res = await getBlanketCertificateAvailableContactGroups({
        clientId: selectedClientId,
        contactId: contactId,
      });
      setAvailableContactGroups(res);
    } catch (error) {
      showToast('error', 'There was an error retreiving the blanket certificate contact group. Please try again.');
    }
  };

  const [availableCertificateHolders, setAvailableCertificateHolders] = useState<IAssociation[]>([]);
  const getBlanketCertAvailableCertHolders = async () => {
    try {
      const res = await getBlanketCertificateAvailableCertificateHolders(selectedClientId);
      setAvailableCertificateHolders(res);
    } catch (error) {
      showToast('error', 'There was an error retreiving the blanket certificate cert holders. Please try again.');
    }
  };

  useEffect(() => {
    if (!blanketCertificateBeingEdited) return;
    setSelectedClientId(blanketCertificateBeingEdited.clientId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blanketCertificateBeingEdited]);

  useEffect(() => {
    if (!blanketCertificateBeingEdited) return;
    if (!open) return;
    getBlanketCertData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectedClientId) {
      getBlanketCertAvailableContactGroups();
      getBlanketCertAvailableCertHolders();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedClientId]);

  // Fetch and set local state from network data, skipping if the user exits the component
  useEffect(() => {
    let cancel = false;
    Promise.all([getClientsForSelector()])
      .then(([client]) => {
        if (cancel) {
          return;
        }
        setClients(client);
      })
      .catch(err => {
        if (cancel) {
          return;
        }
        if (process.env.NODE_ENV !== 'production') {
          showToast('error', err instanceof Error ? err.message : 'Failed to load modal data.');
        }
      });
    // Exists to avoid state updates on an unmounted component (a no-no).
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsEdit(!!blanketCertificateBeingEdited);
  }, [blanketCertificateBeingEdited, open]);

  const [defaultRequirements, setDefaultRequirements] = useState<IBlanketCertificateDefaultRequirement[]>([]);

  const getBlanketCertDefaultRequirements = async () => {
    setIsBlanketCertDefaultRequirementsLoading(true);
    try {
      const res = await getBlanketCertificateDefaultRequirementsByClientId(selectedClientId);
      setDefaultRequirements(res);
    } catch (error) {
      showToast('error', 'There was an error retreiving the blanket certificate. Please try again.');
    } finally {
      setIsBlanketCertDefaultRequirementsLoading(false);
    }
  };

  const [selectedRequirements, setSelectedRequirements] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState(TabEnums.MatchRules);

  useEffect(() => {
    if (!selectedClientId) {
      setSelectedClientId(null);
      setAvailableContactGroups([]);
      setAvailableCertificateHolders([]);
      setBlanketCertData(null);
      setSelectedRequirements([]);
      setSelectedTab(TabEnums.MatchRules);
    } else {
      getBlanketCertDefaultRequirements();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  const [associatedFiles, setAssociatedFiles] = useState<File[] | null>(null);

  const valuesAreNotValid = values => {
    if (selectedRequirements.length > 0) {
      // find requirement
      const required = values.requirements.filter(req => selectedRequirements.includes(req.defaultRequirementId));

      const shouldBeDisabled = required.reduce((prev: boolean, curr: IBulkDataDefaultRequirement) => {
        return (
          prev ||
          !curr.carrierId ||
          !curr.carrierName ||
          !curr.documentDate ||
          !curr.expirationDate ||
          (!curr.actualAmount && (curr.coverageType === 1 || curr.coverageType === 3))
        );
      }, false);

      return shouldBeDisabled;
    }
    return false;
  };

  const [thinSelectedClient, setThinSelectedClient] = useState<IClientThin>(null);

  const getThinSelectedClient = async () => {
    try {
      const res = await getThinClient(selectedClientId);
      setThinSelectedClient(res);
    } catch (error) {
      showToast('error', 'There was an error retreiving the detailed client information. Please try again.');
    }
  };
  useEffect(() => {
    if (selectedClientId) {
      getThinSelectedClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  return !blanketCertData && isLoading && isBlanketCertDefaultRequirementsLoading ? (
    <Loader size='medium' position='centered' />
  ) : (
    <>
      <Formik<IUpdateBlanketCertificate>
        enableReinitialize
        initialValues={{
          contactId: 0,
          clientId: selectedClientId,
          expirationDate: null,
          documentDate: null,
          matchAllCertificateHolders: !!thinSelectedClient ? !thinSelectedClient?.useLocations : false,
          matchAllContactGroups: false,
          contactGroupAssociations: [],
          certificateHolderAssociations: [],
          requirements: !isEdit ? defaultRequirements ?? [] : blanketCertData?.requirements ?? [],
          fileId: blanketCertData?.fileId ?? null,
          fileName: blanketCertData?.fileName ?? null,
          ...blanketCertData,
        }}
        validateOnMount={isEdit}
        validationSchema={blanketCertificateSchema()}
        onSubmit={async (values, actions) => {
          const blanketCertificate: IUpdateBlanketCertificate = {
            blanketCertificateId: values.blanketCertificateId,
            contactId: contactId,
            expirationDate: values.expirationDate,
            documentDate: values.documentDate,
            matchAllCertificateHolders: values.matchAllCertificateHolders,
            matchAllContactGroups: values.matchAllContactGroups,
            contactGroupAssociations: values.contactGroupAssociations,
            certificateHolderAssociations: values.certificateHolderAssociations,
            clientId: values.clientId,
            requirements: values.requirements,
            fileId: values.fileId,
            fileName: values.fileName,
          };

          if (!!blanketCertificateBeingEdited) {
            blanketCertificate.clientId = values.clientId;
          }

          onSave(blanketCertificate, associatedFiles);
          handleClose();
          actions.resetForm();
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
        }) => {
          return (
            <Modal
              maxWidth='lg'
              open={open}
              title={blanketCertificateBeingEdited ? 'Edit Blanket Certificates' : 'Add New Blanket Certificate'}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    handleClose();
                  } else {
                    return;
                  }
                } else {
                  handleClose();
                  resetForm();
                }
              }}
            >
              {/* FORM */}
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Grid container spacing={2} direction='row'>
                    <Grid item container xs={12}>
                      <Grid container alignItems={'center'} spacing={1} className={classes.sectionContainer}>
                        {/* CLIENT DROPDOWN */}
                        {!isEdit && (
                          <Grid item xs={12} sm={3}>
                            <Autocomplete
                              id='searchable-dropdown'
                              options={clients}
                              value={clients?.find(client => client.clientId === values.clientId)}
                              getOptionLabel={option => option.name}
                              noOptionsText='No Clients'
                              onChange={(e, item: IClient) => {
                                setSelectedClientId(item?.clientId);
                                setFieldValue('clientId', item?.clientId ?? null);
                              }}
                              renderInput={params => <TextField required label='Client' fullWidth {...params} />}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={isEdit ? 4 : 3}>
                          <DatePicker
                            fullWidth
                            required
                            id='expiration-date-picker'
                            label='Expiration Date'
                            placeholder=''
                            autoComplete='nope'
                            name='expirationDate'
                            value={values.expirationDate ? new Date(values.expirationDate) : null}
                            onBlur={handleBlur}
                            onChange={date => setFieldValue('expirationDate', date?.toISOString())}
                            error={Boolean(touched.expirationDate && errors.expirationDate)}
                            helperText={touched.expirationDate && errors.expirationDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={isEdit ? 4 : 3}>
                          <DatePicker
                            fullWidth
                            required
                            id='document-date-picker'
                            label='Document Date'
                            placeholder=''
                            autoComplete='nope'
                            name='DocumentDate'
                            value={values.documentDate ? new Date(values.documentDate) : null}
                            onBlur={handleBlur}
                            onChange={date => setFieldValue('documentDate', date?.toISOString())}
                            error={Boolean(touched.documentDate && errors.documentDate)}
                            helperText={touched.documentDate && errors.documentDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={isEdit ? 4 : 3}>
                          {associatedFiles || values.fileId ? (
                            <Typography
                              onClick={() => {
                                setAssociatedFiles(null);
                                setFieldValue('fileId', null);
                                setFieldValue('fileName', null);
                              }}
                            >{`${associatedFiles?.[0]?.name ?? values?.fileName}`}</Typography>
                          ) : (
                            <MultiFileUpload
                              uploadText={'Add File'}
                              multiple={false}
                              isRequired={true}
                              handleFileChange={files => {
                                setAssociatedFiles(files);
                                setFieldValue('fileId', files[0].name);
                                setFieldValue('fileName', files[0].name);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction='row'>
                    <Grid item container xs={12}>
                      <Tabs
                        centered
                        value={selectedTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: TabEnums) => {
                          setSelectedTab(newValue);
                        }}
                      >
                        <Tab label='MATCH RULES' value={TabEnums.MatchRules} />
                        <Tab label={'FULFILLED'} value={TabEnums.Fulfilled} />
                      </Tabs>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction='row'>
                    <Grid item container xs={12}>
                      {selectedTab === TabEnums.MatchRules && (
                        <Grid item container xs={12}>
                          <Grid item xs={12} md={4}>
                            <div className={classes.column}>
                              <FormControl component='fieldset'>
                                <Typography variant={'body1'}>Please select at least 1 option:</Typography>
                                <FormGroup>
                                  {/* CLIENT */}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className={classes.formControlCheckBox}
                                        checked={values.matchAllContactGroups}
                                        onChange={() =>
                                          setFieldValue('matchAllContactGroups', !values.matchAllContactGroups)
                                        }
                                        name='MatchesAllContactGroups'
                                      />
                                    }
                                    label='Matches All Contact Groups'
                                  />
                                </FormGroup>
                              </FormControl>
                              {!values.matchAllContactGroups &&
                                !isLoading &&
                                availableContactGroups &&
                                availableContactGroups.length > 0 && (
                                  <FormControl component='fieldset'>
                                    <FormLabel component='legend'>Contact Groups</FormLabel>
                                    <FormGroup>
                                      {availableContactGroups.map(contactGroup => {
                                        return (
                                          <FormControlLabel
                                            key={contactGroup.id}
                                            disabled={values.matchAllContactGroups}
                                            control={
                                              <Checkbox
                                                className={classes.formControlCheckBox}
                                                checked={!!values.contactGroupAssociations.includes(contactGroup?.id)}
                                                onChange={() => {
                                                  if (
                                                    !!values.contactGroupAssociations.find(
                                                      association => association === contactGroup?.id
                                                    )
                                                  ) {
                                                    const newContactGroupAssociation = JSON.parse(
                                                      JSON.stringify(
                                                        values.certificateHolderAssociations.filter(
                                                          contactGroupAssociation =>
                                                            contactGroupAssociation !== contactGroup?.id
                                                        ) ?? null
                                                      )
                                                    );
                                                    setFieldValue('contactGroupAssociations', [
                                                      ...newContactGroupAssociation,
                                                    ]);
                                                  } else {
                                                    setFieldValue('contactGroupAssociations', [
                                                      ...values.contactGroupAssociations,
                                                      contactGroup?.id,
                                                    ]);
                                                  }
                                                }}
                                                name={`${contactGroup.id}`}
                                              />
                                            }
                                            label={`${contactGroup.text}`}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                )}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className={classes.column}>
                              <FormControl component='fieldset'>
                                <Typography variant={'body1'}>Please select at least 1 option:</Typography>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className={classes.formControlCheckBox}
                                        checked={values.matchAllCertificateHolders}
                                        onChange={() =>
                                          setFieldValue(
                                            'matchAllCertificateHolders',
                                            !values.matchAllCertificateHolders
                                          )
                                        }
                                        name='MatchesAllCertHolders'
                                      />
                                    }
                                    label='Matches All Cert Holders'
                                  />
                                </FormGroup>
                              </FormControl>
                              {!values.matchAllCertificateHolders &&
                                !isLoading &&
                                availableCertificateHolders &&
                                availableCertificateHolders.length > 0 && (
                                  <FormControl component='fieldset'>
                                    <FormLabel component='legend'>Cert Holders</FormLabel>
                                    <FormGroup>
                                      {availableCertificateHolders.map(certificateHolder => {
                                        return (
                                          <FormControlLabel
                                            key={certificateHolder.id}
                                            disabled={values.matchAllCertificateHolders}
                                            control={
                                              <Checkbox
                                                className={classes.formControlCheckBox}
                                                checked={
                                                  !!values.certificateHolderAssociations.includes(certificateHolder?.id)
                                                }
                                                onChange={() => {
                                                  if (
                                                    !!values.certificateHolderAssociations.find(
                                                      association => association === certificateHolder?.id
                                                    )
                                                  ) {
                                                    const newCertificateHolderAssociations = JSON.parse(
                                                      JSON.stringify(
                                                        values.certificateHolderAssociations.filter(
                                                          certificateHolderAssociation =>
                                                            certificateHolderAssociation !== certificateHolder.id
                                                        ) ?? null
                                                      )
                                                    );
                                                    setFieldValue('certificateHolderAssociations', [
                                                      ...newCertificateHolderAssociations,
                                                    ]);
                                                  } else {
                                                    setFieldValue('certificateHolderAssociations', [
                                                      ...values.certificateHolderAssociations,
                                                      certificateHolder?.id,
                                                    ]);
                                                  }
                                                }}
                                                name={`${certificateHolder.id}`}
                                              />
                                            }
                                            label={`${certificateHolder.text}`}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      )}
                      {selectedTab === TabEnums.Fulfilled && !!selectedClientId && (
                        <Fulfilled
                          clientId={selectedClientId}
                          carriers={[]}
                          isSaving={isSubmitting}
                          entitiesAreDirty={false}
                          requirements={defaultRequirements}
                          isLoading={isBlanketCertDefaultRequirementsLoading}
                          setFieldValue={setFieldValue}
                          values={values}
                          submit={handleSubmit}
                          dirty={dirty}
                          isSubmitting={isSubmitting}
                          isValid={isValid}
                          selectedRequirements={selectedRequirements}
                          setSelectedRequirements={setSelectedRequirements}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* FORM BUTTONS */}
                  <CardActions>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          if (!deepEqual(initialValues, values)) {
                            const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                            if (result) {
                              resetForm();
                              handleClose();
                            } else {
                              return;
                            }
                          } else {
                            resetForm();
                            handleClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid || valuesAreNotValid(values)}
                        type='submit'
                        startIcon={blanketCertificateBeingEdited ? <Check /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {blanketCertificateBeingEdited ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControlCheckBox: {
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  sectionContainer: {
    marginBottom: theme.spacing(1),
  },
  distributionField: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  deletePadding: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  distributionListHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  distributionListContainer: {
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
  },
  clearIcon: {
    color: theme.palette.grey[400],
    cursor: 'pointer',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    // width: isMobile => (isMobile ? '100%' : '33%')
  },
}));
