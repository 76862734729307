import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, withFormik, FormikProps } from 'formik';
import { Add, Save } from '@material-ui/icons';
import { Fade, CardActions, Button, TextField, Switch, FormControlLabel, Grid } from '@material-ui/core';
// components
import { Modal, Loader, Select } from '../../components';
// Icons
// constants
import { ClientTypeItems } from '../../constants';
// fetch
import { updateReport, createReport, getClientsForSelector , getReportSecurityTypes} from '../../fetch';
// styles
import { colors } from '../../styles';
// types
import { IClient, IReport, IEnum, IReportSecurityType } from '../../types';

interface IAddEditReportsFormProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialValues: IReport | {};
  showToast: (type: string, message: string) => void;
}

const AddEditReports: FC<IAddEditReportsFormProps & FormikProps<IReport>> = ({
  open,
  onClose,
  onSave,
  initialValues,
  resetForm,
  isSubmitting,
  values,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur
}) => {
  const [clientItems, setClientItems] = useState<IClient[]>([]);
  const [reportSecurityTypes, setReportSecurityTypes] = useState<IEnum[]>([]);
  const classes = useStyles();

  useEffect(() => {
    getClientsForSelector().then(data => {
      setClientItems(data);
    });

    getReportSecurityTypes().then(data => {
      setReportSecurityTypes(data);
    })

  }, []);

  return (
    <Modal
      maxWidth={'md'}
      open={open}
      title={values.reportId ? 'Edit Report' : 'Add New Report'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <Grid container spacing={2}>
            {/* NAME */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                required
                autoComplete='nope'
                label='Report Name'
                name='name'
                value={values.name}
                onBlur={handleBlur}
                onChange={e => setFieldValue('name', e.target.value)}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            {/* CLIENT TYPE */}
            <Grid item xs={12} md={6} lg={4}>
              <Select
                name='clientType'
                id='client-type-select'
                value={typeof values.clientType === 'undefined' ? '' : values.clientType}
                options={ClientTypeItems}
                label='Client Type'
                onChange={e => setFieldValue('clientType', e.target.value)}
              />
            </Grid>

            {/* CLIENT */}
            <Grid item xs={12} md={6} lg={4}>
              <Select
                name='clientId'
                id='client-select'
                value={typeof values.clientId === 'undefined' ? '' : values.clientId}
                options={clientItems.map(item => ({
                  key: item.clientId,
                  label: item.name,
                  value: item.clientId
                }))}
                label='Client'
                onChange={e => setFieldValue('clientId', e.target.value)}
              />
            </Grid>

            {/* Power BI Report ID */}
            <Grid item xs={12} md={7} lg={5}>
              <TextField
                fullWidth
                required
                autoComplete='nope'
                label='Power BI Report ID'
                name='powerBIReportId'
                value={values.powerBIReportId}
                onBlur={handleBlur}
                onChange={e => setFieldValue('powerBIReportId', e.target.value)}
                error={Boolean(touched.powerBIReportId && errors.powerBIReportId)}
                helperText={touched.powerBIReportId && errors.powerBIReportId}
              />
            </Grid>

            {/* Report Security Type */}
            <Grid item xs={12} md={6} lg={3}>
              <Select
                name='reportSecurityType'
                id='report-security-type-select'
                value={typeof values.reportSecurityType === 'undefined' ? '' : values.reportSecurityType}
                options={reportSecurityTypes.map(ct => ({
                  key: ct.value,
                  label: `${ct.description}`,
                  value: ct.value
                }))}
                label='Report Security Mode'
                onChange={e => {
                  setFieldValue('reportSecurityType', e.target.value);
                  if (e.target.value === IReportSecurityType.NoSecurity) {
                    setFieldValue('enableExternalAccess', false);
                  }
                }}
              />
            </Grid>

            {/* ENABLE EXTERNAL ACCESS */}
            <Grid item>
              <FormControlLabel
                label='Enable External Access'
                control={
                  <Switch
                    checked={values.enableExternalAccess}
                    onChange={() => setFieldValue('enableExternalAccess', !values.enableExternalAccess)}
                    color='primary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={values.reportSecurityType === IReportSecurityType.NoSecurity}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                variant='outlined'
                required
                autoComplete='nope'
                label='Report Description'
                name='description'
                value={values.description}
                onBlur={handleBlur}
                onChange={e => setFieldValue('description', e.target.value)}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
          </Grid>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.reportId ? <Save /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.reportId ? 'Save' : 'Add Report'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const ReportSchema = Yup.object().shape({
  powerBIReportId:
    Yup
    .string()
    .nullable()
    .required('Power BI Report ID is required.')
    .matches(new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i'), 'You must provide a valid GUID format.'),
  name: Yup.string().nullable().required('Report Name is required.'),
  description: Yup.string().nullable().required('Report Description is required.'),
  enableExternalAccess: Yup.boolean(),
  clientType: Yup.number().nullable(),
  clientId: Yup.number().nullable()
});

export default withFormik<IAddEditReportsFormProps, IReport>({
  enableReinitialize: true,
  validationSchema: ReportSchema,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      reportId: 0,
      name: '',
      description: '',
      enableExternalAccess: false,
      clientType: undefined,
      clientId: undefined,
      powerBIReportId: '',
      reportSecurityType: undefined,
      ...initialValues
    };
  },
  handleSubmit: async (values, { resetForm, props: { onClose, onSave, showToast }}) => {
    try {
      if (values.reportId) {
        await updateReport(values);
        showToast('success', 'Report Added!');
        onClose();
        onSave();
        resetForm();
      } else {
        await createReport(values);
        showToast('success', 'Report Updated!');
        onClose();
        onSave();
        resetForm();
      }
    } catch (error) {
      showToast('error', 'We were unable to create the report at this time. Please try again later. Please contact support if this issue continues.');
    }
  }
})(AddEditReports);

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px'
  },
  dropdown: {
    width: '100%'
  },
  menuOptions: {
    color: theme.palette.common.black
  },
  columnPadding: {
    paddingRight: theme.spacing(9)
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  },
  dropdownClear: {
    fill: theme.palette.grey[400],
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
    bottom: theme.spacing(0)
  }
}));
