import {
  Button,
  CardActions,
  ClickAwayListener,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Check, Refresh, Loop } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { deepEqual } from 'fast-equals';
import { Form, FormikProps, withFormik } from 'formik';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';
import * as Yup from 'yup';

import { DatePicker, IOptionType, Loader, Modal, Select } from '../../components';
import { ClientType, routes } from '../../constants';
import { getClientReferenceNumberIsInUse, updateMonitoredEntity } from '../../fetch';
import { colors } from '../../styles';
import {
  IClient,
  IContact,
  IContactDropdownListItem,
  ILocation,
  IMonitoredEntity,
  IMonitoredEntityStatus,
  IMonitoredEntityType,
} from '../../types';
import { UserContext } from '../../context';
import { debounce } from 'lodash';
import { useDebounce } from '../../hooks/useDebounce';

interface IAddEditUnitFormProps extends Pick<IClient, 'requireUnitCodes'> {
  open: boolean;
  useLocations?: boolean;
  onClose: () => void;
  onSave?: (IMonitoredEntity) => void;
  initialValues?: IMonitoredEntity | null;
  locations?: Array<ILocation>;
  clientType?: number;
  contacts?: Array<IContactDropdownListItem>;
  jobLabel?: string;
  // NOTE: Must be a property. Do not convert to useContext call.
  showToast?: (type: string, message: string) => void;
  showSupplementalCode?: boolean;
  clientId: number;
  monitoredEntityTypes?: Array<IMonitoredEntityType>;
  refreshContacts: () => void;
  contactsIsLoading: boolean;
  updateContactsEmailFilter: (arg: string) => void;
  fetchEntities?: () => Promise<IMonitoredEntity[]>;
}

/**
 * Get contacts that have emails (removing the ones that do not) and attach that
 * email to each IContact instance.
 */
export const getContactsByEmailFilterOptions = (contacts: IContact[]): (IContact & { email: string })[] =>
  contacts.flatMap(c => c.contactGroups.flatMap(cg => cg?.distributionLists?.map(dl => ({ ...c, email: dl.email }))));

const AddEditMonitoredEntities: FC<IAddEditUnitFormProps & FormikProps<IMonitoredEntity>> = ({
  showSupplementalCode,
  contacts,
  open,
  onClose,
  clientType,
  locations,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur,
  useLocations,
  monitoredEntityTypes,
  refreshContacts,
  contactsIsLoading,
  updateContactsEmailFilter,
  requireUnitCodes,
  clientId,
}) => {
  const { userContext } = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [currentContact, setCurrentContact] = useState<undefined | IContactDropdownListItem>(() =>
    contacts.find(contact => contact.contactId === values.contactId)
  );

  const [emailFilter, setEmailFilter] = useState('');
  const [contactName, setContactName] = useState(values?.contactName || '');
  /** State of the autocomplete contacts list. */
  const [emailOpen, setEmailOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEmailSearch = useCallback(
    debounce(async (email: string) => {
      /** @see MonitoredEntitiesTable.tsx - handleGetContacts */
      if (email.length === 1) {
        return;
      }
      // Send the updated state upwards to trigger `contacts` list to update
      updateContactsEmailFilter(email);
      // Wait a bit for the update to propagate
      await Promise.resolve();
      // Focus the autocomplete, triggering the list of searched items to open
      if (email.length > 1) setEmailOpen(true);
    }, 500),
    []
  );

  const [isReferenceNumberInUse, setIsReferenceNumberInUse] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDebounce(
    async () => {
      if (!values.unitNumber) {
        setIsReferenceNumberInUse(false);
      } else if (clientType === ClientType.Retail) {
        setIsReferenceNumberInUse(await fetchClientReferenceNumberIsInUser());
      }
    },
    500,
    [values.unitNumber, values.locationId]
  );

  const [isLoadingReferenceNumberCheck, setIsLoadingReferenceNumberCheck] = useState(false);
  const fetchClientReferenceNumberIsInUser = async () => {
    setIsLoadingReferenceNumberCheck(true);
    try {
      const res = await getClientReferenceNumberIsInUse({
        clientId: clientId,
        entityId: !!values.entityId ? values.entityId : null,
        referenceNumber: values.unitNumber,
        locationId: values.locationId,
      });
      return res;
    } catch (error) {
    } finally {
      setIsLoadingReferenceNumberCheck(false);
    }
  };

  useEffect(() => {
    resetForm();
    if (!values.entityId) {
      setCurrentContact(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!open]);

  useEffect(() => {
    debouncedEmailSearch(emailFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFilter]);

  const confirmCloseModal = useCallback(() => {
    // Confirm close if changes exist
    const hasChanges = !deepEqual(initialValues, values);
    if (hasChanges && !window.confirm('You have unsaved changes, are you sure you want to exit?')) return;
    setCurrentContact(null);
    onClose();
    resetForm();
    setEmailFilter('');

    // Closing modal deletes `location.search` from URL in order to prevent
    // modal from re-opening upon navigating client form tab away then back:
    const absoluteUrlWithoutEntityId = location.href.replace(location.search, '');
    // - Modify the current browser tab URL without causing page refresh.
    // - Use `window.location` here rather than history from `useHistory`
    // due to differences in how `pushState`/`push` behavior writes to URL.
    // - Modifies **appearance** of current browser tab URL without actually
    // mutating the `location.search` value.
    window.history.pushState(null, '', absoluteUrlWithoutEntityId);
    // Actually mutate `location.search`. The `pushState` call above solely
    // keeps up appearances so the user is aware of this URL params change.
    // (Basically, changing `location.search` does not change the URL.)
    history.location.search = '';
  }, [history.location, initialValues, location.href, location.search, onClose, resetForm, values]);

  const getMonitoredEntityTypeOptions = (): IOptionType[] => {
    if (!useLocations) {
      return monitoredEntityTypes?.map(monitoredEntityType => ({
        key: monitoredEntityType.monitoredEntityTypeId,
        label: monitoredEntityType.name,
        value: monitoredEntityType.monitoredEntityTypeId,
      }));
    } else {
      const filteredMonitoredEntityTypes = monitoredEntityTypes.filter(monitoredEntityType =>
        monitoredEntityType.locationIds.some(locationId => locationId === values.locationId)
      );
      if (filteredMonitoredEntityTypes?.length > 0) {
        return filteredMonitoredEntityTypes?.map(monitoredEntityType => ({
          key: monitoredEntityType.monitoredEntityTypeId,
          label: monitoredEntityType.name,
          value: monitoredEntityType.monitoredEntityTypeId,
        }));
      }
    }

    return [{ key: null, label: 'No Monitored Entity Types found.', value: '' }];
  };

  return (
    <Modal
      maxWidth={'md'}
      open={open}
      title={values.entityId ? `Edit Unit` : `Add New Unit`}
      onClose={confirmCloseModal}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid xs={12} md={6}>
              {/* NAME */}
              <TextField
                fullWidth
                required
                autoComplete='nope'
                label='Retail Name'
                name='name'
                value={values.name}
                onBlur={e => {
                  handleBlur(e);
                  if (!values.dba) setFieldValue('dba', values.name);
                }}
                onChange={e => setFieldValue('name', e.target.value)}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            {/* ACTIVE */}
            <Grid xs={12} md={6} className={classes.toggle}>
              <FormControlLabel
                label='Active'
                control={
                  <Switch
                    checked={values.isActive}
                    onChange={() => {
                      const hasChanged = values.isActive === initialValues.isActive;
                      if (hasChanged) {
                        // If toggling *back*, remove the new entity status history entry we would have sent
                        if (values.statuses.length > 0 && values.statuses[values.statuses.length - 1].id === 0) {
                          // Remove the new element
                          values.statuses = values.statuses.filter(_ => _.id !== 0);
                        } else {
                          values.statuses.push({ id: 0, status: !values.isActive } as IMonitoredEntityStatus);
                        }
                      }
                      setFieldValue('isActive', !values.isActive);
                    }}
                    color='primary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
              />
            </Grid>

            {/* FILTER CONTACTS BY EMAIL */}
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete='nope'
                value={emailFilter}
                label='Filter Contacts by Email'
                onBlur={handleBlur}
                onChange={event => setEmailFilter(event.target.value)}
              />
            </Grid>

            {/* ADD NEW CONTACT BUTTON*/}
            <Grid xs={12} md={6} className={classes.contactButtonContainer}>
              <Button
                color='primary'
                onClick={() => {
                  // Open Add Contact page in new tab
                  window.open(`${routes.manageContacts}/-1`, '_blank');
                }}
              >
                <Add />
                Add New Contact
              </Button>
            </Grid>

            {/* CONTACT DROPDOWN */}
            <Grid xs={12} md={6}>
              <ClickAwayListener
                onClickAway={() => {
                  if (emailOpen) {
                    setEmailOpen(false);
                  }
                }}
              >
                <Autocomplete
                  fullWidth
                  value={currentContact}
                  open={emailOpen}
                  onClose={() => setEmailOpen(false)}
                  id='contact-searchable-dropdown'
                  loading={contactsIsLoading}
                  loadingText='Loading...'
                  options={contacts}
                  getOptionLabel={option => option.name}
                  noOptionsText={emailFilter.length > 0 ? `No Contacts for "${emailFilter}"` : 'No Contacts'}
                  onChange={(_event, item: null | IContactDropdownListItem) => {
                    // **If** item is not null, ensure contactId exists
                    if (item && !item.contactId) {
                      return;
                    }
                    if (values.contactId !== item?.contactId) {
                      // Update the chosen contact
                      setFieldValue('contactId', item?.contactId || null);
                      // Clear contact group
                      setFieldValue('contactGroupId', null);
                      // If a dropdown item was chosen, take its name as the input display
                      setContactName(item?.name || '');
                      // Update the selected contact value of the dropdown
                      if (!!item && 'contactId' in item) {
                        setCurrentContact(contacts.find(_ => _.contactId === item.contactId));
                      }
                    }
                  }}
                  inputValue={contactName}
                  onInputChange={(_event, newValue) => {
                    setContactName(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      fullWidth
                      placeholder={'Please select a contact'}
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      onFocus={() => {
                        setEmailOpen(true);
                      }}
                      label='Contact *'
                      error={Boolean(touched.contactId && errors.contactId)}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Tooltip title={'Click to refresh the "Contact" and "Contact Group" field.'}>
                              <IconButton color='primary' size='small' onClick={() => refreshContacts()}>
                                <Refresh />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </ClickAwayListener>
            </Grid>
            {/* CONTACT GROUP DROPDOWN */}
            <Grid xs={12} md={6}>
              <Select
                name='contactGroupId'
                id='contact-group-select'
                value={values.contactGroupName}
                label='Contact Group *'
                onChange={event => {
                  const newContactGroupId = event.target.value;
                  setFieldValue('contactGroupId', newContactGroupId);
                  // prettier-ignore
                  const newContactGroupName: string | undefined =
                    contacts?.find(_ => _.contactId === values.contactId)
                    ?.contactGroups
                    ?.find(_ => _.contactGroupId === newContactGroupId)
                    ?.name;
                  // Update the displayed contact group name to reflect what was chosen
                  if (!!newContactGroupName) {
                    setFieldValue('contactGroupName', newContactGroupName);
                  }
                }}
                error={Boolean(touched.contactGroupId && errors.contactGroupId)}
                onBlur={handleBlur}
                className={!values.contactGroupId ? classes.placeholder : ''}
                renderValue={value => {
                  return !values.contactGroupId ? 'Please select a Contact Group' : value;
                }}
                options={
                  // Get the contact groups of the currently selected contact or display the default
                  contacts
                    .find(_ => _.contactId === values.contactId)
                    ?.contactGroups?.map(_ => ({
                      key: _.contactGroupId,
                      label: _.name,
                      value: _.contactGroupId,
                    })) ?? [{ key: null, label: 'No Contact Groups found.', value: '' }]
                }
              />
            </Grid>
            {/* LOCATION DROPDOWN */}
            {useLocations && (
              <Grid xs={12} md={6}>
                <Autocomplete
                  id='searchable-location-dropdown'
                  options={locations}
                  value={locations.find(location => location.locationId === values.locationId)}
                  getOptionLabel={option => option.name}
                  noOptionsText='No Locations'
                  onChange={(e, item: ILocation) => {
                    if (item && !item.locationId) {
                      return;
                    }
                    setFieldValue('locationId', item?.locationId);
                    setFieldValue('monitoredEntityTypeId', null);
                  }}
                  renderInput={params => (
                    <TextField
                      fullWidth
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      label='Location *'
                      error={Boolean(touched.locationId && errors.locationId)}
                      onBlur={handleBlur}
                    />
                  )}
                />
              </Grid>
            )}
            {/* MONITORED ENTITY TYPE */}
            <Grid xs={12} md={6}>
              <Select
                name='monitoredEntityTypeId'
                id='unit-type-select'
                value={values.monitoredEntityTypeId}
                label='Unit Type'
                onChange={e => setFieldValue('monitoredEntityTypeId', e.target.value)}
                error={Boolean(touched.monitoredEntityTypeId && errors.monitoredEntityTypeId)}
                onBlur={handleBlur}
                className={!values.monitoredEntityTypeId ? classes.placeholder : ''}
                options={getMonitoredEntityTypeOptions()}
              />
            </Grid>
            <Grid xs={12} md={useLocations ? 4 : 6}>
              <TextField
                fullWidth
                autoComplete='nope'
                label='Client Store Number'
                name='clientStoreNumber'
                value={values.clientStoreNumber}
                onBlur={handleBlur}
                onChange={e => setFieldValue('clientStoreNumber', e.target.value)}
                error={Boolean(touched.clientStoreNumber && errors.clientStoreNumber)}
                helperText={touched.clientStoreNumber && errors.clientStoreNumber}
              />
            </Grid>
            {showSupplementalCode && (
              <Grid xs={12} md={4}>
                <TextField
                  fullWidth
                  autoComplete='nope'
                  label='Supplemental Code'
                  name='supplementalCode'
                  value={values.supplementalCode}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('supplementalCode', e.target.value)}
                  error={Boolean(touched.supplementalCode && errors.supplementalCode)}
                  helperText={touched.supplementalCode && errors.supplementalCode}
                />
              </Grid>
            )}
            <Grid xs={12} md={4}>
              <TextField
                fullWidth
                autoComplete='nope'
                label='DBA'
                name='dba'
                value={values.dba}
                onBlur={handleBlur}
                onChange={e => setFieldValue('dba', e.target.value)}
                error={Boolean(touched.dba && errors.dba)}
                helperText={touched.dba && errors.dba}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                fullWidth
                autoComplete='nope'
                label='Alternate DBA'
                name='alternateDBA'
                value={values.alternateDBA}
                onBlur={handleBlur}
                onChange={e => setFieldValue('alternateDBA', e.target.value)}
                error={Boolean(touched.alternateDBA && errors.alternateDBA)}
                helperText={touched.alternateDBA && errors.alternateDBA}
              />
            </Grid>
            {/* CONTRACT ON FILE */}
            <Grid xs={12} md={4} className={classes.toggle}>
              <FormControlLabel
                label='Contract on File'
                control={
                  <Switch
                    checked={values.contractOnFile}
                    onChange={() => setFieldValue('contractOnFile', !values.contractOnFile)}
                    color='primary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
              />
            </Grid>
            <Grid xs={12} md={4}>
              <TextField
                fullWidth
                autoComplete='nope'
                label='Contract Number'
                name='contractNumber'
                value={values.contractNumber}
                onBlur={handleBlur}
                onChange={e => setFieldValue('contractNumber', e.target.value)}
                error={Boolean(touched.contractNumber && errors.contractNumber)}
                helperText={touched.contractNumber && errors.contractNumber}
              />
            </Grid>
            {/* CONTRACT EXPIRES */}
            <Grid xs={12} md={4}>
              <DatePicker
                fullWidth
                id='contract-expires-date-picker'
                label='Contract Expires'
                placeholder=''
                autoComplete='nope'
                name='contractExpires'
                value={values.contractExpires ? new Date(values.contractExpires) : null}
                onBlur={handleBlur}
                onChange={date => setFieldValue('contractExpires', date?.toISOString())}
                error={Boolean(touched.contractExpires && errors.contractExpires)}
                helperText={touched.contractExpires && errors.contractExpires}
              />
            </Grid>
            {/* MARK CONTRACT ENTERED */}
            <Grid xs={12} md={4}>
              <Button
                className={classes.contractReviewedButton}
                onClick={() => {
                  setFieldValue('contractReviewDate', new Date().toISOString());
                  setFieldValue('contractReviewedBy', userContext.name);
                }}
                color='primary'
              >
                Mark Contract Entered
              </Button>
            </Grid>
            {/* CONTRACT REVIEW DATE */}
            <Grid xs={12} md={4} alignItems={'flex-end'}>
              <DatePicker
                fullWidth
                id='contract-entered-date'
                label='Contract Entered Date'
                placeholder=''
                name='contractReviewDate'
                value={values.contractReviewDate ? new Date(values.contractReviewDate) : null}
                onBlur={handleBlur}
                onChange={date => setFieldValue('contractReviewDate', date?.toISOString())}
              />
            </Grid>
            {/* CONTRACT REVIEWED BY */}
            <Grid xs={12} md={4}>
              <TextField
                fullWidth
                label='Contract Entered By'
                name='contractReviewedBy'
                id='contract-entered-by'
                value={values.contractReviewedBy}
                InputLabelProps={{ shrink: !!values.contractReviewedBy }}
                onChange={e => setFieldValue('contractReviewedBy', e.target.value)}
              />
            </Grid>
            {/* RETAIL UNIT DETAILS SECTION */}
            {clientType === ClientType.Retail && (
              <>
                <Grid xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                {/* UNIT NUMBER */}
                <Grid xs={12} md={4}>
                  <TextField
                    fullWidth
                    required={requireUnitCodes}
                    autoComplete='nope'
                    label='Unit Number'
                    name='unitNumber'
                    value={values.unitNumber}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: isLoadingReferenceNumberCheck ? (
                        <InputAdornment position='start'>
                          <Loop className={classes.rotateIcon} />
                        </InputAdornment>
                      ) : null,
                    }}
                    onChange={e => setFieldValue('unitNumber', e.target.value)}
                    error={Boolean(touched.unitNumber && errors.unitNumber)}
                    helperText={
                      <span className={classes.helperText}>
                        {(touched.unitNumber && errors.unitNumber) ||
                          (isReferenceNumberInUse ? 'This reference number is already in use.' : '')}
                      </span>
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button onClick={confirmCloseModal}>Cancel</Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.entityId ? <Check /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.entityId ? 'Update' : 'Add'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const UnitSchemaWithLocations = Yup.object().shape({
  name: Yup.string().required('Required'),
  isActive: Yup.boolean(),
  locationId: Yup.number().required('Locations cannot be empty on this client type.'),
  unitNumber: Yup.string(),
  contactId: Yup.number().required('You must provide a contact.'),
  contactGroupId: Yup.number().required('You must provide a contact group.'),
  contractorType: Yup.number().nullable(),
  contractExpires: Yup.date().nullable(),
  contractOnFile: Yup.boolean(),
  monitoredEntityTypeId: Yup.number().nullable(),
  contactEmailFilter: Yup.string().nullable(),
});

const UnitSchemaWithoutLocations = Yup.object().shape({
  name: Yup.string().required('Required'),
  isActive: Yup.boolean(),
  unitNumber: Yup.string(),
  contactId: Yup.number().nullable(),
  contactGroupId: Yup.number().required('You must provide a contact group.'),
  contractorType: Yup.number().nullable(),
  contractExpires: Yup.date().nullable(),
  contractOnFile: Yup.boolean(),
  clientStoreNumber: Yup.string().nullable(),
  dba: Yup.string().nullable(),
  alternateDBA: Yup.string().nullable(),
  supplementalCode: Yup.string().nullable(),
  contractNumber: Yup.string().nullable(),
  contractReviewDate: Yup.string().nullable(),
  contractReviewedBy: Yup.string().nullable(),
  monitoredEntityTypeId: Yup.number().nullable(),
  contactEmailFilter: Yup.string().nullable(),
});

export default withFormik<IAddEditUnitFormProps, IMonitoredEntity>({
  enableReinitialize: true,
  validationSchema: props => (props.useLocations ? UnitSchemaWithLocations : UnitSchemaWithoutLocations),
  mapPropsToValues: ({ initialValues }) => {
    return {
      ...IMonitoredEntity.createInitialValue(ClientType.Retail),
      // contactEmailFilter is not a field present on IMonitoredEntity,
      // but it does exist on the Schema for this form
      contactEmailFilter: '',
      ...initialValues,
    };
  },
  handleSubmit: async (
    values,
    { resetForm, setSubmitting, props: { onClose, onSave, showToast, clientId, fetchEntities } }
  ) => {
    setSubmitting(true);
    try {
      const updated = {
        clientId: clientId,
        ...values,
      };

      await updateMonitoredEntity(updated);
      const entities = await fetchEntities?.();

      onSave?.(entities);
      onClose();
      showToast('success', values.entityId ? 'Unit Updated' : 'Unit Created!');
      resetForm();
    } catch (error) {
      showToast(
        'error',
        'We were unable to update the unit at this time. Please try again later. Please contact support if this issue continues.'
      );
    } finally {
      setSubmitting(false);
    }
  },
})(AddEditMonitoredEntities);

const useStyles = makeStyles((theme: Theme) => ({
  rotateIcon: {
    color: theme.palette.grey[400],
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  gridContainer: {
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  contactButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  placeholder: {
    '& > div': {
      color: theme.palette.grey[400],
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
  },
  contactDropdown: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshButton: {
    marginTop: theme.spacing(1),
  },
  contractReviewedButton: {
    marginTop: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.error.main,
  },
}));
