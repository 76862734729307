import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography
} from '@material-ui/core';
// components
import { Toast, Page } from '../../components';
// types
import {
  IFile
} from '../../types';


export const DownloadFile = () => {
  // @ts-ignore
  let { fileId } = useParams();

  const [isError, showError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleGetFile = async (fileId: number) => {
    try {
      await IFile.downloadFileById(fileId);
    } catch (error) {
      setErrorMessage(error?.response?.status === 403 ? "Unauthorized to view file" : error?.message ?? `Error retrieving file.`);
      showError(true);
    }
  };

  useEffect(() => {
    handleGetFile(fileId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page customPageHeader title={'Download File'}>
      <Typography variant='h4'>
        Downloading your file. You can close this tab when completed.
      </Typography>
      <Toast
        id='file-error'
        autoHideDuration={6000}
        message={
          errorMessage
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />

    </Page>
  );
};