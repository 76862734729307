import React, { FC, useState, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Edit, Delete, Add } from '@material-ui/icons';
import { Fab, IconButton } from '@material-ui/core';
// components
import { Table, ITableColumn, ConfirmationDialogue, PageTitle } from '../../components';
import { AddEditPortfolio } from './AddEditPortfolio';
// helpers
import { colors } from '../../styles';
// types
import { IClient, IPortfolio } from '../../types';
// hooks
import { useToastContext } from '../../hooks';
// fetch
import { updatePortfolio } from '../../fetch';

interface IPortfolioProps
  extends Pick<
    IClient,
    'entityType' | 'portfolios' | 'locations' | 'useLocations' | 'clientId' | 'clientCertificateHolders'
  > {
  isLoading: boolean;
  updatePortfolios: (values: IPortfolio[]) => void;
}

export const Portfolios: FC<IPortfolioProps> = ({
  useLocations,
  portfolios = [],
  locations,
  entityType,
  isLoading,
  clientId,
  updatePortfolios,
}) => {
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IPortfolio | null>(null);
  const classes = useStyles();
  const { showToast } = useToastContext();

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPortfolio } };
        }) => {
          return (
            <div key={original.clientCertificateHolderId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              <IconButton
                color='secondary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowConfirmDeleteDialogue(true);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Portfolios' marginOff />
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          loadingPageSize={!Array.isArray(portfolios) || portfolios.length === 0 ? 3 : portfolios.length}
          key={JSON.stringify(portfolios.filter(portfolio => !portfolio.isDeleted))}
          columns={columns}
          data={portfolios.filter(portfolio => !portfolio.isDeleted)}
          headerClasses={classes.tableHeader}
          centerPagination
          stickyHeader
        />
      </div>

      {/* ADD NEW BUTTON */}
      <Fab className={classes.addButton} onClick={() => setShowAddEditModal(true)}>
        <Add />
      </Fab>

      {/* ADD/EDIT MODAL */}
      {showAddEditModal && (
        <AddEditPortfolio
          open
          selectedRowItem={selectedRowItem}
          locations={locations}
          entityType={entityType}
          useLocations={useLocations}
          clientId={clientId}
          showToast={showToast}
          onClose={() => {
            setSelectedRowItem(null);
            setShowAddEditModal(false);
          }}
          onSave={portfolios => {
            updatePortfolios(portfolios);
            setSelectedRowItem(null);
            setShowAddEditModal(false);
          }}
        />
      )}

      {/* CONFIRM DELETE MODAl */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-portfolio-delete'
          title='Delete Portfolio'
          text='Are you sure you want to delete this portfolio?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            const updated = {
              ...selectedRowItem,
              isDeleted: true,
            };
            try {
              const response = await updatePortfolio(updated);
              updatePortfolios(response);
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
            } catch (error) {
              showToast('error', 'There was an error deleting the portfolio. Please try again.');
            }
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
