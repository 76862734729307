import { AxiosResponse } from 'axios';

import { authFetch } from './';
import {
  IClient,
  IClientGridRes,
  IEnum,
  IClientPost,
  IMonitoredEntity,
  MonitoredEntitySortBy,
  IResponse,
  IClientThin,
  SortOptions,
  IEnumDropdown,
} from '../types';

const baseUrl = '/api/client';

export const getClient = async (id: number): Promise<IClient> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting client. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getThinClient = async (id: number): Promise<IClientThin> => {
  try {
    const res = await authFetch.get(`${baseUrl}/ManageClientInfo`, { params: { clientId: id } });

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting client. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClientMonitoredEntityLookup = async (clientId: number): Promise<IEnum[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/GetMonitoredEntityLookup`, { params: { clientId } });

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting client monitored entity lookup. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClientMonitoredEntities = async (params?: {
  clientId?: number;
  page?: number;
  perPage?: number;
  sortBy?: MonitoredEntitySortBy;
  sortDirection?: SortOptions;
  locationIdFilter?: number;
}): Promise<IResponse<IMonitoredEntity[]>> => {
  try {
    const response: AxiosResponse<IResponse<IMonitoredEntity[]>> = await authFetch.get(
      `${baseUrl}/ListClientMonitoredEntities`,
      { params }
    );

    if (response?.data) {
      return response.data;
    }

    const error = { Detail: 'Error getting client monitored entities. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateClient = async (client: IClient): Promise<IClientPost> => {
  try {
    const res = await authFetch.post(`${baseUrl}`, client);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating client. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClients = async (filters?: {
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  code?: string;
  name?: string;
  clientType?: string;
}): Promise<IClientGridRes> => {
  let query = '';
  if (filters) {
    query = `?page=${filters.page}&perPage=${filters.perPage}&SortBy=${filters.sortBy}&SortDirection=${filters.sortDirection}`;

    if (filters.code) {
      query += `&code=${filters.code}`;
    }

    if (filters.name) {
      query += `&name=${filters.name}`;
    }

    if (filters.clientType) {
      query += `&clientType=${filters.clientType}`;
    }
  }
  try {
    const res = await authFetch.get(`${baseUrl}/List${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
  return {
    records: [],
    totalRecordCount: 0,
  };
};

export const getClientsForSelector = async (): Promise<Array<IClient>> => {
  try {
    const res = await authFetch.get(`${baseUrl}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting clients for selector. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const getLocationStatuses = async (): Promise<IEnum[]> => {
  try {
    const { data } = await authFetch.get('/api/client/LocationStatuses');

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCoverageTypes = async (): Promise<IEnum[]> => {
  try {
    const { data } = await authFetch.get('/api/client/CoverageTypes');

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMonitoredEntityByEntityId = async (entityId: number | string): Promise<IMonitoredEntity> => {
  try {
    const { data } = await authFetch.get(`/api/client/GetMonitoredEntityByEntityId?EntityId=${entityId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientReferenceNumberIsInUse = async (params: {
  clientId: number;
  entityId: number;
  referenceNumber: string;
  locationId: number | string;
}): Promise<boolean> => {
  try {
    const res = await authFetch.get(`${baseUrl}/EntityReferenceNumberIsInUse`, { params });

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting client. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClientContactGroups = async (clientId: number): Promise<IEnumDropdown[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${clientId}/contact-groups`);
    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting client contact groups. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
