import React, { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
// Icons
import { Clear } from '@material-ui/icons';
// Models
import { IEnum, INotificationsTableFilters } from '../../types';
import { Select } from '../../components';

interface INotificationsTableFiltersProps {
  handleFilter: (filters: INotificationsTableFilters) => void;
  isLoading: boolean;
  sourceTypes?: IEnum[];
}

export const NotificationsTableFilters: FC<INotificationsTableFiltersProps> = ({ handleFilter, isLoading, sourceTypes }) => {
  const classes = useStyles();
  const [sourceType, setSourceType] = useState<number>(null);

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce(sourceTypeFilter => {
      handleFilter({
        sourceType: sourceTypeFilter
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(sourceType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType]);

  return (
    <div className={classes.filterBarContainer}>
      <div className={classes.selectContainer}>
        {!sourceType &&
        <Typography className={`${classes.placeholder} ${classes.selectLabel}`}>
          Type
        </Typography>
        }
        <Select
          className={`${classes.filter} ${classes.select}`}
          name='complianceStatusSelector'
          id='complianceStatusSelector'
          value={sourceType === null ? '' : sourceType}
          autoWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSourceType(Number(e.target.value))}
          options={sourceTypes.map(s => ({ key: s.value, label: s.description, value: s.value }))}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {!!sourceType && (
                  <IconButton size='small' className={classes.clearIcon} onClick={() => setSourceType(null)}>
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(.5)
    },
    filter: {
      marginRight: theme.spacing(1)
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    placeholder: {
      color: theme.palette.grey[400]
    },
    selectLabel: {
      position: 'absolute',
      left: 0,
      bottom: theme.spacing(.2)
    },
    selectContainer: {
      position: 'relative'
    },
    select: {
      width: theme.spacing(14)
    }
  };
});
