import React, { FC } from 'react';
import * as Yup from 'yup';
import { Add, Save } from '@material-ui/icons';
import { deepEqual } from 'fast-equals';
import { Fade, CardActions, Button, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, withFormik, FormikProps } from 'formik';
import { useMedia } from 'react-use';
// components
import { Modal, Loader } from '../../components';
// constants
import { screenSizes } from '../../constants';
// fetch
import { updateLineOfCoverage, createLineOfCoverage } from '../../fetch';
// styles
import { colors } from '../../styles';
// types
import { ILineOfCoverage } from '../../types';

interface IAddEditLinesOfCoverageFormProps {
  initialValues: ILineOfCoverage | {};
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  showToast: (type: string, message: string) => void;
}

const AddEditLinesOfCoverage: FC<IAddEditLinesOfCoverageFormProps & FormikProps<ILineOfCoverage>> = ({
  open,
  onClose,
  onSave,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur
}) => {
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles();

  return (
    <Modal
      maxWidth={'sm'}
      open={open}
      title={values.lineOfCoverageId ? 'Edit Line of Coverage' : 'Add New Line of Coverage'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <div className={isMobile ? classes.mobileContent : classes.content}>
            <TextField
              fullWidth
              required
              autoComplete='nope'
              label='Line of Coverage Name'
              name='name'
              value={values.name}
              className={classes.formTextField}
              onBlur={handleBlur}
              onChange={e => setFieldValue('name', e.target.value)}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </div>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.lineOfCoverageId ? <Save /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.lineOfCoverageId ? 'Save' : 'Add Line of Coverage'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const LinesOfCoverageSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

export default withFormik<IAddEditLinesOfCoverageFormProps, ILineOfCoverage>({
  enableReinitialize: true,
  validationSchema: LinesOfCoverageSchema,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      lineOfCoverageId: 0,
      name: '',
      ...initialValues
    }

  },
  handleSubmit: async (values, { resetForm, props: { onClose, onSave, showToast }}) => {
    try {
      if (values.lineOfCoverageId) {
        await updateLineOfCoverage(values);
        showToast('success', 'Line of Coverage Updated!');
        onClose();
        onSave();
        resetForm();
      } else {
        await createLineOfCoverage(values);
        showToast('success', 'Line of Coverage Added!');
        onClose();
        onSave();
        resetForm();
      }
    } catch (error) {
      showToast('error', 'We were unable to create the line of coverage at this time. Please try again later. Please contact support if this issue continues.');
    }
  }
})(AddEditLinesOfCoverage);

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px'
  },
  formTextField: {
    marginBottom: theme.spacing(3)
  },
  content: {
    marginTop: '31px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  mobileContent: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column'
  },
  columnPadding: {
    paddingRight: theme.spacing(9)
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue
  }
}));
