import React, { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { Select } from '../../components';
import { Button, InputAdornment, TextField, IconButton, Typography } from '@material-ui/core';
// Icons
import { Clear } from '@material-ui/icons';
// Constants
import { screenSizes } from '../../constants';
// Models
import { IManageUsersFilters } from '../../types';

interface IManageUsersFiltersProps {
  handleFilter: (filters: IManageUsersFilters) => void;
  isLoading: boolean;
}

export const ManageUsersFilters: FC<IManageUsersFiltersProps> = ({ handleFilter, isLoading }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);
  const isTablet = useMedia(screenSizes.tablet);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce((firstNameFilter, lastNameFilter, userNameFilter, emailFilter, roleFilter) => {
      handleFilter({
        firstName: firstNameFilter,
        lastName: lastNameFilter,
        userName: userNameFilter,
        email: emailFilter,
        role: roleFilter
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(firstName, lastName, userName, email, role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, userName, email, role]);

  return (
    <div className={!isMobile ? classes.filterBarContainer : ''}>
      <TextField
        placeholder='First Name'
        className={classes.filter}
        value={firstName}
        onChange={({ target: { value } }) => setFirstName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!firstName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setFirstName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      <TextField
        placeholder='Last Name'
        className={classes.filter}
        value={lastName}
        onChange={({ target: { value } }) => setLastName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!lastName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setLastName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      <TextField
        placeholder='User Name'
        className={classes.filter}
        value={userName}
        onChange={({ target: { value } }) => setUserName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!userName && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setUserName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      <TextField
        placeholder='Email Address'
        className={classes.filter}
        value={email}
        onChange={({ target: { value } }) => setEmail(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!email && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setEmail('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      <div className={classes.selectContainer}>
        {!role &&
        <Typography className={`${classes.placeholder} ${classes.selectLabel}`}>
          Role
        </Typography>
        }
        <Select
          name='role'
          id='role-select'
          value={role}
          className={`${classes.filter} ${classes.select} ${role === '' ? classes.placeholder : ''}`}
          options={[
            { key: 'undefined', value: '', label: 'Role' },
            { key: 'Administrator', value: 'Administrator', label: 'Administrator' },
            { key: 'User', value: 'User', label: 'User' },
            { key: 'ExternalUser', value: 'ExternalUser', label: 'External User' }
          ]}
          onChange={({ target: { value } }) => setRole(value as string)}
        />
      </div>

      {(!!firstName || !!lastName || !!userName || !!email || !!role) && (
        <Button
          startIcon={<Clear />}
          className={isMobile || isTablet ? classes.mobileResetButton : classes.resetButton}
          onClick={() => {
            setFirstName('');
            setLastName('');
            setUserName('');
            setEmail('');
            setRole('');
          }}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    filter: {
      marginRight: theme.spacing(1)
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1)
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    placeholder: {
      color: theme.palette.grey[400]
    },
    select: {
      maxWidth: '175px'
    },
    selectLabel: {
      position: 'absolute',
      left: 0,
      bottom: theme.spacing(0.2)
    },
    selectContainer: {
      position: 'relative',
      width: theme.spacing(12)
    }
  };
});
