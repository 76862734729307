import React, { FC, useState, useEffect } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import { Select } from '../../../components';
// types
import { IEntityRequirement, IEnum, ILineOfCoverage, INamedPolicyStatus } from '../../../types';
// icons
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import AlarmIcon from '@material-ui/icons/Alarm';
import { isBefore } from 'date-fns';

interface INamedPolicyStatusCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
  linesOfCoverage: ILineOfCoverage[];
  requirements: IEntityRequirement[];
  namedPolicyStatusEnums: IEnum[];
  showInactive: boolean;
  parentValues: any;
}

export const NamedPolicyStatusCell: FC<INamedPolicyStatusCellProps> = ({
  cell: { row: original },
  row: { index },
  column: { id },
  editing,
  updateData,
  linesOfCoverage,
  requirements,
  namedPolicyStatusEnums,
  showInactive,
  parentValues,
}) => {
  const classes = useStyles();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.linesOfCoverage);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.linesOfCoverage);
  }, [original.original.linesOfCoverage]);

  useEffect(() => {
    // Set the value if it isn't the original value
    if (value !== original.original.linesOfCoverage) {
      updateData('linesOfCoverage', value, original.original);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getColumnImg = (status?: INamedPolicyStatus): JSX.Element => {
    switch (status) {
      case INamedPolicyStatus.Compliant:
        return (
          <Tooltip title={'Evidence of Requirement Received'}>
            <CheckIcon className={classes.checkIcon} />
          </Tooltip>
        );
      case INamedPolicyStatus.Missing:
        return (
          <Tooltip title={'Missing'}>
            <WarningIcon className={classes.missingIcon} />
          </Tooltip>
        );
      case INamedPolicyStatus.IncorrectlyListed:
        return (
          <Tooltip title={'Incorrectly Listed'}>
            <WarningIcon className={classes.incorrectlyIcon} />
          </Tooltip>
        );
      case INamedPolicyStatus.Expired:
        return (
          <Tooltip title={'Expired'}>
            <AlarmIcon className={classes.alarmIcon} />
          </Tooltip>
        );
      default: // Do nothing
    }
  };

  const handleInactiveFilter = (linesOfCoverage: any[]): any[] => {
    if (!showInactive) return linesOfCoverage.filter(x => x.isActive);

    return linesOfCoverage;
  };

  const getMatchingRequirement = (lineOfCoverageId: number) =>
    requirements.find(requirement => lineOfCoverageId === requirement.lineOfCoverageId);

  const isExpired = coveredPolicy => {
    const matchingRequirement = getMatchingRequirement(coveredPolicy.lineOfCoverageId);

    return (
      matchingRequirement &&
      matchingRequirement.expirationDate &&
      isBefore(new Date(matchingRequirement.expirationDate.split('T')[0]), new Date())
    );
  };

  const getCoveredPoliciesForReadOnly = additionalInsuredEntity => {
    let entitiesToShow = handleInactiveFilter(additionalInsuredEntity.linesOfCoverage);

    return entitiesToShow.map((coveredPolicy, index) => (
      <div key={`${coveredPolicy.lineOfCoverageId}_${index}`} className={classes.policyContainer}>
        {isExpired(coveredPolicy)
          ? getColumnImg(INamedPolicyStatus.Expired)
          : getColumnImg(coveredPolicy?.namedPolicyStatus)}
        <Typography>{coveredPolicy?.name}</Typography>
      </div>
    ));
  };

  const setLineOfCoverageStatus = (coveredPoliciesList, coveredPolicy, value) => {
    coveredPolicy.namedPolicyStatus = value;

    const index = coveredPoliciesList.indexOf(coveredPolicy);
    coveredPoliciesList[index] = coveredPolicy;
    setValue(coveredPoliciesList);
  };

  const getStatusOptions = coveredPolicy => {
    const mappedStatuses = namedPolicyStatusEnums.map(c => ({ value: c.value, label: c.description, key: value }));

    // Add this to our menu options so we can display to the user 'Expired' in our select component, even though they can't pick it
    if (isExpired(coveredPolicy)) {
      mappedStatuses.push({ value: 4, label: 'Expired', key: 4 });
    }

    return mappedStatuses;
  };

  const getCoveredPoliciesForEditing = additionalInsuredEntity => {
    let entitiesToShow = handleInactiveFilter(additionalInsuredEntity.linesOfCoverage);

    return entitiesToShow.map((coveredPolicy, index) => (
      <div key={`${coveredPolicy.lineOfCoverageId}_${index}`} className={classes.policyContainer}>
        <Select
          showReset={false}
          disabled={isExpired(coveredPolicy)}
          name='linesOfCoverage'
          id='covered-policies-status-select'
          className={classes.statusDropdown}
          value={isExpired(coveredPolicy) ? 4 : coveredPolicy?.namedPolicyStatus}
          options={getStatusOptions(coveredPolicy)}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setLineOfCoverageStatus(entitiesToShow, coveredPolicy, event.target.value ? event.target.value : null)
          }
        />
        <Typography>{coveredPolicy?.name}</Typography>
      </div>
    ));
  };

  return editing ? (
    <>{getCoveredPoliciesForEditing(original.original)}</>
  ) : (
    <>{getCoveredPoliciesForReadOnly(original.original)}</>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    policyContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
    missingIcon: {
      color: theme.palette.error.main,
    },
    incorrectlyIcon: {
      color: theme.palette.warning.main,
    },
    alarmIcon: {
      color: theme.palette.error.main,
    },
    statusDropdown: {
      width: theme.spacing(10),
      marginRight: theme.spacing(1),
    },
  };
});
