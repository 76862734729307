import { createContext, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// helpers
import { setUserLocalStorage, removeUserLocalStorage, getUserLocalStorage } from '../helpers';
// models
import { ILoginUser } from '../types';
// constants

interface IUserContext {
  setUserContext: (userContext: ILoginUser | undefined) => Promise<void>;
  userContext: ILoginUser | undefined;
  isFetching: boolean;
}

export const UserContext = createContext<IUserContext>({ setUserContext: () => Promise.resolve(), userContext: undefined, isFetching: false });

interface IUserContextHandlerProps {}

export const UserContextHandler: FC<IUserContextHandlerProps> = ({ children }): JSX.Element => {
  const location = useLocation();

  const [userContext, setUserContext] = useState<ILoginUser | undefined>(undefined);
  const [isFetching, setFetching] = useState<boolean>(true);

  const handleSetUser = async (userContext: ILoginUser | undefined) => {
    let userDataMutationPromise:Promise<any> = null;
    setFetching(true)
    if (userContext) {
      userDataMutationPromise = setUserLocalStorage(userContext);
    } else {
      userDataMutationPromise = removeUserLocalStorage();
    }
    console.info('setting userContext as ', userContext)
    return userDataMutationPromise.then(x=>{
      setUserContext(userContext);
      console.debug('set user context in memory')
    }).finally(() => {
      setFetching(false);
    });
  };

  /**
   * This has an effect on user information being available when duplicating tabs
   */
  const fetchUserFromLocalStorage = async () => {
    try {
      const userContext = await getUserLocalStorage();
      console.info('fetchUserFromLocalStorage userContext',userContext)
      if (userContext) {
        setUserContext(userContext);
      }
    } catch (e) {
      console.log(e, 'fetchUserFromLocalStorage');
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    console.info('userContextPathName',location.pathname)
    fetchUserFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <UserContext.Provider value={{ userContext, setUserContext: handleSetUser, isFetching }}>{children}</UserContext.Provider>;
};
