import { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMedia } from 'react-use';
// Components
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
// Icons
import { Clear } from '@material-ui/icons';
// Constants
import { screenSizes } from '../../constants';
// Models
import { ILetterRunFilters } from '../../types';

interface IContactLetterFiltersProps {
  handleFilter: (filters: ILetterRunFilters) => void;
  isLoading: boolean;
}

export const ContactLetterFilters: FC<IContactLetterFiltersProps> = ({ handleFilter, isLoading }) => {
  const classes = useStyles();
  const isMobile = useMedia(screenSizes.mobile);

  const [name, setName] = useState<string>('');

  // useCallback prevents this from being initialized
  // more than once which allows debounce to work properly.
  // Also we pass in a param to this otherwise we won't have
  // the correct reference and will use initial value of the filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCallback = useCallback(
    debounce(nameFilter => {
      handleFilter({
        name: nameFilter,
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!isLoading) {
      filterCallback(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <div className={!isMobile ? classes.filterBarContainer : ''}>
      <TextField
        placeholder='Letter Run Name'
        className={classes.filter}
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!!name && (
                <IconButton size='small' className={classes.clearIcon} onClick={() => setName('')}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    filter: {
      marginRight: theme.spacing(1),
    },
    resetButton: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
    mobileResetButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    clearIcon: {
      color: theme.palette.grey[400],
      cursor: 'pointer',
    },
  };
});
