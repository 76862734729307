import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Select, Table } from '../../components';
import { colors } from '../../styles';
import { IEnum } from '../../types';
import { IBulkDataDefaultRequirement, IBulkUpdateData } from '../../types/bulkUpdate';

interface IBulkUpdateMonitoredEntitiesColumns {
  // This is the props.location.state.<t> that gets passed from React Router
  isLoading: boolean;
  requirements: IBulkDataDefaultRequirement[];
  updateData: (
    field: string,
    newValue: any,
    original: IBulkDataDefaultRequirement,
    values: any,
    setFieldValue: any,
    secondField?: string,
    secondNewValue?: any
  ) => void;
  carriers: Omit<IEnum, 'description'>[];
  values: any;
  setFieldValue: any;
  touched: boolean;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
  valuesAreNotValid: (values: any) => any;
  bulkUpdateData: IBulkUpdateData;
  associatedFiles: { key: string; label: string; value: string }[];
  monitoredEntityIdArrays: string[];
  setFileAssociations: React.Dispatch<React.SetStateAction<any[]>>;
  fileAssociations: any[];
  setSelectedMonitoredEntityId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const BulkUpdateMonitoredEntitiesColumns: FC<PropsWithChildren<IBulkUpdateMonitoredEntitiesColumns>> = ({
  isLoading,
  requirements,
  updateData,
  carriers,
  values,
  setFieldValue,
  touched,
  setTouched,
  valuesAreNotValid,
  bulkUpdateData,
  associatedFiles,
  monitoredEntityIdArrays,
  setFileAssociations,
  fileAssociations,
  setSelectedMonitoredEntityId,
}) => {
  const classes = useStyles();

  useEffect(() => {
    // File Associations Check is necessary to prevent rerendering infinite loop caused by setting the state of the parent.
    if (associatedFiles.length === 1 && monitoredEntityIdArrays.length > 0 && fileAssociations.length < 1) {
      const autoAssociations = monitoredEntityIdArrays.map(monitoredEntityId => {
        return { [monitoredEntityId]: associatedFiles?.[0].value };
      });

      setFileAssociations(autoAssociations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedFiles]);

  const BulkUpdateMonitoredEntitiesColumns = useMemo(() => {
    return [
      {
        accessor: 'location',
        Header: 'Location',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: number } };
        }) => {
          return bulkUpdateData?.monitoredEntitiesToData[original].locationName ?? '';
        },
      },
      {
        Header: 'Entity Name',
        id: 'coverageType',

        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: number } };
        }) => {
          return bulkUpdateData?.monitoredEntitiesToData[original].entityName ?? '';
        },
      },
      {
        Header: 'File',
        overrideWidth: 450,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: number } };
        }) => {
          return (
            <Select
              name='file'
              id='unit-type-select'
              label='File Name'
              disabled={associatedFiles.length < 1}
              value={fileAssociations?.find(file => file?.hasOwnProperty(original))?.[original] ?? null}
              onChange={e => {
                setFileAssociations(prev => {
                  const previousVal = prev.findIndex(file => file?.hasOwnProperty(original)) ?? null;
                  if (previousVal > -1) {
                    prev.splice(previousVal, 1);
                  }

                  if (e.target.value) {
                    return [...prev, { [original]: e.target.value }];
                  } else {
                    const cleaned = JSON.parse(JSON.stringify(prev));

                    if (prev.findIndex(file => file?.hasOwnProperty(original))) {
                      delete cleaned[original];
                    }
                    return cleaned;
                  }
                });
              }}
              options={associatedFiles}
            />
          );
        },
      },
    ].filter(col => {
      if (!bulkUpdateData?.doesClientUseLocations) {
        return col.accessor !== 'location';
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedFiles, bulkUpdateData]);

  const [activeRowIndex, setActiveRowIndex] = useState(null);
  return (
    <>
      <Table
        isLoading={isLoading}
        useTableProps={{
          requirements,
          updateData,
          carriers,
          values,
          setFieldValue,
          touched,
          setTouched,
          valuesAreNotValid,
        }}
        loadingPageSize={
          !Array.isArray(values.requirements) || values.requirements.length === 0 ? 3 : values.requirements.length
        }
        containerClasses={classes.table}
        columns={BulkUpdateMonitoredEntitiesColumns}
        data={monitoredEntityIdArrays}
        headerClasses={classes.tableHeader}
        rowOnClick={(row, index) => {
          setSelectedMonitoredEntityId(row);
          setActiveRowIndex(index);
        }}
        activeRowIndex={activeRowIndex}
        rowClasses={classes.row}
        rowClassActive={classes.activeRow}
        centerPagination
        stickyHeader
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  table: {
    '& table': {
      borderCollapse: 'collapse',
    },
  },
  activeRow: {
    backgroundColor: ` ${fade(theme.palette.secondary.main, 0.075)}!important`,
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
}));
