export enum ApplicationSettings {
    RunComplianceJob = 1
}

export const ApplicationSettingsNameLookup = (value: ApplicationSettings) => {
    switch (value) {
        case ApplicationSettings.RunComplianceJob:
            return "Scheduled Compliance Job";
        default:
            throw new Error(`Application Setting ${value} does not have a configured name lookup.`);
    }
}

export const ApplicationSettingsWarningLookup = (value: ApplicationSettings) => {
    switch (value) {
        case ApplicationSettings.RunComplianceJob:
            return "Warning, enabling/disabling the Compliance job may result in incorrect data and compliance calculations. Please confirm you would like to proceed";
        default:
            throw new Error(`Application Setting ${value} does not have a configured warning message.`);
    }
}