import { Card, CardContent, Container, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia, useSearchParam } from 'react-use';
import * as Yup from 'yup';

import background from '../../assets/background.png';
import BBLogo from '../../assets/logo.png';
import { Button, Loader, LoginHeader, Toast } from '../../components';
import { routes, screenSizes } from '../../constants';
import { UserContext } from '../../context';
import { resetPassword } from '../../fetch';
import { colors } from '../../styles';
import { ArrowBack } from '@material-ui/icons';
import { PasswordRequirements, isValidPassword } from '../../helpers';

interface IResetPasswordValues {
  username: string;
  password: string;
  confirmPassword: string;
}

const ResetPasswordSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string(),
  confirmPassword: Yup.string().when('password', {
    is: password => !password,
    then: schema => schema,
    otherwise: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Please re-enter your password to confirm.'),
  }),
});

export const ResetPassword: FC = () => {
  const token = useSearchParam('token');

  const isMobile = useMedia(screenSizes.mobile);
  const username = useRef(null);
  const history = useHistory<{ from: { pathname: string } & Record<string, unknown> }>();
  const userContextProvider = useContext(UserContext);
  const classes = useStyles();

  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState<null | { message: string }>(null);

  useEffect(() => {
    document.title = `Reset Password | Beecher Carlson`;
    setIsLoading(false);
  }, []);

  if (userContextProvider.isFetching) {
    return null;
  }

  const handleFormSubmit = async (values: IResetPasswordValues, actions: FormikHelpers<IResetPasswordValues>) => {
    try {
      const payload = { username: values.username, resetToken: token, password: values.password };
      await resetPassword(payload);
      setSuccess({ message: 'Password reset!' });
      history.push(routes.login);
    } catch (error) {
      console.dir(error);
      if (error && error?.detail) {
        setError(error?.detail);
      } else if (error && error?.BulkProblems) {
        setError(error?.BulkProblems?.[0]);
      } else if (error && error?.Detail) {
        setError(error?.Detail);
      } else {
        console.log('error', error);
        setError('Something went wrong. Please try again later.');
      }
      username.current && username.current.focus();
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <LoginHeader />
      <div className={classes.loginContainer}>
        {isLoading && (
          <Loader position='centered' type='fullscreen'>
            Loading...
          </Loader>
        )}
        <Formik
          initialValues={{
            username: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={async (values: IResetPasswordValues, actions: FormikHelpers<IResetPasswordValues>) =>
            handleFormSubmit(values, actions)
          }
        >
          {(formikProps: FormikProps<IResetPasswordValues>) => (
            <Form data-testid='login-form' onSubmit={formikProps.handleSubmit} className={classes.formik}>
              <Container maxWidth='sm'>
                <Grid alignItems='center' container={true} direction='column' justify='center'>
                  <Card>
                    {/* LOGIN FORM */}
                    <CardContent className={isMobile ? classes.mobileFormContainer : classes.formContainer}>
                      <Grid container={true} direction='column' alignItems='center'>
                        {/* B&B LOGO */}
                        <img src={BBLogo} alt='logo' className={isMobile ? classes.mobileLogo : classes.logo} />

                        {/* WELCOME MESSAGE */}
                        <Typography className={isMobile ? classes.mobileWelcomeMessage : classes.welcomeMessage}>
                          Reset your Password.
                        </Typography>

                        <div className={isMobile ? classes.mobileInputContainer : classes.inputContainer}>
                          {/* USERNAME TEXT-FIELD */}

                          <FormControl margin='normal'>
                            <Field name='username'>
                              {({ field, form }: FieldProps<IResetPasswordValues>) => (
                                <TextField
                                  {...field}
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.username && form.errors && form.errors.username)}
                                  fullWidth={true}
                                  helperText={form.touched.username && form.errors && form.errors.username}
                                  inputRef={username}
                                  label='Username'
                                  margin='none'
                                  required={true}
                                  value={formikProps.values.username}
                                />
                              )}
                            </Field>
                          </FormControl>
                          {/* PASSWORD TEXT-FIELD */}
                          <FormControl margin='dense'>
                            <Field
                              name='password'
                              validate={value =>
                                isValidPassword(value) && value === formikProps.values.password ? false : 'invalid'
                              }
                            >
                              {({ field, form }: FieldProps<IResetPasswordValues>) => (
                                <TextField
                                  {...field}
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.password && form.errors && form.errors.password)}
                                  fullWidth={true}
                                  helperText={
                                    form.touched.password &&
                                    form.errors &&
                                    form.errors.password &&
                                    (form.errors.password === 'invalid' ? (
                                      <PasswordRequirements value={formikProps.values.password} color={'#BA2031'} />
                                    ) : (
                                      form.errors.password
                                    ))
                                  }
                                  id='password'
                                  label='Password'
                                  margin='none'
                                  required={true}
                                  type='password'
                                  value={formikProps.values.password}
                                />
                              )}
                            </Field>
                          </FormControl>
                          {/* PASSWORD TEXT-FIELD */}
                          <FormControl margin='dense'>
                            <Field name='confirmPassword'>
                              {({ field, form }: FieldProps<IResetPasswordValues>) => (
                                <TextField
                                  {...field}
                                  className={isMobile ? classes.mobileTextField : classes.textField}
                                  error={!!(form.touched.confirmPassword && form.errors && form.errors.confirmPassword)}
                                  fullWidth={true}
                                  helperText={
                                    form.touched.confirmPassword && form.errors && form.errors.confirmPassword
                                  }
                                  id='confirm-password'
                                  label='Confirm Password'
                                  margin='none'
                                  required={true}
                                  type='password'
                                  value={formikProps.values.confirmPassword}
                                />
                              )}
                            </Field>
                          </FormControl>
                          {/* RESET BUTTONS */}
                          <div className={isMobile ? classes.mobileResetButtons : classes.resetButtons}>
                            <FormControl margin={isMobile ? 'dense' : 'normal'}>
                              <Button
                                color='secondary'
                                className={isMobile ? classes.mobileLoginButton : classes.loginButton}
                                disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}
                                id='submit'
                                loading={formikProps.isSubmitting ? 'Resetting...' : ''}
                                size='large'
                                type='submit'
                                variant='contained'
                                startIcon={<ArrowForwardOutlinedIcon />}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    formikProps.handleSubmit();
                                  }
                                }}
                              >
                                {!formikProps.isSubmitting ? 'Reset Password' : ''}
                              </Button>
                            </FormControl>
                            <FormControl margin={isMobile ? 'dense' : 'normal'}>
                              <Button
                                color='secondary'
                                className={isMobile ? classes.mobileLoginButton : classes.loginButton}
                                id='submit'
                                disabled={formikProps.isSubmitting}
                                size='large'
                                type='submit'
                                variant='contained'
                                startIcon={<ArrowBack />}
                                onClick={() => history.push('/')}
                              >
                                Back
                              </Button>
                            </FormControl>
                          </div>
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>

        {/* SUCCESS */}
        <Toast
          id='reset-success'
          message={success && success.message}
          onClick={() => setSuccess(null)}
          onClose={() => setSuccess(null)}
          open={!!success}
          variant='success'
        />

        {/* FAILURE */}
        <Toast
          id='reset-error'
          message={error}
          onClick={() => setError(null)}
          onClose={() => setError(null)}
          open={!!error}
          variant='error'
        />
      </div>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    loginContainer: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflow: 'auto',
    },
    formik: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    formContainer: {
      width: '486px',
      minHeight: '642px',
      height: '100%',
      padding: '0px',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    mobileFormContainer: {
      maxWidth: '328px',
      minHeight: '446px',
      maxHeight: '100%',
      border: `5px solid ${colors.secondary.cheetahYellow}`,
    },
    logo: {
      width: '177px',
      height: '198px',
      marginTop: '56px',
    },
    mobileLogo: {
      maxWidth: '80px',
      maxHeight: '90px',
      marginTop: '12px',
    },
    welcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '19px',
      marginBottom: '20px',
      fontWeight: 600,
    },
    mobileWelcomeMessage: {
      color: colors.primary.navyBlue,
      fontSize: '18px',
      marginTop: '9px',
      marginBottom: '10px',
      fontWeight: 600,
      textAlign: 'center',
    },
    inputContainer: {
      width: '321px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    mobileInputContainer: {
      width: '205px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    textField: {
      width: '321px',
    },
    mobileTextField: {
      width: '205px',
    },
    loginButton: {
      marginTop: '32px',
    },
    mobileLoginButton: {
      marginTop: '16px',
    },
    resetButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '321px',
    },
    mobileResetButtons: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };
});
