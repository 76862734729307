import React, { FC, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Field, FieldProps, withFormik, FormikProps } from 'formik';
import { useMedia } from 'react-use';
// Icons
import { Add, Save } from '@material-ui/icons';
import {
  Fade,
  Divider,
  CardActions,
  Button,
  TextField,
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  FormLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
} from '@material-ui/core';
// Components
import { Modal, Loader, Select } from '../../components';
// Constants
import { screenSizes, userRoles } from '../../constants';
// Types
import { IClient, IUser, IPortfolioListItem } from '../../types';
// Fetch
import { updateUser, createUser, getClientsForSelector, getPortfolios } from '../../fetch';
// Helpers
import { PasswordRequirements, isValidPassword } from '../../helpers';

interface IAddEditUsersFormProps {
  initialValues: IUser | {};
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  showToast: (type: string, message: string) => void;
}

const AddEditUsers: FC<IAddEditUsersFormProps & FormikProps<IUser>> = ({
  open,
  onClose,
  onSave,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur,
}) => {
  const isMobile = useMedia(screenSizes.mobile);
  const classes = useStyles(isMobile);

  const [loading, setLoading] = useState<boolean>(false);
  const [clientItems, setClientItems] = useState<IClient[]>([]);
  const [portfolios, setPortfolios] = useState<IPortfolioListItem[] | []>([]);

  const loadPortfolios = async () => {
    setLoading(true);
    try {
      const response = await getPortfolios(values.clientId);
      setPortfolios(response);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (values.clientId) {
      loadPortfolios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.clientId]);

  useEffect(() => {
    getClientsForSelector().then(data => {
      setClientItems(data);
    });
  }, []);

  const handleCheckboxClick = (idList, value, setFieldValue, field) => () => {
    const currentIndex = idList.findIndex(i => i.portfolioId === value.portfolioId);
    const newChecked = [...idList];

    if (currentIndex === -1) {
      newChecked.push({ portfolioId: value.portfolioId });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFieldValue(field, newChecked);
  };

  return (
    <Modal
      maxWidth={'md'}
      open={open}
      title={values.userId ? 'Edit User' : 'Add New User'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <div className={!isMobile ? classes.content : ''}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <div className={classes.column}>
                  {/* FIRST NAME */}
                  <TextField
                    required
                    autoComplete='nope'
                    label='First Name'
                    name='firstName'
                    value={values.firstName}
                    className={classes.formTextField}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('firstName', e.target.value)}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />

                  {/* LAST NAME */}
                  <TextField
                    required
                    autoComplete='nope'
                    label='Last Name'
                    name='lastName'
                    value={values.lastName}
                    className={classes.formTextField}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('lastName', e.target.value)}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />

                  {/* E-MAIL */}
                  <TextField
                    required
                    autoComplete='nope'
                    label='Email Address'
                    name='email'
                    value={values.email}
                    className={classes.formTextField}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('email', e.target.value)}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.column}>
                  {/* USERNAME */}
                  {!values.userId && (
                    <TextField
                      error={!!(touched.username && errors && errors.username)}
                      helperText={touched.username && errors && errors.username}
                      required
                      autoComplete='nope'
                      id='username'
                      label='Username'
                      margin='none'
                      value={values.username}
                      className={classes.formTextField}
                      onBlur={handleBlur}
                      onChange={e => setFieldValue('username', e.target.value)}
                    />
                  )}

                  {/* NEW PASSWORD */}
                  <FormControl>
                    <Field
                      name='newPassword'
                      validate={value => ((values.userId && !value) || isValidPassword(value) ? false : 'invalid')}
                    >
                      {({ field }: FieldProps<IUser>) => (
                        <TextField
                          {...field}
                          error={!!(touched.newPassword && errors && errors.newPassword)}
                          helperText={
                            touched.newPassword &&
                            errors &&
                            errors.newPassword &&
                            (errors.newPassword === 'invalid' ? (
                              <PasswordRequirements value={values?.newPassword} />
                            ) : (
                              errors.newPassword
                            ))
                          }
                          required={!values.userId}
                          id='newPassword'
                          label='New Password'
                          margin='none'
                          type='password'
                          value={values.newPassword}
                          className={classes.formTextField}
                          onBlur={handleBlur}
                          onChange={e => setFieldValue('newPassword', e.target.value)}
                        />
                      )}
                    </Field>
                  </FormControl>

                  {/* CONFIRM NEW PASSWORD */}
                  <TextField
                    error={!!(touched.confirmPassword && errors && errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors && errors.confirmPassword}
                    required={!values.userId}
                    id='confirmPassword'
                    label='Confirm Password'
                    margin='none'
                    type='password'
                    value={values.confirmPassword}
                    className={classes.formTextField}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('confirmPassword', e.target.value)}
                  />

                  {/* CLIENT */}
                  {values.role === userRoles.EXTERNAL && (
                    <Select
                      name='client'
                      id='client-select'
                      label='Client *'
                      value={values.clientId}
                      onChange={e => setFieldValue('clientId', e.target.value)}
                      showReset={false}
                      options={clientItems.map(item => ({
                        key: item.clientId,
                        label: item.name,
                        value: item.clientId,
                      }))}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.column}>
                  {/* ROLE CHECKBOX SELECTION */}
                  <FormControl component='fieldset' className={isMobile ? classes.checkBoxContainer : ''}>
                    <FormLabel component='legend'>Role</FormLabel>
                    <FormGroup>
                      {/* CLIENT */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            checked={values.role === userRoles.EXTERNAL}
                            onChange={() => setFieldValue('role', 'ExternalUser')}
                            name='client'
                          />
                        }
                        label='Client'
                      />

                      {/* EMPLOYEE */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            checked={values.role === userRoles.INTERNAL}
                            onChange={() => setFieldValue('role', 'User')}
                            name='employee'
                          />
                        }
                        label='Employee'
                      />

                      {/* ADMIN */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            checked={values.role === userRoles.ADMIN}
                            onChange={() => setFieldValue('role', 'Administrator')}
                            name='client'
                          />
                        }
                        label='Administrator'
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>

              <Divider className={classes.tabDivider} />
              <Grid item xs={12} md={4}>
                {values.role === userRoles.EXTERNAL && (
                  <>
                    <Typography variant='h6'>User Portfolios</Typography>
                    {!loading &&
                      (!Array.isArray(values.userAllowedPortfolios) || values.userAllowedPortfolios.length === 0) &&
                      (!Array.isArray(values.userAllowedPortfolios) || values.userAllowedPortfolios.length === 0) && (
                        <Typography>A portfolio has not been added yet.</Typography>
                      )}

                    <FormControl component='fieldset' className={isMobile ? classes.checkBoxContainer : ''}>
                      <FormGroup>
                        {/* CLIENT */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkBox}
                              checked={values.allowAllPortfolios}
                              onChange={e => setFieldValue('allowAllPortfolios', e.target.checked)}
                              name='allowAllPortfolios'
                            />
                          }
                          label='Allow All Portfolios'
                        />
                      </FormGroup>
                    </FormControl>

                    {!values.allowAllPortfolios &&
                      (loading ? (
                        <Loader type='inline' position='centered' size='medium' />
                      ) : (
                        <List className={classes.listContainer}>
                          {portfolios.map(p => (
                            <ListItem
                              key={p.portfolioId}
                              role={undefined}
                              dense
                              button
                              onClick={handleCheckboxClick(
                                values.userAllowedPortfolios,
                                p,
                                setFieldValue,
                                'userAllowedPortfolios'
                              )}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge='start'
                                  checked={
                                    values.userAllowedPortfolios.find(v => v.portfolioId === p.portfolioId)
                                      ? true
                                      : false
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': p.name }}
                                />
                              </ListItemIcon>
                              <ListItemText id={p.name} primary={p.name} />
                            </ListItem>
                          ))}
                        </List>
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              {/* CANCEL */}
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>

              {/* SAVE */}
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.userId ? <Save /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.userId ? 'Save' : 'Add User'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const UserSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('Please provide a first name.'),
    lastName: Yup.string().required('Please provide a last name.'),
    email: Yup.string().email().required('Please provide a valid e-mail address that has not been used.'),
    role: Yup.string().required('A role is required.'),
    newPassword: Yup.string(),
    allowAllPortfolios: Yup.boolean(),
    confirmPassword: Yup.string().when('newPassword', {
      is: newPassword => !newPassword,
      then: schema => schema,
      otherwise: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
        .required('Please re-enter your password to confirm.'),
    }),
    clientId: Yup.number()
      .nullable()
      .when('role', {
        is: userRoles.EXTERNAL,
        then: Yup.number().nullable().required('Please select a client for this user.'),
        otherwise: Yup.number().nullable(),
      }),
  },
  [['newPassword', 'confirmPassword']]
);

export default withFormik<IAddEditUsersFormProps, IUser>({
  enableReinitialize: true,
  validationSchema: UserSchema,
  mapPropsToValues: ({ initialValues }) => {
    if (initialValues) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        username: '',
        newPassword: '',
        confirmPassword: '',
        clientId: null,
        allowAllPortfolios: false,
        userAllowedPortfolios: [],
        ...initialValues,
      };
    } else {
      return {
        userId: 0,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        username: '',
        newPassword: '',
        confirmPassword: '',
        clientId: null,
        allowAllPortfolios: false,
        userAllowedPortfolios: [],
        ...initialValues,
      };
    }
  },
  handleSubmit: async (values, { resetForm, props: { onClose, onSave, showToast } }) => {
    try {
      if (values.userId) {
        await updateUser(values);
        showToast('success', 'User Updated!');
        onClose();
        onSave();
        resetForm();
      } else {
        await createUser(values);
        showToast('success', 'User Added!');
        onClose();
        onSave();
        resetForm();
      }
    } catch (error) {
      let message =
        'We were unable to update the user at this time. Please try again later. Please contact support if this issue continues.';

      if (error?.Title || error?.BulkProblems.length)
        message = `${error?.Title} ${error?.BulkProblems?.join(' ') ?? ''}`;

      showToast('error', message);
    }
  },
})(AddEditUsers);

const useStyles = makeStyles((theme: Theme) => ({
  formTextField: {
    marginBottom: theme.spacing(2),
  },
  checkBox: {
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    // width: isMobile => (isMobile ? '100%' : '33%')
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkBoxContainer: {
    marginTop: theme.spacing(1),
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  listContainer: {
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  tabDivider: {
    marginBottom: theme.spacing(1),
  },
}));
