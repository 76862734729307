import { Button, Checkbox, CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ITableColumn, UnsavedChanges, PageTitle, MultiSelect, Separator, Page, Table } from '../../components';
import { routes, LetterRunSourceEnums } from '../../constants';
import { ClientSelectorContext } from '../../context';
import {
  getClientsForSelector,
  updateLetterRun,
  updateLetterRunDetails,
  getClientContactGroups,
  getClientContactLetters,
} from '../../fetch';
import { useToastContext } from '../../hooks';
import { IClientSelector, IContact, IEnumDropdown } from '../../types';
import { colors } from '../../styles';
import { formatError } from '../../helpers/errors';

interface IClientContactGroupLetters {
  contactGroupId: number;
  contactGroupName: string;
  contactName: string;
  emailCount: number;
}

export const ContactLetterRun: FC = () => {
  const [clientList, setClientList] = useState<IClientSelector[]>([]);
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [contactGroupList, setContactGroupList] = useState<IEnumDropdown[]>([]);
  const [clientLettersData, setClientLettersData] = useState<IClientContactGroupLetters[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [startLetterRun, setStartLetterRun] = useState<boolean>(false);
  const [selectedContactGroups, setSelectedContactGroups] = useState<number[]>([]);
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const { showToast } = useToastContext();
  const history = useHistory();
  const classes = useStyles();

  const load = async () => {
    try {
      // Fetch necessary all at once rather than one after the other
      const [clientResponse] = await Promise.all([
        getClientsForSelector(),
        clientSelectorContext?.clientId
          ? getClientContactGroups(clientSelectorContext.clientId).then(list => setContactGroupList(list))
          : Promise.resolve(),
      ]);
      setClientList(clientResponse);
    } catch (error) {
      showToast('error', 'There was a problem getting the data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When we load up the tables, load them with everything selected
  useEffect(() => {
    const fullContactGroupList = [];
    clientLettersData?.forEach(entity => fullContactGroupList.push(entity.contactGroupId));

    setSelectedContactGroups(fullContactGroupList);
  }, [clientLettersData]);

  const getContactGroupDropdownOptions = () => {
    const contactGroupOptions = contactGroupList?.map(contactGroup => ({
      key: contactGroup.value,
      label: contactGroup.text,
      value: contactGroup.value,
    }));
    if (contactGroupOptions?.length > 0) {
      return contactGroupOptions;
    } else {
      return [{ key: null, label: 'No Contact Groups found.', value: null }];
    }
  };

  const clientContactsColumns = useMemo(() => {
    return [
      {
        id: 'checkboxes',
        Header: () => {
          return (
            <Checkbox
              indeterminate={
                !!(selectedContactGroups?.length !== clientLettersData.length) && selectedContactGroups.length > 0
              }
              checked={
                !!(selectedContactGroups?.length === clientLettersData.length) && selectedContactGroups.length > 0
              }
              onClick={() => {
                // Select all if indeterminate or if all are unselected
                if (!!(selectedContactGroups?.length !== clientLettersData.length)) {
                  const missingFromSelected = clientLettersData
                    .map(clientLetter => clientLetter.contactGroupId)
                    .filter(clientLetterId => selectedContactGroups.includes(clientLetterId));

                  setSelectedContactGroups(prev => [...prev, ...missingFromSelected]);
                }

                // Unselect all from filtered results if they are all selected
                if (selectedContactGroups?.length === clientLettersData.length) {
                  setSelectedContactGroups([]);
                } else {
                  setSelectedContactGroups([...clientLettersData.map(clientLetter => clientLetter.contactGroupId)]);
                }
              }}
            />
          );
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IClientContactGroupLetters } };
        }) => {
          const foundIndex = selectedContactGroups.findIndex(i => i === original.contactGroupId);

          // MONITORED ENTITY CHECKBOX FOR LETTER GENERATION
          return (
            <Checkbox
              size='small'
              checked={selectedContactGroups.includes(original.contactGroupId)}
              onClick={() => {
                if (foundIndex > -1) {
                  setSelectedContactGroups(prev =>
                    prev.filter(contactGroup => contactGroup !== original.contactGroupId)
                  );
                } else {
                  console.log('else');
                  setSelectedContactGroups(prev => [...prev, original.contactGroupId]);
                }
              }}
            />
          );
        },
      },

      {
        accessor: 'contactName',
        Header: 'Contact',
      },
      {
        accessor: 'contactGroupName',
        Header: 'Contact Group',
      },
      {
        accessor: 'emailCount',
        Header: 'Number of Contacts',
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContactGroups, contactList]);

  const resetTable = () => {
    setContactGroupList([]);
    setContactList([]);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          client: {
            clientId: clientSelectorContext?.clientId ?? null,
            name: clientSelectorContext?.name ?? '',
            code: clientSelectorContext?.code ?? undefined,
            clientType: clientSelectorContext?.clientType ?? undefined,
          },
          contact: {
            contactId: null,
            name: '',
          },
          contactGroupIds: [],
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, dirty, handleBlur }) => {
          return (
            <Page customPageHeader title='New Client Letter Run'>
              <UnsavedChanges dirty={dirty && startLetterRun} />
              <div className={classes.headerContainer}>
                {/* BACK BUTTON */}
                <IconButton
                  className={classes.backButton}
                  color='primary'
                  onClick={() => {
                    history.push(routes.contactLetterRuns);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                {/* HEADER TITLE */}
                <PageTitle title={'New Client Letter Run'} marginOff />
                <div className={classes.contentSpacer} />
              </div>

              <Grid container alignItems={'center'} justify={'center'} spacing={1}>
                <div className={classes.tabContent}>
                  <div className={classes.dropdownWithLabel}>
                    <Typography className={classes.label}>Client:</Typography>
                    <Autocomplete
                      value={values.client}
                      id='searchable-dropdown'
                      options={clientList}
                      getOptionLabel={option => option.name}
                      className={classes.dropdown}
                      noOptionsText='No Clients'
                      onChange={async (_e, item: IClientSelector) => {
                        if (!!item) {
                          setFieldValue('client', item);
                          setFieldValue('contactGroupIds', []);
                          setClientLettersData([]);
                          try {
                            setContactGroupList(await getClientContactGroups(item.clientId));
                          } catch (error) {
                            showToast(
                              'error',
                              error instanceof Error ? error.message : 'Failed to retrieve client contact Information.'
                            );
                          }
                        } else {
                          setFieldValue('client', '');
                          setFieldValue('contactGroupIds', []);
                          resetTable();
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} name={`client-textfield`} onBlur={handleBlur} autoComplete='none' />
                      )}
                    />
                  </div>

                  <div className={classes.dropdownWithLabel}>
                    <Typography className={classes.label}>Contact Groups:</Typography>
                    <MultiSelect
                      options={getContactGroupDropdownOptions()}
                      selected={values.contactGroupIds}
                      setSelected={async contactGroupIds => {
                        if (!contactGroupIds?.length) return;
                        setFieldValue('contactGroupIds', contactGroupIds);
                      }}
                      clearSelected={() => {
                        setFieldValue('contactGroupIds', []);
                      }}
                    />
                  </div>

                  <Button
                    color='primary'
                    size='medium'
                    onClick={() => {
                      if (values.contactGroupIds.length === 0) {
                        const ids = contactGroupList.map(contactGroup => contactGroup.value);
                        setFieldValue('contactGroupIds', ids);
                      } else {
                        setFieldValue('contactGroupIds', []);
                      }
                    }}
                  >
                    {values.contactGroupIds.length > 0 ? 'Deselect All' : 'Select All'}
                  </Button>
                  <div className={classes.horizontalRule}>
                    <Separator />
                  </div>
                  <Button
                    color='primary'
                    size='medium'
                    disabled={
                      isLoading ||
                      !values?.client?.clientId ||
                      !values?.contactGroupIds?.length ||
                      values?.contactGroupIds?.includes(null)
                    }
                    onClick={async () => {
                      setLoading(true);
                      try {
                        const clientId = values.client?.clientId;
                        await getClientContactLetters(clientId, values.contactGroupIds).then(setClientLettersData);
                      } catch {
                        showToast('error', 'There was a problem retrieving the client letters. Please try again.');
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    PROCESS
                  </Button>
                </div>

                {/* GRIDS */}
                <div className={classes.header}>
                  <Grid container>
                    <PageTitle title='Preview' marginOff />
                  </Grid>

                  <Grid container alignItems='center' justify={'flex-end'}>
                    {/* GENERATE LETTERS BUTTON */}
                    <Button
                      disabled={
                        isLoading || !selectedContactGroups.length || startLetterRun || !values?.contactGroupIds?.length
                      }
                      color='primary'
                      onClick={async () => {
                        setStartLetterRun(true);

                        try {
                          // A necessary pre-step before generating letters
                          const letterRunId = await updateLetterRun(
                            values.client ? values.client.clientId : null,
                            values.contact ? values.contact.contactId : null,
                            LetterRunSourceEnums.ContactLetterRun
                          );

                          // Start generating the letters
                          await updateLetterRunDetails(letterRunId, null, selectedContactGroups);

                          setStartLetterRun(false);

                          const url = `${routes.contactLetterGenerationProgress}/letterRunId=${letterRunId}`;
                          history.replace(url);
                        } catch (letterError) {
                          formatError(letterError, showToast);
                        } finally {
                          setStartLetterRun(false);
                        }
                      }}
                    >
                      {startLetterRun && (
                        <CircularProgress size={'1rem'} color={'primary'} className={classes.circularProgress} />
                      )}
                      Generate Letters
                    </Button>
                  </Grid>
                </div>
                <div className={classes.gridContainer}>
                  <Table
                    columns={clientContactsColumns}
                    data={clientLettersData ?? []}
                    isLoading={isLoading}
                    headerClasses={classes.tableHeader}
                    stickyHeader
                  />
                </div>
              </Grid>
            </Page>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: 0,
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  contentSpacer: {
    width: '.001rem',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'hidden',
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  tabContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    marginTop: theme.spacing(1),
  },
  dropdownWithLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  horizontalRule: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  label: {
    marginRight: theme.spacing(1),
  },
  dropdown: {
    width: theme.spacing(16),
  },
  complianceMessage: {
    marginTop: theme.spacing(2),
  },
  circularProgress: {
    marginRight: theme.spacing(0.5),
  },
}));
