import { authFetch } from './';
import {
  IEnumResponse,
  IRequirementFilesResponseItem,
  IMonitoredEntityFile,
  IEnum,
  ILetterRunDetailToMonitoredEntity,
  IFile,
  IMonitoredEntityRequirements,
  IMonitoredEntityFileDelete,
} from '../types';
import axios, { AxiosResponse } from 'axios';

const baseUrl = '/api/File';

export const getFilesByRequirementId = async (id: number): Promise<IRequirementFilesResponseItem[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/ByRequirementId/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting files. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getFilesByMonitoredEntityId = async (id: number): Promise<IMonitoredEntityFile[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/ByMonitoredEntityId/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting files. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getFileData = async (fileId: number): Promise<string> => {
  try {
    const res = await authFetch.get(`${baseUrl}/FileData/${fileId}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting file. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getFileById = async (fileId: number): Promise<IFile> => {
  try {
    const res = await authFetch.get(`${baseUrl}/File/${fileId}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting file. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getFileStatuses = async (): Promise<IEnumResponse[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/FileStatus/`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting file statuses. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const addFileToMonitoredEntity = async (file: IMonitoredEntityFile): Promise<IEnum> => {
  try {
    const res = await authFetch.post(`${baseUrl}/UpdateMonitoredEntityFile`, file);

    // Check if status is in the 2XX range
    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText);
    }

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const addFileWithAccordProcessing = async (
  file: IMonitoredEntityFile
): Promise<IMonitoredEntityRequirements> => {
  try {
    const res = await authFetch.post(`${baseUrl}/AddToMonitoredEntityForAccord`, file);

    if (res) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const getLetterRunDetailSourceTypes = async (): Promise<IEnum[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/LetterRunDetailSourceTypes`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting letter run detail source types. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getByLetterRunDetailToMonitoredEntityId = async (
  monitoredEntityId: number
): Promise<ILetterRunDetailToMonitoredEntity[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/ByLetterRunDetailToMonitoredEntityId/${monitoredEntityId}`);

    if (res && res.data) {
      return res.data;
    }
    const error = {
      Detail: 'Error getting letter run detail by monitored entity id. Please try again.',
      status_code: 500,
    };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteMonitoredEntityFile = async (file: IMonitoredEntityFileDelete): Promise<void> => {
  try {
    const response: AxiosResponse<boolean> = await authFetch.delete(`${baseUrl}/DeleteMonitoredEntityFile`, file);

    // Check if status is in the 2XX range
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const getFileAssociationsCount = async (fileId: number): Promise<number> => {
  try {
    const res = await authFetch.get(`${baseUrl}/GetFileAssociationsCount`, { params: { fileId: fileId } });

    if (res && res.data) {
      return res.data;
    }
    //this check shouldn't prevent the file from being deleted from the selected entity
    return 0;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const addFileForBulkData = async (formData: FormData): Promise<any> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Files`, formData);

    if (res) {
      return res.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};
