import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, CheckCircle, KeyboardArrowDown } from '@material-ui/icons';
import { format } from 'date-fns';
import * as localForage from 'localforage';
import { Page, PageTitle, Table } from '../../components';
import { LetterRunStatusEnums, NotificationStatusEnum, routes } from '../../constants';
import {
  getLetterRun,
  getMasterMailPdf,
  getSendingEmailProgress,
  initiateFailedLetterRun,
  initiateLetterRun,
} from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IFile, IGeneratedLetter, ILetterRun } from '../../types';

const sentEmailsStorageKey = ({ letterRunId }: { letterRunId: string }) => `sent-email-notifications-${letterRunId}`;

const sentFailedEmailsStorageKey = ({ letterRunId }: { letterRunId: string }) =>
  `sent-failed-email-notifications-${letterRunId}`;

export const LettersGenerated = () => {
  const [letterRun, setLetterRun] = useState<ILetterRun>({
    clientId: 0,
    lettersGenerated: 0,
    status: '',
    statusValue: 0,
    clientName: '',
    dateTime: '',
    emailLetters: [],
    letterRunId: 0,
    mailLetters: [],
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [downloadingMailList, setDownloadingMailList] = useState<boolean>(false);
  const { showToast } = useToastContext();
  const [emailGridExpanded, setEmailGridExpanded] = useState<boolean>(true);
  const [failedEmailsGridExpanded, setFailedEmailsGridExpanded] = useState<boolean>(true);
  const [letterGridExpanded, setLetterGridExpanded] = useState<boolean>(true);
  const [startLetterRun, setStartLetterRun] = useState<boolean>(false);
  const [finishLetterRun, setFinishLetterRun] = useState<boolean>(false);
  const [finishedCount, setFinishedCount] = useState<number>(0);
  const [failedEmailData, setFailedEmailData] = useState<IGeneratedLetter[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const classes = useStyles();
  const history = useHistory();
  // @ts-ignore
  const { letterRunId } = useParams();
  const isContactLetter = window.location.pathname.includes(routes.contactLettersGenerated);

  const getBackUrl = () => {
    if (isContactLetter) {
      return routes.newContactLetterRun;
    }
    return routes.newLetterRun;
  };
  // api
  const fetchLetterRuns = async () => {
    setLoading(true);

    try {
      const res = await getLetterRun(letterRunId);
      setLetterRun(res);
      const failedEmails = res.emailLetters.filter(
        letter => letter.notificationStatus === NotificationStatusEnum.Failed
      );
      setFailedEmailData(failedEmails);

      // If sending emails was in progress, pick up where we left off
      if (res.statusValue === LetterRunStatusEnums.SendingEmails) {
        // Collapse the generated emails grid
        setEmailGridExpanded(false);

        // Set since letter run has already started
        setStartLetterRun(true);

        const progressResponse = await getSendingEmailProgress(letterRunId);

        // Set the total count of emails to be attempted to send
        setTotalCount(progressResponse.totalEmails);
      }

      // Otherwise, show the completed run page
      if (res.statusValue === LetterRunStatusEnums.LetterRunCompleted) {
        setFinishLetterRun(true);
        setEmailGridExpanded(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLetterRuns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterRunId]);

  const handleSetFailedLetterRunData = async () => {
    const letterRunResponse = await getLetterRun(letterRun.letterRunId);
    setLetterRun(letterRunResponse);
    const failedLetterRuns = letterRunResponse?.emailLetters?.filter(
      email => email.notificationStatus === NotificationStatusEnum.Failed
    );
    setFailedEmailData(failedLetterRuns);
  };

  const getCurrentSentEmailsProgress = async interval => {
    if (finishedCount === totalCount && totalCount > 0) {
      setStartLetterRun(false);
      setFinishLetterRun(true);

      handleSetFailedLetterRunData();
      clearInterval(interval);
    } else {
      const progressResponse = await getSendingEmailProgress(letterRun.letterRunId);
      setFinishedCount(progressResponse.processedEmails);
    }
  };

  const handleGetFile = async (original: IGeneratedLetter) => {
    try {
      if (!original.letter) {
        return;
      }
      const letter = original.letter;
      IFile.download({
        fileId: letter.fileId,
        fileType: letter.fileType,
        name: letter.name,
      });
    } catch (_) {
      showToast('error', 'There was a problem downloading the file. Please try again.');
    }
  };

  const emailsGeneratedColumns = useMemo(() => {
    return [
      {
        Header: 'Contact',
        accessor: 'contactName',
      },
      {
        Header: 'Contact Group',
        accessor: 'contactGroupName',
      },
      {
        id: 'letter',
        Header: 'Letter',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original } };
        }) => {
          return (
            <div style={{ cursor: 'pointer', color: '#002855' }} onClick={() => handleGetFile(original)}>
              {original.letter.name}
            </div>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const failedEmailColumns = useMemo(() => {
    return [
      {
        Header: 'Contact',
        accessor: 'contactName',
      },
      {
        Header: 'Contact Group',
        accessor: 'contactGroupName',
      },
      {
        id: 'letter',
        Header: 'Letter',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original } };
        }) => {
          return (
            <div style={{ cursor: 'pointer', color: '#002855' }} onClick={() => handleGetFile(original)}>
              {original.letter.name}
            </div>
          );
        },
      },
      {
        Header: 'Reason For Failure',
        accessor: 'failureReason',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the component unmounts or the page exits, unset the local storage key
  // which blocks the user from clicking Send Email Notifications more than once
  useEffect(() => {
    const onUnload = () => {
      localForage.removeItem(sentEmailsStorageKey({ letterRunId })).catch(console.error);
      localForage.removeItem(sentFailedEmailsStorageKey({ letterRunId })).catch(console.error);
    };
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [letterRunId]);

  const handleSendEmails = async () => {
    // If we have already clicked the send button, do nothing.
    const hasSent = await localForage.getItem<boolean>(sentEmailsStorageKey({ letterRunId }));
    if (letterRun.statusValue !== LetterRunStatusEnums.LetterGenerationCompleted || hasSent) {
      showToast('info', 'Cannot send email notifications after they have already been sent.');
      return;
    }

    setLoading(true);

    try {
      // Collapse the generated emails grid
      setEmailGridExpanded(false);

      // Start the run
      setStartLetterRun(true);
      await initiateLetterRun(letterRunId);

      // Store whether or not we have successfully sent email notifications for this letter run
      await localForage.setItem(sentEmailsStorageKey({ letterRunId }), true);

      const progressResponse = await getSendingEmailProgress(letterRunId);

      // Set the total count of emails to be attempted to send
      setTotalCount(progressResponse.totalEmails);
    } catch (error) {
      showToast('error', 'There was a problem sending emails: ' + error + ' Please try again. ');
    } finally {
      setLoading(false);
    }
  };

  const handleSendFailedEmails = async () => {
    setLoading(true);

    try {
      // Collapse the generated emails grid
      setEmailGridExpanded(false);

      // Start the run
      setStartLetterRun(true);
      await initiateFailedLetterRun(letterRun.letterRunId);

      // Store whether or not we have clicked the send email notifications button
      await localForage.setItem(sentFailedEmailsStorageKey({ letterRunId }), true);

      // Set the total count of emails to be attempted to send
      const progressResponse = await getSendingEmailProgress(letterRunId);

      // Set the total count of emails to be attempted to send
      setTotalCount(progressResponse.totalEmails);
    } catch {
      showToast('error', 'There was a problem sending the emails. Please try again');
    } finally {
      setLoading(false);
    }
  };

  // Handles Checking the Sending Email Progress
  useEffect(() => {
    let interval;

    if (startLetterRun && totalCount > 0) {
      interval = setInterval(() => {
        getCurrentSentEmailsProgress(interval);
      }, 3000);
    }
    if (!startLetterRun && interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  });

  const handleGetProgressBarValue = () => {
    const progressBarValue = Math.round((finishedCount / totalCount) * 100);

    if (progressBarValue) {
      return Math.round((finishedCount / totalCount) * 100);
    } else {
      return 0;
    }
  };

  return (
    <Page customPageHeader title='Letters Generated'>
      {/* HEADER */}
      <div className={classes.headerContainer}>
        {/* BACK BUTTON */}
        <IconButton
          className={classes.backButton}
          color='primary'
          onClick={() => {
            history.push(getBackUrl());
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* HEADER TITLE */}
        {!!letterRun.clientName && (
          <PageTitle
            title={`${letterRun.clientName} |
              ${format(new Date(letterRun.dateTime ? letterRun.dateTime : new Date()), "M/d/yyyy ' at ' h:mm a")} |
              ${ILetterRun.displayStatusEnum(letterRun.statusValue)}`}
            marginOff
          />
        )}
      </div>

      {/* SENDING EMAILS PROGRESS BAR */}
      {startLetterRun && (
        <Grid container justify='center' alignItems={'center'} direction={'column'}>
          {/* SUB HEADER */}
          <div className={classes.header}>
            <CircularProgress color='primary' size={'1.5rem'} className={classes.circularProgress} />
            <PageTitle title='Letter Run In Progress' marginOff />
          </div>

          {/* LETTERS GENERATED COUNT */}
          <Typography className={classes.label}>
            {finishedCount} out of {totalCount} Email Notifications Successfully Sent
          </Typography>

          {/* PROGRESS BAR */}
          <div className={classes.progressBarContainer}>
            <LinearProgress className={classes.progressBar} variant='determinate' value={handleGetProgressBarValue()} />
            <Typography
              className={classes.progressLabel}
              color='primary'
            >{`${handleGetProgressBarValue()}%`}</Typography>
          </div>
        </Grid>
      )}

      {/* SUCCESS HEADER */}
      {finishLetterRun && !startLetterRun && (
        <div className={classes.header}>
          <CheckCircle className={classes.successIcon} />
          <PageTitle title='Letter Run Completed!' marginOff />
        </div>
      )}

      {/* EMAILS GENERATED SECTION */}
      <Accordion expanded={emailGridExpanded}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<KeyboardArrowDown onClick={() => setEmailGridExpanded(!emailGridExpanded)} />}
        >
          <Grid container alignItems='center' justify='flex-start'>
            <Typography className={classes.countMessage} onClick={() => setEmailGridExpanded(!emailGridExpanded)}>
              {letterRun.emailLetters?.length} Email Letters Generated
            </Typography>
          </Grid>

          {/* SEND EMAILS BUTTON */}
          <Grid container alignItems={'center'} justify={'flex-end'}>
            <Button color='primary' onClick={() => handleSendEmails()} disabled={startLetterRun}>
              Send Email Notifications
            </Button>
          </Grid>
        </AccordionSummary>

        {/* GENERATED EMAILS GRID */}
        <AccordionDetails>
          <div className={classes.gridContainer}>
            <Table
              columns={emailsGeneratedColumns}
              data={letterRun?.emailLetters}
              isLoading={isLoading}
              headerClasses={classes.tableHeader}
              stickyHeader
            />
          </div>
        </AccordionDetails>
      </Accordion>

      {/* FAILED EMAILS SECTION */}
      {failedEmailData.length > 0 && (
        <Accordion expanded={failedEmailsGridExpanded}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<KeyboardArrowDown onClick={() => setFailedEmailsGridExpanded(!failedEmailsGridExpanded)} />}
          >
            <Typography
              className={classes.failedEmailMessage}
              onClick={() => setFailedEmailsGridExpanded(!failedEmailsGridExpanded)}
            >
              {failedEmailData.length} Failed Email Notifications
            </Typography>

            {/* RETRY NOTIFICATIONS BUTTON */}
            <Grid container alignItems={'center'} justify={'flex-end'}>
              <Button
                color='primary'
                disabled={startLetterRun || !failedEmailData?.length}
                onClick={() => {
                  handleSendFailedEmails().then(() => {
                    // Check to see if there are still failures
                    handleSetFailedLetterRunData();
                  });
                }}
              >
                Retry Notifications
              </Button>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            {/* FAILED EMAILS GRID */}
            <div className={classes.gridContainer}>
              <Table
                columns={failedEmailColumns}
                data={failedEmailData}
                isLoading={isLoading}
                headerClasses={classes.tableHeader}
                stickyHeader
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {/* GENERATED LETTERS TO MAIL SECTION */}
      <Accordion expanded={letterGridExpanded} onChange={e => e.stopPropagation()}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<KeyboardArrowDown onClick={() => setLetterGridExpanded(!letterGridExpanded)} />}
        >
          <Grid container alignItems='center' justify='flex-start'>
            <Typography onClick={() => setLetterGridExpanded(!letterGridExpanded)} className={classes.countMessage}>
              {letterRun.mailLetters?.length} Mail Letters Generated
            </Typography>
          </Grid>

          {/* DOWNLOAD MASTER PRINT PDF BUTTON */}
          <Grid container alignItems={'center'} justify={'flex-end'}>
            <Button
              disabled={downloadingMailList}
              color='primary'
              onClick={async () => {
                setDownloadingMailList(true);

                try {
                  const fileContents = await getMasterMailPdf(letterRunId);
                  // Create a DOM element behind the scenes
                  const link = document.createElement('a');
                  link.style.display = 'none';
                  // Convert string of file contents to a file-like object
                  const blob = new Blob([fileContents], { type: 'application/pdf' });
                  // Create a DOMString containing a URL representing the file
                  link.href = window.URL.createObjectURL(blob);
                  // Remove `.download` field to open in a new browser.
                  link.download = `Mail Notifications - ${letterRun.clientName}`;
                  // Click file link programmatically, downloading the file
                  link.click();
                } catch {
                  showToast('error', 'Could not successfully download the master mail list. Please try again.');
                } finally {
                  setDownloadingMailList(false);
                }
              }}
            >
              Download Master Print PDF
            </Button>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <div className={classes.gridContainer}>
            <Table
              columns={emailsGeneratedColumns}
              data={letterRun?.mailLetters}
              isLoading={isLoading}
              headerClasses={classes.tableHeader}
              stickyHeader
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    backButton: {
      position: 'absolute',
      left: 0,
      '& svg': {
        paddingLeft: '8px',
        height: '2rem',
        width: '2rem',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    accordionSummary: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    countMessage: {
      display: 'flex',
      alignItems: 'center',
    },
    failedEmailMessage: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      '@media (max-width: 600px)': {
        whiteSpace: 'normal',
      },
    },
    progressBarContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    progressBar: {
      height: theme.spacing(2),
      width: '40%',
      marginLeft: theme.spacing(1),
      borderRadius: '0.8rem',
    },
    progressLabel: {
      marginLeft: theme.spacing(1),
    },
    label: {
      marginTop: theme.spacing(1),
    },
    circularProgress: {
      marginRight: theme.spacing(0.5),
    },
    successIcon: {
      color: theme.palette.success.main,
    },
  };
});
