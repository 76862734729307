export interface ILineOfCoverage {
  lineOfCoverageId: number;
  name: string;
  namedPolicyStatus?: INamedPolicyStatus;
  additionalInsuredRequirementStatusId?: number;
}

export enum INamedPolicyStatus {
  Compliant = 1,
  Missing = 2,
  IncorrectlyListed = 3,
  Expired = 4
}

export interface ILinesOfCoverageFilters {
  name?: string;
}
