import { IClientCertificateHolder } from './../types/clientCertificateHolders';
import { authFetch } from './';
import { IError } from '../types';

const baseUrl = `/api/CertificateHolder`;

export const getClientCertificateHolders = async (id: number): Promise<IClientCertificateHolder[]> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data.value;
    }
    const error = { Detail: 'Error getting certificate holder. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createClientCertificateHolder = async (
  clientCertificateHolder: IClientCertificateHolder
): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}`, clientCertificateHolder);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating certificate holder. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateClientCertificateHolder = async (
  clientCertificateHolder: IClientCertificateHolder
): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, clientCertificateHolder);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating certificate holder. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
export const deleteClientCertificateHolder = async (clientCertificateHolderId: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${clientCertificateHolderId}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting certificate holder. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
