import { FormControl, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { getCarriersBySearch } from '../../../fetch';
import { useToastContext } from '../../../hooks';
import { IEnum } from '../../../types';
import { IBulkDataDefaultRequirement } from '../../../types/bulkUpdate';

/**
 * Amount of time to wait for new inputs to stop coming in.
 */
// TODO: Constant-ify all `debounce` usages with this constant.
const DEBOUNCE_MS = 500;

interface ICarrierCellProps {
  cell: { row: { original: IBulkDataDefaultRequirement } };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (
    columnId: string,
    value: any,
    original: IBulkDataDefaultRequirement,
    values: any[],
    setFieldValue: Function,
    secondField?: string,
    secondNewValue?: any
  ) => void;
  requirements: IBulkDataDefaultRequirement[];
  touched: boolean;
  setTouched: (arg: boolean) => void;
  values: any;
  setFieldValue: Function;
  selectedRequirements: number[];
  setSelectedRequirements?: (value: React.SetStateAction<number[]>) => void;
  updateSelectedRequirements?: (row: any) => void;
  setRequirements: React.Dispatch<React.SetStateAction<IBulkDataDefaultRequirement[]>>;
  setEntitiesIsDirty(isChanged: boolean): void;
}

export const CarrierCell: FC<ICarrierCellProps> = ({
  cell: { row: original },
  requirements,
  touched,
  setTouched,
  updateData,
  values,
  setFieldValue,
  selectedRequirements,
  setSelectedRequirements,
  updateSelectedRequirements,
  setRequirements,
  setEntitiesIsDirty,
}) => {
  const { showToast } = useToastContext();

  const [err, setErr] = useState(false);

  // const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  // Set the initial selected carrier to the data of the rendered row if that data is available
  const [selectedCarrier, setSelectedCarrier] = useState<Omit<IEnum, 'description'> | null>(() => {
    const { carrierName, carrierId } = original.original;
    // carrierId may be void if this Cell is being cleared
    if (!!carrierId) {
      return {
        value: carrierId,
        text: carrierName,
      };
    }
    return null;
  });

  useEffect(() => {
    if (selectedCarrier === null || selectedCarrier.value === null) return;
    updateData(
      'carrierId',
      selectedCarrier?.value,
      original.original,
      values,
      setFieldValue,
      'carrierName',
      selectedCarrier?.text
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCarrier]);

  // We need to keep and update the state of the cell normally
  // This tracks the controlled state of the input
  const [searchValue, setSearchValue] = useState('');

  // The list of carriers fetched due to the inputted `searchTerm` from the user
  // Includes the previously fetched results if the user searches multiple times
  const [searchedCarriers, setSearchedCarriers] = useState<Omit<IEnum, 'description'>[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCarriersByName = useCallback(
    debounce(async (searchTerm: string) => {
      // Only allow words of 3 characters or more to trigger the search
      if (searchTerm.length <= 2) {
        return;
      }
      setLoading(true);
      try {
        setSearchedCarriers(await getCarriersBySearch(searchTerm));
      } catch (err) {
        // Show error or fallback message if NOT in prod
        if (process.env.NODE_ENV !== 'production') {
          const message = err instanceof Error && err.message;
          showToast('error', message || 'Failed to search carriers.');
        }
      } finally {
        setLoading(false);
      }
    }, DEBOUNCE_MS),
    []
  );

  // See if the row is selected
  const [isSelected, setIsSelected] = useState(selectedRequirements.includes(original.original.defaultRequirementId));

  useEffect(() => {
    setIsSelected(selectedRequirements.includes(original.original.defaultRequirementId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirements]);

  useEffect(() => {
    fetchCarriersByName(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // If the row data is changed, sync it up with our local state
  useEffect(() => {
    setSelectedCarrier({
      text: original.original.carrierName,
      value: original.original.carrierId,
    });
    setSearchValue(original.original.carrierName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original.original.carrierId, original.original.carrierName]);

  useEffect(() => {
    // If fields are partially filled-in, display validation on this rendered
    // Cell if this Cell is in the list of missing fields (still waiting/needing
    // to be entered).
    if (isSelected) {
      setErr(true);
    }
    if (!isSelected) {
      setErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableClearable={false}
        id='combo-box-carrier'
        loading={loading}
        loadingText='Loading...'
        onFocus={() => {
          // if (touched) {
          //   return;
          // }
          // setTouched(true);
        }}
        noOptionsText='No carriers.'
        // Hide previously searched-for carriers unless the search has been triggered
        options={searchValue.length <= 2 ? [] : searchedCarriers}
        getOptionLabel={option => {
          if (typeof option === 'string') return option;
          return option?.text;
        }}
        onChange={(_, carrier) => {
          if (typeof carrier === 'string') {
            return;
          }
          if (setEntitiesIsDirty) setEntitiesIsDirty(true);
          setSelectedCarrier(carrier);
          if (updateSelectedRequirements) {
            updateSelectedRequirements(original.original);
          }
          if (setRequirements) {
            const index = values.requirements.findIndex(
              _ => _.defaultRequirementId === original.original.defaultRequirementId
            );
            // Copy the list
            const updatedRequirements = JSON.parse(JSON.stringify(values.requirements));
            updatedRequirements[index] = {
              ...updatedRequirements[index],
              carrierId: carrier?.value ?? null,
              carrierName: carrier?.text ?? null,
            };
            setRequirements(updatedRequirements);
          }
        }}
        value={selectedCarrier?.text ?? ''}
        renderInput={params => (
          <TextField
            fullWidth
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            onChange={event => setSearchValue(event.target.value)}
            label={!selectedCarrier?.value && err ? 'Field required.' : undefined}
            error={!selectedCarrier?.value && err}
            InputLabelProps={{ shrink: !selectedCarrier?.value && err }}
          />
        )}
        // Write changes to values.requirements - this causes this Cell to
        // re-render with new prop data
        onBlur={() => {
          const index = requirements.findIndex(_ => _.defaultRequirementId === original.original.defaultRequirementId);
          // Copy the list
          const updatedRequirements = requirements.slice();
          updatedRequirements[index] = {
            ...updatedRequirements[index],
            carrierId: selectedCarrier?.value ?? null,
            carrierName: selectedCarrier?.text ?? null,
          };
          // Will need to address this on save
          // onUpdate(updatedRequirements);
        }}
        PaperComponent={params => (
          <Paper
            {...params}
            style={{
              width: '160%',
              fontSize: '14px',
              visibility: searchValue.length <= 2 ? 'hidden' : 'visible',
            }}
          />
        )}
      />
    </FormControl>
  );
};
