import { createContext, FC, ReactNode, useContext } from 'react';

import { IClientThin } from '../types';

const ClientContext = createContext(null as unknown as IClientThin);

/**
 * CRITICAL: This function can NOT be called outside of the `ClientProvider`
 * component.
 * 
 * Returns the current client data.
 */
export const useClient = () => useContext(ClientContext);

export const ClientProvider: FC<{
  children: ReactNode;
  client: Exclude<IClientThin, null | undefined>;
}> = ({ children, client }) => <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
