import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Table } from '../../components';
import { getNamedPolicyStatuses } from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IAdditionalInsured, IAdditionalInsuredForMonitoredEntity, IEnum, ILineOfCoverage } from '../../types';
import { IBulkDataDefaultRequirement } from '../../types/bulkUpdate';
import { NamedPolicyStatusCell } from './bulkUpdateTableCells';

interface IBulkUpdateAdditionalInsuredTableProps {
  requirements: IBulkDataDefaultRequirement[];
  setRequirements: React.Dispatch<React.SetStateAction<IBulkDataDefaultRequirement[]>>;
  linesOfCoverage: ILineOfCoverage[];
  coveredPolicies: IAdditionalInsuredForMonitoredEntity[];
  values: { additionalInsureds: IAdditionalInsuredForMonitoredEntity[] };
  setFieldValue(field: 'additionalInsureds', value: IAdditionalInsuredForMonitoredEntity[]): void;
  setEntitiesIsDirty(isChanged: boolean): void;
  selectedMonitoredEntityId: number | null;
  setSelectedMonitoredEntityId: React.Dispatch<React.SetStateAction<number | null>>;
  namedPolicyStatusEnums: IEnum[];
  setNamedPolicyStatusEnums: React.Dispatch<React.SetStateAction<IEnum[]>>;
  selectedCreditForAll: Number[];
  additionalInsuredData: IAdditionalInsuredForMonitoredEntity[];
  setAdditionalInsuredData: React.Dispatch<React.SetStateAction<IAdditionalInsuredForMonitoredEntity[]>>;
  copyAdditionalInsuredData: IAdditionalInsuredForMonitoredEntity[];
  previousValues: any;
  setPreviousValues: React.Dispatch<React.SetStateAction<any>>;
  setCopyAdditionalInsuredData: React.Dispatch<React.SetStateAction<IAdditionalInsuredForMonitoredEntity[]>>;
}

export const BulkUpdateAdditionalInsuredTable: FC<IBulkUpdateAdditionalInsuredTableProps> = ({
  requirements,
  setRequirements,
  linesOfCoverage,
  values,
  setFieldValue,
  setEntitiesIsDirty,
  selectedMonitoredEntityId,
  setNamedPolicyStatusEnums,
  namedPolicyStatusEnums,
  selectedCreditForAll,
  additionalInsuredData,
  setAdditionalInsuredData,
  setCopyAdditionalInsuredData,
  copyAdditionalInsuredData,
  previousValues,
  setPreviousValues,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const classes = useStyles();
  const { showToast } = useToastContext();
  // @ts-ignore
  let { monitoredEntityIds } = useParams();

  const fetchData = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }

    try {
      const namedPolicyStatusEnums = await getNamedPolicyStatuses();
      // Set the status enums for the covered policy column in the Additional Insured table
      if (namedPolicyStatusEnums.length) {
        setNamedPolicyStatusEnums(namedPolicyStatusEnums);
      }
    } catch (error) {
      showToast('error', 'There was a problem retrieving the data for the additional insured table. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredEntityIds]);

  const additionalInsuredColumns = [
    {
      accessor: 'name',
      Header: 'Additional Insured',
    },
    {
      id: 'linesOfCoverage',
      Header: 'Covered Policies',
      Cell: NamedPolicyStatusCell,
    },
  ];

  const updateAdditionalInsuredData = (
    field: string,
    newValue: any,
    original: IAdditionalInsured,
    values: any,
    setFieldValue: Function
  ) => {
    let updatedValues = [...values.additionalInsureds];

    const reqIndexToUpdate = updatedValues.findIndex(v => v.additionalInsuredId === original.additionalInsuredId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      [`${field}`]: newValue,
    };

    setAdditionalInsuredData(updatedValues);

    setFieldValue('additionalInsureds', updatedValues);
    setEntitiesIsDirty(true);
  };

  const updateCopyAdditionalInsuredData = (
    field: string,
    newValue: any,
    original: IAdditionalInsured,
    values: any,
    setFieldValue: Function
  ) => {
    let updatedValues = [...values.additionalInsureds];

    const reqIndexToUpdate = updatedValues.findIndex(v => v.additionalInsuredId === original.additionalInsuredId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      [`${field}`]: newValue,
    };

    setCopyAdditionalInsuredData(updatedValues);
  };

  return (
    <>
      {/* ADDITIONAL INSURED GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          useTableProps={{
            updateAdditionalInsuredData,
            linesOfCoverage,
            requirements,
            setRequirements,
            namedPolicyStatusEnums,
            values,
            setFieldValue,
            selectedCreditForAll,
            additionalInsuredData,
            copyAdditionalInsuredData,
            updateCopyAdditionalInsuredData,
            previousValues,
            setPreviousValues,
          }}
          loadingPageSize={
            !Array.isArray(values?.additionalInsureds) || values?.additionalInsureds?.length === 0
              ? 3
              : values?.additionalInsureds?.length
          }
          columns={additionalInsuredColumns}
          data={
            values.additionalInsureds.find(entity => entity.monitoredEntityId === selectedMonitoredEntityId)
              ?.additionalInsureds ?? []
          }
          headerClasses={classes.tableHeader}
          centerPagination
          stickyHeader
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1),
  },
  gridContainer: {
    width: '100%',
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  entitySelectorContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
  entitySelector: {
    width: theme.spacing(16),
  },
}));
