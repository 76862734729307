import { Fab, FormControlLabel, FormGroup, IconButton, Switch } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Edit } from '@material-ui/icons';
import { isAfter } from 'date-fns';
import { orderBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';

import { ITableColumn, PageTitle, Table } from '../../components';
import { CoverageTypeEnums } from '../../constants/CoverageTypeEnums';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { IAdditionalInsured, ICoverageType, IDefaultRequirement, ILineOfCoverage } from '../../types';
import AddEditDefaultRequirement from './AddEditDefaultRequirement';

interface IDefaultRequirementsProps {
  defaultRequirements?: IDefaultRequirement[];
  isLoading: boolean;
  linesOfCoverage: ILineOfCoverage[];
  coverageTypes: ICoverageType[];
  entities?: IAdditionalInsured[];
  clientType: number;
  clientId: number;
  updateDefaultRequirements: (values: IDefaultRequirement[]) => void;
}

const findLineOfCoverageName = (
  linesOfCoverage: ILineOfCoverage[],
  requirement: IDefaultRequirement
): string | undefined => linesOfCoverage.find(loc => loc.lineOfCoverageId === requirement.lineOfCoverageId)?.name;

export const DefaultRequirements: FC<IDefaultRequirementsProps> = ({
  defaultRequirements = [],
  isLoading,
  linesOfCoverage,
  coverageTypes,
  entities,
  clientType,
  updateDefaultRequirements,
  clientId,
}) => {
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IDefaultRequirement | null>(null);
  const classes = useStyles();
  const { showToast } = useToastContext();

  const getUniqueExcessCoverageNamesForGrid = (defaultRequirement: IDefaultRequirement): string => {
    // We don't set excess coverage on excess coverage type requirements
    if (defaultRequirement.coverageType === CoverageTypeEnums.EXCESS) {
      return '';
    }

    if (
      !defaultRequirement ||
      !defaultRequirement.assignedRequiredExcessIds ||
      !defaultRequirement.assignedReceivedExcessIds
    ) {
      return '';
    }

    // First, we find all unique id's between the 2 sets of check box lists
    const uniqueExcessIds = [
      ...defaultRequirement.assignedRequiredExcessIds,
      ...defaultRequirement.assignedReceivedExcessIds,
    ];

    // Then, we find which default requirement entries those ids are tied too
    const matchingDefaultRequirements = defaultRequirements.filter(requirement =>
      uniqueExcessIds.some(excessId => excessId === requirement.defaultRequirementId)
    );

    // Finally we parse out the name from lines of coverage by using the line of coverage id from our
    // list of default requirement entries
    const uniqueLinesOfCoverageNames = matchingDefaultRequirements.flatMap<string>(
      // Filter out requirements where no LOC name is found
      requirement => findLineOfCoverageName(linesOfCoverage, requirement) ?? []
    );

    // Return the unique list of names in a comma separated list
    return uniqueLinesOfCoverageNames.join(', ');
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'sortOrder',
        Header: 'Sort Order',
        disableSortBy: true,
        overrideWidth: 140,
      },
      {
        id: 'lineOfCoverageId',
        accessor: (d: IDefaultRequirement) => findLineOfCoverageName(linesOfCoverage, d),
        Header: 'Line of Coverage',
      },
      {
        id: 'coverageType',
        Header: 'Coverage Type',
        accessor: (d: IDefaultRequirement) => coverageTypes.find(c => c.value === d.coverageType)?.description,
      },
      {
        accessor: 'requiredAmount',
        Header: 'Required Amount',
        columnAlignment: 'right',
        isCurrency: true,
      },
      {
        id: 'requiredAdditionalInsured',
        Header: 'Addtl Reqs',
        accessor: (d: IDefaultRequirement) => (d.requiredAdditionalInsured ? 'Yes' : 'No'),
        isCentered: true,
        overrideWidth: 140,
      },
      {
        accessor: 'effectiveDate',
        Header: 'Effective Date',
        isDate: true,
      },
      {
        accessor: 'endDate',
        Header: 'End Date',
        isDate: true,
      },
      {
        id: 'excessCoverage',
        Header: 'Excess Coverage',
        accessor: (d: IDefaultRequirement) => getUniqueExcessCoverageNamesForGrid(d),
      },
      {
        id: 'actions',
        Header: '',
        overrideWidth: 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IDefaultRequirement } };
        }) => {
          return (
            <div key={original.defaultRequirementId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  setShowAddEditModal(true);
                }}
              >
                <Edit />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRequirements]);

  const [showExpired, setShowExpired] = useState<boolean>(false);

  const defaultRequirementsLocIds = defaultRequirements.map(m => m.lineOfCoverageId);
  const filteredLinesOfCoverage = linesOfCoverage.filter(
    obj => !defaultRequirementsLocIds.includes(obj.lineOfCoverageId)
  );

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title='Default Requirements' marginOff />
        <FormGroup row className={classes.expiredToggle}>
          <FormControlLabel
            control={<Switch checked={showExpired} onChange={() => setShowExpired(!showExpired)} />}
            label='Show Expired'
          />
        </FormGroup>
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isLoading}
          loadingPageSize={
            !Array.isArray(defaultRequirements) || defaultRequirements.length === 0 ? 3 : defaultRequirements.length
          }
          key={JSON.stringify(defaultRequirements)}
          columns={columns}
          data={orderBy(
            showExpired
              ? defaultRequirements
              : defaultRequirements.filter(d => !d.endDate || new Date(d.endDate) > new Date(new Date())),
            ['sortOrder', defReq => findLineOfCoverageName(linesOfCoverage, defReq)],
            ['asc', 'asc']
          )}
          headerClasses={classes.tableHeader}
          stickyHeader
          hidePagination
        />
      </div>

      {/* ADD NEW BUTTON */}
      <Fab className={classes.addButton} onClick={() => setShowAddEditModal(true)}>
        <Add />
      </Fab>

      {/* ADD/EDIT MODAL */}
      <AddEditDefaultRequirement
        initialValues={selectedRowItem}
        open={showAddEditModal}
        excessCoverageList={defaultRequirements.filter(
          requirement =>
            requirement.coverageType === CoverageTypeEnums.EXCESS &&
            (!requirement.endDate || isAfter(new Date(requirement.endDate), new Date()))
        )}
        clientId={clientId}
        clientType={clientType}
        coverageTypes={coverageTypes}
        linesOfCoverage={linesOfCoverage}
        filteredLinesOfCoverage={filteredLinesOfCoverage}
        entities={entities}
        showToast={showToast}
        onClose={() => {
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
        onSave={defaultRequirements => {
          updateDefaultRequirements(defaultRequirements);
          setSelectedRowItem(null);
          setShowAddEditModal(false);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    expiredToggle: {
      marginLeft: theme.spacing(1),
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };
});
