export const routes = {
  // Login Screen
  login: '/user/login',

  // Reset Password Screen
  requestResetPassword: '/request-reset-password',
  resetPassword: '/reset-password',

  // Forgot Username Screen
  forgotUsername: '/forgot-username',

  // Manage Requirements Tab
  manageRequirements: '/manage-requirements',
  bulkUpdateRequirements: '/manage-requirements/bulk-update-requirements',

  // Manage Clients Tab
  manageClients: '/manage-clients',

  // Reports Tab
  reports: '/reports',
  powerBi: '/reports/power-bi',

  // Manage Users Tab
  manageUsers: '/manage-users',

  // Manage Carriers Tab
  manageCarriers: '/manage-carriers',

  // Manage Location Types Tab
  manageLocationTypes: '/manage-location-types',

  // Manage Contacts Tab
  manageContacts: '/manage-contacts',

  // Manage Lines Of Coverage Tab
  linesOfCoverage: '/lines-of-coverage',

  // Manage System Tab
  adminSystem: '/admin-system',

  // Compliance Letters Tab
  letterRuns: '/letters',
  newLetterRun: '/letters/new-letter-run',
  lettersGenerated: '/letters/letters-generated',
  letterGenerationProgress: '/letters/letter-generation-progress',

  
  // Client Letters Tab
  contactLetterRuns: '/contact-letters',
  newContactLetterRun: '/contact-letters/new-contact-letter-run',
  contactLettersGenerated: '/contact-letters/contact-letters-generated',
  contactLetterGenerationProgress: '/contact-letters/contact-letters-generation-progress',

  //Download File
  files: '/file'
};
