import React, { FC } from 'react';
// Components
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker';

interface IDatePickerProps extends KeyboardDatePickerProps {
  id: string;
  label?: string;
  value: MaterialUiPickersDate;
  onChange: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
  className?: string;
  /**
   * Allows custom behavior on clear.
   */
  onClear?: () => void;
}

export const DatePicker: FC<IDatePickerProps> = ({ id, label, value, onChange, disabled, className, onBlur, onClear, ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        className={className}
        id={id}
        label={label}
        format='MM/dd/yyyy'
        value={value}
        KeyboardButtonProps={{ style: { padding: 0 }}}
        onChange={(date: null | Date, value: string | null | undefined) => {
          // we allow partial dates now, don't return the date unless
          // it is actually valid. If invalid the .getDate() method
          // will return NaN
          if (date && !isNaN(date.getDate())) {
            onChange(date);
          }
          if (date === null && value === null) {
            onClear?.();
          }
        }}
        onBlur={e => {
          const value = e.target.value;

          // if our value is a valid date, just return that. Valid would mean
          // getDate doesn't return NaN
          const dateValue = new Date(value);
          if (!isNaN(dateValue.getDate())) {
            onChange(dateValue);
          } else {
            onChange(null);
          }

          if (onBlur) {
            onBlur(e);
          }
        }}
        disabled={disabled}
        data-testid={id}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};
