import React, { FC } from 'react';
// Redux
// Components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

interface IConfirmationDialogueProps {
  id: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  text?: string;
  customContent?: any;
}

export const ConfirmationDialogue: FC<IConfirmationDialogueProps> = ({
 id,
 open,
 onClose,
 onConfirm,
 title = 'Confirm?',
 text = 'Are you sure?',
 customContent = null
}) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby={`alert-dialog-${id}`}
      aria-describedby={`alert-dialog-${id}-description`}
    >
      <DialogTitle id='alert-dialog-save-entries-title'>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id={`alert-dialog-${id}-description`}>{text}</DialogContentText>
        {customContent}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color='default'>
          Cancel
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
