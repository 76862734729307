import { FC, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { Add, Check } from '@material-ui/icons';
import { Modal, Loader, DatePicker, Select, CheckboxList } from '../../components';
import { deepEqual } from 'fast-equals';
import { Form, withFormik, FormikProps } from 'formik';
import {
  Fade,
  CardActions,
  Button,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// styles
import { colors } from '../../styles';
// types
import {
  IDefaultRequirement,
  ILineOfCoverage,
  ICoverageType,
  IAdditionalInsured,
  ILinkedRequirementsResponse,
} from '../../types';
import { CoverageTypeEnums } from '../../constants/CoverageTypeEnums';
// fetch
import { addDefaultRequirementV2, checkForInUseDefaultRequirements, updateDefaultRequirementV2 } from '../../fetch';
import { formatError } from '../../helpers/errors';

const RETAIL = 1;

interface IAddEditDefaultRequirement {
  open: boolean;
  onClose: () => void;
  onSave: (IDefaultRequirement) => void;
  initialValues: IDefaultRequirement | {};
  linesOfCoverage: ILineOfCoverage[];
  filteredLinesOfCoverage: ILineOfCoverage[];
  coverageTypes: ICoverageType[];
  entities?: IAdditionalInsured[];
  showToast: (type: string, message: string) => void;
  excessCoverageList: IDefaultRequirement[];
  clientType: number;
  clientId: number;
}

const AddEditDefaultRequirement: FC<IAddEditDefaultRequirement & FormikProps<IDefaultRequirement>> = ({
  open,
  onClose,
  linesOfCoverage,
  filteredLinesOfCoverage,
  coverageTypes,
  entities,
  resetForm,
  isSubmitting,
  values,
  initialValues,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  handleSubmit,
  dirty,
  isValid,
  handleBlur,
  excessCoverageList,
  clientType,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [linkData, setLinkData] = useState<ILinkedRequirementsResponse | null>(null);
  const [editModeLOC, setEditModeLOC] = useState<ILineOfCoverage[]>([]);
  // Total count of Monitored Entities with a specific requirement linked to it
  const totalMonitoredEntityCount = isNaN(linkData?.totalNumberOfCustomized + linkData?.totalNumberOfNonCustomized)
    ? 0
    : linkData?.totalNumberOfCustomized + linkData?.totalNumberOfNonCustomized;

  /** True if editing a default requirement, false if adding one. */
  const inEditingMode = !!initialValues.defaultRequirementId;

  const setValuesForEditModeLOC = () => {
    let currentLocItem = linesOfCoverage.find(obj => obj.lineOfCoverageId === values.lineOfCoverageId);
    if (currentLocItem) {
      setEditModeLOC([...filteredLinesOfCoverage, currentLocItem]);
    }
  };

  useEffect(() => {
    const checkRequirementUsage = async () => {
      setLoading(true);
      try {
        const response: ILinkedRequirementsResponse = await checkForInUseDefaultRequirements(
          initialValues.defaultRequirementId
        );
        setLinkData(response);
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    };

    if (initialValues.defaultRequirementId) {
      checkRequirementUsage();
    }

    setValuesForEditModeLOC();
    return () => setLinkData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Modal
      maxWidth={'md'}
      open={open}
      title={inEditingMode ? 'Edit Default Requirement' : 'Add New Default Requirement'}
      onClose={() => {
        if (!deepEqual(initialValues, values)) {
          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
          if (result) {
            resetForm();
            onClose();
          } else {
            return;
          }
        } else {
          onClose();
          resetForm();
        }
      }}
    >
      {/* FORM */}
      {isSubmitting && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <Form onSubmit={handleSubmit} autoComplete='none'>
          <Grid container spacing={1}>
            {/* NUMBER OF UNITS WITH REQUIREMENT MESSAGE*/}
            {!loading &&
            linkData?.hasRequirements &&
            linkData?.totalNumberOfNonCustomized &&
            totalMonitoredEntityCount ? (
              <div>
                <Typography className={classes.linkedReqHeader}>Default Requirement in use!</Typography>
                There {`${totalMonitoredEntityCount === 1 ? 'is ' : 'are '}`}
                {totalMonitoredEntityCount}
                {`${
                  clientType === RETAIL
                    ? totalMonitoredEntityCount === 1
                      ? ' Unit '
                      : ' Units '
                    : totalMonitoredEntityCount === 1
                    ? ' Contractor '
                    : ' Contractors '
                }
                `}
                which {`${totalMonitoredEntityCount === 1 ? 'has ' : 'have '}`}
                this requirement already, and {linkData.totalNumberOfNonCustomized}
                {`${linkData.totalNumberOfNonCustomized === 1 ? ' has ' : ' have '}`}
                not been customized. Would you like to:
              </div>
            ) : (
              !!totalMonitoredEntityCount && (
                <div>
                  <Typography className={classes.linkedReqHeader}>Default Requirement in use!</Typography>
                  There {`${totalMonitoredEntityCount === 1 ? 'is ' : 'are '}`}
                  {totalMonitoredEntityCount}
                  {`${
                    clientType === RETAIL
                      ? totalMonitoredEntityCount === 1
                        ? ' Unit '
                        : ' Units '
                      : totalMonitoredEntityCount === 1
                      ? ' Contractor '
                      : ' Contractors '
                  }
                `}
                  which {`${totalMonitoredEntityCount === 1 ? 'has ' : 'have '}`}
                  this requirement already. Would you like to:
                </div>
              )
            )}
            {/* APPLY REQUIREMENT TO ALL TOGGLE */}
            <Grid container direction={'column'}>
              <FormControlLabel
                control={
                  <Switch
                    className={classes.checkBox}
                    checked={inEditingMode ? values.createRequirementOnEntitiesThatNeedIt : values.addToExistingUnits}
                    onChange={e => {
                      if (inEditingMode) {
                        setFieldValue('applyToAll', e.target.checked);
                        setFieldValue('applyToNonCustomized', false);
                        setFieldValue('createRequirementOnEntitiesThatNeedIt', e.target.checked);
                      } else {
                        setFieldValue('addToExistingUnits', e.target.checked);
                      }
                    }}
                    name='client'
                  />
                }
                label={`${inEditingMode ? 'Update' : 'Apply to'} the existing ${
                  clientType === RETAIL
                    ? linkData?.totalNumberOfCustomized + linkData?.totalNumberOfNonCustomized === 1
                      ? ' Unit'
                      : ' Units'
                    : linkData?.totalNumberOfCustomized + linkData?.totalNumberOfNonCustomized === 1
                    ? ' Contractor'
                    : ' Contractors'
                }`}
              />

              {/* REQUIREMENT APPLICATION SCOPE CHECKBOX GROUP */}
              {!!(values.createRequirementOnEntitiesThatNeedIt && linkData?.totalNumberOfNonCustomized) && (
                // This only shows when editing, not adding requirements
                <FormControl component='fieldset' className={classes.checkboxGroup}>
                  {/* APPLY TO ALL UNITS */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkBox}
                        checked={values.applyToAll}
                        onChange={() => {
                          setFieldValue('applyToNonCustomized', false);
                          setFieldValue('applyToAll', true);
                        }}
                        name='applyToNonCustomized'
                      />
                    }
                    label='All Units'
                  />

                  {/* APPLY ONLY TO UN-CUSTOMIZED UNITS */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkBox}
                        checked={values.applyToNonCustomized}
                        onChange={() => {
                          setFieldValue('applyToNonCustomized', true);
                          setFieldValue('applyToAll', false);
                        }}
                        name='applyToNonCustomized'
                      />
                    }
                    label='Un-customized Units Only'
                  />
                </FormControl>
              )}
            </Grid>

            <Grid container spacing={1} alignItems={'center'} className={classes.gridContainer}>
              {/* LINE OF COVERAGE SELECTOR */}
              <Grid item xs={12} sm={4}>
                <Select
                  disabled={totalMonitoredEntityCount && inEditingMode ? true : false}
                  showReset={totalMonitoredEntityCount && inEditingMode ? false : true}
                  name='lineOfCoverageId'
                  id='line-of-coverage-select'
                  value={typeof values.lineOfCoverageId === 'undefined' ? '' : values.lineOfCoverageId}
                  options={
                    inEditingMode
                      ? editModeLOC.map(item => ({
                          key: item.lineOfCoverageId,
                          label: item.name,
                          value: item.lineOfCoverageId,
                        }))
                      : filteredLinesOfCoverage.map(item => ({
                          key: item.lineOfCoverageId,
                          label: item.name,
                          value: item.lineOfCoverageId,
                        }))
                  }
                  label='Line of Coverage'
                  onChange={e => setFieldValue('lineOfCoverageId', e.target.value)}
                  onBlur={() => setFieldTouched('lineOfCoverageId', true)}
                  touched={touched && touched.lineOfCoverageId}
                  error={Boolean(errors.lineOfCoverageId)}
                  errorMessage={errors.lineOfCoverageId}
                />
              </Grid>

              {/* SORT ORDER */}
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  fullWidth
                  autoComplete='nope'
                  label='Sort Order'
                  name='sortOrder'
                  value={values.sortOrder}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue('sortOrder', e.target.value)}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  helperText={touched.sortOrder && errors.sortOrder}
                />
              </Grid>

              {/* EFFECTIVE DATE */}
              <Grid item xs={12} sm={3}>
                <DatePicker
                  required
                  id='effective-date-date-picker'
                  fullWidth
                  label='Effective Date'
                  placeholder=''
                  autoComplete='nope'
                  name='effectiveDate'
                  value={values.effectiveDate ? new Date(values.effectiveDate) : null}
                  onBlur={handleBlur}
                  onChange={date => setFieldValue('effectiveDate', date?.toISOString())}
                  error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                  helperText={touched.effectiveDate && errors.effectiveDate}
                />
              </Grid>

              {/* END DATE */}
              <Grid item xs={12} sm={3}>
                <DatePicker
                  id='end-date-date-picker'
                  fullWidth
                  label='End Date'
                  placeholder=''
                  autoComplete='nope'
                  name='endDate'
                  value={values.endDate ? new Date(values.endDate) : null}
                  onBlur={handleBlur}
                  onChange={date => setFieldValue('endDate', date?.toISOString())}
                  error={Boolean(touched.endDate && errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems={'center'} className={classes.gridContainer}>
              {/* COVERAGE TYPE */}
              <Grid item xs={12} sm={4}>
                <Select
                  name='coverageType'
                  id='coverageType-select'
                  value={typeof values.coverageType === 'undefined' ? '' : values.coverageType}
                  options={coverageTypes.map(item => ({
                    key: item.value,
                    label: item.description,
                    value: item.value,
                  }))}
                  label='Coverage Type'
                  onChange={e => {
                    setFieldValue('coverageType', e.target.value);

                    // Reset these values if they pick excess, since they don't apply when that is selected
                    if (e.target.value === CoverageTypeEnums.EXCESS) {
                      const newRequiredExcessList = JSON.parse(JSON.stringify(values.assignedRequiredExcessIds));
                      newRequiredExcessList.excessIdList = [];
                      setFieldValue('assignedRequiredExcess', newRequiredExcessList);

                      const newReceivedExcessList = JSON.parse(JSON.stringify(values.assignedReceivedExcessIds));
                      newReceivedExcessList.excessIdList = [];
                      setFieldValue('assignedReceivedExcess', newReceivedExcessList);
                    }
                  }}
                  onBlur={() => setFieldTouched('coverageType', true)}
                  touched={touched && touched.coverageType}
                  error={Boolean(errors.coverageType)}
                  errorMessage={errors.coverageType}
                />
              </Grid>

              {/* REQUIRED AMOUNT */}
              {(Number(values.coverageType) === CoverageTypeEnums.AMOUNT ||
                Number(values.coverageType) === CoverageTypeEnums.EXCESS) && (
                <Grid item xs={12} sm={4} classes={{ root: classes.toggleWrapper }}>
                  <NumberFormat
                    customInput={TextField}
                    onValueChange={values => setFieldValue('requiredAmount', values.value)}
                    thousandSeparator
                    isNumericString
                    decimalScale={0}
                    prefix='$'
                    label='Required Amount'
                    value={values.requiredAmount}
                    error={Boolean(touched.requiredAmount && errors.requiredAmount)}
                    helperText={touched.requiredAmount && errors.requiredAmount}
                    onBlur={handleBlur}
                    required
                    fullWidth
                  />
                </Grid>
              )}

              {/* REQUIRED ADDITIONAL INSURED TOGGLE */}
              <Grid item xs={12} sm={4}>
                <FormGroup row className={classes.toggle}>
                  <FormControlLabel
                    label='Require Additional Insured'
                    control={
                      <Switch
                        checked={values.requiredAdditionalInsured}
                        onChange={() => setFieldValue('requiredAdditionalInsured', !values.requiredAdditionalInsured)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {/* ADDITIONAL INSURED CHECKBOX LIST */}
            {values.requiredAdditionalInsured && (
              <Grid item xs={12} sm={4}>
                <CheckboxList
                  header='Additional Insured'
                  checkedValuesList={values.additionalInsuredRuleIds}
                  saveCheckboxSelectionToState={updatedCheckedValuesList => {
                    setFieldValue('additionalInsuredRuleIds', updatedCheckedValuesList);
                  }}
                  checkboxItems={entities.map(entity => {
                    return {
                      id: entity.additionalInsuredId,
                      name: entity.name,
                    };
                  })}
                />
              </Grid>
            )}
            {
              // ASSIGNED RECEIVED EXCESS
              values.coverageType !== CoverageTypeEnums.EXCESS && (
                <Grid item xs={12} sm={4}>
                  <CheckboxList
                    header='Assigned Required Excess'
                    checkedValuesList={values.assignedRequiredExcessIds}
                    saveCheckboxSelectionToState={updatedCheckedValuesList => {
                      setFieldValue('assignedRequiredExcessIds', updatedCheckedValuesList);
                    }}
                    checkboxItems={excessCoverageList.map(requirement => {
                      return {
                        id: requirement.defaultRequirementId,
                        name: linesOfCoverage.find(
                          lineOfCoverage => lineOfCoverage.lineOfCoverageId === requirement.lineOfCoverageId
                        )?.name,
                      };
                    })}
                  />
                </Grid>
              )
            }
            {
              // ASSIGNED RECEIVED EXCESS
              values.coverageType !== CoverageTypeEnums.EXCESS && (
                <Grid item xs={12} sm={4}>
                  <CheckboxList
                    header='Assigned Received Excess'
                    checkedValuesList={values.assignedReceivedExcessIds}
                    saveCheckboxSelectionToState={updatedCheckedValuesList => {
                      setFieldValue('assignedReceivedExcessIds', updatedCheckedValuesList);
                    }}
                    checkboxItems={excessCoverageList.map(requirement => {
                      return {
                        id: requirement.defaultRequirementId,
                        name: linesOfCoverage.find(
                          lineOfCoverage => lineOfCoverage.lineOfCoverageId === requirement.lineOfCoverageId
                        )?.name,
                      };
                    })}
                  />
                </Grid>
              )
            }
          </Grid>

          {/* FORM BUTTONS */}
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  if (!deepEqual(initialValues, values)) {
                    const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                    if (result) {
                      resetForm();
                      onClose();
                    } else {
                      return;
                    }
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                startIcon={values.defaultRequirementId ? <Check /> : <Add />}
                variant='contained'
                color='primary'
              >
                {values.defaultRequirementId ? 'Update' : 'Add'}
              </Button>
            </div>
          </CardActions>
        </Form>
      </Fade>
    </Modal>
  );
};

const DefaultRequirementsSchema = Yup.object().shape({
  lineOfCoverageId: Yup.number().required('Line of Coverage is Required').typeError('Line of Coverage is Required'),
  coverageType: Yup.number().required('Coverage Type is Required').typeError('Coverage Type is Required'),
  requiredAmount: Yup.string().when('coverageType', {
    is: coverageType => coverageType === 1,
    then: Yup.string().required('Coverage Amount is Required'),
    otherwise: Yup.string().nullable(),
  }),
  effectiveDate: Yup.string().required('Effective Date is Required'),
  sortOrder: Yup.number().required('Sort Order is Required').typeError('Sort Order Must Be a Number'),
  applyToAll: Yup.boolean(),
  applyToNonCustomized: Yup.boolean(),
});

export default withFormik<IAddEditDefaultRequirement, IDefaultRequirement>({
  enableReinitialize: true,
  validationSchema: DefaultRequirementsSchema,
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      sortOrder: 1,
      lineOfCoverageId: '',
      coverageType: '',
      requiredAmount: '',
      requiredAdditionalInsured: false,
      addToExistingUnits: false,
      defaultRequirementId: 0,
      effectiveDate: '',
      endDate: '',
      additionalInsuredRuleIds: [],
      assignedRequiredExcessIds: [],
      assignedReceivedExcessIds: [],
      applyToAll: false,
      applyToNonCustomized: false,
      createRequirementOnEntitiesThatNeedIt: false,
      ...initialValues,
    };
  },
  handleSubmit: async (values, { resetForm, props: { onSave, showToast, clientId } }) => {
    try {
      // @ts-ignore
      const inEditingMode = !!values?.defaultRequirementId;

      let updatedValues = {
        clientId: Number(clientId),
        defaultRequirement: values,
      };

      if (!updatedValues?.defaultRequirement?.additionalInsuredRuleIds) {
        updatedValues.defaultRequirement.additionalInsuredRuleIds = [];
      }

      if (!updatedValues?.defaultRequirement?.assignedRequiredExcessIds) {
        updatedValues.defaultRequirement.assignedRequiredExcessIds = [];
      }

      if (!updatedValues?.defaultRequirement?.assignedReceivedExcessIds) {
        updatedValues.defaultRequirement.assignedReceivedExcessIds = [];
      }

      let response;
      if (inEditingMode) {
        response = await updateDefaultRequirementV2(updatedValues);
      } else {
        response = await addDefaultRequirementV2(updatedValues);
      }
      onSave(response);
      showToast(
        'success',
        values.defaultRequirementId ? 'Default Requirement Updated' : 'Default Requirement Created!'
      );
      resetForm();
    } catch (error) {
      formatError(error, showToast);
    }
  },
})(AddEditDefaultRequirement);

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '611px',
  },
  content: {
    marginTop: '31px',
    display: 'flex',
  },
  mobileContent: {
    marginTop: '31px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
  menuOptions: {
    color: theme.palette.common.black,
  },
  filter: {
    marginRight: '14px',
    marginBottom: '37px',
  },
  columnPadding: {
    paddingRight: theme.spacing(1),
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formGroupWrapper: {
    margin: '0 auto',
  },
  toggle: {
    justifyContent: 'center',
  },
  selectionContainer: {
    marginBottom: theme.spacing(1),
  },
  listContainer: {
    width: '100%',
    maxHeight: theme.spacing(12),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  gridContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  linkedReqHeader: {
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main,
  },
  checkBox: {
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  checkboxGroup: {
    marginLeft: theme.spacing(1),
  },
}));
