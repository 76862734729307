import { authFetch } from './';
import { IDefaultRequirementPost, IDefaultRequirement } from '../types';

const baseUrl = '/api/DefaultRequirement';

export const updateDefaultRequirement = async (postModel: IDefaultRequirementPost): Promise<IDefaultRequirement[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/UpdateDefaultRequirement`, postModel);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const addDefaultRequirementV2 = async (postModel: IDefaultRequirementPost): Promise<IDefaultRequirement[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/v2/CreateDefaultRequirement`, postModel);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateDefaultRequirementV2 = async (postModel: IDefaultRequirementPost): Promise<IDefaultRequirement[]> => {
  try {
    const res = await authFetch.post(`${baseUrl}/v2/UpdateDefaultRequirement`, postModel);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};