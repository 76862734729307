import { CircularProgress, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Loader, Page, PageTitle } from '../../components';
import { routes } from '../../constants';
import { getLetterRunGenerationProgress } from '../../fetch';
import { ILetterRun } from '../../types';

export const LetterGenerationProgress = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [finishedCount, setFinishedCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [letterRunName, setLetterRunName] = useState<string>('');

  const isContactLetter = window.location.pathname.includes(routes.contactLetterGenerationProgress);

  const getBackUrl = () => {
    return isContactLetter ? routes.newContactLetterRun : routes.newLetterRun;
  };

  const classes = useStyles();
  const history = useHistory();
  // @ts-ignore
  const { letterRunId } = useParams();

  // api
  const fetchLetterGenerationProgress = async () => {
    setLoading(true);

    try {
      const response = await getLetterRunGenerationProgress(letterRunId);
      const letterRunDatetime = format(new Date(response.utcStartTime), "M/d/yyyy ' at ' h:mm a");
      setLetterRunName(
        `${response.letterRunName} | ${letterRunDatetime} | ${ILetterRun.displayStatusEnum(response.letterRunStatus)}`
      );
      setTotalCount(response.totalLetters);
      setFinishedCount(response.completedLetters);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLetterGenerationProgress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentProgress = async () => {
    // re-direct when finished
    if (finishedCount === totalCount && totalCount > 0) {
      if (!letterRunId) {
        return;
      }

      const route = isContactLetter ? routes.contactLettersGenerated : routes.lettersGenerated;
      history.push(`${route}/letterRunId=${letterRunId}`);
    } else {
      const response = await getLetterRunGenerationProgress(letterRunId);
      setTotalCount(response.totalLetters);
      setFinishedCount(response.completedLetters);
    }
  };

  useEffect(() => {
    const timer = setInterval(getCurrentProgress, 10000);
    return () => {
      clearTimeout(timer);
    };
  });

  const handleGetProgressBarValue = () => {
    const progressBarValue = Math.round((finishedCount / totalCount) * 100);

    if (progressBarValue) {
      return progressBarValue;
    } else {
      return 0;
    }
  };

  return (
    <Page title='Letter Generation Progress' customPageHeader>
      {isLoading && <Loader type='overlay' position='centered' />}

      {/* HEADER */}
      <div className={classes.headerContainer}>
        {/* BACK BUTTON */}
        <IconButton
          className={classes.backButton}
          color='primary'
          onClick={() => {
            history.push(getBackUrl());
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* HEADER TITLE */}
        <PageTitle title={letterRunName} marginOff />
      </div>

      <Grid container justify='center' alignItems={'center'} direction={'column'}>
        {/* LETTERS GENERATED COUNT */}
        <Typography className={classes.label}>
          {finishedCount} out of {totalCount} Letters generated
        </Typography>

        {/* PROGRESS BAR */}
        <div className={classes.progressBarContainer}>
          <CircularProgress color='primary' size={'1.5rem'} className={classes.circularProgress} />
          <LinearProgress className={classes.progressBar} variant='determinate' value={handleGetProgressBarValue()} />
          <Typography className={classes.progressLabel} color='primary'>{`${handleGetProgressBarValue()}%`}</Typography>
        </div>

        {/* DESCRIPTION */}
        <Typography className={classes.label}>
          When letter generation is completed, you will be able to review letters below and initiate the letter run.
        </Typography>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    backButton: {
      position: 'absolute',
      left: 0,
      '& svg': {
        paddingLeft: '8px',
        height: '2rem',
        width: '2rem',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    progressBarContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    progressBar: {
      height: theme.spacing(2),
      width: '40%',
      marginLeft: theme.spacing(1),
      borderRadius: '0.8rem',
    },
    progressLabel: {
      marginLeft: theme.spacing(1),
    },
    label: {
      marginTop: theme.spacing(1),
    },
    circularProgress: {
      marginRight: theme.spacing(0.5),
    },
  };
});
