import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { IconButton, InputLabel, MenuItem, Select as MUISelect, FormControl, FormHelperText, InputLabelProps } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
// Icons
import { Clear } from '@material-ui/icons';

export interface IOptionType {
  key: string | number;
  label: string;
  value: string | number | string[] | undefined;
}

interface ISelect extends SelectProps {
  id: string;
  label?: string;
  value?: string | number | null;
  options: IOptionType[];
  disabled?: boolean;
  className?: string;
  onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void;
  showReset?: boolean;
  resetValue?: any; // allow user to define a resetValue
  error?: boolean;
  errorMessage?: string;
  touched?: boolean;
  selectAllValue?: string;
  customClearFunction?: () => void;
  InputLabelProps?: InputLabelProps;
}

export const Select: FC<ISelect> = ({
  className = '',
  customClearFunction,
  disabled,
  error,
  errorMessage,
  id,
  InputLabelProps = {},
  label,
  onChange,
  options,
  resetValue,
  selectAllValue,
  showReset = true,
  touched,
  value,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={`${classes.dropdown} ${className}`} error={error && touched}>
      {label && (
        <InputLabel id={`${label}-label`} {...(InputLabelProps ?? {})} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <MUISelect id={id} labelId={`${label}-label`} value={value} onChange={onChange} disabled={disabled} {...props}>
        {/* SELECT ALL OPTION */}
        {selectAllValue &&
          options &&
          options.length > 1 && ( //
            <MenuItem value={selectAllValue}>{selectAllValue}</MenuItem>
          )}
        {options.map(option => (
          <MenuItem key={option.key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>

      {/* CLEAR BUTTON */}
      {showReset && value ? (
        <IconButton
          size='small'
          disabled={disabled}
          className={classes.dropdownClear}
          onClick={() => {
            customClearFunction
              ? customClearFunction()
              : onChange(
                  { target: { value: resetValue } } as React.ChangeEvent<{ name?: string; value: unknown }>,
                  null
                );
          }}
        >
          <Clear />
        </IconButton>
      ) : null}
      {touched && error && <FormHelperText classes={{ root: classes.errorText }}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    width: '100%'
  },
  dropdownClear: {
    fill: theme.palette.grey[400],
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
    bottom: theme.spacing(0)
  },
  errorText: {
    color: theme.palette.error.main
  }
}));
