import { Box, Card, CardContent, CardHeader, Fab, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Loader, PageTitle } from '../../components';
import { useClient } from '../../context';
import { ICommentsAPI } from '../../fetch';
import { convertToUTC } from '../../helpers';
import { useToastContext } from '../../hooks';
import { CommentType, IMonitoredEntityComment } from '../../types';
import { ConfirmationDialogue } from '../modal';
import { CommentModal } from './CommentModal';

/**
 * CRITICAL: This component utilizes ClientProvider context and as such must be
 * wrapped in <ClientProvider /> when rendered.
 */
export const Comments: FC<{
  /**
   * A fetch function wrapping ICommentsAPI.getAllByType with pre-bound
   * arguments to prevent passing unavailable contactGroupId or
   * monitoredEntityId props.
   */
  getComments(): Promise<IMonitoredEntityComment[]>;
  /**
   * POSTs the given comment data.
   *
   * If this prop is not provided, no Add Comment button is rendered.
   */
  uploadComment?(text: string, type: CommentType): Promise<void>;
}> = ({ getComments, uploadComment }) => {
  const classes = useStyles();
  const { showToast } = useToastContext();
  const client = useClient();

  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<IMonitoredEntityComment[]>([]);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  // False if delete confirmation is closed, otherwise modal is open and using
  // the provided data to facilitate deletion.
  const [deleteConfirmationComment, openDeleteConfirmation] = useState<false | IMonitoredEntityComment>(false);

  const deleteComment = useCallback(
    async (commentId: number): Promise<void> => {
      try {
        await ICommentsAPI.delete(commentId);
        setComments(state => state.filter(_ => _.commentId !== commentId));
      } catch (error) {
        const message = error instanceof Error && error.message;
        showToast('error', message || 'Failed to delete comment.');
      }
    },
    [showToast]
  );

  useEffect(() => {
    fetchCommentsOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchCommentsOnMount() {
    setLoading(true);
    try {
      setComments(await getComments());
    } catch (error) {
      if (error instanceof Error) {
        showToast('error', error.message || 'Failed to fetch comments.');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className={classes.header}>
        <PageTitle title='Comments' marginOff />
      </div>
      <div className={classes.gridContainer}>
        {loading ? (
          <Loader type='overlay' position='centered' />
        ) : (
          <>
            {comments.length > 0 ? (
              comments.map((comment: IMonitoredEntityComment) => (
                <Card classes={{ root: classes.commentCard }} key={comment.commentId}>
                  <CardHeader
                    title={comment.userName}
                    subheader={
                      <Box display='flex' flexDirection='column'>
                        {format(new Date(convertToUTC(comment.dateCreated)), "M/d/yyyy ' at ' h:mm a")}
                        <Box color='textSecondary'>
                          {IMonitoredEntityComment.displayCommentType(comment.commentType, client.entityType)}
                        </Box>
                      </Box>
                    }
                    classes={{ subheader: clsx(classes.subheader, classes.font), title: classes.font }}
                  />
                  <CardContent className={classes.cardContent}>
                    <div
                      style={{
                        // Break lines at newline characters
                        whiteSpace: 'pre-line',
                        fontSize: '11px',
                      }}
                    >
                      {comment.text}
                    </div>
                    <Box display='flex' flex={1} justifyContent='flex-end'>
                      <IconButton color='secondary' size='small' onClick={() => openDeleteConfirmation(comment)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              ))
            ) : (
              <div>No Comments yet</div>
            )}
          </>
        )}
      </div>
      {!!uploadComment && (
        <>
          <Fab
            className={classes.addButton}
            onClick={() => {
              setShowCommentsModal(true);
            }}
          >
            <Add />
          </Fab>

          {showCommentsModal && (
            <CommentModal
              open={showCommentsModal}
              onClose={() => setShowCommentsModal(false)}
              uploadComment={async (...args) => {
                // Add an entry
                await uploadComment(...args);
                // Update the rendered list
                setComments(await getComments());
              }}
            />
          )}
        </>
      )}
      {!!deleteConfirmationComment && (
        <ConfirmationDialogue
          open
          id={`confirm-delete-comment-${deleteConfirmationComment.commentId}`}
          title='Delete Comment'
          text='Are you sure you want to delete this comment?'
          onClose={() => openDeleteConfirmation(false)}
          onConfirm={() => {
            openDeleteConfirmation(false);
            deleteComment(deleteConfirmationComment.commentId);
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  font: {
    fontSize: '12px',
  },
  subheader: {
    whiteSpace: 'nowrap',
    minWidth: '160px',
  },
  gridContainer: {
    width: '100%',
    overflowX: 'hidden',
    height: `calc(${document.getElementById('form-container')?.clientHeight}px - ${
      document.getElementById('header-area')?.clientHeight
    }px - ${theme.spacing(6)}px)`,
  },
  addButton: {
    position: 'absolute',
    left: '89%',
    bottom: '5%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    zIndex: 9999,
  },
  commentCard: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    // Not sure why this was here, retaining it just in case.
    // maxWidth: '80%'
  },
  cardContent: {
    display: 'flex',
    width: '100%',
  },
}));
