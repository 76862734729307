import { Box, Button, Checkbox, Grid, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Check as CheckIcon, Edit, Warning as WarningIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ITableColumn, Page, Pagination, Table } from '../../components';
import { ClientType, routes } from '../../constants';
import { ClientSelectorContext } from '../../context';
import {
  getAllIdsForQuery,
  getContactInformationListByClientId,
  getPagedRequirements,
  getThinClient,
} from '../../fetch';
import { formatError } from '../../helpers/errors';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  ASC_SORT,
  DESC_SORT,
  IContactDropdownListItem,
  ILocation,
  IManageRequirements,
  IManageRequirementsFilters,
  IMonitoredEntityType,
  ManageRequirementsSortBy,
  SortOptions,
} from '../../types';
import { IMonitoredEntitySelectableIds } from '../../types/bulkUpdate';
import AddEditContractor from '../manageClients/AddEditContractor';
import AddEditUnit from '../manageClients/AddEditUnit';
import { ManageRequirementsFilters } from './ManageRequirementsFilters';

export const ManageRequirements = () => {
  const classes = useStyles();
  const { clientSelectorContext } = useContext(ClientSelectorContext);
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [initialRequirements, setInitialRequirements] = useState<IManageRequirements[]>([]);
  const [requirementsRecordCount, setRequirementsRecordCount] = useState(0);
  const [filters, setFilters] = useState<IManageRequirementsFilters>({});
  const [showAddEditEntityModal, setShowAddEditEntityModal] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<ManageRequirementsSortBy>(ManageRequirementsSortBy.Name);
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
    Location?: SortOptions;
    Contact?: SortOptions;
    ContactGroup?: SortOptions;
    City?: SortOptions;
    State?: SortOptions;
    ClientCode?: SortOptions;
    Compliance?: SortOptions;
  }>({
    Name: ASC_SORT,
  });
  const [selectedSort, setSelectedSort] = useState<keyof typeof sortDirection>('Name');
  const [contactsEmailFilter, setContactsEmailFilter] = useState('');
  const [contactsIsLoading, setContactsIsLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IContactDropdownListItem[]>([]);
  const { showToast } = useToastContext();
  const [monitoredEntityTypes, setMonitoredEntityTypes] = useState<IMonitoredEntityType[]>([]);
  const clientSelected = !!clientSelectorContext?.clientId;

  const clientEntityName = clientSelectorContext?.clientType;

  const [allIds, setAllIds] = useState<IMonitoredEntitySelectableIds[]>([]);
  const [selectedIds, setSelectedIds] = useState<IMonitoredEntitySelectableIds[]>([]);
  // Hit the paged endpoint
  // eslint-disable-next-line
  const fetchRequirements = async (filtersChanged = false) => {
    if (filtersChanged) {
      setPage(0);
    }
    setLoading(true);
    try {
      const filtersParms = {
        name: filters.monitoredEntityName || undefined,
        unitNumber: filters.unitNumber || undefined,
        location: filters.locationName || undefined,
        contact: filters.contactName || undefined,
        contactGroup: filters.contactGroupName || undefined,
        contactEmail: filters.contactEmail || undefined,
        isCompliant: filters.complianceStatus
          ? filters.complianceStatus === 'Evidence of Requirement Received'
          : undefined,
        isActive: typeof filters.isActive === 'boolean' ? filters.isActive : undefined,
        selectedClientId: clientSelectorContext?.clientId || undefined,
        letterRunDetailsId: filters.letterRunDetailsId || undefined,
      };
      const response = await getPagedRequirements({
        page: page + 1,
        perPage,
        sortBy,
        sortDirection: sortDirection[selectedSort],
        ...filtersParms,
      });

      const allIds = await getAllIdsForQuery(filtersParms);
      setAllIds(allIds);

      setInitialRequirements(response.records);
      setRequirementsRecordCount(response.totalRecordCount);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const [locations, setLocations] = useState<ILocation[]>([]);
  const [useLocations, setUseLocations] = useState<boolean>(false);

  const fetchClient = async () => {
    setLoading(true);
    try {
      const response = await getThinClient(clientSelectorContext?.clientId);

      setLocations(response.locations);
      setUseLocations(response.useLocations);
      if (response.monitoredEntityTypes) {
        const filteredMonitoredEntityTypes = response.monitoredEntityTypes.filter(
          monitoredEntityType => !monitoredEntityType.isDeleted
        );
        setMonitoredEntityTypes(filteredMonitoredEntityTypes);
      }
    } catch (error) {
      formatError(error, showToast);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientSelectorContext?.clientId) {
      fetchClient();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectorContext?.clientId]);

  useEffect(() => {
    fetchRequirements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectorContext?.clientId, page, perPage, selectedSort, sortBy, sortDirection]);

  useEffect(() => {
    fetchRequirements(true);
    setSelectedIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getComplianceCell = status => {
    switch (status) {
      case 'Evidence of Requirement Received':
        return (
          <Tooltip title={'Evidence of Requirement Received'}>
            <CheckIcon className={clsx(classes.icon, classes.checkIcon)} />
          </Tooltip>
        );
      case 'Deficient':
        return (
          <Tooltip title={'Deficient'}>
            <WarningIcon className={clsx(classes.icon, classes.deficientIcon)} />
          </Tooltip>
        );
      default: // Do nothing
    }
  };

  // This function is triggered by setContactsEmailFilter/updateContactsEmailFilter
  // via changes to contactsEmailFilter and the relevant useEffect
  const handleGetContacts = async ({ isInitial }: { isInitial: boolean }) => {
    // If the length is zero, there is no email filter, fetch all contacts
    // If the length is one, the email filter is NOT long enough, do NOT filter
    // If the length is greater than one, the email filter is valid, trigger the search
    if (contactsEmailFilter.length === 1) {
      return;
    }

    // `isInitial` exists to prevent the modal UI from flickering when a search is inputted
    if (isInitial) setContactsIsLoading(true);

    try {
      const records = await getContactInformationListByClientId(
        clientSelectorContext?.clientId,
        contactsEmailFilter || undefined
      );
      setContacts(records);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        const message = err instanceof Error ? err.message : 'Failed to fetch contacts.';
        showToast('error', message);
      }
    } finally {
      setContactsIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetContacts({ isInitial: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectorContext?.clientId, contactsEmailFilter, showToast]);

  const columns = useMemo(() => {
    return [
      {
        id: 'checkbox',
        Header: () => {
          const selectedEntityIds = selectedIds.map(selected => selected.entityId);

          const isChecked = allIds.length > 0 && selectedEntityIds.length === allIds.length;
          return (
            <Checkbox
              defaultChecked={false}
              checked={isChecked}
              indeterminate={selectedEntityIds.length > 0 && selectedEntityIds.length < allIds.length}
              onClick={() => {
                if (selectedEntityIds.length === 0) {
                  setSelectedIds(allIds);
                }
                if (selectedEntityIds.length > 0 && selectedEntityIds.length < allIds.length) {
                  setSelectedIds(allIds);
                }
                if (selectedEntityIds.length === allIds.length) {
                  setSelectedIds([]);
                }
              }}
            />
          );
        },
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IManageRequirements } };
        }) => {
          const selectedEntityIds = selectedIds.map(selected => selected.entityId);
          return (
            <Checkbox
              size='small'
              checked={!!selectedEntityIds.find(id => id === original.monitoredEntityId)}
              onClick={() => {
                if (!!selectedEntityIds.find(id => id === original.monitoredEntityId)) {
                  setSelectedIds(prev => prev.filter(id => id.entityId !== original.monitoredEntityId));
                } else {
                  setSelectedIds(prev => [
                    ...prev,
                    { entityId: original.monitoredEntityId, clientId: original.clientId },
                  ]);
                }
              }}
            />
          );
        },
      },

      {
        id: 'monitoredEntityName',
        accessor: original =>
          original?.unitNumber === null
            ? original?.monitoredEntityName
            : original?.monitoredEntityName + ' - ' + original?.unitNumber ?? '',
        Header: 'Monitored Entity',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Name');
          setSortBy(ManageRequirementsSortBy.Name);
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'locationName',
        Header: 'Location',
        isServerSorted: selectedSort === 'Location',
        isServerSortedDesc: sortDirection.Location === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Location');
          setSortBy(ManageRequirementsSortBy.Location);
          setSortDirection({
            ...sortDirection,
            Location: sortDirection.Location === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'locationStatus',
        Header: 'Location Status',
      },
      {
        accessor: 'contactName',
        Header: 'Contact',
        isServerSorted: selectedSort === 'Contact',
        isServerSortedDesc: sortDirection.Contact === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Contact');
          setSortBy(ManageRequirementsSortBy.Contact);
          setSortDirection({
            ...sortDirection,
            Contact: sortDirection.Contact === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'contactGroupName',
        Header: 'Contact Group',
        isServerSorted: selectedSort === 'ContactGroup',
        isServerSortedDesc: sortDirection.ContactGroup === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('ContactGroup');
          setSortBy(ManageRequirementsSortBy.ContactGroup);
          setSortDirection({
            ...sortDirection,
            ContactGroup: sortDirection.ContactGroup === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'city',
        Header: 'City',
        isServerSorted: selectedSort === 'City',
        isServerSortedDesc: sortDirection.City === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('City');
          setSortBy(ManageRequirementsSortBy.City);
          setSortDirection({
            ...sortDirection,
            City: sortDirection.City === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'state',
        Header: 'State',
        isServerSorted: selectedSort === 'State',
        isServerSortedDesc: sortDirection.State === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('State');
          setSortBy(ManageRequirementsSortBy.State);
          setSortDirection({
            ...sortDirection,
            State: sortDirection.State === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        accessor: 'clientCode',
        Header: 'Client Code',
        isServerSorted: selectedSort === 'ClientCode',
        isServerSortedDesc: sortDirection.ClientCode === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('ClientCode');
          setSortBy(ManageRequirementsSortBy.Client);
          setSortDirection({
            ...sortDirection,
            ClientCode: sortDirection.ClientCode === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        id: 'complianceStatus',
        accessor: original => (original?.complianceStatus ? getComplianceCell(original.complianceStatus) : ''),
        Header: 'Compliance',
        isCentered: true,
        columnAlignment: 'center',
        isServerSorted: selectedSort === 'Compliance',
        isServerSortedDesc: sortDirection.Compliance === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Compliance');
          setSortBy(ManageRequirementsSortBy.Compliance);
          setSortDirection({
            ...sortDirection,
            Compliance: sortDirection.Compliance === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IManageRequirements } };
        }) => {
          return (
            <div key={original.monitoredEntityId} className={classes.gridButtonContainer}>
              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  window.open(`${routes.manageRequirements}/${original.monitoredEntityId}`, '_blank');
                }}
              >
                <Edit />
              </IconButton>
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection, selectedIds, allIds, isLoading]);

  return (
    <Page title='Manage Requirements'>
      {/* FILTER BAR */}
      <Box className={classes.buttons}>
        {clientSelected && (
          <Button
            color={'primary'}
            disabled={!clientSelected}
            onClick={() => setShowAddEditEntityModal(true)}
            className={classes.button}
          >
            {`Add ${clientEntityName === ClientType.Retail ? 'Unit' : 'Contractor'}`}
          </Button>
        )}
        <Button
          color={'primary'}
          disabled={selectedIds.length < 1}
          onClick={() => {
            const selectedClientIds = selectedIds.map(selected => selected.clientId);

            const everySelectedMonitoredEntitiesHaveSameClientId = selectedClientIds.every(
              clientId => clientId === selectedClientIds?.[0]
            );
            if (everySelectedMonitoredEntitiesHaveSameClientId) {
              history.push(`${routes.bulkUpdateRequirements}/monitoredEntityIds`, { selectedIds });
            } else {
              showToast('error', `All selected monitored entities must be for the same client`);
            }
          }}
          className={classes.button}
        >
          Bulk Update {selectedIds.length > 0 ? `(${selectedIds.length})` : ''}
        </Button>
      </Box>
      <Grid container alignItems={'center'} justify={'space-between'}>
        <ManageRequirementsFilters handleFilter={setFilters} isLoading={isLoading} />
      </Grid>

      {/* Table of client requirements */}
      <div className={classes.gridContainer}>
        <Table
          stickyHeader
          hidePagination
          columns={columns}
          data={initialRequirements}
          isLoading={isLoading}
          headerClasses={classes.tableHeader}
          rowClasses={(req: IManageRequirements) => (req.isActive === false ? classes.inactiveRequirement : undefined)}
        />
        {!isLoading && initialRequirements.length > 0 && (
          <Pagination
            page={page}
            count={requirementsRecordCount}
            rowsPerPage={perPage}
            setPage={setPage}
            setRowsPerPage={setPerPage}
          />
        )}
      </div>

      {clientEntityName === ClientType.Retail ? (
        <AddEditUnit
          open={showAddEditEntityModal}
          clientId={clientSelectorContext?.clientId}
          onClose={() => {
            setShowAddEditEntityModal(false);
            fetchRequirements();
          }}
          onSave={entities => {
            setShowAddEditEntityModal(false);
            fetchRequirements();
          }}
          refreshContacts={() => handleGetContacts({ isInitial: false })}
          contactsIsLoading={contactsIsLoading}
          updateContactsEmailFilter={(arg: string) => setContactsEmailFilter(arg)}
          requireUnitCodes={false}
          contacts={contacts}
          clientType={clientEntityName}
          monitoredEntityTypes={monitoredEntityTypes}
          locations={locations}
          useLocations={useLocations}
        />
      ) : (
        <AddEditContractor
          open={showAddEditEntityModal}
          clientId={clientSelectorContext?.clientId}
          onClose={() => {
            setShowAddEditEntityModal(false);
            fetchRequirements();
          }}
          onSave={entities => {
            setShowAddEditEntityModal(false);
            fetchRequirements();
          }}
          refreshContacts={() => handleGetContacts({ isInitial: false })}
          contactsIsLoading={contactsIsLoading}
          updateContactsEmailFilter={(arg: string) => setContactsEmailFilter(arg)}
          requireUnitCodes={false}
          contacts={contacts}
          monitoredEntityTypes={monitoredEntityTypes}
          locations={locations}
          useLocations={useLocations}
        />
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    tableHeader: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      backgroundColor: colors.secondary.catskillWhite,
    },
    gridButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    buttons: {
      marginBottom: theme.spacing(1),
      marginLeft: 'auto',
      marginTop: '-20px',
    },
    button: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(0.5),
      },
    },
    countMessage: {
      marginBottom: theme.spacing(1),
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
    deficientIcon: {
      color: theme.palette.error.main,
    },
    icon: {
      marginLeft: theme.spacing(-0.75),
    },
    inactiveRequirement: {
      '& > *': {
        color: theme.palette.error.light,
      },
    },
  };
});
