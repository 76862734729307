/**
 * ASP .NET generic ProblemDetails
 * https://docs.microsoft.com/en-us/dotnet/api/microsoft.aspnetcore.mvc.problemdetails?view=aspnetcore-6.0
 */
export interface IError {
  Detail: string;
  Extensions: Record<string, unknown>;
  Instance: null;
  Status: number;
  Title: string;
  Type: string;
  BulkProblems?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IError = {
  /**
   * Returns whether or not the given argument is an IError instance.
   * 
   * Narrows/type-guards `value` down to the `IError` type.
   */
  is(value: unknown): value is IError {
    if (typeof value !== 'object') return false;
    if (value === null) return false;

    // prettier-ignore
    const hasProperties =
      'Detail' in value &&
      'Extensions' in value &&
      'Instance' in value &&
      'Status' in value &&
      'Title' in value &&
      'Type' in value;
    return hasProperties;
  }
};
