import { Button, CardActions, Fade, Grid, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import React, { FC, useState } from 'react';

import { Loader, Modal, Select } from '../../components';
import { useClient, } from '../../context';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import { CommentType, IClient, IEnum, IMonitoredEntityComment } from '../../types';

interface ICommentModalProps {
  open: boolean;
  onClose: () => void;
  uploadComment(text: string, type: CommentType): Promise<void>
}

const mockCommentTypeOptions = (entityType: IClient['entityType']): IEnum[] => [
  {
    value: CommentType.ContactGroups,
    description: 'Contact Group',
    text: 'Contact Group',
  },
  {
    value: CommentType.Entities,
    description: entityType.slice(0, entityType.length - 1),
    text: entityType.slice(0, entityType.length - 1),
  },
]

export const CommentModal: FC<ICommentModalProps> = ({
  open,
  onClose,
  uploadComment,
}) => {
  const classes = useStyles();
  const { showToast } = useToastContext();
  const client = useClient();

  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState('');
  // Default comment type in <Select /> based on client type
  const [commentType, setCommentType] = useState(CommentType.Entities);

  return (
    <Modal maxWidth='sm' open={open} title='New Comment' onClose={onClose}>
      {loading && <Loader type='overlay' position='centered' />}
      <Fade in={open}>
        <div>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Select
                displayEmpty
                required
                showReset={false}
                name='commentType'
                id='comment-type-select'
                value={commentType}
                label='Comment Type'
                defaultValue={CommentType.Entities}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (!value) {
                    return;
                  }
                  setCommentType(parseInt(value, 10));
                }}
                renderValue={value => {
                  if (IMonitoredEntityComment.isCommentType(value)) {
                    return IMonitoredEntityComment.displayCommentType(value, client.entityType);
                  }
                  return value;
                }}
                options={mockCommentTypeOptions(client.entityType).map(_ => ({
                  key: _.value,
                  label: _.description,
                  value: _.value,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                required
                autoComplete='nope'
                label='Comment'
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
              />
            </Grid>
          </Grid>
          <CardActions>
            <div className={classes.buttonContainer}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                className={classes.saveButton}
                disabled={!commentText}
                type='button'
                onClick={async function handleSaveComment() {
                  setLoading(true);
                  try {
                    await uploadComment(commentText, commentType);
                    showToast('success', 'Comment added successfully.');
                    onClose();
                  } catch (uploadError) {
                    console.log('upload comment error', uploadError);
                    showToast('error', 'Problem adding comment.');
                  } finally {
                    setLoading(false);
                  }
                }}
                startIcon={<Add />}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </div>
          </CardActions>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(1),
    '& > div': {
      padding: theme.spacing(0.75),
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.primary.navyBlue,
  },
}));
