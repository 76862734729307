import { Typography, Box, Button, SvgIconTypeMap } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface IMultiFileUpload {
  handleFileChange: (val: File[]) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  handleUploadClick?: () => void;
  isLoading?: boolean;
  heading?: string;
  uploadText?: string;
  uploadingText?: string;
  startIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  multiple?: boolean;
}

export const MultiFileUpload: FC<IMultiFileUpload> = ({
  handleUploadClick,
  handleFileChange,
  isLoading,
  isDisabled = false,
  isRequired = false,
  heading,
  multiple = true,
  uploadText = 'Upload File(s)',
  uploadingText = 'Uploading...',
  startIcon = <Publish />,
}) => {
  const classes = useStyles();

  const handleCapture = async (target: EventTarget & HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const allFiles: File[] = Array.from(target.files);
        handleFileChange(allFiles);
      }
    }
  };

  return (
    <div>
      {heading && (
        <Typography variant='h6' className={classes.heading}>
          {heading}
          {isRequired && '*'}
        </Typography>
      )}
      {isRequired && heading && (
        <Typography variant='caption' className={classes.imageHelpText}>
          Uploading an image is required.
        </Typography>
      )}
      <input
        disabled={isDisabled}
        className={classes.input}
        id='icon-button-file'
        type='file'
        // the 'accept' attribute takes the MIME types and only allows users to input files of that type
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        accept={'application/pdf'}
        multiple={multiple}
        onChange={e => {
          handleCapture(e.target);
          // reset the input value so you can re-upload the same photo if you want to
          e.target.value = '';
        }}
      />
      <Box>
        <label htmlFor='icon-button-file'>
          <Button
            onClick={handleUploadClick}
            disabled={isDisabled || isLoading}
            startIcon={startIcon}
            component={'span'}
          >
            {isLoading ? uploadingText : uploadText.toUpperCase()}
          </Button>
        </label>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  imageHelpText: {
    color: theme.palette.error.main,
  },
}));
