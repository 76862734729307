import {
  Box,
  Button,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete, Edit, History } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { format } from 'date-fns';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  ConfirmationDialogue,
  ImportModal,
  ITableColumn,
  Modal,
  PageTitle,
  Pagination,
  Separator,
  Table,
} from '../../components';
import { ClientType, routes, userRoles } from '../../constants';
import { UserContext } from '../../context';
import {
  getClientMonitoredEntities,
  getContactInformationListByClientId,
  importEntities,
  importRequirements,
  updateMonitoredEntity,
} from '../../fetch';
import { useToastContext } from '../../hooks';
import { colors } from '../../styles';
import {
  ASC_SORT,
  DESC_SORT,
  IClient,
  IContactDropdownListItem,
  IError,
  IMonitoredEntity,
  MonitoredEntitySortBy,
  SortOptions,
} from '../../types';
import AddEditContractor from './AddEditContractor';
import AddEditUnit from './AddEditUnit';
import { MonitoredEntityFilters } from './MonitoredEntitiesTableFilters';

interface IMonitoredEntityProps
  extends Required<
    Pick<
      IClient,
      | 'useLocations'
      | 'locations'
      | 'clientType'
      | 'jobLabel'
      | 'showSupplementalCode'
      | 'clientId'
      | 'monitoredEntityTypes'
      | 'requireUnitCodes'
    >
  > {
  defaultOpenEntityId: number;
}

export const MonitoredEntities: FC<IMonitoredEntityProps> = ({
  useLocations,
  showSupplementalCode,
  jobLabel,
  clientType,
  locations,
  defaultOpenEntityId,
  clientId,
  monitoredEntityTypes,
  requireUnitCodes,
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<MonitoredEntitySortBy>(MonitoredEntitySortBy.Name);
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
    Location?: SortOptions;
    ContactName?: SortOptions;
    Status?: SortOptions;
  }>({
    Name: ASC_SORT,
  });
  const [entities, setEntities] = useState<IMonitoredEntity[]>([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isFetchingEntities, setIsFetchingEntities] = useState(false);

  const [showEntityStatusModal, setShowEntityStatusModal] = useState(false);
  const [showConfirmDeleteDialogue, setShowConfirmDeleteDialogue] = useState<boolean>(false);
  const [contactsIsLoading, setContactsIsLoading] = useState<boolean>(false);
  const [monitoredEntitiesIsLoading, setMonitoredEntitiesIsLoading] = useState<boolean>(false);
  const [showAddEditContractorModal, setShowAddEditContractorModal] = useState<boolean>(false);
  const [showAddEditUnitModal, setShowAddEditUnitModal] = useState<boolean>(false);
  const [selectedRowItem, setSelectedRowItem] = useState<IMonitoredEntity | null>(null);
  const [locationIdFilter, setLocationIdFilter] = useState<number>(null);
  const [locationFilterChanged, setLocationFilterChanged] = useState(false);
  const [contacts, setContacts] = useState<IContactDropdownListItem[]>([]);
  const [contactsEmailFilter, setContactsEmailFilter] = useState('');
  const [createDefaultRequirements, setCreateDefaultRequirements] = useState<boolean>(false);
  const [showImportMonitoredEntitiesModal, setShowImportMonitoredEntitiesModal] = useState<boolean>(false);
  const [showImportRequirementsModal, setShowImportRequirementsModal] = useState<boolean>(false);

  const activeMonitoredEntityType = clientType === ClientType.Retail ? 'Units' : 'Contractors';

  const { userContext } = useContext(UserContext);
  const { showToast } = useToastContext();
  const classes = useStyles();

  const fetchEntities = useCallback(async (): Promise<IMonitoredEntity[]> => {
    setIsFetchingEntities(true);
    try {
      if (locationFilterChanged) setPage(0);

      const response = await getClientMonitoredEntities({
        clientId,
        page: page + 1,
        perPage,
        sortBy,
        sortDirection: sortDirection[selectedSort],
        locationIdFilter,
      });
      setEntities(response.records);
      setTotalRecordCount(response.totalRecordCount);

      // Handle opening add/edit entity modal based on entityId param in URL
      if (isInitialRender && defaultOpenEntityId) {
        setIsInitialRender(false);
        const entities: IMonitoredEntity[] = response.records;
        const defaultEntity = entities.find(e => e.entityId === Number(defaultOpenEntityId));
        if (defaultEntity) {
          // This row is the one being edited
          setSelectedRowItem(defaultEntity);
          // Open the proper modal
          if (clientType === ClientType.Retail) setShowAddEditUnitModal(true);
          else if (clientType === ClientType.Contractor) setShowAddEditContractorModal(true);
        }
      }
      return response.records;
    } catch (err) {
      let message = err instanceof Error && err.message;
      showToast('error', message || 'Failed to fetch entities.');
      return [];
    } finally {
      setIsFetchingEntities(false);
      setLocationFilterChanged(false);
    }
  }, [
    clientId,
    clientType,
    defaultOpenEntityId,
    locationIdFilter,
    locationFilterChanged,
    isInitialRender,
    page,
    perPage,
    selectedSort,
    showToast,
    sortBy,
    sortDirection,
  ]);
  // Fetch monitored entities table rows on mount
  useEffect(() => {
    fetchEntities();
    // Ignore showToast dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEntities]);

  // This function is triggered by setContactsEmailFilter/updateContactsEmailFilter
  // via changes to contactsEmailFilter and the relevant useEffect
  const handleGetContacts = useCallback(
    async ({ isInitial }: { isInitial: boolean }) => {
      // If the length is zero, there is no email filter, fetch all contacts
      // If the length is one, the email filter is NOT long enough, do NOT filter
      // If the length is greater than one, the email filter is valid, trigger the search
      if (contactsEmailFilter.length === 1) {
        return;
      }

      // `isInitial` exists to prevent the modal UI from flickering when a search is inputted
      if (isInitial) setContactsIsLoading(true);

      try {
        const records = await getContactInformationListByClientId(clientId, contactsEmailFilter || undefined);
        setContacts(records);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          const message = err instanceof Error ? err.message : 'Failed to fetch contacts.';
          showToast('error', message);
        }
      } finally {
        setContactsIsLoading(false);
      }
    },
    [clientId, contactsEmailFilter, showToast]
  );

  // Handle loading contacts data on search
  useEffect(() => {
    handleGetContacts({ isInitial: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsEmailFilter]);

  // Handle loading initial contacts data on mount
  useEffect(() => {
    handleGetContacts({ isInitial: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = location => {
    setLocationFilterChanged(true);
    if (location) {
      setLocationIdFilter(location.locationId);
    } else {
      setLocationIdFilter(null);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: 'Name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Name');
          setSortBy(MonitoredEntitySortBy.Name);
          setSortDirection({
            ...sortDirection,
            Name: sortDirection.Name === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      ...(useLocations
        ? [
            {
              id: 'locationName',
              Header: 'Location',
              isServerSorted: selectedSort === 'Location',
              isServerSortedDesc: sortDirection.Location === DESC_SORT,
              handleClickColumn: () => {
                setPage(0);
                setSelectedSort('Location');
                setSortBy(MonitoredEntitySortBy.Location);
                setSortDirection({
                  ...sortDirection,
                  Location: sortDirection.Location === ASC_SORT ? DESC_SORT : ASC_SORT,
                });
              },
              accessor: (monitoredEntity: IMonitoredEntity) => {
                if (monitoredEntity.locationId) {
                  const location = locations.find(location => location.locationId === monitoredEntity.locationId);
                  if (!location) return '';
                  return location?.name;
                } else {
                  return '';
                }
              },
            },
            {
              id: 'locationStatus',
              Header: 'Location Status',
              accessor: 'locationStatus',
            },
          ]
        : []),
      {
        id: 'contactName',
        Header: 'Contact Name',
        accessor: (monitoredEntity: IMonitoredEntity) => (monitoredEntity ? monitoredEntity.contactName : ''),
        isServerSorted: selectedSort === 'ContactName',
        isServerSortedDesc: sortDirection.ContactName === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('ContactName');
          setSortBy(MonitoredEntitySortBy.ContactName);
          setSortDirection({
            ...sortDirection,
            ContactName: sortDirection.ContactName === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
      },
      {
        id: 'isActive',
        isServerSorted: selectedSort === 'Status',
        isServerSortedDesc: sortDirection.Status === DESC_SORT,
        handleClickColumn: () => {
          setPage(0);
          setSelectedSort('Status');
          setSortBy(MonitoredEntitySortBy.Status);
          setSortDirection({
            ...sortDirection,
            Status: sortDirection.Status === ASC_SORT ? DESC_SORT : ASC_SORT,
          });
        },
        accessor: (entity: IMonitoredEntity) => {
          return (
            <div>
              {/* SHOW HISTORY BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setShowEntityStatusModal(true);
                  setSelectedRowItem(entity);
                }}
              >
                <History />
              </IconButton>
              {entity.isActive ? 'Active' : 'Inactive'}
            </div>
          );
        },
        Header: 'Status',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMonitoredEntity } };
        }) => {
          return (
            <div key={original.entityId} className={classes.gridButtonContainer}>
              <IconButton
                color='primary'
                size='small'
                onClick={() => window.open(`${routes.manageRequirements}/${original.entityId}`, '_blank')}
              >
                <AssignmentIcon />
              </IconButton>

              {/* EDIT BUTTON */}
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedRowItem(original);
                  clientType === ClientType.Retail
                    ? setShowAddEditUnitModal(true)
                    : setShowAddEditContractorModal(true);
                }}
              >
                <Edit />
              </IconButton>

              {/* DELETE BUTTON */}
              {userContext.role === userRoles.ADMIN && (
                <IconButton
                  color='secondary'
                  size='small'
                  onClick={() => {
                    setSelectedRowItem(original);
                    setShowConfirmDeleteDialogue(true);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ].filter(Boolean) as ITableColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  const getTableData = () => {
    if (!entities.length) {
      return [];
    }

    // Filter out the entities that are flagged as deleted
    return entities.filter(entity => !entity.isDeleted);
  };

  const handleShowAddEditModal = () => {
    clientType === ClientType.Retail ? setShowAddEditUnitModal(true) : setShowAddEditContractorModal(true);
  };

  return (
    <div>
      <div className={classes.header}>
        <PageTitle title={activeMonitoredEntityType} marginOff />
        {useLocations && locations.length > 0 && (
          <MonitoredEntityFilters handleFilter={handleFilter} locations={locations} />
        )}

        {userContext.role === userRoles.ADMIN && (
          <>
            {/* IMPORT MONITORED ENTITY BUTTON */}
            <Button color='primary' size='small' onClick={() => setShowImportMonitoredEntitiesModal(true)}>
              Import {activeMonitoredEntityType}
            </Button>

            <Separator />

            {/* IMPORT REQUIREMENTS BUTTON */}
            <Button color='primary' size='small' onClick={() => setShowImportRequirementsModal(true)}>
              Import Requirements
            </Button>
          </>
        )}
      </div>

      {/* GRID */}
      <div className={classes.gridContainer}>
        <Table
          isLoading={isFetchingEntities}
          loadingPageSize={!Array.isArray(entities) || entities.length === 0 ? 3 : entities.length}
          key={entities.length ? JSON.stringify(entities.filter(entity => !entity.isDeleted)) : ''}
          columns={columns}
          data={getTableData()}
          headerClasses={classes.tableHeader}
          hidePagination
          stickyHeader
        />
        {!isFetchingEntities && entities.length > 0 && (
          <Pagination
            page={page}
            count={totalRecordCount}
            rowsPerPage={perPage}
            setPage={setPage}
            setRowsPerPage={setPerPage}
          />
        )}
      </div>

      {/* ADD NEW BUTTON */}
      {useLocations && locations.filter(x => !x.isDeleted).length === 0 ? (
        <Tooltip title='Must add at least one location first!'>
          <Fab
            className={locations.filter(x => !x.isDeleted).length === 0 ? classes.disabledAddButton : classes.addButton}
            onClick={locations.filter(x => !x.isDeleted).length === 0 ? () => {} : () => handleShowAddEditModal()}
          >
            <Add />
          </Fab>
        </Tooltip>
      ) : (
        <Fab className={classes.addButton} onClick={() => handleShowAddEditModal()}>
          <Add />
        </Fab>
      )}

      {/* IMPORT MONITORED ENTITIES */}
      <ImportModal
        isLoading={monitoredEntitiesIsLoading}
        title={`Import ${activeMonitoredEntityType}`}
        onClose={() => {
          setShowImportMonitoredEntitiesModal(false);
          setCreateDefaultRequirements(false);
        }}
        open={showImportMonitoredEntitiesModal}
        allowedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        handleImport={file => {
          setMonitoredEntitiesIsLoading(true);
          importEntities(clientId, file, createDefaultRequirements)
            .then(async response => {
              const tableFetch = await getClientMonitoredEntities({
                clientId,
                page: 1,
                perPage,
                sortBy,
                sortDirection: sortDirection[selectedSort],
                locationIdFilter,
              });
              setEntities(tableFetch.records);
              showToast('success', `Successfully imported the ${activeMonitoredEntityType}!`, null, true);
            })
            .catch(error => {
              let message = `There was a problem importing the ${activeMonitoredEntityType}. Please try again.`;
              if (IError.is(error)) {
                const bulk = error?.BulkProblems?.filter(str => !!str)?.join(' ') ?? '';
                message = `Error status ${error.Status}. ${error.Title}. ${error.Detail} ${bulk}`;
              }
              showToast('error', message, null, true);
            })
            .finally(() => {
              setShowImportMonitoredEntitiesModal(false);
              setMonitoredEntitiesIsLoading(false);
            });
        }}
      >
        <div className={classes.toggle}>
          <FormGroup>
            <FormControlLabel
              label='Create Default Requirements'
              control={
                <Switch
                  checked={createDefaultRequirements}
                  onChange={() => setCreateDefaultRequirements(!createDefaultRequirements)}
                />
              }
            />
          </FormGroup>
        </div>
      </ImportModal>

      {/* IMPORT REQUIREMENTS */}
      <ImportModal
        isLoading={monitoredEntitiesIsLoading}
        title='Import Requirements'
        onClose={() => setShowImportRequirementsModal(false)}
        open={showImportRequirementsModal}
        allowedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        handleImport={file => {
          setMonitoredEntitiesIsLoading(true);
          importRequirements(clientId, file)
            .then(() => {
              showToast('success', 'File pre-validation checks successful. Background Import process beginning, please check logs for any import file errors', null, true);
            })
            .catch(error => {
              let message = 'There was a problem importing the requirements. Please try again.';
              if (IError.is(error)) {
                const bulk = error?.BulkProblems?.filter(str => !!str)?.join(' ') ?? '';
                message = `Error status ${error.Status}. ${error.Title}. ${error.Detail} ${bulk}`;
              }
              showToast('error', message, null, true);
            })
            .finally(() => {
              setShowImportRequirementsModal(false);
              setMonitoredEntitiesIsLoading(false);
            });
        }}
      />

      {/* ADD/EDIT CONTRACTOR MODAL */}
      {showAddEditContractorModal && (
        <AddEditContractor
          open
          jobLabel={jobLabel}
          locations={locations}
          clientType={clientType}
          useLocations={useLocations}
          showSupplementalCode={showSupplementalCode}
          contacts={contacts}
          initialValues={selectedRowItem}
          showToast={showToast}
          clientId={clientId}
          onClose={() => {
            setSelectedRowItem(null);
            setShowAddEditContractorModal(false);
          }}
          onSave={entities => {
            setEntities(entities);
            setSelectedRowItem(null);
            setShowAddEditContractorModal(false);
          }}
          monitoredEntityTypes={monitoredEntityTypes}
          refreshContacts={() => handleGetContacts({ isInitial: false })}
          contactsIsLoading={contactsIsLoading}
          updateContactsEmailFilter={(arg: string) => setContactsEmailFilter(arg)}
          requireUnitCodes={requireUnitCodes}
          fetchEntities={fetchEntities}
        />
      )}

      {/* ADD/EDIT UNIT MODAL */}
      {showAddEditUnitModal && (
        <AddEditUnit
          open
          jobLabel={jobLabel}
          locations={locations}
          contacts={contacts}
          useLocations={useLocations}
          showSupplementalCode={showSupplementalCode}
          clientType={clientType}
          initialValues={selectedRowItem}
          showToast={showToast}
          clientId={clientId}
          onClose={() => {
            setSelectedRowItem(null);
            setShowAddEditUnitModal(false);
          }}
          onSave={entities => {
            setEntities(entities);
            setSelectedRowItem(null);
            setShowAddEditUnitModal(false);
          }}
          monitoredEntityTypes={monitoredEntityTypes}
          refreshContacts={() => handleGetContacts({ isInitial: false })}
          contactsIsLoading={contactsIsLoading}
          updateContactsEmailFilter={(arg: string) => setContactsEmailFilter(arg)}
          requireUnitCodes={requireUnitCodes}
          fetchEntities={fetchEntities}
        />
      )}

      {/* CONFIRM DELETE MODAL */}
      {selectedRowItem && (
        <ConfirmationDialogue
          id='confirm-entity-delete'
          title='Delete Monitored Entity'
          text='Are you sure you want to delete this monitored entity?'
          open={showConfirmDeleteDialogue}
          onClose={() => {
            setShowConfirmDeleteDialogue(false);
            setSelectedRowItem(null);
          }}
          onConfirm={async () => {
            const updated = {
              ...selectedRowItem,
              isDeleted: true,
            };

            try {
              await updateMonitoredEntity(updated);
              const entities = await fetchEntities();
              setEntities(entities);
              setShowConfirmDeleteDialogue(false);
              setSelectedRowItem(null);
            } catch (error) {
              showToast('error', 'There was an error deleting the entity. Please try again.');
            }
          }}
        />
      )}

      {/** ENTITY STATUS MODAL */}
      <Modal
        fullWidth={false}
        maxWidth={'sm'}
        open={showEntityStatusModal && !!selectedRowItem}
        title='Entity Status History'
        onClose={() => setShowEntityStatusModal(false)}
      >
        <TableContainer>
          <MUITable size='small' aria-label='entity-status-history'>
            <TableBody>
              {!!selectedRowItem?.statuses?.length ? (
                selectedRowItem.statuses.map(({ statusText, effectiveDate, userDisplayName }, i) => (
                  <TableRow key={`${statusText}-${effectiveDate}-${userDisplayName}-${i}`}>
                    <TableCell align='left'>{statusText}</TableCell>
                    <TableCell align='left'>
                      {!!effectiveDate && format(new Date(effectiveDate), "M/d/yyyy ' at ' h:mma")}
                    </TableCell>
                    <TableCell align='left'>{userDisplayName || ''}</TableCell>
                  </TableRow>
                ))
              ) : (
                <Box display='flex' justifyContent='center'>
                  <Typography color='textSecondary' variant='body1'>
                    No Results.
                  </Typography>
                </Box>
              )}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    disabledAddButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: '#e0e0e0',
      color: '#a6a6a6',
      zIndex: 9999,
      boxShadow: 'none',
      cursor: 'default',
    },
    addButton: {
      float: 'right',
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      zIndex: 9999,
    },
    tableHeader: {
      fontWeight: 600,
      color: colors.primary.accentRed,
      backgroundColor: colors.secondary.catskillWhite,
    },
    button: {
      color: colors.primary.navyBlue,
      fontWeight: 600,
    },
    gridButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    toggle: {
      marginTop: theme.spacing(1),
    },
  };
});
