import React, { FC, useState, useEffect } from 'react';
import { FormControl } from '@material-ui/core';
// components
import { Select } from '../../../components';
// types
import { IEntityRequirement } from '../../../types';

const coverageTypes = [
  { value: 1, label: 'Amount' },
  { value: 2, label: 'Evidence' },
  { value: 3, label: 'Excess' }
];

interface ICoverageTypeCellProps {
  cell: { row: any };
  row: { index: number };
  column: { id: string };
  editing: boolean;
  updateData: (columnId: string, value: any, original: IEntityRequirement) => void;
}

export const CoverageTypeCell: FC<ICoverageTypeCellProps> = ({
  cell: { row: original },
  cell,
  row: { index },
  column: { id },
  editing,
  updateData
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(original.original.coverageType);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(original.original.coverageType);
  }, [original.original.coverageType]);

  return (
    editing ? (
      <FormControl fullWidth>
        <Select
          showReset={false}
          name='coverageType'
          id='coverage-type-select'
          value={value}
          options={coverageTypes.map((c, i) => ({ value: c.value, label: c.label, key: i}))}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setValue(event.target.value ? event.target.value : null)}
          onBlur={() => updateData('coverageType', value ? value : null, original.original)}
        />
      </FormControl>
    ) : (
      <div>{coverageTypes.find(c => c.value === original.original.coverageType) ? coverageTypes.find(c => c.value === original.original.coverageType).label : null}</div>
    )
  )
};
