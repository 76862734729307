import { Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { Loader, Table, UnsavedChanges } from '../../components';
import { colors } from '../../styles';
import { IBlanketCertificateDefaultRequirement, IEnum } from '../../types';
import { IBulkDataDefaultRequirement } from '../../types/bulkUpdate';
import {
  ActualAmountCell,
  CarrierCell,
  CoverageTypeCell,
  DocumentDateCell,
  ExpirationDateCell,
} from '../manageRequirements/bulkUpdateTableCells';

interface IFulfilledForm {
  // This is the props.location.state.<t> that gets passed from React Router
  clientId: number;
  carriers: Omit<IEnum, 'description'>[];
  isLoading: boolean;
  isSaving: boolean;
  entitiesAreDirty: boolean;
  requirements: IBlanketCertificateDefaultRequirement[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  submit: () => void;
  dirty: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  selectedRequirements: number[];
  setSelectedRequirements: React.Dispatch<React.SetStateAction<number[]>>;
}

export const Fulfilled: FC<IFulfilledForm> = ({
  carriers,
  isLoading,
  requirements,
  setFieldValue,
  values,
  submit,
  dirty,
  isSubmitting,
  selectedRequirements,
  setSelectedRequirements,
}) => {
  /** Modal is closed when false. When not false, modal is open and depends on the provided data. */

  const classes = useStyles();

  const updateData = (
    field: string,
    newValue: any,
    original: IBulkDataDefaultRequirement,
    values: any,
    setFieldValue,
    secondField?: string,
    secondNewValue?: any
  ) => {
    let updatedValues = [...values.requirements];

    const reqIndexToUpdate = updatedValues.findIndex(v => v.defaultRequirementId === original.defaultRequirementId);
    updatedValues[reqIndexToUpdate] = {
      ...updatedValues[reqIndexToUpdate],
      [`${field}`]: newValue,
    };

    if (secondField && secondNewValue) {
      updatedValues[reqIndexToUpdate] = {
        ...updatedValues[reqIndexToUpdate],
        [`${secondField}`]: secondNewValue,
      };
    }

    // Display the uniquely changed rows
    setFieldValue('requirements', updatedValues);
  };

  const valuesAreNotValid = values => {
    if (selectedRequirements.length > 0) {
      // find requirement
      const required = values.requirements.filter(req => selectedRequirements.includes(req.defaultRequirementId));

      const shouldBeDisabled = required.reduce((prev: boolean, curr: IBulkDataDefaultRequirement) => {
        return (
          prev ||
          !curr.carrierId ||
          !curr.carrierName ||
          !curr.documentDate ||
          !curr.expirationDate ||
          (!curr.actualAmount && (curr.coverageType === 1 || curr.coverageType === 3))
        );
      }, false);

      return shouldBeDisabled;
    }
    return false;
  };

  const bulkUpdateColumns = useMemo(() => {
    return [
      {
        id: 'checkbox',
        Header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBulkDataDefaultRequirement } };
        }) => {
          // eslint-disable-next-line
          {
            /* MONITORED ENTITY CHECKBOX FOR BULK UPDATE */
          }
          return (
            <Checkbox
              size='small'
              checked={original.applyRequirementChanges}
              onClick={() => {
                const requirementsCopy = JSON.parse(JSON.stringify(values.requirements));

                const updateIndex = requirementsCopy.findIndex(
                  req => req.defaultRequirementId === original.defaultRequirementId
                );

                requirementsCopy[updateIndex].applyRequirementChanges = !original.applyRequirementChanges;

                if (!selectedRequirements.includes(original.defaultRequirementId)) {
                  setSelectedRequirements(prev => [...prev, original.defaultRequirementId]);
                }

                if (
                  selectedRequirements.includes(original.defaultRequirementId) &&
                  !requirementsCopy[updateIndex].applyRequirementChanges &&
                  !original.applyCredit
                ) {
                  setSelectedRequirements(prev => prev.filter(req => req !== original.defaultRequirementId));
                }
                setFieldValue('requirements', requirementsCopy);
              }}
            />
          );
        },
      },
      {
        accessor: 'lineOfCoverageName',
        Header: 'Line of Coverage',
      },
      {
        Header: 'Type',
        id: 'coverageType',
        overrideWidth: 150,
        Cell: CoverageTypeCell,
      },
      {
        Header: 'Actual Fulfilled',
        overrideWidth: 165,
        Cell: ActualAmountCell,
      },
      {
        Header: 'Expiration',
        overrideWidth: 165,
        Cell: ExpirationDateCell,
      },
      {
        Header: 'Document Date',
        overrideWidth: 165,
        Cell: DocumentDateCell,
      },
      {
        accessor: 'carrier',
        Header: 'Carrier',
        overrideWidth: 185,
        Cell: CarrierCell,
      },
      {
        id: 'apply-credit',
        Header: 'Apply Credit for All Additional Insured',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IBulkDataDefaultRequirement } };
        }) => {
          // eslint-disable-next-line
          {
            /* APPLY CREDIT CHECKBOX FOR BULK UPDATE */
          }
          return (
            <Checkbox
              className={classes.checkboxContainer}
              size='small'
              checked={original.applyCredit}
              onClick={() => {
                const requirementsCopy = JSON.parse(JSON.stringify(values.requirements));

                const updateIndex = requirementsCopy.findIndex(
                  req => req.defaultRequirementId === original.defaultRequirementId
                );

                requirementsCopy[updateIndex].applyCredit = !original.applyCredit;

                if (selectedRequirements.includes(original.defaultRequirementId) && !original.applyRequirementChanges) {
                  setSelectedRequirements(prev => prev.filter(req => req !== original.defaultRequirementId));
                } else {
                  setSelectedRequirements(prev => [...prev, original.defaultRequirementId]);
                }

                setFieldValue('requirements', requirementsCopy);
              }}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirements, values.requirements]);

  const updateSelectedRequirements = row => {
    const requirementsCopy = JSON.parse(JSON.stringify(values.requirements));

    const updateIndex = requirementsCopy.findIndex(req => req.defaultRequirementId === row.defaultRequirementId);

    requirementsCopy[updateIndex].applyRequirementChanges = true;

    if (!selectedRequirements.includes(row.defaultRequirementId)) {
      setSelectedRequirements(prev => [...prev, row.defaultRequirementId]);
    }

    setFieldValue('requirements', requirementsCopy);
  };
  const [touched, setTouched] = useState(false);

  return (
    <>
      {isLoading || !requirements ? (
        <Loader type='overlay' position='centered' />
      ) : (
        <div>
          <UnsavedChanges dirty={dirty && !isSubmitting} />
          <div className={classes.headerContainer}></div>
          {/* FORM */}
          {isSubmitting && <Loader type='overlay' position='centered' title={'Saving...'} />}
          <Form autoComplete='none'>
            {/* BULK UPDATE GRID */}
            <div className={classes.locGrid}>
              <Table
                isLoading={isLoading || !values.requirements}
                useTableProps={{
                  requirements,
                  setSelectedRequirements,
                  selectedRequirements,
                  updateData,
                  carriers,
                  values,
                  setFieldValue,
                  touched,
                  setTouched,
                  valuesAreNotValid,
                  updateSelectedRequirements,
                }}
                loadingPageSize={
                  !Array.isArray(values.requirements) || values.requirements.length === 0
                    ? 3
                    : values.requirements.length
                }
                columns={bulkUpdateColumns}
                data={values.requirements}
                headerClasses={classes.tableHeader}
                stickyHeader
                hidePagination
              />
            </div>
          </Form>
          {/* ADDITIONAL INSURED GRID */}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  backButton: {
    '& svg': {
      paddingLeft: '8px',
      height: '2rem',
      width: '2rem',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  progressBar: {
    height: theme.spacing(2),
    width: '40%',
    marginLeft: theme.spacing(1),
    borderRadius: '0.8rem',
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  tableHeader: {
    fontWeight: 600,
    color: colors.primary.accentRed,
    backgroundColor: colors.secondary.catskillWhite,
  },
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  entitySelectorContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },
  locGrid: {
    yOverflow: 'scroll',
  },
  checkboxContainer: {
    display: 'flex',
    marginRight: theme.spacing(4),
  },
}));
