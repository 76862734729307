import { authFetch } from './';
import { IEmbedParams, IReportSecurityType } from '../types';

const baseUrl = '/api/PowerBI';

export const getPowerBIReport = async (reportId: string, reportSecurityType: IReportSecurityType): Promise<IEmbedParams> => {
  try {
    const res = await authFetch.get(`${baseUrl}/?reportSecurityType=${reportSecurityType}&reportId=${reportId}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting report embedded configuration information. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
