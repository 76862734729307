import { authFetch } from './';
import { IClientSelector, IError, IReport, IEnum } from '../types';

const baseUrl = '/api/Report';

export const getReport = async (id: number): Promise<IReport> => {
  try {
    const res = await authFetch.get(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error getting report. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const deleteReport = async (id: number): Promise<boolean | IError> => {
  try {
    const res = await authFetch.delete(`${baseUrl}/${id}`);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error deleting report. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateReport = async (report: IReport): Promise<boolean | IError> => {
  try {
    const res = await authFetch.put(`${baseUrl}`, report);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error updating report. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createReport = async (report: IReport): Promise<boolean | IError> => {
  try {
    const res = await authFetch.post(`${baseUrl}/Create`, report);

    if (res && res.data) {
      return res.data;
    }
    const error = { Detail: 'Error creating report. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getReports = async (selectedClient: IClientSelector): Promise<IReport[]> => {
  let subRoute = 'List';
  // If a selection has been made from the client selector, add query params to filter results
  if (typeof selectedClient !== 'undefined' && selectedClient) {
    subRoute = subRoute + `?SelectedClientId=${selectedClient.clientId}&ClientType=${selectedClient.clientType}`;
  }

  try {
    const res = await authFetch.get(`${baseUrl}/${subRoute}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getReportSecurityTypes = async (): Promise<IEnum[]> => {
  try {

    const { data } = await authFetch.get(`${baseUrl}/ReportSecurityTypes`);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
