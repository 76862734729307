import {
  IDeficiencies,
  ILetterGenerationProgress,
  ILetterRun,
  ILetterRunHistory,
  INotificationStatus,
  IOnDemandSummary,
  IOnDemandSummaryNotification,
  IResponse,
} from '../types';
import { authFetch } from './fetch';
import { IHttpPostMessageResponse } from 'http-post-message';
import axios, { AxiosResponse } from 'axios';

const baseUrl = '/api/LetterRun';

/**
 * Send the on demand summary.
 */
export const postOnDemandSummaryNotifications = async (
  data: IOnDemandSummaryNotification
): Promise<IOnDemandSummaryNotification> => {
  try {
    const response: AxiosResponse<IOnDemandSummaryNotification> = await authFetch.post(
      `${baseUrl}/SummaryNotification`,
      data
    );

    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const getLetterRunHistory = async (): Promise<ILetterRunHistory[]> => {
  try {
    const response = await authFetch.get(`${baseUrl}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the letter run history. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClientDeficiencies = async (clientId: number, certificateHolderIds?: number[]): Promise<IDeficiencies> => {
  try {
    let url = `${baseUrl}/DeficientEntities/Client/${clientId}`;

    const response = await authFetch.post(url, { clientId, certificateHolderIds });

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the client deficiencies. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getContactDeficiencies = async (contactId: number): Promise<IDeficiencies> => {
  try {
    const response = await authFetch.get(`${baseUrl}/DeficientEntities/Contact/${contactId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the contact deficiencies. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateLetterRun = async (
  clientId?: number,
  contactId?: number,
  letterRunSource?: number
): Promise<number> => {
  try {
    let url = `${baseUrl}/UpdateLetterRun`;

    const res = await authFetch.post(url, {
      clientId: clientId ?? null,
      contactId: contactId ?? null,
      letterRunSource: letterRunSource ?? null,
    });

    return res.data;

  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getOnDemandSummary = async (
  letterRunId: number,
  contactGroupId: number | null
): Promise<IOnDemandSummary> => {
  try {
    let url = `${baseUrl}/Summary/${letterRunId}`;
    if (contactGroupId) {
      url += `?contactGroupId=${contactGroupId}`;
    }

    const response = await authFetch.get(url);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the on demand summary. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const createOnDemandSummary = async (
  clientId: number,
  contactGroupId: number,
  monitoredEntityId: number,
  letterRunId: number
): Promise<IHttpPostMessageResponse<any>> => {
  try {
    let url = `${baseUrl}/Summary`;

    const res = await authFetch.post(url, {
      clientId,
      contactGroupId,
      monitoredEntityId,
      letterRunId,
    });

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const updateLetterRunDetails = async (
  letterRunId: number,
  monitoredEntityIds?: number[],
  contactGroupIds?: number[]
): Promise<IHttpPostMessageResponse<any>> => {
  try {
    let url = `${baseUrl}/UpdateLetterRunDetails`;

    await authFetch.post(url, {
      monitoredEntityIds: monitoredEntityIds ? monitoredEntityIds : null,
      contactGroupIds: contactGroupIds ? contactGroupIds : null,
      letterRunId: letterRunId,
    });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getLetterRunGenerationProgress = async (letterRunId: number): Promise<ILetterGenerationProgress> => {
  try {
    const response = await authFetch.get(`${baseUrl}/Status/${letterRunId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the letter run generation progress. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error);
  }
};

export const getLetterRun = async (letterRunId: number): Promise<ILetterRun> => {
  try {
    const response = await authFetch.get(`${baseUrl}/${letterRunId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the generated letters. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const initiateLetterRun = async (letterRunId: number[]): Promise<number> => {
  try {
    await authFetch.post(`${baseUrl}/UpdateLetterRunNotifications`, { letterRunId });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const initiateFailedLetterRun = async (letterRunId: number): Promise<number> => {
  try {
    await authFetch.post(`${baseUrl}/RetryFailedNotifications`, { letterRunId });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getSendingEmailProgress = async (letterRunId: number): Promise<INotificationStatus> => {
  try {
    const response = await authFetch.get(`${baseUrl}/NotificationStatus/${letterRunId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the sent email progress. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

// @ts-ignore
export const getMasterMailPdf = async (letterRunId: number): Promise<Blob> => {
  try {
    const response = await authFetch.getBlob(`${baseUrl}/CompileLettersForSending/${letterRunId}`);

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the master mail list. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const requeueFailed = async (letterRunId: number): Promise<any> => {
  try {
    await authFetch.post(`${baseUrl}/RequeueFailed`, { letterRunId });
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const ODSPrecheck = async (clientId: number, contactGroupId: number, monitoredEntityId: number): Promise<{isValid: boolean; problems: string[]}> => {
  try {
    const response = await authFetch.get(`${baseUrl}/OdsPrecheck`, {params: { clientId, contactGroupId, monitoredEntityId} });
    return response.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getLetterRunPaged = async (filters?: {
  sourceType: number;
  clientName: string;
  sortBy: number;
  sortDirection: number;
  page?: number;
  perPage?: number;
}): Promise<IResponse<ILetterRunHistory[]>> => {
  try {
    const response = await authFetch.get(`${baseUrl}/paged`,{params: filters} );

    return response.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const getClientContactLetters = async (clientId: number, contactGroupIds?: number[]): Promise<any> => {
  try {
    let url = `${baseUrl}/client-contact/${clientId}`;

    const response = await authFetch.post(url, { clientId, contactGroupIds });

    if (response && response.data) {
      return response.data;
    }
    const error = { Detail: 'Error getting the client contacts. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};
