import React from 'react';
import { makeStyles, Theme, } from '@material-ui/core/styles';
import { Logo } from './Logo';

export const LoginHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loginContain}>
      <Logo />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    loginContain: {
      paddingLeft: '15px',
      alignItems: 'center',
      backgroundColor: '#002855',
      display: 'flex',
      height: '62px',
      color: theme.palette.common.white
    }
  };
});
