import { format, parseISO } from 'date-fns';

export const formatInputPhoneNumber = (val: string | undefined) => {
  let number = val?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // taken from here, https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  return number && (!number[2] ? number[1] : number[1] + '-' + number[2] + (number[3] ? '-' + number[3] : ''));
};

export const phoneRegExp = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
export const passwordRegex = new RegExp(
  /^(?=.*[A-Z])(?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])\S{6,20}$/
);
export const numbersOnlyRegExp = /^[0-9\b]+$/;

export const convertToUTC = (dateString: string) => {
  if (dateString.substr(dateString.length - 1) === 'Z') {
    return dateString;
  }

  return dateString + 'Z';
};

export const dateFormatter = (val: string | number | null | undefined) => {
  if (val === null || val === undefined) {
    return val;
  }

  // allow for shortcuts
  let today = new Date();
  let newVal = new Date();

  if (!isNaN(val as number)) {
    // convert dates like 0428 to 04/28/YYYY
    if (val.toString().length === 4) {
      const month = val.toString().slice(0, 2);
      const day = val.toString().slice(2, 4);
      val = `${month}/${day}/${today.getFullYear()}`;
    } else {
      val = newVal.setDate(today.getDate() + Number(val));
    }
  } else if ((val as string).split('/').length === 2) {
    // apply a year since we can assume it wasn't provided
    today.getFullYear();
    val = new Date(val).setFullYear(today.getFullYear());
  }

  return format(new Date(val), 'MM/dd/yyyy');
};

// Calling val.toFixed(2) drops decimals down and creates a string representation of the number. If we then parse out the
// string into a float and call .toString() on that we can remove any 0s padded on at the end. I.e. '16.00` becomes '16'.
// Take that final string value and parse it back to a float and return.
export const trimDecimals = (val, decimals = 2) => parseFloat(parseFloat(val.toFixed(decimals)).toString());

// format percent and ensure at least 1 decimal place even if it is .0%
export const formatPercent = val => {
  let percent = `${trimDecimals(parseFloat((val * 100).toString()))}`;
  if (percent.indexOf('.') === -1) {
    return `${percent}.0%`;
  }
  return `${percent}%`;
};

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDateWithTime = (date: Date | number | string): string => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yyyy h:mma');
  }
  return null;
};

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDate = (date: Date | number | string): string => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yyyy');
  }
  return null;
};
